import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./index.scss";
import MoreProduct from "../MoreProduct";
import ProductItem from "../ProductItem";
import ProductItem2 from "../ProductItemNew";

type productProps = {
  productData: any;
  jobNumber: any;
  listPageLink: any;
};
const ProductList: React.FC<productProps> = (props: any) => {
  const { productData, jobNumber, listPageLink } = props;
  const [data, setData] = useState([]);
  const [type, setType] = useState(0);
  const [kind, setKind] = useState(0);
  const [group, setGroup] = useState(0); //一级分类
  const [subList, setSubList] = useState<any[]>([]); //二级分类
  const [secondList, setSecondList] = useState([]); //三级分类
  const [prokind, setProkind] = useState<any>([]);

  //类别的选择事件
  const handleTypeChoice = (num: number) => {
    setGroup(0);
    setKind(0);
    setType(num);
    const selectedType = productData[num];
    if (selectedType && selectedType.children) {
      console.log(productData, selectedType.children);
      setSubList(selectedType.children);
      // 默认选择第一项险种
      handleKindChoice(0);
    } else {
      setSubList([]);
      setSecondList([]);
      setProkind([]);
    }
  };

  // 险种的选择事件;
  const handleKindChoice = (num: number) => {
    setKind(num);
    setGroup(0); // 重置人群分类选择
    const selectedKind = subList[num];
    console.log(subList, subList[num]);
    if (selectedKind && selectedKind.children) {
      console.log(22);
      setSecondList(selectedKind.children);
      // 默认选择第一项人群分类
      handleGroupChoice(0);
    } else if (selectedKind && selectedKind.productions) {
      setSecondList([]);
      setProkind(selectedKind.productions);
    } else {
      setSecondList([]);
      setProkind([]);
    }
  };

  const handleGroupChoice = (num: number) => {
    setGroup(num);
    // 如果有产品列表，设置prokind状态
    const selectedGroup = secondList[num];
    if (selectedGroup && selectedGroup.productions) {
      setProkind(selectedGroup.productions);
    } else {
      setProkind([]);
    }
  };

  useEffect(() => {
    if (secondList.length > 0) {
      handleGroupChoice(0);
    }
  }, [secondList]);

  useEffect(() => {
    if (subList.length > 0) {
      handleKindChoice(0);
    }
  }, [subList]);

  useEffect(() => {
    setData(productData);
    // 默认选择第一项类别
    if (productData.length > 0) {
      handleTypeChoice(0);
    }
  }, [productData]);

  return (
    <div className="productList">
      <div className="insure_kind">
        <div className="kind_one">
          <div className="kind_name">按类别&nbsp;:</div>
          <div className="kind_value">
            {data.map((item: any, index: any) => (
              <span
                key={index}
                className={index === type ? "_name_item" : "name_item"}
                onClick={() => handleTypeChoice(index)}
              >
                {item.categoryName}
              </span>
            ))}
          </div>
        </div>
        {subList && (
          <div className="kind_one kind_two">
            <div className="kind_name">
              {data[0]?.subDesc || "按险种"}&nbsp;:
            </div>
            <div className="kind_value">
              {subList.map((item: any, index: any) => (
                <span
                  key={index}
                  className={index === kind ? "_name_item" : "name_item"}
                  onClick={() => handleKindChoice(index)}
                >
                  {item.categoryName}
                </span>
              ))}
            </div>
          </div>
        )}
        {secondList && secondList.length > 0 && (
          <div className="kind_one kind_two">
            <div className="kind_name">
              {subList?.[0]?.subDesc || "按人群"}:
            </div>
            <div className="kind_value">
              {secondList.map((item: any, index: any) => (
                <span
                  key={index}
                  className={index === group ? "_name_item" : "name_item"}
                  onClick={() => handleGroupChoice(index)}
                >
                  {item.categoryName}
                </span>
              ))}
            </div>
          </div>
        )}
      </div>
      <div className="product_num">
        共<span>{prokind?.length > 0 ? prokind?.length : 0}</span>款产品
      </div>
      <div className="product_all">
        <ProductItem2 prokind={prokind} />
        <MoreProduct jobNumber={jobNumber} listPageLink={listPageLink} />
      </div>
    </div>
  );
};

export default ProductList;
