import React, { memo } from 'react';
import './index.scss';
import { ICDNInfo } from '@/lib/order/types'
import ImgAgreementItem from './item'

interface IProps {
	data: ICDNInfo[]
}

const ImgAgreement = (props: IProps) => {

	const {data} = props;

	return (
		<div className='img-agreement'>
			{
				data?.length > 0 &&
				data.map((item, index) => {
					return (
						<React.Fragment key={`img-agreement-item-${index}`}>
							<ImgAgreementItem
								data={item}
								index={index}
								scale={1}
							/>
						</React.Fragment>
					)
				})
			}
		</div>
	);
};

export default memo(ImgAgreement);
