import React from 'react';
import './index.scss';
import { data1 } from './staticData';
import small_icon_red from 'static/imgs/small_icon_red.svg';
import small_icon_gray from 'static/imgs/small_icon_gray.svg';
import big_icon_gray from 'static/imgs/big_icon_gray.svg';
import big_icon_red from 'static/imgs/big_icon_red.svg';

const Milepost: React.FC = () => {
  return (
    <div className='milepost-wrap'>
      <h2 className='title'>公司里程碑</h2>
      <div className='thing-wrap'>
        <div className='line'></div>
        {data1.map((item, yearIndex) => (
          <div key={item.year} className='thing-item'>
            {item.value.map((subItem, index) => (
              <div className='small-item thing-flex' key={index}>
                <div className='s_icon'>
                  <img
                    src={yearIndex === 0 ? small_icon_red : small_icon_gray}
                    alt=''
                  />
                </div>

                <div className='date'>{subItem.month}</div>
                <div className='thing'>{subItem.value}</div>
              </div>
            ))}
            <div className='big-item thing-flex'>
              <div className='s_icon'>
                <img
                  src={yearIndex === 0 ? big_icon_red : big_icon_gray}
                  alt=''
                />
              </div>
              <div className={yearIndex === 0 ? 'year red_year' : 'year'}>
                {item.year}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Milepost;
