import React, { useEffect, useState } from 'react';
import './index.scss';
import { IFileItem } from 'lib/types';
import { fetchFileList } from 'lib/websiteApi';
import zuojiantou from 'static/imgs/zuojiantou.png';
import icons_search from 'static/imgs/icons_search.png';
import { previewPdf } from 'lib/utils';
import { Pagination } from 'antd';
import { useParams } from 'react-router-dom';
import 'antd/dist/antd.css';
import { data2 } from '../Production/staticData';
const pageSize = 10;
const ProductionListPage: React.FC = () => {
  const [pageIndex, setPageIndex] = useState(1);
  const [total, setTotal] = useState(0);
  const [title, setTitle] = useState('');
  const [inputVal, setInputVal] = useState('');
  const [data, setData] = useState<IFileItem[]>([]);
  const { id } = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
    getData();
    let pro = data2.filter((item) => {
      return item.id === id;
    });
    if (pro && pro.length > 0) {
      setTitle(pro[0].name);
    }
  }, []);

  const doSearch = () => {
    getData(1);
  };

  const getInputValueFn = (e: any) => {
    setInputVal(e.target.value || '');
  };

  const onChange = (page: any) => {
    setPageIndex(page);
    getData(page);
  };

  const getData = async (pageNum?: number) => {
    const params = {
      fileItem1Code: '002',
      fileItem2Code: id,
      pageIndex: pageNum ? pageNum : pageIndex,
      pageSize: pageSize,
      fuzzyFilename: inputVal ? inputVal : null,
    };
    const result: any = await fetchFileList(params);
    if (inputVal && result.list && result.list.length > 0) {
      result.list.forEach((item: IFileItem) => {
        item.fileName = `${item.fileName.slice(
          0,
          item.fileName.indexOf(inputVal)
        )}<span style="color: #e1251b" >${inputVal}</span>${item.fileName.slice(
          item.fileName.indexOf(inputVal) + inputVal.length
        )}`;
        return item;
      });
    }
    setData(result.list);
    setTotal(result.total);
    setPageIndex(result.pageIndex);
  };

  return (
    <div className='production-list-wrap'>
      <div className='content'>
        <div className='header'>
          <h2 className='title'>{title}</h2>
          <div className='input_wrap'>
            <img src={icons_search} alt='img' className='icon_search' />
            <input
              type='text'
              onInput={getInputValueFn}
              placeholder='请输入保险条款名称'
            />
            <button onClick={doSearch}>搜索</button>
          </div>
        </div>

        <div className='p_wrap'>
          {data.map((item, index) => (
            <div
              key={item.fileId}
              className='line_item'
              onClick={() => {
                previewPdf(item.url);
              }}
            >
              <div
                className='line_title'
                dangerouslySetInnerHTML={{ __html: item.fileName }}
              ></div>
              <div className='line_img'>
                <img src={zuojiantou} alt='' />
              </div>
            </div>
          ))}
        </div>

        <Pagination
          defaultCurrent={pageIndex}
          total={total}
          current={pageIndex}
          onChange={onChange}
          pageSize={pageSize}
          showSizeChanger={false}
        />
      </div>
    </div>
  );
};

export default ProductionListPage;
