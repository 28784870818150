import React, { useEffect, useState } from 'react';
import './index.scss';
import { data1 } from './staticData';

const navData = [
  {
    name: '通用情况',
  },
  {
    name: '个人险',
  },
  {
    name: '车险',
  },
];

const QuestionPage: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const scrollToAnchor = (anchorName: number) => {
    if (anchorName) {
      let id = 'scroll_' + anchorName;
      let anchorElement = document.getElementById(id);
      if (anchorElement) {
        anchorElement.scrollIntoView({
          block: 'nearest',
          behavior: 'smooth',
        });
      }
    }
  };

  useEffect(() => {
    scrollToAnchor(activeIndex);
  }, [activeIndex]);

  return (
    <div className='question-page-wrap'>
      <h2 className='title'>常见问题</h2>
      <div className='qa_nav_wrap'>
        {navData.map((item, index) => (
          <div
            className={activeIndex === index ? 'qa_nav active' : 'qa_nav'}
            onClick={() => setActiveIndex(index)}
            key={index}
          >
            {item.name}
          </div>
        ))}
      </div>

      {data1.map((item, index) => (
        <div key={index} className='sub_box' id={'scroll_' + index}>
          <p className='sub_title_item'>{item.title}</p>
          {item.children &&
            item.children.map((sub, subIndex) => (
              <div className='qa_item' key={subIndex}>
                <div className='qa_q_wrap'>
                  <div className='qa_icon qa_q_icon'>Q</div>
                  <p className='qa_q'>{sub.question}</p>
                </div>
                <div className='qa_ans_wrap'>
                  <div className='qa_icon qa_a_icon'>A</div>
                  <div
                    className={
                      index === data1.length - 1 &&
                      subIndex === item.children.length - 1
                        ? 'qa_ans'
                        : 'qa_ans qa_border'
                    }
                  >
                    {sub.answer.map((item, index) => (
                      <div key={index}>
                        {item.type === 'p' && (
                          <p className='qa_p'>{item.value}</p>
                        )}
                        {item.type === 'h' && (
                          <p className='qa_h'>{item.value}</p>
                        )}
                        {item.type === 'a' && (
                          <p
                            className='qa_p'
                            dangerouslySetInnerHTML={{ __html: item.value }}
                          ></p>
                        )}
                        {item.type === 'p2' && (
                          <p className='qa_p2'>{item.value}</p>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
        </div>
      ))}
    </div>
  );
};

export default QuestionPage;
