import React, { useEffect, useState } from "react";
import "./index.scss";
import { getPathApi, getMenusApi, websiteQuery } from "lib/Apis";
// import Banner from '../../common/components/Banner';
import Banner from "../../common/components/Banner3";
import Aoyun from "../../common/components/Aoyun";
import Floors from "../../common/components/Floors";
import Floating from "../../common/components/Floating";
// import Notice from '../../common/components/Notice';
import { IFloor } from "lib/types";

const Main: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [floorsList, setFloorsList] = useState<IFloor[]>([]);
  const [cdnUrl, setCdnUrl] = useState<any>({});
  const [iTypedData, setITypedData] = useState<string[]>([]);
  // const [isShowNotice, setIsShowNotice] = useState(true);

  useEffect(() => {
    document.title =
      "京东安联保险-健康险|意外险|车险|财产险-成长优享，安联财险成长优享，成长优享儿童高端医疗保险";
    getFloors();
  }, []);

  const getFloors = async () => {
    try {
      const path: any = await getPathApi("home");
      if (path) {
        const floorsObj: any = await getMenusApi(path.dataPath);
        const floorsObj2: any = await websiteQuery({});
        setFloorsList(floorsObj.floors);
        setITypedData(floorsObj.ext.ttwa);
        setCdnUrl(floorsObj2);
      }
    } catch (e) {
      console.log(e);
    }
  };

  // const closeFn = () => {
  //   setIsShowNotice(false);
  // };

  return (
    <div>
      <Banner defaultStringAll={iTypedData} />
      {/* <Aoyun /> */}
      <Floors floorsList={floorsList} cdnUrl={cdnUrl} />
      {/* <Floating /> */}
      {/* {isShowNotice && <Notice onClose={closeFn} />} */}
    </div>
  );
};

export default Main;
