import React from 'react';
import './index.scss';
import { data1 } from './staticData';

const ContactUs: React.FC = () => {
  return (
    <div className='concact-wrap'>
      <h2 className='title'>联系我们</h2>

      <div className='content-wrap'>
        {data1.map((item) => (
          <div className='content-item'>
            <div className='sub_title'>{item.title}</div>
            <div className='addre'>
              <span>办公地址：</span>
              {item.address}
            </div>
            <div className='phone'>
              <span>电话号码：</span>
              {item.phone}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ContactUs;
