import React, { useState, useEffect } from "react";
import "./index.scss";
import { IMGURL } from "lib/config";
import classNames from "classnames";
import more_product1 from "../../img/more_product1.png";
import more_product2 from "../../img/more_product2.png";
import { randomInsConsultantInfo } from "lib/Apis";
import { QRCodeCanvas } from "qrcode.react";

type moreProps = {
  jobNumber: any;
  listPageLink: any;
};
const MoreProduct: React.FC<moreProps> = (props: any) => {
  const { jobNumber, listPageLink } = props;
  const [jobNumberUrl, setJobNumberUrl] = useState<any>("");
  useEffect(() => {
    //获取二维码
    async function fetchData() {
      const jobNumberFemale: any = await randomInsConsultantInfo({
        jobNumber: jobNumber,
      });
      console.log(jobNumberFemale, jobNumber, listPageLink);
      setJobNumberUrl(jobNumberFemale.ewechatUrl);
    }
    if (jobNumber) {
      fetchData();
    }
  }, [jobNumber, listPageLink]);
  return (
    <>
      <div className={classNames("search_more")}>
        <div className="search_product">
          <img src={more_product1} alt="" />
          <div className="qrcode-canvas">
            <QRCodeCanvas value={listPageLink} size={118} />
          </div>
        </div>
        <div className="search_product">
          <img src={more_product2} alt="" />
          <div className="search_product_baogu">
            <img src={jobNumberUrl} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};
export default MoreProduct;
