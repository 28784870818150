import React, { memo, useEffect, useState } from 'react';
import './index.scss';
import { IDetailAgreementItem } from '@/lib/order/types'
import InsDialog from '@/order-components/ins-dialog';
import InsButton from '@/order-components/ins-button';
import InsDialogHeader from '@/order-components/ins-dialog-header'
import CommonAgreement from '../common-agreement'

interface IProps {
	show: boolean,
	onClose: () => void,
	onConfirmCallback: (flag: boolean) => void,
	data: IDetailAgreementItem
}

const HealthNoticeDialog = (props: IProps) => {

	const {show, onClose, onConfirmCallback, data} = props;

	const [readOverState, setReadOverState] = useState(false);

	useEffect(() => {
		if (!show) {
			setReadOverState(false);
		}
	}, [show])

	const handleCheck = () => {
		onConfirmCallback(false);
	}

	const handleConfirm = () => {
		onConfirmCallback(true);
	}

	const handleReadOverCallback = () => {
		setReadOverState(true);
	}

	return (
		<InsDialog
			show={show}
			onClose={onClose}
			location={'center'}
			disableOverlayClose={true}
		>
			<div className='health-notice-dialog'>
				<InsDialogHeader
					title='健康告知'
					onClose={onClose}
				/>
				{
					show &&
					<React.Fragment>
						<CommonAgreement
							data={data}
							readOverCallback={handleReadOverCallback}
						/>
						<div className='health-notice-dialog__footer'>
							<InsButton
								className='v-footer-left'
								value={readOverState ? '全部符合' : '阅读至页面底部可点击'}
								onClick={handleConfirm}
								disabled={!readOverState}
							/>
							<InsButton
								className='v-footer-right'
								mode={'plain'}
								value={readOverState ? '部分符合' : '阅读至页面底部可点击'}
								onClick={handleCheck}
								disabled={!readOverState}
							/>
						</div>
					</React.Fragment>
				}
			</div>
		</InsDialog>
	)
}

export default memo(HealthNoticeDialog)
