import React, { useEffect } from 'react';
import './index.scss';
import emptyPng from 'static/imgs/empty_page.png';

const NewProduction: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='empty-info-wrap'>
      <h2 className='title'>新型产品</h2>
      <div className='empty_wrap'>
        <img src={emptyPng} alt='' />
        <p>暂无内容哦，敬请期待～</p>
      </div>
    </div>
  );
};

export default NewProduction;
