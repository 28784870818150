import React, { useEffect, useState } from 'react';
import './index.scss';
import { data1, data2 } from './staticData';
import { IFileItem } from 'lib/types';
import { fetchFileList } from 'lib/websiteApi';
import { previewPdf } from 'lib/utils';
import { Pagination } from 'antd';
const pageSize = 10;
const ShortInsureance: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    getData();
  }, []);

  const [activeIndex, setActiveIndex] = useState(1);
  const [total, setTotal] = useState(0);
  const [pageIndex, setPageIndex] = useState(1);
  const [data, setData] = useState<IFileItem[]>([]);

  const tabTitleList = [
    {
      id: 1,
      title: '健康管理服务信息披露',
    },
    {
      id: 2,
      title: '综合赔付率',
    },
    {
      id: 3,
      title: '个人短期健康保险产品停售情况',
    },
  ];

  const onChange = (page: any) => {
    setPageIndex(page);
    getData(page);
  };

  const getData = async (pageNum?: number) => {
    const params = {
      fileItem1Code: '012',
      fileItem2Code: '012',
      pageIndex: pageNum ? pageNum : pageIndex,
      pageSize: pageSize,
    };
    const result: any = await fetchFileList(params);
    setData(result.list);
    setTotal(result.total);
    setPageIndex(result.pageIndex);
  };

  return (
    <div className='short-insure-info-wrap'>
      <h2 className='title'>短期健康保险</h2>
      <div className='tab_wrap'>
        {tabTitleList.map((item) => (
          <div
            className={activeIndex === item.id ? 'tab_item active' : 'tab_item'}
            key={item.id}
            onClick={() => setActiveIndex(item.id)}
          >
            {item.title}
          </div>
        ))}
      </div>
      <div className='p_wrap'>
        {activeIndex === 1 &&
          data1.map((item, index) => (
            <div key={index} className='p_item'>
              <div className='p_index'>{index + 1}.</div>
              <p className='p_content'>{item}</p>
            </div>
          ))}

        {activeIndex === 2 &&
          data2.map((item, index) => (
            <div key={index} className='line_item'>
              <div className='line_title'>
                {item.title}
                <span>{item.count}</span>
              </div>
              <div className='line_date'>{item.date}</div>
            </div>
          ))}

        {activeIndex === 3 &&
          data.map((item, index) => (
            <div
              key={index}
              className='word_item'
              onClick={() => previewPdf(item.url)}
            >
              <div className='word_title'>{item.fileName}</div>
              <div className='word_date'>{item.dateLabel}</div>
            </div>
          ))}

        {activeIndex === 3 && (
          <Pagination
            defaultCurrent={pageIndex}
            total={total}
            pageSize={pageSize}
            current={pageIndex}
            onChange={onChange}
            showSizeChanger={false}
          />
        )}
      </div>
    </div>
  );
};

export default ShortInsureance;
