import { useState, useEffect } from 'react'
import { setGlobalData } from '@/lib/global'
import { IChannelFile, IDetailAgreementItem, IPageCommonConfig } from '@/lib/order/types'
import { getPageCommonConfig } from '@/lib/order/apis'

/**
 * 获取页面通用配置
 */
const usePageCommonConfig = ({productId, jdaz_chl, jdaz_source}: { productId: string, jdaz_chl: string, jdaz_source: string }) => {

	const [pageCommonConfig, setPageCommonConfig] = useState<IPageCommonConfig>(null);

	useEffect(() => {
		if (productId) {
			const body = {
				queryType: 'new',
				plansPackageId: productId || '',
				channelIdentificationCode: jdaz_chl || '',
				sourceCode: jdaz_source || ''
			}
			if (body.queryType) {
				getPageCommonConfig(body)
				.then((res: IPageCommonConfig) => {
					let pageConfig = res;
					if (pageConfig?.channelAttrs) {
						const {channelAttrs, customNotify} = pageConfig.channelAttrs;
						if (channelAttrs?.length > 0) {
							channelAttrs.forEach((item, index) => {
								convertChannelFileData(item, index);
							})
						} else if (customNotify) {
							convertChannelFileData(customNotify, 1);
						}
					}
					setPageCommonConfig(pageConfig);
					setGlobalData('pageCommonConfig', pageConfig);
				})
				.catch(() => {
					setGlobalData('pageCommonConfig', null);
				})
			}
		}
	}, [productId])

	const convertChannelFileData = (channelFile: IChannelFile, index: number) => {
		if (channelFile?.display) {
			let isProdFactConf = false;
			let prodFactMaterial: any = [];
			if (channelFile?.isForcedReading) {
				isProdFactConf = channelFile.imgPath?.length > 0;
				if (isProdFactConf) {
					prodFactMaterial.push({
						pdfName: channelFile.name || '',
						pdfPath: channelFile.path,
						imgPath: channelFile.imgPath
					})
				}
			}
			let defDetailAgreementItem: IDetailAgreementItem = null;
			channelFile.detailAgreementItem = {
				...defDetailAgreementItem,
				type: channelFile.type || `channelFile${index}`,
				name: channelFile.name || '',
				pdf: channelFile.path,
				isProdFactConf: isProdFactConf,
				prodFactMaterial: prodFactMaterial,
				isForcedReading: channelFile.isForcedReading
			};
		}
	}

	return pageCommonConfig;
}

export default usePageCommonConfig;
