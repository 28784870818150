import React, { useEffect, useState, memo } from 'react';
import './index.scss';

interface IProps {
	path: string
}

const PdfAgreement = (props: IProps) => {

	const {path} = props;

	const [] = useState('');

	useEffect(() => {

	}, []);

	return (
		<div className='pdf-agreement'>
			<span>{path}</span>
		</div>
	);
};

export default memo(PdfAgreement);
