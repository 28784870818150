import { get, getJson, post } from "./request";

const DATA_API = {
  dev: "https://m-test.jdallianz.com/jc/ins-data-360/test/m",
  test: "https://m-test.jdallianz.com/jc/ins-data-360/test/m",
  pre: "https://m-test.jdallianz.com/jc/ins-data-360/pre/m",
  prod: "https://img01.az.jd.com/ins-data-360/m",
  //@ts-ignore
}[process.env.REACT_APP_MODE];

const TEMP_API = {
  dev: "https://xgw-test.jdazcn.com/c/api/360",
  test: "https://xgw-test.jdazcn.com/c/api/360",
  pre: "https://xgw-pre.jdazcn.com/c/api/360",
  prod: "https://www.jdallianz.com/c/api/360",
  //@ts-ignore
}[process.env.REACT_APP_MODE];

const NITICE_API = {
  dev: "https://jdapi-pre.jdallianz.com",
  test: "https://jdapi-pre.jdallianz.com",
  pre: "https://api-work-pre.jdazcn.com",
  prod: "https://jdapi.jdallianz.com",
  //@ts-ignore
}[process.env.REACT_APP_MODE];

const IMG_API = {
  dev: "https://xgw-test.jdazcn.com/c/api/user_new/api/v1",
  test: "https://xgw-test.jdazcn.com/c/api/user_new/api/v1",
  pre: "https://xgw-pre.jdazcn.com/c/api/user_new/api/v1",
  prod: "https://www.jdallianz.com/c/api/user_new/api/v1",
  //@ts-ignore
}[process.env.REACT_APP_MODE];

export const getPathApi = (type: string) => {
  return get({
    url: `${TEMP_API}/show/retail/list?type=${type}`,
    params: {},
  });
};

export const getMenusApi = (path: string) => {
  return getJson({
    url: `${DATA_API}${path}`,
  });
};

export const getCustomerPath = () => {
  return get({
    url: `${TEMP_API}/chat/entrance?entry=360PC`,
    params: {},
  });
};

//降级公告
export const getNotice = (params: any) => {
  return post({
    url: `${NITICE_API}/notice/config/getNotice`,
    body: params,
  });
};

//查询官网配置
export const websiteQuery = (params: any) => {
  return get({
    url: `${TEMP_API}/website/query`,
    params: {},
  });
};

//查询图片
export const randomInsConsultantInfo = (params: any) => {
  return post({
    url: `${IMG_API}/InsConsultant/randomInsConsultantInfo`,
    body: params,
  });
};
