export const data1 = [
  {
    year: '2022',
    value: [
      {
        month: '2022-02',
        value:
          '京东安联保险为2022年北京冬奥会护航，所提供的保险服务涵盖意外健康险、车险以及综合责任险和财产险。',
      },
    ],
  },
  {
    year: '2021',
    value: [
      {
        month: '2021-05',
        value:
          '京东安联保险沉淀保险服务能力，突破京东场景，开始服务抖音、快手等新型电商平台。',
      },
    ],
  },
  {
    year: '2020',
    value: [
      {
        month: '2020-07',
        value: '经中国银保监会四川监管局批准，京东安联保险四川分公司正式开业。',
      },
    ],
  },
  {
    year: '2019',
    value: [
      {
        month: '2019-12',
        value:
          '合资首年实现盈利，同比增速位列行业前茅，公司荣获中国银保监会年度保险公司法人机构经营A级评价。',
      },
    ],
  },
  {
    year: '2018',
    value: [
      {
        month: '2018-10',
        value:
          '获中国银保监会批准，安联财产保险（中国）有限公司获准更名为京东安联财产保险有限公司。',
      },
      {
        month: '2018-07',
        value: '中国银保监会批准京东等股东入股安联财产保险（中国）有限公司。',
      },
    ],
  },
  {
    year: '2017',
    value: [
      {
        month: '2017-11',
        value:
          '安联财险北京分公司于11月8日正式开业。加上在深圳的营销服务部，安联财险完成了在中国一线城市的布局。',
      },
    ],
  },
  {
    year: '2016',
    value: [
      {
        month: '2016-12',
        value: '安联“臻爱医疗保险”获评为“2016年度保险产品”。',
      },
      {
        month: '2016-01',
        value:
          '安联财险宣布广东分公司于1月29日开业，标志着安联财险在华南地区的发展再往前迈进一步。',
      },
    ],
  },
  {
    year: '2015',
    value: [
      {
        month: '2015-12',
        value: '安联乐享人生儿童综合保险获评为“2015年度保险产品”。',
      },
      {
        month: '2015-08',
        value: '安联保险直销商城 www.allianz360.com 上线。',
      },
    ],
  },
  {
    year: '2014',
    value: [
      {
        month: '2014-03',
        value:
          '在2014中国互联网金融大会春季峰会上，经过“品牌力、贡献力、责任力、创新力”的角逐和层层评选，安联财险当选“2013中国互联网金融领军榜100强品牌”。',
      },
      {
        month: '2014-01',
        value:
          '第十一届中国财经风云榜暨“中国改革再出发”财经中国年会在北京隆重召开，安联财险凭借创新的“赏月险”和市场影响力，荣获“2013年度最佳保险创新案例” 奖。本届年会由和讯网和中国证券市场研究设计中心（SEEC）联合主办。',
      },
    ],
  },
  {
    year: '2013',
    value: [
      {
        month: '2013-07',
        value:
          '安联保险在淘宝网开设旗舰店，成为国内首家进驻淘宝网的外资财险公司。',
      },
      {
        month: '2013-07',
        value:
          '安联财险针对新兴的代驾服务所衍生的风险，开发了国内首个代驾责任险，填补了市场空白。',
      },
      {
        month: '2013-05',
        value:
          '安联财险获得中国保监会关于交强险业务经营资格的批复。安联财险由此得以向客户提供一站式的车险解决方案。',
      },
      {
        month: '2013-04',
        value:
          '荣获“2012年度广东省最佳诚信企业”的称号，这是安联财险连续第二年获此荣誉。',
      },
    ],
  },
  {
    year: '2012',
    value: [
      {
        month: '2012-11',
        value:
          '由第一财经举办的“2012第一财经金融价值榜”评选中，安联财险被评为 “年度保险公司-外资财险”的称号。',
      },
      {
        month: '2012-08',
        value:
          '推出安联康睿寰球医疗保险（团体）计划，为本土和跨国企业在华机构职员提供国际高端医疗保险服务。',
      },
      {
        month: '2012-06',
        value:
          '由南方日报社举办的“金榕奖2012南方金融年度大奖”颁奖典礼上，安联财险凭着业内首个“电影电视制作方保险”产品夺得“2012最具竞争力金融创新产品”。',
      },
      {
        month: '2012-03',
        value:
          '安联财产保险(中国)有限公司上海分公司完成工商注册登记等相关手续，正式对外营业。',
      },
    ],
  },
  {
    year: '2010',
    value: [
      {
        month: '2010-12',
        value:
          '在由《金融时报》联合中国社会科学院金融研究所推出的“2009~2010中国金融机构金牌榜--第三届‘金龙奖’评选”活动中，安联财产保险(中国)有限公司荣获“年度最佳外资财产保险公司”称号。',
      },
      {
        month: '2010-07',
        value:
          '2010年7月1日，安联财产保险(中国)有限公司完成工商注册登记等相关手续，正式对外开业。',
      },
      {
        month: '2010-01',
        value:
          '2010年1月12日，中国保险监督管理委员会批准安联保险公司广州分公司改建为安联财产保险(中国)有限公司。',
      },
    ],
  },
  {
    year: '2009',
    value: [
      {
        month: '2009-12',
        value: '安联广州完成“数据仓库项目”，实现自动生成保监报告。',
      },
      {
        month: '2009-11',
        value:
          '安联广州通过“卓越运营”项目对货运险业务流程进行改进，提升了从报价、出单到理赔的效率。',
      },
    ],
  },
  {
    year: '2008',
    value: [
      {
        month: '2009-09',
        value:
          '安联广州被广州市国家税务局、广州市地方税务局评为“2006-2007年度纳税信用等级A级纳税人”。',
      },
      {
        month: '2008-02',
        value:
          '安联广州连同姊妹公司向受雪灾影响的广东省韶关市始兴县澄江镇农户捐赠钱物。',
      },
    ],
  },
  {
    year: '2007',
    value: [
      {
        month: '2007-02',
        value:
          '安联广州开始推行“OPEX卓越运营”项目，用以优化流程，改善公司运营。',
      },
    ],
  },
  {
    year: '2006',
    value: [
      {
        month: '2006-09',
        value:
          '安联广州会同广州市体育局成功举办F1赛车在中国的公共道路上的首次试驾活动。',
      },
      {
        month: '2006-09',
        value:
          '安联广州连同姊妹公司一起制作世界杯足球赛“安联体育场模型”，在上海、广州等地进行巡回展示。',
      },
      {
        month: '2006-04',
        value:
          '安联广州向广州市智灵学校捐款，持续关注国内特殊教育，承担企业社会责任。',
      },
      {
        month: '2006-02',
        value: '安联保险公司广州分公司深圳营销服务部正式成立。',
      },
    ],
  },
  {
    year: '2005',
    value: [
      {
        month: '2005-09',
        value:
          '安联保险公司广州分公司等六家知名企业，参加广州日报报业集团举行的“助百名贫困生圆大学梦活动”捐赠仪式，共同捐赠50万元学费，帮助100名贫困大学生。',
      },
      {
        month: '2005-09',
        value:
          '广州市市长张广宁带领广州市政府代表团和广州市经贸代表团的部分成员在慕尼黑访问德国安联集团总部。',
      },
      {
        month: '2005-03',
        value:
          '安联广州向“广东省扬爱特殊孩子家长俱乐部”捐款，帮助社会弱势群体改善生存和教育条件。',
      },
    ],
  },
  {
    year: '2004',
    value: [
      {
        month: '2004-09',
        value:
          '安联广州邀请商业伙伴、客户、全体员工参加首次在中国举办的一级方程式赛车，体验公司不断创新、充满活力和追求高绩效的企业文化。',
      },
    ],
  },
  {
    year: '2003',
    value: [
      {
        month: '2003-02-14',
        value: '安联保险公司广州分公司正式开始运营。',
      },
    ],
  },
];
