import React from 'react';
import './index.scss';
import { data1, data2, data3, data4 } from './staticData';

const ServePromise: React.FC = () => {
  return (
    <div className='serve-promise-wrap'>
      <h2 className='title'>销售承诺和服务承诺</h2>

      <h3 className='sub_title'>
        一、 销售服务——合规守法，严禁销售误导，保障消费者权益
      </h3>
      <p className='sub_title_desc'>
        1.
        公司在线上自营平台开展保险产品销售的，将按照监管要求做好信息披露、风险提示、客户告知等内容展示，确保展示内容完整、清晰、易懂，防范出现侵害消费者权益的流程与操作。
      </p>
      <p className='sub_title_desc'>
        2.
        公司保险销售人员（包括保险公司的销售人员和保险代理机构的保险销售人员，下同。）在线下面对面销售保险产品过程中，会提醒投保人有关犹豫期内的权利、退保损失及保险责任、责任免除及在收到保单后仔细阅读合同条款等保险合同的重要内容，并向投保人提供保险合同条款，主动提示保险产品的特点和风险，使投保人在购买产品时能够了解产品保障内容、责任免除等与其自身权益密切相关的情况。
      </p>
      <p className='sub_title_desc'>
        3.
        公司要求保险销售人员开展保险产品销售过程中不得存在欺骗、隐瞒、误导、进行不实、不当宣传推介或承诺等行为，违反相关规定的将根据公司及监管相关制度进行相应惩罚。
      </p>
      <p className='sub_title_desc'>
        4.
        公司及与保险中介机构合作开展保险产品销售的，如发生消费者投诉、争议、纠纷时，会第一时间积极配合调查取证、制定解决方案并妥善处理，并就案件处理过程中发现的问题与漏洞，主动进行整改或优化，保障消费者权益。
      </p>

      <h3 className='sub_title'>二、 车险理赔服务</h3>
      {data1.map((item, index) => (
        <div key={index} className='sub_box'>
          <p className='sub_title_item'>{item.title}</p>
          {item.value &&
            item.value.map((sub, subIndex) => (
              <p key={subIndex} className='sub_title_item_con'>
                {sub}
              </p>
            ))}
        </div>
      ))}

      <h3 className='sub_title'>三、 非车险理赔服务</h3>
      {data2.map((item, index) => (
        <div key={index} className='sub_box'>
          <p className='sub_title_item'>{item.title}</p>
          {item.value &&
            item.value.map((sub, subIndex) => (
              <p key={subIndex} className='sub_title_item_con'>
                {sub}
              </p>
            ))}
        </div>
      ))}

      <p className='sub_title_item'>*线上申请</p>
      <p className='sub_title_item_con'>
        1）微信公众号“京东安联保险” &gt; 自助服务 &gt; 理赔服务 &gt; 我要理赔（需登录）
      </p>
      <p className='sub_title_item_con mb_40'>2）微信小程序“京东安联客户服务” &gt; 理赔服务 &gt; 我要理赔（需登录）</p>
      <p className='sub_title_item'>*线下申请</p>
      <p className='sub_title_item_con'>
      拨打全国理赔服务热线&nbsp;&nbsp;950610
      </p>

      <div className='box_4'>
        <div className='box_4_header'>
          <div
            className='header_item body_item_border'
            style={{ width: '80px' }}
          >
            序号
          </div>
          <div
            className='header_item body_item_border'
            style={{ width: '190px' }}
          >
            机构名称
          </div>
          <div className='header_item body_item_border w_auto'>邮寄地址</div>
          <div
            className='header_item body_item_border'
            style={{ width: '100px' }}
          >
            邮编
          </div>
          <div className='header_item' style={{ width: '130px' }}>
            联系电话
          </div>
        </div>

        {data3.map((item, index) => (
          <div className='box_4_body' key={index}>
            <div
              className='body_item body_item_border'
              style={{ width: '80px' }}
            >
              {item.index}
            </div>
            <div
              className='body_item body_item_border'
              style={{ width: '190px' }}
            >
              {item.name}
            </div>
            <div className='body_item body_item_border w_auto'>{item.emil}</div>
            <div
              className='body_item body_item_border'
              style={{ width: '100px' }}
            >
              {item.zipCode}
            </div>
            <div className='body_item' style={{ width: '130px' }}>
              {item.phone}
            </div>
          </div>
        ))}
      </div>

      <h3 className='sub_title'>四、 保全服务</h3>
      {data4.map((item, index) => (
        <div key={index} className='sub_box'>
          <p className='sub_title_item'>{item.title}</p>
          {item.value &&
            item.value.map((sub, subIndex) => (
              <p key={subIndex} className='sub_title_item_con'>
                {sub}
              </p>
            ))}
        </div>
      ))}
    </div>
  );
};

export default ServePromise;
