import React, { memo, useState, useEffect } from 'react';
import './index.scss';
import { Cascader } from 'antd'
import classNames from 'classnames'
import { getMajorByParentId } from '@/lib/order/apis'
import { IOccupationDetail } from '@/lib/order/types'

interface IProps {
	className?: string,
	limitData?: number[],
	data: IOccupationDetail,
	onChange: (detail: IOccupationDetail) => void
}

interface ISelectOption {
	id: number,
	parentId: number,
	level: number,
	value: string;
	label: string;
	disabled?: boolean;
	children?: ISelectOption[];
	isLeaf?: boolean;
	loading?: boolean;
}

const InsuredOccupationSelect = (props: IProps) => {

	const {className, limitData, data, onChange} = props;

	const [options, setOptions] = useState<ISelectOption[]>([]);

	useEffect(() => {
		// {
		// 	"id": 888,
		// 	"level": 1,
		// 	"parentId": 885,
		// 	"name": "渔业",
		// 	"code": "C",
		// 	"rank": 1,
		// 	"status": 1
		// },
		if (options.length === 0) {
			if (data.parentIds?.length === 3) {
				initData();
			} else {
				getOptionList(885)
				.then((res: ISelectOption[]) => {
					setOptions(res);
				})
			}
		}
	}, [data.parentIds])

	const initData = async () => {
		const level1OptionList: ISelectOption[] = await getOptionList(data.parentIds[0]);
		const level2OptionList: ISelectOption[] = await getOptionList(data.parentIds[1]);
		const level3OptionList: ISelectOption[] = await getOptionList(data.parentIds[2]);

		let level2TargetOption = level2OptionList.find((item) => item.id === data.parentIds[2]);
		level2TargetOption.children = level3OptionList;

		let level1TargetOption = level1OptionList.find((item) => item.id === data.parentIds[1]);
		level1TargetOption.children = level2OptionList;

		setOptions(level1OptionList);
	}

	const handleChange = (value: string[], selectedOptions: ISelectOption[]) => {
		// console.log('onChange', value, selectedOptions);
		const targetOption = selectedOptions[selectedOptions.length - 1];
		if (targetOption?.level === 3) {
			console.log('onChange', value, selectedOptions);
			let result: IOccupationDetail = {
				name: targetOption.label,
				code: targetOption.value,
				codes: [],
				labels: [],
				parentIds: []
			};
			selectedOptions.forEach((item) => {
				result.codes.push(item.value);
				result.labels.push(item.label);
				result.parentIds.push(item.parentId);
			})
			onChange(result);
		}
	};

	const getOptionList = (id: number): Promise<ISelectOption[]> => {
		return new Promise((resolve) => {
			getMajorByParentId(id)
			.then((res: any[]) => {
				if (res?.length > 0) {
					let optionList: ISelectOption[] = [];
					res.forEach((item) => {
						const rank = item.rank || 1;
						const level = item.level || 1;
						const disabled = level === 3 && ((limitData && !limitData.includes(rank)) || rank === 1000);
						optionList.push({
							id: item.id,
							parentId: item.parentId,
							level: item.level,
							value: item.code,
							label: disabled ? `${item.name}（不支持承保）` : item.name,
							isLeaf: level === 3,
							disabled: disabled
						})
					})
					resolve(optionList);
				}
			})
			.catch(() => {
				resolve([]);
			})
		})
	}

	const loadData = (selectedOptions: ISelectOption[]) => {
		const targetOption = selectedOptions[selectedOptions.length - 1];
		targetOption.loading = true;
		getOptionList(targetOption.id)
		.then((res: ISelectOption[]) => {
			targetOption.children = res;
			targetOption.loading = false;
			setOptions([...options]);
		})
	};

	const handleDisplayRender = (label: string[], selectedOptions: ISelectOption[]) => {
		// console.log('handleDisplayRender', label)
		if (data?.labels?.length === 3) {
			return data.labels[2];
		}
		// const targetOption = selectedOptions[selectedOptions.length - 1];
		// if (targetOption?.level === 3) {
		// 	return targetOption.label;
		// }
		return '请选择职业类别';
	}

	return (
		<Cascader
			className={classNames('insured-occupation-select', className)}
			placeholder='请选择职业类别'
			options={options}
			loadData={loadData}
			onChange={handleChange}
			changeOnSelect={true}
			displayRender={handleDisplayRender}
			defaultValue={data.codes}
		/>
	)
}

export default memo(InsuredOccupationSelect)
