import React, { memo } from 'react';
import './index.scss';
import icon_Q from '@/static/imgs/order/icon_question.svg'
import { IDetailQuestionItem } from '@/lib/order/types'
import DetailHtml from '@/order-components/detail-html';

interface IProps {
	data: IDetailQuestionItem[]
}

const DetailQuestion = (props: IProps) => {

	const {data} = props

	return (
		<div className='detail-question'>
			{
				data &&
				data.map((item, index) => {
					return (
						<React.Fragment key={`detail-question-item-${index}`}>
							<div className='detail-question-item-wrap'>
								<img className='detail-question-img-question' src={icon_Q} alt={''} />
								<div className='detail-question-item-wrap-right'>
									<div className='detail-question-item'>
										<div className='detail-question-item-question'>
											<DetailHtml html={item.question} />
										</div>
									</div>
									<div className='detail-question-item-answer'>
										<DetailHtml className='detail-question-item-answer-text' html={item.answer} />
									</div>
								</div>
							</div>
						</React.Fragment>
					)
				})
			}
		</div>
	)
}

export default memo(DetailQuestion)
