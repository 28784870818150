import React, { useState, useEffect, memo } from 'react';
import './index.scss';
import { DatePicker, Input, Select, Upload, Popover } from 'antd'
import InsDialog from '@/order-components/ins-dialog'
import { DatePickerProps, RangePickerProps } from 'antd/lib/date-picker'
import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import {
	IInsuredLimit,
	IInsureForm,
	IOccupationDetail,
	ISafeguardLimitData,
	ISelectOptionItem
} from '@/lib/order/types'
import InsInputLabel from '@/order-components/ins-input-label'
import { UploadChangeParam, UploadFile, UploadProps } from 'antd/lib/upload'
import InsDialogHeader from '@/order-components/ins-dialog-header'
import InsButton from '@/order-components/ins-button'
import RegExpUtils from '@/lib/order/regex'
import { checkInsuredData, getBirthdayFromIdCard, getGenderByIdCard, zhToPin } from '@/lib/order/utils'
import { getGlobalData } from '@/lib/global'
import { getOSSPath, uploadImgFile } from '@/lib/order/apis'
import {
	CertTypesMap, EXPENSIVE_HOSPITAL_OPTIONS, ExpensiveHospitalList,
	GENDER_OPTIONS, IDCardTypeGroup,
	MEDICAL_OPTIONS, MedicalExplainList,
	RELATION_OPTIONS,
	SECOND_CERTTYPE_OPTIONS, UploadImageTypeGroup
} from '@/lib/order/constants'
import InsLoading from '@/order-components/ins-loading'
import ToastUtils from '@/lib/toast'
import InsuredOccupationSelect from '../insured-occupation-select'
import useDebounceList from '@/lib/useDebounceList'
import DetailRadioGroup from '@/order-components/detail-radio-group';
import icon_add from '@/static/imgs/order/icon_add.svg'
import InsRadioGroup from '@/order-components/ins-radio-group';

interface IRadioData {
	code: string | number,
	display: string,
	disabled: boolean
}

interface IProps {
	show: boolean,
	onClose: () => void,
	onConfirm: (isNew: boolean, data: IInsureForm) => void,
	data: IInsureForm,
	isNew: boolean,
	listData: IInsureForm[],
	limitData: IInsuredLimit,
	safeguardLimitData: ISafeguardLimitData,
}

const TodayMomentDay = moment().endOf('day');

const InsuredDialog = (props: IProps) => {

	const {show, onClose, onConfirm, data, isNew, listData, limitData, safeguardLimitData} = props;

	const [formInfo, setFormInfo] = useState<IInsureForm>(data);

	const [certTypeList, setCertTypeList] = useState<ISelectOptionItem[]>([]);

	const [showLoading, setShowLoading] = useState(false);

	const [deductibleData, setDeductibleData] = useState<IRadioData[]>([]);

	const safeguardLimitDataDebounce = useDebounceList([safeguardLimitData], 200);

	const [showExpensiveHospital, setShowExpensiveHospital] = useState(false);

	useEffect(() => {
		setShowExpensiveHospital(getGlobalData('showExpensiveHospital'));
	}, [])

	useEffect(() => {
		if (limitData) {
			if (limitData?.certTypes && limitData.certTypes.length > 0) {
				let certTypeOption = [];
				limitData.certTypes.forEach((item) => {
					const label = CertTypesMap[item];
					if (label) {
						certTypeOption.push({
							label: label,
							value: item
						})
					}
				})
				setCertTypeList(certTypeOption);
			} else {
				setCertTypeList([{
					label: '身份证',
					value: '01'
				}])
			}
		}
	}, [limitData])

	useEffect(() => {
		if (safeguardLimitData && safeguardLimitData.limitData) {
			const {Deductible} = safeguardLimitData.limitData;
			if (Deductible) {
				let deductibleList: IRadioData[] = Deductible.map((item) => {
					return {
						code: item.code,
						display: item.display,
						disabled: false
					}
				})
				if (formInfo?.deductible) {
					const findData = deductibleList.find((item) => `${item.code}` === formInfo.deductible)
					if (!findData) {
						deductibleList.push({
							code: Number.parseInt(formInfo.deductible),
							display: formInfo.deductibleDisplay,
							disabled: true
						})
					}
				}
				setDeductibleData(deductibleList)
			}
		}
	}, [safeguardLimitDataDebounce])

	useEffect(() => {
		setFormInfo(data);
	}, [data])

	const onRelationChange = (value: string) => {
		console.log('onRelationChange', value);
		if (formInfo.relation === value) return;
		switch (value) {
			case '01':
				if (listData.length > 0 && listData.find(item => item.relation === '01')) {
					ToastUtils.info('您已经添加过[本人]关系了，不能重复添加');
					return;
				}
				if (isNew) {
					const policyholder = getGlobalData('jdaz-policyholder');
					if (policyholder) {
						setFormInfo({
							...formInfo,
							...policyholder,
							relation: value
						})
					} else {
						setFormInfo({
							...formInfo,
							relation: value
						})
					}
				} else {
					setFormInfo({
						...formInfo,
						relation: value
					})
				}
				break;
			case '04':
				if (listData.length > 0 && listData.find(item => item.relation === '04')) {
					ToastUtils.info('您已经添加过[配偶]关系了，不能重复添加');
					return;
				}
				setFormInfo({
					...formInfo,
					relation: value
				})
				break;
			default:
				setFormInfo({
					...formInfo,
					relation: value
				})
				break;
		}
	};

	const handleOnChange = (name: string, e) => {
		const value = e.target.value || '';
		// console.log('handleOnChange',name, value);
		let displayValue = value.trim();
		if (name === 'name') {
			setFormInfo({
				...formInfo,
				name: displayValue,
				nameError: '',
				namePinYin: displayValue.length > 1 ? zhToPin(displayValue).toUpperCase() : '',
				namePinYinError: ''
			})
		} else if (name === 'certNo') {
			setFormInfo({
				...formInfo,
				certNo: displayValue.toUpperCase(),
				certNoError: ''
			})
		} else {
			setFormInfo({
				...formInfo,
				[name]: displayValue,
				[`${name}Error`]: ''
			})
		}
	}

	const handleOnBlur = (name: string, e) => {
		const value = e.target.value || '';
		if (name === 'certNo' && IDCardTypeGroup.includes(formInfo.certType)) {
			if (RegExpUtils.checkIdCard(value) || RegExpUtils.checkGangAoTaiIDCard(value)) {
				setFormInfo({
					...formInfo,
					birthday: getBirthdayFromIdCard(value),
					gender: getGenderByIdCard(value),
				})
			} else {
				setFormInfo({
					...formInfo,
					birthday: '',
					gender: ''
				})
			}
		}
	}

	const onCertTypeChange = (value: string) => {
		console.log('onCertTypeChange', value);
		if (formInfo.certType === value) return;
		setFormInfo({
			...formInfo,
			certType: value,
			certNo: '',
			certNoError: '',
			imgData: []
		})
	};

	const onSecondCertTypeChange = (value: string) => {
		console.log('onSecondCertTypeChange', value);
		if (formInfo.secondCertType === value) return;
		setFormInfo({
			...formInfo,
			secondCertType: value,
			secondCertNo: '',
			secondCertNoError: ''
		})
	};

	const onGenderChange = (data: ISelectOptionItem) => {
		console.log('onGenderChange', data);
		if (formInfo.gender === data.value) return;
		setFormInfo({
			...formInfo,
			gender: data.value
		})
	};

	const disabledDate: RangePickerProps['disabledDate'] = current => {
		return current && current > TodayMomentDay;
	};

	const onBirthdayChange: DatePickerProps['onChange'] = (date, dateString) => {
		console.log('onBirthdayChange', dateString);
		if (formInfo.birthday === dateString) return;
		setFormInfo({
			...formInfo,
			birthday: dateString
		})
	};

	const onOccupationChange = (detail: IOccupationDetail) => {
		console.log('onOccupationChange', detail);
		if (formInfo.occupation.code === detail.code) return;
		setFormInfo({
			...formInfo,
			occupation: detail
		})
	}

	const onMedicalChange = (data: ISelectOptionItem) => {
		console.log('onMedicalChange', data);
		if (formInfo.medical === data.value) return;
		setFormInfo({
			...formInfo,
			medical: data.value
		})
	};

	const handleDeductibleChange = (code, index) => {
		if (code === formInfo.deductible) return;
		setFormInfo({
			...formInfo,
			deductible: code,
			deductibleDisplay: deductibleData[index].display
		})
	}

	const onExpensiveHospitalChange = (data: ISelectOptionItem) => {
		console.log('onExpensiveHospitalChange', data);
		if (formInfo.expensiveHospital === data.value) return;
		setFormInfo({
			...formInfo,
			expensiveHospital: data.value
		})
	};

	const beforeUpload = () => {
		return false;
	};

	const handleImgChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
		// console.log('handleImgChange', info);
		if (!UploadImageTypeGroup.includes(info.file.type)) {
			ToastUtils.info('请选择图片');
			return;
		}
		// @ts-ignore
		uploadImgFile(info.file)
		.then((token: string) => {
			setFormInfo({
				...formInfo,
				imgData: [{
					name: info.file.name,
					token: token,
					ossUrl: getOSSPath(token),
				}]
			})
		})
	};

	const handleSubmit = async () => {
		if (isNew) {
			// 校验是否重复
			if (formInfo.certNo && listData?.length === 0) {
				const repeatData = listData.find(item => item.certNo === formInfo.certNo);
				if (repeatData) {
					ToastUtils.info('您已经添加过该被保人了，不能重复添加');
					return;
				}
			}
		}
		// 校验关系
		if (!formInfo.relation) {
			ToastUtils.info('请选择与投保人关系');
			return;
		}
		// 校验证件类型
		if (!formInfo.certType) {
			ToastUtils.info('请选择证件类型');
			return;
		}
		if (!limitData?.certTypes?.includes(formInfo.certType)) {
			ToastUtils.info('当前证件类型不支持投保，请选择其他证件类型');
			return;
		}
		setShowLoading(true);
		const {flag, errorData} = await checkInsuredData(formInfo, limitData, safeguardLimitData);
		setShowLoading(false);
		if (!flag) {
			return;
		}
		onConfirm(isNew, formInfo);
	}

	const handleCancel = () => {
		onClose();
	}

	const MedicalTips = (
		<div className='tip-popover'>
			{
				MedicalExplainList.map((item, index) => {
					return (
						<p key={index} className='v-text-sub'>{item}</p>
					)
				})
			}
		</div>
	);

	const ExpensiveHospitalTips = (
		<div className='tip-popover'>
			{
				ExpensiveHospitalList.map((item, index) => {
					return (
						<div key={index} className='v-list-item'>
							<p className='v-text-title'>{item.title}</p>
							{
								item.list.map((subItem, subItemIndex) => {
									return (
										<p key={subItemIndex} className='v-text-sub'>{subItem}</p>
									)
								})
							}
						</div>
					)
				})
			}
		</div>
	);

	return (
		<React.Fragment>

			<InsLoading show={showLoading} />

			<InsDialog
				show={show}
				onClose={onClose}
				location={'center'}
				disableOverlayClose={true}
			>
				<div className='insured-dialog'>

					<InsDialogHeader
						title={isNew ? '新增被保险人' : '编辑被保险人'}
						onClose={onClose}
					/>

					{
						formInfo &&
						<div className='insured-dialog_body'>
							<div className='item-wrap'>
								<InsInputLabel className='item-wrap_label' text='与投保人关系' require={true} />
								<div className='v-relation-wrap'>
									{
										RELATION_OPTIONS.map((item, index) => {
											return (
												<React.Fragment key={`relation-item-${index}`}>
													{
														limitData?.relations?.includes(item.value) &&
														<span
															className={`v-relation-item ${item.value === formInfo.relation ? 'v-relation-item-active' : ''}`}
															onClick={() => onRelationChange(item.value)}
														>
														{item.label}
													</span>
													}
												</React.Fragment>
											)
										})
									}
								</div>
							</div>

							<div className='item-wrap'>
								<InsInputLabel className='item-wrap_label' text='姓名' require={true} />
								<Input
									className='item-wrap_input'
									placeholder='请输入姓名'
									disabled={false}
									maxLength={20}
									status={''}
									type={'text'}
									value={formInfo.name}
									onChange={(e) => handleOnChange('name', e)}
									onBlur={(e) => handleOnBlur('name', e)}
								/>
							</div>

							<div className='item-wrap'>
								<InsInputLabel className='item-wrap_label' text='姓名拼音' require={false} />
								<Input
									className='item-wrap_input'
									placeholder='请输入姓名拼音，只支持字母+空格'
									disabled={false}
									maxLength={40}
									status={''}
									type={'text'}
									value={formInfo.namePinYin}
									onChange={(e) => handleOnChange('namePinYin', e)}
								/>
							</div>

							<div className='item-wrap'>
								<InsInputLabel className='item-wrap_label' text='证件' require={true} />
								<Select
									className='item-wrap_select'
									placeholder='请选择证件类型'
									value={formInfo.certType}
									onChange={onCertTypeChange}
									options={certTypeList}
								/>
								<Input
									className='item-wrap_input'
									placeholder={'请输入证件号码'}
									disabled={false}
									maxLength={20}
									status={''}
									type={'text'}
									value={formInfo.certNo}
									onChange={(e) => handleOnChange('certNo', e)}
									onBlur={(e) => handleOnBlur('certNo', e)}
								/>
							</div>

							<div
								className='item-wrap'
								style={{display: formInfo.certType === '01' && limitData.insuredSecondCertTypeSupportLimit ? 'flex' : 'none'}}
							>
								<InsInputLabel className='item-wrap_label' text='其他证件' require={false} />
								<Select
									className='item-wrap_select'
									placeholder='请选择证件类型'
									value={formInfo.secondCertType}
									onChange={onSecondCertTypeChange}
									options={SECOND_CERTTYPE_OPTIONS}
								/>
								<Input
									className='item-wrap_input'
									placeholder={'请输入证件号码'}
									disabled={false}
									maxLength={20}
									status={''}
									type={'text'}
									value={formInfo.secondCertNo}
									onChange={(e) => handleOnChange('secondCertNo', e)}
								/>
							</div>

							<div style={{display: formInfo.certType !== '01' ? 'block' : 'none'}}>
								<div className='item-wrap'>
									<InsInputLabel className='item-wrap_label' text='证件图片' require={true} />
									<Upload
										name='file'
										accept='image/png,image/jpeg'
										// listType='picture-card'
										maxCount={1}
										showUploadList={false}
										beforeUpload={beforeUpload}
										onChange={handleImgChange}
									>
										{
											formInfo.imgData?.length > 0 ?
												<img className='v-img-result' src={formInfo.imgData[0].ossUrl} alt={'证件图片'} />
												:
												<div className='v-img-upload'>
													<img className='v-img-add' src={icon_add} alt={''} />
													<span className='v-text-add'>点击上传证件照片</span>
												</div>
										}
									</Upload>
								</div>

								<div className='item-wrap'>
									<InsInputLabel className='item-wrap_label' text='性别' require={true} />
									<InsRadioGroup
										options={GENDER_OPTIONS}
										value={formInfo.gender}
										onChange={onGenderChange}
										disabled={IDCardTypeGroup.includes(formInfo.certType)}
									/>
								</div>

								<div className='item-wrap'>
									<InsInputLabel className='item-wrap_label' text='出生日期' require={true} />
									<DatePicker
										className='item-wrap_select'
										locale={locale}
										placeholder={'请选择/输入(格式:YYYY-MM-DD)'}
										disabledDate={disabledDate}
										disabled={IDCardTypeGroup.includes(formInfo.certType)}
										value={formInfo.birthday ? moment(formInfo.birthday, 'YYYY-MM-DD') : undefined}
										onChange={onBirthdayChange}
									/>
								</div>

							</div>

							{
								limitData?.supportOccupationLimit &&
								<div className='item-wrap'>
									<InsInputLabel className='item-wrap_label' text='职业类别' require={true} />
									<InsuredOccupationSelect
										className='item-wrap_select'
										limitData={limitData?.supportOccupationTypes}
										data={formInfo.occupation}
										onChange={onOccupationChange}
									/>
								</div>
							}

							{
								limitData?.socialSecurity &&
								<div className='item-wrap'>
									<Popover
										content={MedicalTips}
										title=''
										trigger='click'
										placement='rightBottom'
									>
										<div className='v-popover-wrap' />
									</Popover>
									<InsInputLabel className='item-wrap_label' text='有无医保' require={true} showTips={true} />
									<InsRadioGroup
										options={MEDICAL_OPTIONS}
										value={formInfo.medical}
										onChange={onMedicalChange}
									/>
								</div>
							}

							{
								deductibleData?.length > 0 &&
								<div className='item-wrap'>
									<InsInputLabel className='item-wrap_label' text='免赔额' require={true} />
									<DetailRadioGroup
										data={deductibleData}
										onChange={handleDeductibleChange}
										checkedCode={formInfo.deductible}
										type={'deductible'}
									/>
								</div>
							}

							{
								showExpensiveHospital &&
								<div className='item-wrap'>
									<Popover
										content={ExpensiveHospitalTips}
										title='昂贵医院清单'
										trigger='click'
										placement='rightBottom'
									>
										<div className='v-popover-wrap' />
									</Popover>
									<InsInputLabel className='item-wrap_label' text='昂贵医院' require={true} showTips={true} />
									<InsRadioGroup
										options={EXPENSIVE_HOSPITAL_OPTIONS}
										value={formInfo.expensiveHospital}
										onChange={onExpensiveHospitalChange}
									/>
								</div>
							}

						</div>
					}

					<div className='item-footer'>
						<InsButton
							className='btn-submit'
							value='确定'
							onClick={handleSubmit}
						/>
						<InsButton
							className='btn-cancel'
							value='取消'
							mode={'plain'}
							onClick={handleCancel}
						/>
					</div>

				</div>
			</InsDialog>
		</React.Fragment>
	);
};

export default memo(InsuredDialog);
