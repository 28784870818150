import React, { useState, useEffect } from "react";
import "./index.scss";
import zuojiantou from "../../img/zuojiantou.png";
import yinhao from "../../img/yinhao.png";
import BuyBtn from "../BuyBtnNew";
import { useNavigate } from "react-router-dom";
import { IMGURL } from "lib/config";
import LazyImg from "common/components/LazyImg";
import QueueAnim from "rc-queue-anim";
import { OverPack } from "rc-scroll-anim";
import { Button } from "antd";
import { LINK_TO_BXSC} from "lib/utils";

type JingxuanProps = {
  jingxuan: any;
  recommendInfo: any;
};
const RecommendProduct: React.FC<JingxuanProps> = (props: any) => {
  const navigate = useNavigate();
  const { recommendInfo, jingxuan } = props;
  const { groups } = jingxuan;
  const [serial, setSerial] = useState(0);
  const [isScroll, setIsScroll] = useState(false);
  const tagText = "官方自营 | 高性价比 | 理赔省心";

  const handleToJump = (num: any) => {
    setSerial(num);
  };

  //内链和外链跳转url
  const getJumpUrl = (type: any, link: any, id: any) => {
    let url = "";
    if (type === "1") {
      url = link;
      window.open(link, "_blank");
    } else {
      url = `${window.location.origin}/bxsc/product/detail?p=${id}&jdaz_chl=360&jdaz_source=PC`;
      window.location.href = url
    }
    return url;
  };

  useEffect(() => {
    console.log(recommendInfo, jingxuan);
    setTimeout(() => {
      setIsScroll(true);
    }, 500);
  }, []);
  return (
    <div className="relection_recommend_wrap">
      <div className="relection_recommend" key="recommend">
        <div className="commend_title" key="title">
          <div className="title_left">
            <div className="commend_bigTit">京选推荐</div>
          </div>
          <div className="title_right">
            {tagText.split("|").map((str: string, index: number) => (
              <span className="title_right_tag" key={index}>
                {str}
              </span>
            ))}
          </div>
        </div>

        <OverPack
          style={{ height: "690px" }}
          playScale={0.1}
          // @ts-ignore
          always={false}
          appear={isScroll}
        >
          <QueueAnim key="queue" type={"bottom"} delay={100} duration={600}>
            <div className="kind" key="kind">
              <div className="kind_all noselect">
                {recommendInfo.map((item: any, index: any) => (
                  <div
                    key={index}
                    className={
                      index === serial ? "_product_for" : "product_for"
                    }
                    onClick={() => handleToJump(index)}
                    onMouseEnter={() => {
                      handleToJump(index);
                    }}
                  >
                    {item?.categoryName}
                  </div>
                ))}
              </div>
              <div
                className="more"
                onClick={() => {
                  LINK_TO_BXSC()
                }}
              >
                <div className="more_mesg">查看更多</div>
                <div className="more_img">
                  <img src={zuojiantou} alt="" />
                </div>
              </div>
            </div>
            <div className="desc" key="desc">
              <div className="desc_img">
                <img src={yinhao} alt="" />
              </div>
              <div className="desc_mesg">
                {recommendInfo[serial]?.categoryDesc}
              </div>
            </div>
            <div className="recommend" key="recommend">
              {Array.isArray(recommendInfo[serial]?.productions) &&
                recommendInfo[serial]?.productions.map(
                  (item: any, index: any) => (
                    <div className="recommend_product" key={index}>
                      <div className="recomend_img">
                        <LazyImg src={item.recommendImageUrl} />
                      </div>
                      <div className="product_info">
                        <h2 className="product_name">{item.productionName}</h2>
                        <div className="product_description">
                          {item.recommendReasonDesc
                            .split(";")
                            .map((label, index) => (
                              <span key={index}>{label}</span>
                            ))}
                        </div>
                        <div className="product_operation">
                          <div className="product_price">
                            <div
                              className="price"
                              title={item.productionBottomPrice}
                            >
                              {item.productionBottomPrice}
                            </div>
                            &nbsp;
                            <div className="price_start">元/起</div>
                          </div>
                          {
                            <div style={{ display: "flex" }}>
                              {item.recommendProductionAllowPolicyType ===
                                1 && (
                                <div style={{ marginRight: "10px" }}>
                                  <Button
                                    className="jumpBtn"
                                    onClick={() =>
                                      getJumpUrl(
                                        item.productionType,
                                        item.productionLink,
                                        item.productionId
                                      )
                                    }
                                  >
                                    <span className="title">立即投保</span>
                                  </Button>
                                </div>
                              )}
                              <BuyBtn
                                recommendProductionAllowPolicyType={
                                  item.recommendProductionAllowPolicyType
                                }
                                text={"扫码投保"}
                                link={{
                                  type: item.productionType,
                                  link:
                                    item.productionType === "1"
                                      ? item.productionLink
                                      : item.productionId,
                                }}
                              />
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  )
                )}
            </div>
          </QueueAnim>
        </OverPack>
      </div>
    </div>
  );
};

export default RecommendProduct;
