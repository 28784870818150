import React, { memo, useState, useEffect } from 'react';
import './index.scss';
import { IDetailAgreementItem } from '@/lib/order/types'
import InsDialog from '@/order-components/ins-dialog';
import InsDialogHeader from '@/order-components/ins-dialog-header'
import InsButton from '@/order-components/ins-button';
import DetailMergeAgreement from '../detail-agreement-merge'
import InsScrollView from '@/order-components/ins-scroll-view'

interface IProps {
	show: boolean,
	onClose: () => void,
	onConfirmCallback: () => void,
	data: IDetailAgreementItem[],
	current: number,
	total: number
}

const AgreementMergeDialog = (props: IProps) => {

	const {show, onClose, onConfirmCallback, data, current, total} = props

	const [readOverState, setReadOverState] = useState(false)

	useEffect(() => {
		if (!show) {
			setReadOverState(false);
		}
	}, [show])

	const handleOnScrollToLower = () => {
		setReadOverState(true);
	}

	const handleOnOnePage = () => {
		setReadOverState(true);
	}

	return (
		<InsDialog
			show={show}
			onClose={onClose}
			location={'center'}
			disableOverlayClose={true}
		>
			<div className='agreement-merge-dialog'>
				<InsDialogHeader
					title='责任免除及赔付说明'
					onClose={onClose}
				/>
				<InsScrollView
					className='agreement-merge-dialog__body'
					onScrollToLower={handleOnScrollToLower}
					data={data}
					onOnePage={handleOnOnePage}
				>
					{
						// show &&
						data?.length > 0 &&
						data.map((item, index) => {
							return (
								<React.Fragment key={`agreement-merge-dialog-item-${index}`}>
									<DetailMergeAgreement data={item} />
								</React.Fragment>
							)
						})
					}
				</InsScrollView>
				<div className='agreement-merge-dialog__footer'>
					<span className='v-footer-step'>{current}/{total}</span>
					<InsButton
						id={'agreement-merge-dialog-footer-agree'}
						className='v-footer-btn'
						value={readOverState ? '我已逐页阅读并同意' : '阅读至页面底部可点击'}
						onClick={onConfirmCallback}
						disabled={!readOverState}
					/>
				</div>
			</div>
		</InsDialog>
	)
}

export default memo(AgreementMergeDialog)
