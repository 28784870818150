import React, { useEffect, useState } from 'react';
import './index.scss';
import { navData } from './staticData';
import { useLocation } from 'react-router-dom';
// import download_icon from 'static/imgs/download_icon.svg';
import { previewPdf } from 'lib/utils';
import { ReactComponent as download_icon } from 'static/imgs/download_icon.svg';
import Icon from '@ant-design/icons';

const BASE_CDN_PATH = 'https://img03.az.jd.com/ins-user-m/develop/website/pdf/';

const emptyData = {
  title: '',
  navMenus: [
    {
      name: '',
      subMenus: [
        {
          name: '',
          id: '',
          list: [
            {
              urlLink: '',
              title: '',
            },
          ],
        },
      ],
    },
  ],
};

const CommonPage: React.FC = () => {
  const location = useLocation();
  const { pathname } = location;
  const [, , path] = pathname.split('/');
  const [activeData, setActiveData] = useState(emptyData);
  const [activeIndex, setActiveIndex] = useState(0);
  const [subActiveIndex, setSubActiveIndex] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setActiveIndex(0);
    setSubActiveIndex(0);
    // @ts-ignore
    if (navData[path]) {
      // @ts-ignore
      setActiveData(navData[path]);
    } else {
      setActiveData(emptyData);
    }
  }, [path]);

  const onOneLevenClick = (index: number) => {
    setActiveIndex(index);
    setSubActiveIndex(0);
  };

  return (
    <div className='common-page-wrap'>
      <h2 className='title'>{activeData.title}</h2>
      <div className='qa_nav_wrap'>
        {activeData.navMenus.map((item, index) => (
          <div
            className={activeIndex === index ? 'qa_nav active' : 'qa_nav'}
            onClick={() => onOneLevenClick(index)}
            key={index}
          >
            {item.name}
          </div>
        ))}
      </div>

      <div className='btn_nav_wrap'>
        {activeData.navMenus[activeIndex].subMenus.map((item, index) => (
          <div
            className={subActiveIndex === index ? 'btn_nav active' : 'btn_nav'}
            onClick={() => setSubActiveIndex(index)}
            key={index}
          >
            {item.name}
          </div>
        ))}
      </div>

      <div className='p_wrap'>
        {activeData.navMenus?.[activeIndex]?.subMenus?.[
          subActiveIndex
        ]?.list.map((child, index) => (
          <div
            key={child.title}
            className='line_item'
            onClick={() => previewPdf(BASE_CDN_PATH + child.urlLink)}
          >
            <div className='line_title'>{child.title}</div>
            <div className='line_img'>
              {/* <img src={download_icon} alt='' /> */}
              <Icon component={download_icon} className={'down_icon'} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CommonPage;
