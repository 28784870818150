import React, { useState, useEffect } from 'react';
import './index.scss';
import PhoneStore from '../../img/phoneStore.png';
import classNames from 'classnames';
import { getCustomerPath } from 'lib/Apis';
const Floating: React.FC = () => {
  const [isFloating, setIsFloating] = useState(false);
  const getCustomer = async () => {
    try {
      const res = await getCustomerPath();
      console.log(res);
      if (typeof res === 'string') {
        window.open(res);
      }
    } catch (e) {
      console.log(e);
    }
  };
  // useEffect(() => {
  //   window.addEventListener("scroll", function () {
  //     const scrollTop =
  //       document.body.scrollTop || document.documentElement.scrollTop;
  //     setIsFloating(scrollTop > 355);
  //   });
  // }, []);
  return (
    // <div className={classNames('floating', isFloating ? 'isFloating' : '')}>
    <div className='floating'>
      <div className={classNames('service')} onClick={getCustomer}>
        <div className='service_img'></div>
        <div className='des'>在线客服</div>
      </div>
      <div className={classNames('service', 'phone')}>
        <div className='phone_img '></div>
        <div className='des'>手机商城</div>
        <div className='phone_store'>
          <div className='phone_img'>
            <img src={PhoneStore} alt='' />
          </div>
          <p className='text'>扫码查看更多产品</p>
          <div className='arrow'></div>
        </div>
      </div>
      <div
        className='goUp'
        onClick={() => {
          window.scrollTo({
            left: 0,
            top: 0,
            behavior: 'smooth',
          });
        }}
      >
        <div className='goUpImg'></div>
      </div>
    </div>
  );
};

export default Floating;
