export const data1 = [
  {
    title: '通用情况',
    children: [
      {
        question: '在网上买保险，安全吗？',
        answer: [
          {
            type: 'p',
            value:
              '我们将通过有效的技术手段和管理措施对投保人、被保险人的个人信息、投保交易信息等非公开信息进行保密，严格限制保密信息的接触人，妥善保管保密信息并在能力范围内通过各种技术手段保证交易安全。',
          },
        ],
      },
      {
        question: '电子保单有效吗？',
        answer: [
          {
            type: 'p',
            value:
              '网上投保为您提供电子保单。根据《中华人民共和国合同法》第十一条规定，数据电文是合法的合同表现形式，电子保单与纸质保单具有同等法律效力。办理电子保单的理赔业务时无需提供保单。',
          },
        ],
      },
      {
        question: '电子发票的法律效力与普通纸质发票相同吗？',
        answer: [
          {
            type: 'p',
            value:
              '根据国家税务总局发布的《关于推行通过增值税电子发票系统开具的增值税电子普通发票有关问题的公告》，增值税电子普通发票在法律效力、基本用途、基本使用规定等方面等同于增值税普通纸质发票。',
          },
        ],
      },
      {
        question: '电子发票如何验证？',
        answer: [
          {
            type: 'a',
            value:
              '电子发票下载成功后，客户可登陆<a href="http://dzdz.cbit.com.cn/#/" class="link" target="_blank"> http://dzdz.cbit.com.cn/#/</a>，凭借电子发票上所标示的发票代码、发票号码、防伪码在【中国银保信发票服务平台】进行验证。',
          },
        ],
      },
    ],
  },
  {
    title: '个人险',
    children: [
      {
        question: '个人险如何进行理赔申请？',
        answer: [
          {
            type: 'a',
            value:
              '报案人可采取以下两种方式：1.<span style="color:#E1251B">电话报案</span>（全国服务热线 950610）；2.可以通过<span style="color:#E1251B">京东安联保险公众号自助服务平台</span>，绑定信息后进行自助报案（线上报案有条件限制，详见自助报案界面提示）。',
          },
        ],
      },
      {
        question: '需要救援服务时如何联系？',
        answer: [
          {
            type: 'p',
            value:
              '我们将为您提供7*24小时优质服务，国内请拨打热线 950610；国外请拨打热线(+86)20-8513-2999，并请确认拨打的电话已开通漫游功能。听到语音播报后选择2-救援服务。',
          },
        ],
      },
      {
        question: '个人险如何递交理赔资料？',
        answer: [
          {
            type: 'h',
            value: '第一步：报案。',
          },
          {
            type: 'p2',
            value:
              '拨打京东安联财险客服热线 950610或者通过京东安联保险公众号自助服务进行报案。',
          },
          {
            type: 'h',
            value: '第二步：根据客服或自助报案界面指引收集好所有理赔所需资料。',
          },
          {
            type: 'h',
            value: '第三步：根据要求在线上传或快递邮寄理赔资料。',
          },
          {
            type: 'p2',
            value: '如需邮寄资料，请将理赔资料的原件快递至我司（费用自理）。',
          },
          {
            type: 'p2',
            value:
              '地址：广东省广州市天河区珠江西路5号广州国际金融中心34层10单元',
          },
          {
            type: 'p2',
            value: '收件人：非车理赔部',
          },
          {
            type: 'p2',
            value: '前台电话：020-85132900',
          },
          {
            type: 'h',
            value:
              '第四步：保险公司告知理赔决定，保险金将通过转账的方式支付至被保险人本人账户。',
          },
          {
            type: 'h',
            value: '第五步：理赔查询。',
          },
        ],
      },
      {
        question: '如何查询理赔进度？',
        answer: [
          {
            type: 'h',
            value: '官网页面查询',
          },
          {
            type: 'a',
            value:
              '<p class="qa_p2">1.登录<a href="https://www.jdallianz.com" class="link" target="_blank">https://www.jdallianz.com</a></p>',
          },
          {
            type: 'p2',
            value: '2.点击页面上方的“保险服务”',
          },
          {
            type: 'p2',
            value: '3.选择对应险种服务，选择“理赔查询”',
          },
          {
            type: 'p2',
            value: '4.在“保单类型”中选择对应险种',
          },
          {
            type: 'p2',
            value:
              '5.输入“保单号”、“被保险人名称”、“被保险人证件号”等相关信息，点击查询',
          },
          {
            type: 'p2',
            value: '6.点击“核赔”后进入“赔案明细”查看详情',
          },
          {
            type: 'h',
            value: '微信自助服务平台查询',
          },
          {
            type: 'p2',
            value: '1. 关注微信公众号“京东安联保险”并绑定被保险人信息',
          },
          {
            type: 'p2',
            value: '2. 点击“自助服务”下的“理赔服务”',
          },
          {
            type: 'p2',
            value: '3. 点击“理赔记录”',
          },
          {
            type: 'p2',
            value: '4. 理赔列表中会展示理赔中、已结案和待提交的案件',
          },
          {
            type: 'p2',
            value:
              '5. 点击案件进入案件详情界面，可查看案件的处理进度和处理时间',
          },
        ],
      },
      {
        question: '我购买的保单是否有“税优识别码”？',
        answer: [
          {
            type: 'p',
            value:
              '我司目前销售的产品均不属于税收优惠型健康产品，因此您在我司购买的保险产品没有“税优识别码”。※“税优识别码”是指为确保税收优惠商业健康保险保单的唯一性、真实性、有效性，由商业健康保险信息平台按照“一人一单一码”的原则对投保人进行效验后，下发给保险公司，并在保单上打印的数字识别码。',
          },
        ],
      },
      {
        question: '新农合属于社会医疗保险吗？',
        answer: [
          {
            type: 'p',
            value:
              '社会医疗保险是指包括新型农村合作医疗、城镇职工基本医疗保险、城镇居民基本医疗保险等政府举办的基本医疗保障项目。如果客户已经投保了“新农合”，属于“社会医疗保险”，可选择“有社保”。',
          },
        ],
      },
      {
        question: '什么是意外？',
        answer: [
          {
            type: 'p',
            value:
              '意外按照保险业的常见定义，即指因意外导致身体受到伤害的事件。意外伤害是指外来的、突发的、非本意的、非疾病的使身体受到伤害的客观事件。',
          },
        ],
      },
      {
        question: '意外健康险生效日后如果退保，退保费用是如何计算的?',
        answer: [
          {
            type: 'p',
            value: '以您保单所载明的退保计算公式为准，每款产品的退保公式将在条款中列明',
          },
        ],
      },
      {
        question: '臻爱医疗保险是否在国家金融监督管理局备案，如何查询备案的信息？',
        answer: [
          {
            type: 'a',
            value:
              '<p>登录【财产保险公司自主注册产品查询】<a href="http://cxcx.iachina.cn/" target="_blank" class="link">系统进行自助查询（http://cxcx.iachina.cn/）</a>。使用【公司产品查询】在公司名称底下查找【京东安联财产保险有限公司】，留意产品状态【在售】按【查询】按钮，查询结果是京东安联财产保险有限公司所有在售保险的合同备案信息。</p>',
          },
        ],
      },
    ],
  },
  {
    title: '车险',
    children: [
      {
        question: '什么是交强险？',
        answer: [
          {
            type: 'p',
            value:
              '交强险是由保险公司对被保险机动车发生道路交通事故造成受害人（不包括本车人员和被保险人）的人身伤亡、财产损失，在责任限额内予以赔偿的强制性责任保险。',
          },
        ],
      },
      {
        question: '什么是机动车辆商业险？',
        answer: [
          {
            type: 'p',
            value:
              '机动车辆商业险包括主险和附加险两种。其中主险包括车辆损失险、商业第三者责任险、车上人员责任险、全车盗抢险共四个。附加险包括附加车身划痕损失险、附加车上货物责任险、附加医保外医疗费用责任险等险种。',
          },
        ],
      },
    ],
  },
];
