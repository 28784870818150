import React from 'react';
import './index.scss';
// import { data1 } from './staticData';
import alIcon from 'static/imgs/alIcon0627.png';
const ServePromise: React.FC = () => {
  return (
    <div className='achina-wrap'>
      <h2 className='title'>安联在中国</h2>
      {/* <p className='fee_desc mr_b'>
        <span className='f_w'>
          中国市场对于安联集团具有重要的战略发展意义。
        </span>
      </p> */}
      <p className='fee_desc2 mr_b'>
        安联集团在中国大陆已经拥有了包括寿险和健康险、财产险、资产管理、救援服务、信用保险和基金管理等业务在内的全部核心业务，全面致力于在中国金融市场上的发展
      </p>
      <img style={{width: '750px'}}  src={alIcon} alt='图片'/>
      {/* <div className='box2'>
        {data1.map((item, index) => (
          <div className='box2_item' key={index}>
            <p className='box2_p1'>{item.title}</p>
            <p className='box2_p2'>-</p>
            <p className='box2_p3 box2_mb'>{item.value}</p>
          </div>
        ))}
      </div> */}
    </div>
  );
};

export default ServePromise;
