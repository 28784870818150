import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import './index.scss';
import { IMGURL } from 'lib/config';
import nav_arrow_r from 'static/imgs/arrow_right.svg';
import nav_tuozhuai from 'static/imgs/tuozhuai.svg';
import imgDetail from 'static/imgs/fwldImage1.png';
import shuIcon from 'static/imgs/shuIcon.png';
import { LINK_TO_BXSC} from "lib/utils";
const Main: React.FC = () => {
  const navigate = useNavigate();
  const [getSearchArr] = useSearchParams();
  useEffect(() => {  
    const handleWindowLoad = () => {
      // @ts-ignore
      MktoForms2?.loadForm(
        'https://info.allianz-trade.com',
        '133-WKC-682',
        41378
      );
    };
    handleWindowLoad()
  }, []);
  const goBack = () => {
    LINK_TO_BXSC()
    // const backPath = '/bxsc/xyx';
    // navigate(backPath);
  };
  return (
    <div className='bxscDetail_wrap'>
      <div className='news_nav_wrap'>
        <img src={nav_tuozhuai} alt='icon' />
        <div className='news_nav_back' onClick={goBack}>
          保险商城
        </div>
        <img src={nav_arrow_r} alt='icon' />
        <div className='news_nav_title'>信用险</div>
      </div>
      <div className='bxscDetail_box'>
        <div className='mainTitle'>
          <img className='mainTitleIcon' src={shuIcon} />
          信用险
        </div>
        <div className='content'>
          <div className='title'>
            <span className='tag'>产品特色</span>
            贸易信用保险的保障范围
          </div>
          <div className='detail'>
            贸易信用保险能保障企业因未偿付的商业债务而蒙受损失，即企业对企业的应收帐款。假如买方因破产、无力清偿或其他因素导致您无法如期收到款项，贸易信用保险将会支付该笔未偿债务九成的金额，让您的资本和现金流得到保障，并在您为提升竞争力而放宽信用条件的同时确保收益，进而帮助您达成更优胜的企业融资方案。
            在贸易信用保险的应收账款保障下，贸易产生的商业及政治风险即使难以控制，您也能轻松对其进行管理。此外，在您向目前既有的客户展延信用，或开发信用风险较高的潜在大宗客户时，购买贸易信用保险也能让您感到安心。
          </div>
          <div className='title'>
            <span className='tag'>服务亮点</span>
            您需要购买贸易信用保险的八大原因
          </div>
          <div className='imgBox'>
            <img src={imgDetail} />
          </div>
          <div className='title'>
            <span className='tag tag1'>保护您的企业免受拖欠付款而蒙受损失</span>
          </div>
          <div className='detail'>
            通过企业风险管理资料分析，即使客户无力清偿，京东安联信用险也能为您企业的潜在损失做好准备。欢迎与我们的贸易信用专家取得联系！
          </div>
        </div>
        <div
          onClick={() =>
            window.open(`${IMGURL}${getSearchArr.getAll('link')[0]}`)
          }
          className='downLoad'
        >
          下载在线指南PDF
        </div>
      </div>
      <div className='marketo_box'>
        <div className='mainTitle mainTitle1'>
          <img className='mainTitleIcon' src={shuIcon} />
          预约免费咨询
        </div>
        <form id='mktoForm_41378'></form>
      </div>
    </div>
  );
};
export default Main;
