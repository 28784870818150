import React, {useState, useEffect} from 'react';
import './index.scss';
import { data1, data2, data3, data4 } from './staticData';
import phoneSvg from 'static/imgs/dianhua.png';
import procedureJpg from 'static/imgs/procedure20230506.jpg';
import { useLocation } from 'react-router-dom';
const CompanyProfile: React.FC = () => {
  const location = useLocation()
  useEffect(() => {
    if (location.hash) {
      const element = document.querySelector(location.hash);
      if (element) {
        element.scrollIntoView();
      }
    }
  }, [location]);
  const contactService = () => {
    var link = 'https://jimi-jtalk.jdallianz.com/hindex.htm';
    var entrance = 40010;
    var tenantId = 3;
    var kfSwitchLink = link + '?entrance=' + entrance + '&tenantId=' + tenantId;
    window.open(kfSwitchLink);
  };
  const [isHoverClaim, setIsHoverClaim] = useState(false)
  const [isHover, setIsHover] = useState(false)
  return (
    <div className='company-info-wrap'>
      <h2 className='title'>公司概况</h2>
      <div className='table_l_r'>
        {data1.map((item) => (
          <div key={item.title} className='table_item'>
            <div className='table_l_r_left'>{item.title}</div>
            <div className='table_l_r_right'>
              {item.value.map((subItem, index) => {
                return typeof subItem === 'string' ? (
                  <p key={index}>{subItem}</p>
                ) : Object.prototype.toString.call(subItem) ===
                  '[object Object]' ? (
                  <div key={index}>
                    <h3 className='sub_title'>{subItem?.subTitle}</h3>
                    {subItem?.value.map((subValue) => (
                      <p key={subValue}>{subValue}</p>
                    ))}
                    {item.value.length !== index - 1 && (
                      <div className='delay_block'></div>
                    )}
                  </div>
                ) : (
                  <></>
                );
              })}
            </div>
          </div>
        ))}
      </div>
      <h3 className='sub_title'>客服电话、投诉渠道和投诉处理程序</h3>
      <div className='title_3'>
        <div className='tag_block'></div>
        <h4 className='title_3_text'>客户服务热线电话</h4>
      </div>
      <div className='box_2'>
        <div className='line'>
          <div className='box_2_left'>统一客服热线：</div>
          <div className='box_2_right'>
            <span className='num'>950610</span>
            <span className='kuo'>（中国境内）&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <span className='num'>+8620-8513-2999</span>
            <span className='kuo'>（中国境外）</span>
          </div>
        </div>
        <div className='line'>
          <div className='box_2_left'>国际医疗险客服热线：</div>
          <div className='box_2_right'>
            <span className='num'>400-067-1800</span>
            <span className='kuo'>（中国境内）&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <span className='num'>+8610-8535-5624</span>
            <span className='kuo'>（中国境外）</span>
          </div>
        </div>
        <div className='line'>
          <div className='box_2_left'>服务时间：</div>
          <div className='box_2_right'>
            24小时<span className='kuo'>（全年不间断）</span>
          </div>
        </div>
      </div>

      <div className='title_3'>
        <div className='tag_block'></div>
        <h4 className='title_3_text'>在线文字客服</h4>
      </div>
      <div className='box_2'>
        <div className='line'>
          <div className='box_2_left'>微信公众号：</div>
          <div className='box_2_right'>京东安联保险</div>
        </div>
        <div className='line'>
          <div className='box_2_left'>服务时间：</div>
          <div className='box_2_right'>周一至周日 09：00 - 22：00</div>
        </div>
        <div className='line'>
          <div className='box_2_left'> 官网在线客服：</div>
          <div className='box_2_right'>
            <div className='online_box' onClick={contactService}>
              <img src={phoneSvg} alt='' /> 联系客服
            </div>
          </div>
        </div>
      </div>

      <div className='title_3'>
        <div className='tag_block'></div>
        <h4 className='title_3_text'>接收投诉渠道</h4>
      </div>
      <div className='box_2'>
        <div className='line'>
          <div className='box_2_left'>客户服务热线：</div>
          <div className='box_2_right'>950610</div>
        </div>
        <div className='line'>
          <div className='box_2_left'>微信服务号：</div>
          <div className='box_2_right'>“京东安联保险”微信公众号</div>
        </div>
        <div className='line'>
          <div className='box_2_left'>电子邮件：</div>
          <div className='box_2_right'>
            <a href='mailto:Contact@allianz.cn' className='blueBorder'>
              Contact@allianz.cn
            </a>
          </div>
        </div>
        <div className='line'>
          <div className='box_2_left'>在线投诉渠道：</div>
          <div className='box_2_right'>
            <div className='online_box' onClick={contactService}>
              <img src={phoneSvg} alt='' /> 联系客服
            </div>
          </div>
        </div>
      </div>
      <div className='title_3'>
        <div className='tag_block'></div>
        <h4 className='title_3_text'>理赔申请流程</h4>
      </div>
      <div className='box_3'>
        <p className='header' style={{fontWeight: 'bold'}}>线上申请：</p>
        <p className='detail' >1.微信公众号“<span style={{color: '#FA230A'}}>京东安联保险</span>” &gt; 自助服务 &gt; 理赔服务 &gt; 我要理赔（需登录）</p>
        <p className='detail' style={{marginBottom: '10px'}}>2.微信小程序“京东安联客户服务” &gt; 理赔服务 &gt;我要理赔（需登录）</p>
        <p className='header' style={{fontWeight: 'bold'}}>线下理赔：</p>
        <p className='detail'>拨打全国理赔服务热线<span style={{color: '#546AAF'}}>950610</span></p>
      </div>
      <div className='title_3'>
        <div className='tag_block'></div>
        <h4 className='title_3_text'>理赔争议处理流程</h4>
      </div>
      <div className='box_3'>
        <p>理赔争议处理机制参照投诉处理流程执行。如双方协商无法达成共识，消费者可采取申请核查、调解、仲裁、诉讼等途径解决。如有调解需求，可联系当地保险纠纷调解组织申请调解或请分支机构服务人员协助，联系方式详见公开信息披露。</p>
      </div>
      <div className='title_3'>
        <div className='tag_block'></div>
        <h4 className='title_3_text'>信息安全保护措施</h4>
      </div>
      <div className='box_3'>
        <p className='header' style={{fontWeight: 'bold'}}>1. 信息安全</p>
        <p className='detail' style={{marginBottom: '10px'}}>目前我司互联网保险业务，均已采用加密传输协议(https)或证书方式进行信息加密传输，采取了适当的硬件、电子及管理措施以保障网上个人信息、投保交易信息和交易的安全。</p>
        <p className='header' style={{fontWeight: 'bold'}}>2. 个人信息保护</p>
        <p className='detail'><a href="https://img03.az.jd.com/ins-user-m/develop/html/private.html">《京东安联隐私政策》</a></p>
      </div>
      <div className='title_3'>
        <div className='tag_block'></div>
        <h4 className='title_3_text'>投诉处理流程</h4>
      </div>
      <div className='box_5'>
        <a
          target='_blank'
          href={procedureJpg}
          className='link'
          rel='noreferrer'
        >
          <span>京东安联保险客户投诉处理流程</span>
        </a>
      </div>
      <h3 className='sub_title'>各地保险业纠纷调解组织和联系方式</h3>

      <p className='sub_title_desc'>
        消费者与保险公司因履行保险合同发生的争议，由当事人协商解决。协商无法达成共识的，消费者可向保险合同归属地的保险纠纷调解组织申请调解。
        如有调解需求，可联系当地调解组织或请分支机构服务人员协助，附部分调解组织联系方式如下：
      </p>
      <div className='box_4'>
        <div className='box_4_header'>
          <div className='header_item'>保险纠纷调解组织</div>
          <div className='header_item body_item_border' style={{'width': '40%'}}>地址</div>
          <div className='header_item'>联系电话</div>
          <div className='header_item'  style={{'borderLeft': '1px solid rgba(0,0,0,0.08)'}}>线上渠道</div>
        </div>

        {data2.map((item, index) => (
          <div className='box_4_body' key={index} id='section1'>
            <div className='body_item'>{item.name}</div>
            <div className='body_item body_item_border body_item2' style={{'width': '40%'}}>{item.addr}</div>
            <div className='body_item'>{item.phone}</div>
            <div className='body_item body_item1' style={{'borderLeft': '1px solid rgba(0,0,0,0.08)'}}>{item.channel}</div>
          </div>
        ))}
      </div>

      <h3 className='sub_title'>
        各分支机构营业场所和联系电话
      </h3>
      <div className='box_4'>
        {data3.map((item, index) => (
          <div className='box_4_body' key={index}>
            <div className='body_item'>{item.name}</div>
            <div className='body_item body_item_border'>{item.addr}</div>
            <div className='body_item'>{item.phone}</div>
          </div>
        ))}
      </div>

      <h3 className='sub_title'>承保机构设有落地服务机构信息</h3>
      <div className='box_4'>
        {data4.map((item, index) => (
          <div className='box_4_body' key={index}>
            <div className='body_item border_item_right' style={{width: '50%'}}>{item.nameL}</div>
            <div className='body_item w_2' style={{width: '50%'}}>{item.nameR}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CompanyProfile;
