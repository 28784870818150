export const setSessionStorageItem = (key: string, value: string) => {
	try {
		window.sessionStorage && window.sessionStorage.setItem(key, value || '');
		return !!window.sessionStorage;
	} catch (e) {
		return false;
	}
}

export const getSessionStorageItem = (key: string) => {
	try {
		return window.sessionStorage ? window.sessionStorage.getItem(key) || '' : '';
	} catch (e) {
		return '';
	}
}

export const removeSessionStorageItem = (key: string) => {
	try {
		window.sessionStorage && window.sessionStorage.removeItem(key);
		return !!window.sessionStorage;
	} catch (e) {
		return false;
	}
}

export const removeAllSessionStorageItem = () => {
	try {
		window.sessionStorage && sessionStorage.clear();
		return !!window.sessionStorage;
	} catch (e) {
		return false;
	}
}
