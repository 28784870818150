import React, {} from "react";
import "./index.scss";
import {IMGURL} from 'lib/config'
type gzwmProps = {
        gzwm: any;
  };
  const Gzwm: React.FC<gzwmProps> = ({gzwm}) => {
  return (
    <>
    <div className="gzwm">
         <img src={`${IMGURL}${gzwm.img}`} alt="" />
    </div>
    </>
  )
  }
  export default Gzwm;