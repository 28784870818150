import React from "react";
import "rc-texty/assets/index.css";
import "rc-banner-anim/assets/index.css";
import "./index.scss";
import pre_video from "../../img/pre_videoNew.jpg";

interface IProps {
  defaultStringAll: string[];
}

const Banner = (props: IProps) => {
  return (
    <div className="baner-wrap">
      <div className="video-container">
        <video
          className="baner-video"
          poster={pre_video}
          loop
          preload="metadata"
          playsInline
          muted
          autoPlay
        >
          <source
            src="https://img03.az.jd.com/ins-user-m/develop/website/sp3.mp4"
            type="video/mp4"
          />
          <source
            src="https://img03.az.jd.com/ins-user-m/develop/website/sp3.mp4"
            type="video/ogg"
          />
          <source
            src="https://img03.az.jd.com/ins-user-m/develop/website/sp3.mp4"
            type="video/webm"
          />
        </video>
        <div className="overlay"></div> {/* 添加蒙层 */}
      </div>
      <div className="text">
        <div className="text-left">
          <span className="text-left-title">京东集团下自营保险品牌</span>
          <span className="text-left-content">
            京东安联保险由京东集团与德国安联集团在2018年合力打造，为个人和企业客户提供电商互联网保险、意外健康保险、汽车保险等，致力于成为最值得信赖的保险服务供应商。
          </span>
        </div>

        <div className="text-center"></div>

        <div className="text-right">
          <div className="text-right-data">
            <div className="text-right-data-list">
              <div className="list-top">
                <span className="number">2.52</span>
                <span className="unit">亿</span>
              </div>
              <div className="list-bottom">
                <span className="introduce">年服务用户数 (人)</span>
              </div>
            </div>
            <div className="text-right-data-list">
              <div className="list-top">
                <span className="number">27.1</span>
                <span className="unit">万亿</span>
              </div>
              <div className="list-bottom">
                <span className="introduce">累计保额 (元）</span>
              </div>
            </div>
            <div className="text-right-data-list">
              <div className="list-top">
                <span className="number">2848.3</span>
                <span className="unit">万</span>
              </div>
              <div className="list-bottom">
                <span className="introduce">累计赔付案件数 (件）</span>
              </div>
            </div>
          </div>

          <div className="text-right-explain">
            <span className="text-right-explain-title">
              以上为京东安联财产保险有限公司截止2023年12月的统计数据
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
