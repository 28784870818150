import React, { useEffect, useState } from "react";
import './index.scss';
import Gzwm from 'common/components/Gzwm';
import Floating from '../../common/components/Floating'
import {getPathApi,getMenusApi} from 'lib/Apis'
interface Floor {
  [key: string]: any;
  type: string;
}

const Main:React.FC = () => {
  let [floorsList, setFloorsList] = useState<Floor[]>([]);
  useEffect(() => {
    getFloors();
  }, []);

  const getFloors = async () => {
    try {
      const path: any = await getPathApi("gzwm");
      const floorsObj: any = await getMenusApi(path.dataPath);
      setFloorsList(floorsObj.floors);
    } catch (e) {
      console.log(e);
    }
  }
  return (
    <div className='gzwm_wrap'>
       {
        floorsList.map((floor,index)=>{
          return(
            <section key={index}>
             {
             floor.type === 'img'&& <Gzwm gzwm={floor.img} />
             } 
            </section>

          )
        })   
      } 
      <Floating/>
    </div>
  );
};
export default Main;
