import axios from "axios";

const timeout = 10000 * 60 * 3;

const instance = axios.create({
  baseURL: "",
  timeout,
  withCredentials: true,
});

instance.interceptors.request.use(
  function (config) {
    // console.log('instance-config', config);
    if (config.url.includes("/c/api/360/") || config.url.includes("360Api")) {
      config.headers["xxx-jdaz-app"] = "pc";
      config.headers["jdaz-Host"] = window.location.host;
      config.headers["jdaz-referer"] = window.location.href;
    }
    if (config.url.includes("/tools/insured/upload")) {
      config.headers["Content-Type"] = "multipart/form-data";
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    const { status, data, statusText } = response;
    if (status === 200) {
      return Promise.resolve(data);
    } else {
      return Promise.resolve({
        code: status,
        err: statusText || "服务器错误，请重试",
        data: null,
      });
    }
  },
  function (error) {
    return Promise.reject(error);
  }
);

const get = ({
  url,
  params,
  showLoading = true,
  showErr = true,
  isJson = false,
}: {
  url: string;
  params?: Object;
  showLoading?: boolean;
  showErr?: boolean;
  isJson?: boolean;
}) => {
  return new Promise((resolve, reject) => {
    instance
      .get(url, { params })
      .then((res) => {
        manageResult(res, resolve, reject, showLoading, showErr, isJson);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getJson = ({ url }: { url: string }) => {
  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getJSData = ({ url, callback }: { url: string; callback: string }) => {
  console.log(url);
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = `${url}${
      url.indexOf("?") > 0 ? "&" : "?"
    }callback=${callback}`;
    window[callback] = (data) => {
      document.body.removeChild(script);
      delete window[callback];
      resolve(data);
    };
    script.onerror = () => {
      document.body.removeChild(script);
      delete window[callback];
      reject(null);
    };
    document.body.appendChild(script);
  });
};

const post = ({
  url,
  body,
  showLoading = true,
  showErr = true,
}: {
  url: string;
  body: Object;
  showLoading?: boolean;
  showErr?: boolean;
}) => {
  return new Promise((resolve, reject) => {
    instance
      .post(url, body)
      .then((res) => {
        manageResult(res, resolve, reject, showLoading, showErr);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

// 下载文件流
const download = ({
  url,
  body,
  config,
}: {
  url: string;
  body: Object;
  config: Object;
}) => {
  return new Promise((resolve, reject) => {
    instance
      .post(url, body, config)
      .then((res) => {
        // if (res && res.type === 'application/json') {
        //   var reader = new FileReader();
        //   reader.readAsText(res, 'utf-8');
        //   reader.onload = function() {
        //     const data = JSON.parse(reader.result as string);
        //     // message.error(data.err);
        //     reject(data.err);
        //   };
        // } else {
        //   resolve(res);
        // }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

function manageResult(
  res: any,
  resolve: any,
  reject: any,
  showLoading?: boolean,
  showErr?: boolean,
  isJson?: boolean
) {
  if (isJson) {
    resolve(res);
  } else {
    const { code, result, data } = res;
    if (code === "0000") {
      resolve(result || data);
    } else {
      reject(res);
    }
  }
}

// const httpRequest = {
//   get,
//   post,
// };
//
// export default httpRequest;
export { get, post, download, getJson, getJSData };
