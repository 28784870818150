import React, { memo } from 'react';
import './index.scss';
import { IDetailAgreementItem } from '@/lib/order/types'
import ImgAgreement from '../img'
import RichTextAgreement from '../richtext'
import PdfAgreement from '../pdf'
import InsScrollView from '@/order-components/ins-scroll-view'

interface IProps {
	data: IDetailAgreementItem,
	readOverCallback?: () => void
}

const CommonAgreement = (props: IProps) => {

	const {data, readOverCallback} = props;

	const handleOnScrollToLower = () => {
		readOverCallback && readOverCallback();
	}

	const handleOnOnePage = () => {
		readOverCallback && readOverCallback();
	}

	return (
		<InsScrollView
			className='common-agreement'
			onScrollToLower={handleOnScrollToLower}
			data={data}
			onOnePage={handleOnOnePage}
		>
			{
				data &&
				<React.Fragment>
					{/*{*/}
					{/*<p className='v-title-alert'>本人已阅读并同意：</p>*/}
					{/*}*/}
					{
						(data.isProdFactConf && data.prodFactMaterial) ?
							<React.Fragment>
								<ImgAgreement data={data.prodFactMaterial[0].imgPath} />
							</React.Fragment>
							:
							<React.Fragment>
								{
									data.content ?
										<React.Fragment>
											<RichTextAgreement html={data.content} />
										</React.Fragment>
										:
										<React.Fragment>
											{
												data.pdf &&
												<React.Fragment>
													<PdfAgreement path={data.pdf} />
												</React.Fragment>
											}
										</React.Fragment>
								}
							</React.Fragment>
					}
				</React.Fragment>
			}
		</InsScrollView>
	);
};

export default memo(CommonAgreement);
