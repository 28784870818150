import React, { useEffect, useState } from 'react';
import './index.scss';
import { Pagination } from 'antd';
import 'antd/dist/antd.css';
import { fetchFileList } from 'lib/websiteApi';
import { previewPdf } from 'lib/utils';
import { IFileItem } from 'lib/types';
const pageSize = 10;
const TrafficInsurance: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    getData();
  }, []);

  const [pageIndex, setPageIndex] = useState(1);
  const [total, setTotal] = useState();
  const [data, setData] = useState<IFileItem[]>([]);

  const onChange = (page: any) => {
    setPageIndex(page);
    getData(page);
  };

  const getData = async (pageNum?: number) => {
    const params = {
      fileItem1Code: '011',
      fileItem2Code: '011',
      pageIndex: pageNum ? pageNum : pageIndex,
      pageSize: pageSize,
    };
    try {
      const result: any = await fetchFileList(params);
      setData(result.list);
      setTotal(result.total);
      setPageIndex(result.pageIndex);
    } catch (e) {}
  };

  return (
    <div className='marjor-info-wrap'>
      <h2 className='title'>交强险</h2>

      <div className='p_wrap'>
        {data.map((item, index) => (
          <div
            key={item.fileId}
            className='line_item'
            onClick={() => {
              previewPdf(item.url);
            }}
          >
            <div className='line_title'>{item.fileName}</div>
            <div className='line_date'>{item.dateLabel}</div>
          </div>
        ))}
      </div>
      <Pagination
        defaultCurrent={pageIndex}
        total={total}
        current={pageIndex}
        onChange={onChange}
        pageSize={pageSize}
      />
    </div>
  );
};

export default TrafficInsurance;
