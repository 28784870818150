import React, { memo, useState, useEffect } from 'react';
import './index.scss';
import { IDetailPlanItem } from '@/lib/order/types'
import DetailHtml from '@/order-components/detail-html'

// import { Popover } from 'antd'

interface IProps {
	data: IDetailPlanItem[]
}

interface ICoverage {
	groupName: string,
	shared_amount: string,
	name: string,
	money: string,
	detail: string,
}

interface ITable {
	name: string,
	list: ICoverage[]
}

const PlanDetail = (props: IProps) => {

	const {data} = props

	const [tableState, setTableState] = useState<ITable[]>(null);

	useEffect(() => {
		if (data?.length > 0) {
			let _columns: ITable[] = [];
			let groupItemCountList = [];
			data.forEach((item) => {
				let _groupItems = [];
				item.grouping.forEach((groupingItem) => {
					if (groupingItem.name) {
						_groupItems.push({
							groupName: groupingItem.name || '',
							shared_amount: groupingItem.shared_amount || '',
						});
					}
					groupingItem.list.forEach((listItem) => {
						_groupItems.push({
							name: listItem.name,
							money: listItem.money,
							// detail: listItem.details,
							detail: ''
						});
					})
				})
				groupItemCountList.push(_groupItems.length);
				_columns.push({
					name: item.groupName,
					list: _groupItems
				})
			})

			const maxItemCount = Math.max(...groupItemCountList);

			console.log('maxItemCount', maxItemCount);

			// 补全数据
			_columns.forEach((item) => {
				let list = item.list;
				const listLength = list.length;
				if (listLength < maxItemCount) {
					const makeUpData = new Array(maxItemCount - listLength).fill({
						groupName: '',
						shared_amount: '',
						name: '',
						money: '',
						detail: ''
					});
					list.push(...makeUpData);
				}
			})

			setTableState(_columns)

		}
	}, [data])

	return (
		<div className='plan-detail'>

			{
				tableState &&
				<div className='v-table'>
					<div className='v-table-header'>
						{
							tableState.map((item, index) => {
								return (
									<span key={`row-${index}`} className='v-table-row-item'>{item.name}</span>
								)
							})
						}
					</div>
					<div className='v-table-row-wrap'>
						{
							tableState.map((item, index) => {
								return (
									<div key={`column-${index}`} className='v-table-column'>
										{
											item.list.map((coverage, coverageIndex) => {
												return (
													<div key={`coverage-${coverageIndex}`} className='v-table-column-item'>
														{
															coverage.groupName ?
																<div className='v-coverage-wrap v-coverage-header'>
																	<DetailHtml
																		className='v-coverage-wrap_left v-text-strong'
																		html={coverage.groupName}
																	/>
																	{
																		coverage.shared_amount &&
																		<DetailHtml
																			className='v-coverage-wrap_right v-text-strong'
																			html={coverage.shared_amount}
																		/>
																	}
																</div>
																:
																<div className='v-coverage-wrap'>
																	{/*{*/}
																	{/*coverage.name &&*/}
																	{/*<Popover*/}
																	{/*content={coverage.detail ? <DetailHtml html={coverage.detail} /> : ''}*/}
																	{/*title=''*/}
																	{/*placement='bottom'*/}
																	{/*trigger='hover'*/}
																	{/*>*/}
																	{/*<div className='v-popover' />*/}
																	{/*</Popover>*/}
																	{/*}*/}
																	{
																		coverage.name &&
																		<React.Fragment>
																			<DetailHtml className='v-coverage-wrap_left' html={coverage.name} />
																			{/*<span className='v-coverage-wrap_right'>{coverage.money}</span>*/}
																			<DetailHtml className='v-coverage-wrap_right' html={coverage.money} />
																		</React.Fragment>
																	}
																</div>
														}
													</div>
												)
											})
										}
									</div>
								)
							})
						}
					</div>
				</div>
			}

		</div>
	)
}

export default memo(PlanDetail)
