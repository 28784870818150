import React, { memo } from 'react';
import './index.scss';
import arrow_right_icon from '@/static/imgs/order/arrow_right_icon_gray.svg'
import { IDetailAgreementItem } from '@/lib/order/types'
import InsDialog from '@/order-components/ins-dialog';
import InsDialogHeader from '@/order-components/ins-dialog-header'

interface IProps {
	show: boolean,
	onClose: () => void,
	data: IDetailAgreementItem
}

const HandbookListDialog = (props: IProps) => {

	const {show, onClose, data} = props

	const handleItemClick = (item: IDetailAgreementItem) => {
		const {pdf, clauseContent} = item
		const pdfLink = pdf || clauseContent
		if (pdfLink) {
			window.open(pdfLink);
		}
	}

	return (
		<InsDialog
			show={show}
			onClose={onClose}
			location={'center'}
		>
			<div className='handbook-list-dialog'>
				<InsDialogHeader
					title={data ? data.name : '服务手册'}
					onClose={onClose}
				/>
				<div className='handbook-list-dialog__body'>

					{
						data &&
						data.sub &&
						data.sub.length > 0 &&
						data.sub.map((item, index) => {
							return (
								<React.Fragment key={index}>
									<div
										className='handbook-list-dialog__body-item'
										onClick={() => handleItemClick(item)}
									>
										<span className='handbook-list-dialog__body-item-name'>
											{item.clauseName}
										</span>
										<img className='handbook-list-dialog__body-item-arrow' src={arrow_right_icon} alt={''} />
									</div>
								</React.Fragment>
							)
						})
					}

				</div>

			</div>
		</InsDialog>
	)
}

export default memo(HandbookListDialog)
