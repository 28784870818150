import React, { useEffect, useState, memo } from 'react';
import './index.scss';

import { Select, DatePicker, Input, Upload, Button } from 'antd';
import { DatePickerProps, RangePickerProps } from 'antd/lib/date-picker'
import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import { IInsureForm, IPolicyHolderLimit, ISelectOptionItem } from '@/lib/order/types'
import InsInputLabel from '@/order-components/ins-input-label'
import { UploadChangeParam, UploadFile, UploadProps } from 'antd/lib/upload'
import EventUtils from '@/lib/order/event'
import {
	CertTypesMap,
	DefInsureFormInfo,
	GENDER_OPTIONS,
	IDCardTypeGroup,
	INS_EVENT_POLICYHOLDER,
	INS_EVENT_POLICYHOLDER_SYNC,
	INS_EVENT_RELATION_SELF,
	UploadImageTypeGroup
} from '@/lib/order/constants'
import useDebounceList from '@/lib/useDebounceList'
import { getSessionStorageItem, setSessionStorageItem } from '@/lib/storage'
import { getOSSPath, uploadImgFile } from '@/lib/order/apis'
import RegExpUtils from '@/lib/order/regex'
import { getBirthdayFromIdCard, getGenderByIdCard, zhToPin } from '@/lib/order/utils'
import ToastUtils from '@/lib/toast'
import InsRadioGroup from '@/order-components/ins-radio-group';

interface IProps {
	productId: string,
	limitData: IPolicyHolderLimit,
	// safeguardLimitData: ISafeguardLimitData,
	onDataChange: (data: IInsureForm) => void
}

const TodayMomentDay = moment().endOf('day');

let cacheFormInfo: IInsureForm = null;
let needSync = false;

const OrderPolicyholder = (props: IProps) => {

	const {productId, limitData, onDataChange} = props;

	const [formInfo, setFormInfo] = useState<IInsureForm>(null);

	const formInfoDebounce = useDebounceList([formInfo], 500);

	const [certTypeList, setCertTypeList] = useState<ISelectOptionItem[]>([]);

	useEffect(() => {

		const handleEvent = (eventData) => {
			console.log('OrderPolicyholder-INS_EVENT_RELATION_SELF', eventData);
			if (eventData) {
				setFormInfo({
					...cacheFormInfo,
					...eventData
				})
			}
		};

		const handleSyncEvent = (eventData) => {
			console.log('OrderPolicyholder-INS_EVENT_POLICYHOLDER_SYNC', eventData);
			needSync = eventData;
		};

		EventUtils.on(INS_EVENT_RELATION_SELF, handleEvent);
		EventUtils.on(INS_EVENT_POLICYHOLDER_SYNC, handleSyncEvent);

		return () => {
			EventUtils.off(INS_EVENT_RELATION_SELF, handleEvent);
			EventUtils.off(INS_EVENT_POLICYHOLDER_SYNC, handleSyncEvent);
		};
	}, []);

	useEffect(() => {
		if (limitData) {
			let certType = '';
			let insureType = 'individual';
			if (limitData?.certTypes && limitData.certTypes.length > 0) {
				certType = limitData.certTypes[0];
				let certTypeOption = [];
				limitData.certTypes.forEach((item) => {
					const label = CertTypesMap[item];
					if (label) {
						certTypeOption.push({
							label: label,
							value: item
						})
					}
				})
				setCertTypeList(certTypeOption);
			} else {
				certType = '01';
				setCertTypeList([{
					label: '身份证',
					value: '01'
				}])
			}

			const cacheStr = getSessionStorageItem(`jdaz-policyholder-${productId}`);

			if (cacheStr?.length > 2) {
				const cacheData = JSON.parse(cacheStr);
				setFormInfo({
					...cacheData,
					insureType: cacheData.insureType || insureType,
					certType: cacheData.certType || certType,
					relation: '01'
				});
			} else {
				setFormInfo({
					...DefInsureFormInfo,
					insureType: insureType,
					certType: certType,
					relation: '01'
				});
			}

		}
	}, [limitData])

	useEffect(() => {
		cacheFormInfo = formInfo;
	}, [formInfo])

	useEffect(() => {
		if (formInfo) {
			setSessionStorageItem(`jdaz-policyholder-${productId}`, JSON.stringify(formInfo));
			onDataChange(formInfo);
			needSync && EventUtils.emit(INS_EVENT_POLICYHOLDER, formInfo);
		}
	}, [formInfoDebounce])

	const onCertTypeChange = (value: string) => {
		console.log('onCertTypeChange', value);
		if (formInfo.certType === value) return;
		setFormInfo({
			...formInfo,
			certType: value,
			certNo: '',
			certNoError: '',
			imgData: []
		})
	};

	const handleInputChange = (name: string, e) => {
		// console.log('handleInputChange', name, e.target.value);
		const value = e.target.value || '';
		let displayValue = value.trim();
		if (name === 'name') {
			setFormInfo({
				...formInfo,
				name: displayValue,
				nameError: '',
				namePinYin: displayValue.length > 1 ? zhToPin(displayValue).toUpperCase() : '',
				namePinYinError: ''
			})
		} else if (name === 'certNo') {
			setFormInfo({
				...formInfo,
				certNo: displayValue.toUpperCase(),
				certNoError: ''
			})
		} else {
			setFormInfo({
				...formInfo,
				[name]: displayValue,
				[`${name}Error`]: ''
			})
		}
	}

	const handleOnBlur = (name: string, e) => {
		const value = e.target.value || '';
		if (name === 'certNo' && IDCardTypeGroup.includes(formInfo.certType)) {
			if (RegExpUtils.checkIdCard(value) || RegExpUtils.checkGangAoTaiIDCard(value)) {
				setFormInfo({
					...formInfo,
					birthday: getBirthdayFromIdCard(value),
					gender: getGenderByIdCard(value),
				})
			} else {
				setFormInfo({
					...formInfo,
					birthday: '',
					gender: ''
				})
			}
		}
	}

	const onGenderChange = (data: ISelectOptionItem) => {
		console.log('onGenderChange', data);
		if (formInfo.gender === data.value) return;
		setFormInfo({
			...formInfo,
			gender: data.value
		})
	};

	const disabledDate: RangePickerProps['disabledDate'] = current => {
		return current && current > TodayMomentDay;
	};

	const onBirthdayChange: DatePickerProps['onChange'] = (date, dateString) => {
		console.log('onBirthdayChange', dateString);
		if (formInfo.birthday === dateString) return;
		setFormInfo({
			...formInfo,
			birthday: dateString
		})
	};

	const beforeUpload = () => {
		return false;
	};

	const handleImgChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
		// console.log('handleImgChange', info);
		if (!UploadImageTypeGroup.includes(info.file.type)) {
			ToastUtils.info('请选择图片');
			return;
		}
		// @ts-ignore
		uploadImgFile(info.file)
		.then((token: string) => {
			setFormInfo({
				...formInfo,
				imgData: [{
					name: info.file.name,
					token: token,
					ossUrl: getOSSPath(token),
				}]
			})
		})
	};

	return (
		<div className='order-policyholder'>

			{
				formInfo &&
				<React.Fragment>
					<div className='v-item-wrap'>
						<InsInputLabel text='投保人姓名' require={true} />
						<Input
							className='v-item-wrap_input'
							placeholder='请输入姓名'
							disabled={false}
							maxLength={20}
							status={''}
							type={'text'}
							value={formInfo.name}
							onChange={(e) => handleInputChange('name', e)}
							onBlur={(e) => handleOnBlur('name', e)}
						/>
						<span className='v-item-wrap_error'>{''}</span>
					</div>

					<div className='v-item-wrap'>
						<InsInputLabel text='证件类型' require={true} />
						<Select
							className='v-item-wrap_input'
							placeholder='请选择证件类型'
							value={formInfo.certType}
							onChange={onCertTypeChange}
							options={certTypeList}
						/>
					</div>

					<div className='v-item-wrap'>
						<InsInputLabel text='证件号码' require={true} />
						<Input
							className='v-item-wrap_input'
							placeholder='请输入证件号码'
							disabled={false}
							maxLength={20}
							status={''}
							type={'text'}
							value={formInfo.certNo}
							onChange={(e) => handleInputChange('certNo', e)}
							onBlur={(e) => handleOnBlur('certNo', e)}
						/>
						<span className='v-item-wrap_error'>{''}</span>
					</div>

					<div className='v-item-wrap' style={{display: formInfo.certType !== '01' ? 'flex' : 'none'}}>
						<InsInputLabel text='证件图片' require={true} />
						<Upload
							name='file'
							accept='image/png,image/jpeg'
							// className='v-img-upload'
							maxCount={1}
							showUploadList={false}
							beforeUpload={beforeUpload}
							onChange={handleImgChange}
						>
							<Button className='v-img-upload'>点击上传</Button>
						</Upload>
						{
							formInfo.imgData?.length > 0 &&
							<span className='v-upload-result-name'>{formInfo.imgData[0].name}</span>
						}
						{/*{*/}
						{/*formInfo.imgData?.length > 0 &&*/}
						{/*<img className='v-upload-result-img' src={formInfo.imgData[0].ossUrl} alt={''} />*/}
						{/*}*/}
					</div>

					<div className='v-item-wrap' style={{display: formInfo.certType !== '01' ? 'flex' : 'none'}}>
						<InsInputLabel text='性别' require={true} />
						<InsRadioGroup
							className='v-item-wrap_input'
							options={GENDER_OPTIONS}
							onChange={onGenderChange}
							value={formInfo.gender}
							disabled={IDCardTypeGroup.includes(formInfo.certType)}
						/>
					</div>

					<div className='v-item-wrap' style={{display: formInfo.certType !== '01' ? 'flex' : 'none'}}>
						<InsInputLabel text='出生日期' require={true} />
						<DatePicker
							className='v-item-wrap_input'
							locale={locale}
							placeholder={'请选择/输入(格式:YYYY-MM-DD)'}
							disabledDate={disabledDate}
							disabled={IDCardTypeGroup.includes(formInfo.certType)}
							onChange={onBirthdayChange}
							value={formInfo.birthday ? moment(formInfo.birthday, 'YYYY-MM-DD') : undefined}
						/>
					</div>

					<div className='v-item-wrap'>
						<InsInputLabel text='手机号码' require={true} />
						<Input
							className='v-item-wrap_input'
							placeholder='请输入手机号码'
							disabled={false}
							maxLength={11}
							status={''}
							type={'tel'}
							value={formInfo.mobile}
							onChange={(e) => handleInputChange('mobile', e)}
						/>
						<span className='v-item-wrap_error'>{''}</span>
					</div>

					<div className='v-item-wrap'>
						<InsInputLabel text='电子邮箱' require={false} />
						<Input
							className='v-item-wrap_input'
							placeholder='用于接收电子保单（选填）'
							disabled={false}
							maxLength={40}
							status={''}
							type={'text'}
							value={formInfo.email}
							onChange={(e) => handleInputChange('email', e)}
						/>
						<span className='v-item-wrap_error'>{''}</span>
					</div>

				</React.Fragment>
			}

		</div>
	);
};

export default memo(OrderPolicyholder);
