import React from 'react';
import './index.scss';
import conpInfo_banner from 'static/imgs/conpInfo_banner0627.jpg';

const ServePromise: React.FC = () => {
  return (
    <div className='comp-info-wrap'>
      <h2 className='title'>公司介绍</h2>
      <div className='header_img'>
        <img src={conpInfo_banner} alt='图片' />
      </div>
      <p className='comp_desc'>
      2023年，公司累计生效保费突破<span className='f_w'>56亿元</span>。目前公司在广东（含深圳）、上海、北京、四川设有分支机构。京东安联保险凭借在数字化转型、产品创新、卓越运营等方面的突出表现，荣获技术类、产品类、服务类等奖项超过70个，包括全球保险科技企业奖、保险行业杰出科技创新奖、中国互联网金融领军榜100强品牌等。 2024年4月，国际著名信用评级机构惠誉评级（Fitch Ratings）公告给予京东安联保险 “A-” （强劲）的保险公司财务实力（IFS）评级，<span className='f_w'>展望稳定</span>。
      </p>
    </div>
  );
};

export default ServePromise;
