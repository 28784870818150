import React, { memo, useState, useEffect } from 'react';
import './index.scss';
import arrow_right_icon from '@/static/imgs/order/arrow_right_icon_gray.svg'
import { IDetailAgreementItem } from '@/lib/order/types'
import InsDialog from '@/order-components/ins-dialog';
import InsDialogHeader from '@/order-components/ins-dialog-header'

interface IProps {
	show: boolean,
	onClose: () => void,
	data: IDetailAgreementItem
}

const AgreementListDialog = (props: IProps) => {

	const {show, onClose, data} = props

	const [agreementData, setAgreementData] = useState<IDetailAgreementItem[][]>(null)

	useEffect(() => {
		if (data && data.sub.length > 0) {
			//组合条款
			let obj: IDetailAgreementItem[][] = []
			let mainData: IDetailAgreementItem[] = []
			let additionalData: IDetailAgreementItem[] = []
			let handbookData: IDetailAgreementItem[] = []
			for (let item of data.sub) {
				switch (item.clauseType) {
					case '1'://主条款
						mainData.push(item)
						break
					case '2'://附加条款
						additionalData.push(item)
						break
					case '3'://服务手册
						handbookData.push(item)
						break
				}
			}
			if (mainData.length > 0) {
				obj.push(mainData)
			}
			if (additionalData.length > 0) {
				obj.push(additionalData)
			}
			if (handbookData.length > 0) {
				obj.push(handbookData)
			}
			if (obj.length > 0) {
				setAgreementData(obj)
			}
		}
	}, [data])

	const handleItemClick = (item: IDetailAgreementItem) => {
		const {pdf, clauseContent} = item
		const pdfLink = pdf || clauseContent
		if (pdfLink) {
			window.open(pdfLink);
		}
	}

	return (
		<InsDialog
			show={show}
			onClose={onClose}
			location={'center'}
		>
			<div className='agreement-list-dialog'>
				<InsDialogHeader
					title={data ? data.name : '保险条款'}
					onClose={onClose}
				/>
				<div className='agreement-list-dialog__body'>
					{
						agreementData &&
						agreementData.length > 0 &&
						agreementData.map((item, index) => {
							return (
								<React.Fragment key={index}>
									<div className='agreement-list-dialog__body-title'>{item[0].clauseTypeName}</div>
									{
										item.map((subItem, subItemIndex) => {
											return (
												<React.Fragment key={subItemIndex}>
													<div
														className='agreement-list-dialog__body-item'
														onClick={() => handleItemClick(subItem)}
													>
														<span className='agreement-list-dialog__body-item-name'>
															{subItem.clauseName}
														</span>
														<img className='agreement-list-dialog__body-item-arrow' src={arrow_right_icon} alt={''} />
													</div>
												</React.Fragment>
											)
										})
									}
								</React.Fragment>
							)
						})
					}
				</div>
			</div>
		</InsDialog>
	)
}

export default memo(AgreementListDialog)
