export const data1 = [
  {
    title: '京东安联财产保险有限公司',
    address:
      '广东省广州市天河区珠江西路5号广州国际金融中心主塔写字楼第34层01-05、11、12单元',
    phone: '+86 20 85132900',
  },
  {
    title: '上海分公司',
    address:
      '上海市浦东新区银城中路501 号上海中心大厦14 层1406、1407和1408 单元',
    phone: '+86 21 20339669',
  },
  {
    title: '广东分公司',
    address:
      '广东省广州市天河区珠江西路5号广州国际金融中心主塔写字楼第34层08-10单元',
    phone: '+86 20 83966788',
  },
  {
    title: '北京分公司',
    address: '北京市北京经济技术开发区科创十一街20号院3号楼11、12层',
    phone: '+86 10 89128666',
  },
  {
    title: '四川分公司',
    address:
      '四川省成都市温江区光华大道三段1588号珠江国际中心29楼2901-2907、2917单元',
    phone: '+86 28 62347850',
  },
  {
    title: '深圳营销服务部',
    address:
      '深圳市前海深港合作区南山街道梦海大道5033号前海卓越金融中心3号楼L13-01（深圳市南山区桂湾片区二单元前海卓越金融中心T201-0075（4）101三号楼L13-01）',
    phone: '+86 755 88286033',
  },
  {
    title: '北京朝阳营销服务部',
    address: '北京市朝阳区建国门外大街乙12号西塔第11层09B单元',
    phone: '+86 10 85400981',
  },
];
