import React from 'react';
import './index.scss';
import alBanner from 'static/imgs/alBanner0627.png';
const Allianz: React.FC = () => {
  return (
    <div className='alianz-wrap'>
      <h2 className='title'>安联集团</h2>
      <p className='fee_desc mr_b'>
      作为世界领先的保险和资产管理公司之一，安联集团在全球 70 多个国家和地区开展业务，<span className='f_w'>为超过1.25亿多的个人及企业客户提供保险和资产管理服务</span>。从财产险、寿险、健康险到救援服务、信用保险和全球商业保险，安联致力于为客户提供全方位的个人和企业保险服务。
      </p>
      <p className='fee_desc mr_b'>
      安联是全球最大的资产管理公司之一，截至2023年12月31日，安联管理的第三方资产为1.712万亿欧元。得益于在业务流程和投资决策中系统地整合了生态和社会标准，安联在道琼斯可持续发展指数评定中稳居保险行业领先地位。<span className='f_w'>2023年，安联员工数量超过 15.7 万名，取得了总业务量1617亿欧元，营业利润147亿欧元的优秀业绩</span>。
      </p>
      <p className='fee_desc mr_b'>
      2024年安联在BrandFinance发布的全球品牌500强榜单上排名第28位，<span className='f_w'>也是排名最高的全球保险品牌</span>。根据知名咨询公司Interbrand发布的2023年全球最佳品牌排名，安联集团在全球100强品牌中，排名第31位，<span className='f_w'>连续第五年蝉联全球第一保险品牌</span>。
      </p>
      <p className='fee_desc_s mr_b'>
      * 包括与安联客户合作的非合并实体。
      更新：2024 年 3 月 7 日（发布 2023 年年度报告）
      </p>
      <img style={{width: '1224px'}} src={alBanner} alt='图片' />
      {/* <p className='fee_desc mr_b'>
        作为世界领先的金融服务集团之一，安联集团15.5万名员工在世界70多个国家和地区为1.26亿客户提供服务。从财产险、寿险、健康险到救援服务、信用保险和全球商业保险，安联致力于为客户提供全方位的个人和企业保险服务。
      </p>
      <p className='fee_desc mr_b'>
        安联集团2021年业绩表现强劲，
        <span className='f_w'>总收入达1485亿欧元。</span>
        欧洲偿二代偿付能力充足率在2021年底为209%。
      </p>
      <p className='fee_desc mr_b'>
        “面对2021年的诸多挑战，安联集团依然展现出强大的韧劲和适应性。”安联集团主席兼首席执行官奥利弗·贝特（Oliver
        Bäte）先生表示。“2021年营业利润创历史新高，安联集团在寿险、财险、资管管理业务细分领域均实现高业务价值增长，持续加强全球的布局和影响力。”
      </p>
      <p className='fee_desc mr_2b'>
        凭借在全球保险和金融服务领域中坚实的地位和雄厚的财力，
        <span className='f_w'>
          安联集团在2022年美国财富杂志《Fortune》世界500强排行榜中名列第47位，在2019至2021
          Interbrand
          全球品牌排行榜中，安联连续三年位列全球保险公司品牌价值第一名。
        </span>
      </p> */}

      {/* <h3 className='sub_title'>安联的发展历程</h3>
      <p className='fee_desc mr_b'>
        2017年初，京东全面向技术转型，迄今京东体系已经投入了近
        <span className='f_w'>800亿</span>元用于技术研发。
      </p>
      <p className='fee_desc mr_b'>
        安联集团于1890年始创于德国柏林，而今总部位于德国南部巴伐利亚州的首府慕尼黑。
      </p>
      <p className='fee_desc mr_b'>
        早在二十世纪初，安联就已活跃在国际保险市场上。安联承保并赔付了1906年美国旧金山大地震和“泰坦尼克”号沉没造成的损失。在五十年代的“德国经济复苏”中，安联迅猛崛起，成为德国最大的保险公司。六十年代末，安联重新开始其国际业务。
      </p>
      <p className='fee_desc mr_b'>
        八十至九十年代，安联在欧洲和北美展开了一系列战略性并购，将数家著名保险集团，如RAS、Cornhill、AGF、美国Fireman's
        Fund
        保险集团和USA人寿保险公司归并旗下，进一步加强了其作为国际保险人的市场地位。今天，安联集团毛保费收入的近70%来自德国以外的业务。这清楚表明了安联作为全球行业领导者而为之所作的成功努力。
      </p>
      <p className='fee_desc mr_b'>
        安联在1998年作出战略性决定，将资产管理业务整合为集团的独立核心业务。随着先后对美国最大的资产管理公司PIMCO
        Advisor，Nicholas
        Applegate以及之后对德累斯登银行的并购，安联作为全球领先的机构投资者的声誉得以进一步加强。2004年10月，安联被誉为资产管理“全球市场领导者”。作为世界最大的投资者之一，安联拥有许多著名国内及跨国集团的股份。
      </p>
      <p className='fee_desc mr_b'>
        2006年10月13日，安联保险集团的法律形式由德国的“股份公司（Aktiengesellschaft,
        AG）”转变成“欧洲公司 （Societas
        Europaea，SE）”，率先由德国上市公司转化为根据欧盟法律上市的欧洲股份制企业。
      </p> */}
    </div>
  );
};

export default Allianz;
