import JSEncrypt from '@/lib/jsencrypt.min';
import CryptoJS from 'crypto-js';

const PUBLIC_KEY_COMMON = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCWmc4bKr/RQloO3SBk0PMdNTgxWwKwJNStiZXYX41bCFfgGI5P4tKNsxkv2JKjQpmXkchOiUT2/hQB6dOtDaKuvfbWRpSoEDNyTVZdavQ9Ubrh3gU0WojRyiN4ytEDOUW8G2Y59UIPZJhItUllkEwT5JlbIofLD3Aq3OZCI0VbUQIDAQAB';

const CryptUtils = {

	encrypt(value: string) {
		try {
			let encrypt = new JSEncrypt();
			encrypt.setPublicKey(`-----BEGIN PUBLIC KEY-----${PUBLIC_KEY_COMMON}-----END PUBLIC KEY-----`);
			return encrypt.encrypt(value) || value;
		} catch (e) {
			return value;
		}
	},

	md5(str) {
		try {
			return CryptoJS.MD5(str).toString().toLowerCase();
		} catch (e) {
			return str;
		}
	}

}

export default CryptUtils;
