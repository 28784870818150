import { useState ,useEffect} from 'react';


export const useScroll = (scrollTopSize=10) => {

  const [isScrolling,setIsScrolling] = useState(false);
  
  const scrollFn = (event:any)=>{
    const scrollTop = (event.srcElement ? event.srcElement.documentElement.scrollTop : false)
            || window.pageYOffset
            || (event.srcElement ? event.srcElement.body.scrollTop : 0);
    setIsScrolling(scrollTop > scrollTopSize);
  }
  
  useEffect(()=>{
    window.addEventListener('scroll', scrollFn);
    return ()=>{
      window.removeEventListener('scroll',scrollFn);
    }
  },[])// eslint-disable-line react-hooks/exhaustive-deps


  return isScrolling;
}