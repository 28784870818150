import React, { useEffect } from 'react';
import './index.scss';
import { data1, data2 } from './staticData';
import { fetchFileList } from 'lib/websiteApi';
import { previewPdf } from 'lib/utils';
import { useNavigate } from 'react-router-dom';

const Production: React.FC = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const goProductionListPage = (id: string) => {
    navigate(`/clause-page/${id}`);
  };

  const getData = async (itemCode: string) => {
    const params = {
      fileItem1Code: '001',
      fileItem2Code: itemCode,
    };
    try {
      const result: any = await fetchFileList(params);
      if (result?.list?.[0]?.url) {
        previewPdf(result.list?.[0]?.url);
      }
    } catch (e) {}
  };

  const getProductionDetail = (id: string) => {
    getData(id);
  };

  return (
    <div className='production-info-wrap'>
      <h2 className='title'>产品基本信息</h2>
      <h3 className='sub_title'>保险产品目录</h3>
      <div className='box_2'>
        {data1.map((item, index) => (
          <div
            className='box_2_item'
            key={index}
            onClick={() => getProductionDetail(item.id)}
          >
            {item.name}
          </div>
        ))}
      </div>

      <h3 className='sub_title'>保险产品条款</h3>
      <div className='box_2'>
        {data2.map((item, index) => (
          <div
            className='box_2_item'
            key={index}
            onClick={() => goProductionListPage(item.id)}
          >
            {item.name}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Production;
