import React, { useEffect, useState, Suspense } from 'react';
import './index.scss';
import ErrorBoundaryHOC from '../components/error-boundary'
import { useUrlSearchParams } from '@/lib/useUrlSearchParams'
import { getPageData, getRealIP, getStoreyPkg } from '@/lib/order/apis'
import { IPackageInfo, IProductDetail, IStoreyProduction } from '@/lib/order/types'
import { getLBSInfo, openH5ProductDetailPage } from '@/lib/order/utils'
import OrderForm from './components/form'
import InsLoading from '@/order-components/ins-loading';
import { clearGlobalData, setGlobalData } from '@/lib/global'
import { SortCertTypeList } from '@/lib/order/constants'
import JDAZTraceUtils from '@/lib/order/jdaz_trace'
import usePageCommonConfig from '@/lib/order/use-page-common-config'

const ErrorBoundaryFallback = React.lazy(() => import('@/order-components/error-boundary/fallback'));
const InsSysNotice = React.lazy(() => import('@/order-components/ins-sys-notice'));

interface IStateData {
	packageInfo: IPackageInfo | undefined,
	pageData: IProductDetail | undefined
}

const Index = () => {

	const {p: productId, jdaz_chl = '360', jdaz_source = 'PC'} = useUrlSearchParams();

	const pageCommonConfig = usePageCommonConfig({productId, jdaz_chl, jdaz_source});

	const [stateData, setStateData] = useState<IStateData>({
		packageInfo: undefined,
		pageData: undefined
	})

	const [alert, setAlert] = useState('');

	const [showLoading, setShowLoading] = useState(false);

	useEffect(() => {
		if (!productId) {
			setAlert('链接无效');
		}
		return () => {
			clearGlobalData();
		}
	}, []);

	useEffect(() => {
		if (pageCommonConfig) {
			if (pageCommonConfig.pcPolicyable) {
				getPageConfig();
			} else {
				// 不支持PC投保的产品,展示H5详情页
				// setAlert('当前产品不支持在PC端投保');
				openH5ProductDetailPage();
			}
		}
	}, [pageCommonConfig])

	useEffect(() => {
		if (alert || stateData.pageData) {
			setShowLoading(false);
			if (stateData.pageData) {
				document.title = stateData.pageData.storeyProductionData.productionName;
			}
			requestAnimationFrame(() => {
				window.scrollTo({
					left: 0,
					top: 0,
					behavior: 'smooth'
				});
			})
		}
	}, [alert, stateData])

	const getPkgInfo = async () => {

		let areaCode: string[] = [];
		let realIp: string = '';

		try {
			// @ts-ignore
			realIp = await getRealIP();
		} catch (e) {
			realIp = '106.38.115.50';
		}

		try {
			// 根据IP获取位置信息
			const addressInfo: any = await getLBSInfo(realIp);
			if (addressInfo) {
				areaCode.push(addressInfo.provinceId);
				areaCode.push(addressInfo.cityId);
				areaCode.push(addressInfo.districtId);
			}
		} catch (e) {
			areaCode = ['1', '1', '2809'];
		}

		try {
			return await getStoreyPkg(productId, areaCode);
		} catch (e) {
			setAlert('获取商品包配置数据失败');
			return null;
		}
	}

	// 目前只支持标准意健险
	const checkPCPolicyEnable = (temp: string, storeyProductionData: IStoreyProduction) => {
		return !(temp !== 'storeyPage' || storeyProductionData.free || storeyProductionData.insuredObjectType || storeyProductionData.supportFamily);
	}

	const getPageConfig = async () => {

		setShowLoading(true);

		// @ts-ignore
		let pkgInfo: IPackageInfo = await getPkgInfo();
		if (!pkgInfo) return;

		try {
			if (pkgInfo.dataPath && pkgInfo.packageId !== '-1') {
				// @ts-ignore
				let pageData: IProductDetail = await getPageData(pkgInfo.dataPath, 'JDAZCallback');
				if (pageData) {
					if (checkPCPolicyEnable(pkgInfo.temp, pageData.storeyProductionData)) {
						// 证件类型排序
						let policyHolderResultList: string[] = [];
						let insuredResultList: string[] = [];
						SortCertTypeList.forEach((item) => {
							if (pageData.storeyProductionData?.policyHolderInfo?.certTypes?.includes(item)) {
								policyHolderResultList.push(item);
							}
							if (pageData.storeyProductionData?.insuredInfo?.certTypes?.includes(item)) {
								insuredResultList.push(item);
							}
						})
						if (policyHolderResultList.length === 0) {
							policyHolderResultList = ['01'];
						}
						if (insuredResultList.length === 0) {
							insuredResultList = ['01'];
						}
						pageData.storeyProductionData.policyHolderInfo.certTypes = policyHolderResultList;
						pageData.storeyProductionData.insuredInfo.certTypes = insuredResultList;

						setGlobalData('showExpensiveHospital', pageData.storeyProductionData.expensiveHospital || false);

						setStateData({
							packageInfo: pkgInfo,
							pageData: pageData
						})

						JDAZTraceUtils.initStart();
					} else {
						openH5ProductDetailPage();
					}
				}
			} else {
				setAlert('获取商品包配置数据失败');
				return;
			}
		} catch (e) {
			setAlert('获取页面配置数据失败');
			return;
		}

	}

	return (
		<div className='order-container'>

			{
				alert ?
					<Suspense fallback={<div />}>
						<ErrorBoundaryFallback alert={alert} />
					</Suspense>
					:
					<React.Fragment>
						{
							!alert &&
							stateData?.packageInfo &&
							stateData.pageData &&
							<React.Fragment>
								<OrderForm
									productId={productId}
									packageInfo={stateData.packageInfo}
									pageData={stateData.pageData}
									pageCommonConfig={pageCommonConfig}
								/>
							</React.Fragment>
						}
					</React.Fragment>
			}

			<Suspense fallback={<div />}>
				<InsSysNotice />
			</Suspense>

			<InsLoading show={showLoading} />

		</div>
	);
};

export default ErrorBoundaryHOC(Index);
