import React, { useState ,useEffect} from 'react';
import './index.scss';
import erweima from "../../img/erweima-1.svg"
import { useLocation  } from "react-router-dom";
import {ILink} from 'lib/types'

import {createCodeUtil} from 'lib/utils'
interface IProps {
  link : ILink
}
const BuyBtn:React.FC<IProps> = (props: IProps) => {
  const [isShowCode,setIsShowCode] = useState(false);
  let {search}  = useLocation();
  useEffect(()=>{
    if(isShowCode){
      createCode();
    }
  },[isShowCode]) // eslint-disable-line react-hooks/exhaustive-deps  

  const showCode = (()=>{
    setIsShowCode(true);
  })
 const createCode = ()=>{
  createCodeUtil(props.link,search,'canvas',160);
 }
 const removeCode = ()=>{
  setIsShowCode(false);
 }
  return (
    <div className='buy_btn_wrap flex-between hander noselect' onMouseEnter={showCode} onMouseLeave={removeCode}>
      <p className='title'>立即询价</p>
      <img src={erweima} alt="icon" className='icon'/>
      {
        isShowCode &&  <div className="qrcode">
          <canvas id="canvas"></canvas>
          <p className='text'>微信扫码，立即询价</p>
          <div className="arrow"></div>
        </div>
      }
     
       
    </div>
  )
};

export default BuyBtn;
