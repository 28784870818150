import React, { memo } from 'react';
import './index.scss';
import closeImg from '@/static/imgs/order/icon_close.svg'

interface IProps {
	title: string,
	showClose?: boolean,
	onClose?: () => void
}

const InsDialogHeader = (props: IProps) => {

	const {title, showClose = true, onClose} = props

	return (
		<div className='ins-dialog-header'>
			<span className='ins-dialog-header-text'>{title}</span>
			{
				showClose &&
				<img
					className='ins-dialog-header-close'
					src={closeImg}
					decoding={'async'}
					loading={'lazy'}
					onClick={onClose}
					alt={'关闭'}
				/>
			}
		</div>
	)
}

export default memo(InsDialogHeader)
