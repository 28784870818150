// export const ONLINEURL = 'https://m.allianz360.com/ins-temp-m/';
// export const IMGURL = 'https://m-test.jdallianz.com/jc/ins-data-360/test/m/'

// 生产二维码的地址，统一配置线上地址
export const ONLINEURL = {
  dev: "https://m-test.jdallianz.com/ins-temp-m/",
  test: "https://m-test.jdallianz.com/ins-temp-m/",
  pre: "https://m-pre.jdallianz.com/ins-temp-m/",
  prod: "https://m.jdallianz.com/ins-temp-m/",
  //@ts-ignore
}[process.env.REACT_APP_MODE];

// 配置的产品图片地址
export const IMGURL = {
  dev: "https://m-test.jdallianz.com/jc/ins-data-360/test/m",
  test: "https://m-test.jdallianz.com/jc/ins-data-360/test/m",
  pre: "https://m-test.jdallianz.com/jc/ins-data-360/pre/m",
  prod: "https://img01.az.jd.com/ins-data-360/m",
  //@ts-ignore
}[process.env.REACT_APP_MODE];

// 旧官网链接配置
export const OLD_WEBSITE_PATH = {
  dev: "https://xgw-test.jdazcn.com",
  test: "https://xgw-test.jdazcn.com",
  pre: "https://xgw-pre.jdazcn.com",
  prod: "https://www.jdallianz.com",
  //@ts-ignore
}[process.env.REACT_APP_MODE];
