export const INS_EVENT_PRICE = 'ins_event_price';
export const INS_EVENT_RELATION_SELF = 'ins_event_relation_self';
export const INS_EVENT_POLICYHOLDER = 'ins_event_policyholder';
export const INS_EVENT_POLICYHOLDER_SYNC = 'ins_event_policyholder_sync';

export const CertTypesMap = {
	'01': '身份证', // '居民身份证'
	'07': '普通护照', // '中国护照'
	'51': '国际护照',
	'15': '出生医学证明',
	'11': '港澳居民来往内地通行证', // 回乡证
	'10': '台湾居民来往大陆通行证', // 台胞证-旧
	'16': '台湾居民来往大陆通行证', // 台胞证-新
	'04': '军官证',
	'09': '港澳通行证',
	'60': '外交护照', // 小于等于10位数字/字母
	'61': '公务护照',
	'62': '公务普通护照',
	'63': '普通护照',
	'64': '香港特别行政区护照',
	'65': '澳门特别行政区护照',
	'66': '往来港澳通行证',
	'67': '外国人永久居留身份证', // 15位数字/字母
	'68': '外国人居留证或居留许可',
	'69': '外国人临时居留证',
	'70': '港澳台居民居住证'
}

// 身份证
// 普通护照
// 公务普通护照
// 出生医学证明
// 香港特别行政区护照
// 澳门特别行政区护照
// 往来港澳通行证
// 港澳居民来往内地通行证
// 台湾居民来往大陆通行证
// 外国人永久居留身份证
// 港澳台居民居住证
export const SortCertTypeList = ['01', '63', '62', '07', '15', '64', '65', '66', '11', '10', '16', '67', '70'];

export const IDCardTypeGroup = ['01', '70'];

// 军官证、外国人永久居留身份证、港澳台居民居住证
// export const TwoPictureCertTypeList = ['04', '67', '70'];

export const RELATION_OPTIONS = [
	{label: '本人', value: '01'},
	{label: '配偶', value: '04'},
	{label: '父母', value: '02'},
	{label: '子女', value: '03'}
];

export const RelationMap = {
	'01': '本人',
	'02': '父母',
	'03': '子女',
	'04': '配偶',
};

export const GENDER_OPTIONS = [
	{label: '男', value: 'M'},
	{label: '女', value: 'F'}
];

export const MEDICAL_OPTIONS = [
	{label: '有', value: 'Y'},
	{label: '无', value: 'N'}
];

export const EXPENSIVE_HOSPITAL_OPTIONS = [
	{label: '是', value: 'Y'},
	{label: '否', value: 'N'}
]

export const SECOND_CERTTYPE_OPTIONS = [
	{value: '63', label: '普通护照'},
	{value: '62', label: '公务普通护照'},
	{value: '64', label: '香港特别行政区护照'},
	{value: '65', label: '澳门特别行政区护照'},
	{value: '66', label: '往来港澳通行证'}
]

export const JDAndJDAZAreaCodeMap = {
	1: '110000',
	2: '310000',
	3: '120000',
	4: '500000',
	5: '130000',
	6: '140000',
	7: '410000',
	8: '210000',
	9: '220000',
	10: '230000',
	11: '150000',
	12: '320000',
	13: '370000',
	14: '340000',
	15: '330000',
	16: '350000',
	17: '420000',
	18: '430000',
	19: '440000',
	20: '450000',
	21: '360000',
	22: '510000',
	23: '460000',
	24: '520000',
	25: '530000',
	26: '540000',
	27: '610000',
	28: '620000',
	29: '630000',
	30: '640000',
	31: '650000'
	// 32: '710000',
	// 84: '990000', // 未知
	// 52993: '810000'
}

export const DefInsureFormInfo = {
	id: '',
	insureType: '',
	relation: '',
	name: '',
	nameError: '',
	namePinYin: '',
	namePinYinError: '',
	certType: '',
	certNo: '',
	certNoError: '',
	gender: '',
	birthday: '',
	mobile: '',
	mobileError: '',
	email: '',
	emailError: '',
	medical: '',
	deductible: '', // 免赔额
	deductibleDisplay: '',
	imgData: [], //证件照片信息
	// occupation: IProfessionDetail, // 职业
	occupation: {
		// 职业
		name: '',
		code: '',
		codes: [],
		labels: [],
		parentIds: []
	},
	policyArea: {
		// 投保地区
		areaCode: '',
		jdId: '', // 京东地址id
		jdName: '' // 京东地址名称
	},
	secondCertType: '63', // 第二证件类型
	secondCertNo: '', // 第二证件号码
	secondCertNoError: '',
	health: '', // 符合健康告知 Y|N
	amount: '', // 保费
	expensiveHospital: '' // 昂贵医院
}

export const UploadImageTypeGroup = ['image/png', 'image/jpeg'];

export const ExpensiveHospitalList = [
	{
		title: '中国大陆：',
		list: [
			'1、北京和睦家医院（北京市朝阳区将台路2号）',
			'2、莱佛士医疗北京/深圳/天津/天津泰达/南京/大连诊所(南京、天津、天津泰达、深圳国际(SOS) 紧急救援诊所；北京国际(SOS) 救援中心、大连安慎诊所)',
			'3、百汇医疗集团旗下医疗机构（除香港和成都鹰阁外的其它城市；除百汇馨康品牌旗下的医疗机构)',
			'4、上海东方联合医院'
		]
	},
	{
		title: '中国香港：',
		list: [
			'1、港安医院',
			'2、香港明德医院',
			'3、香港养和医院'
		]
	},
	{
		title: '新加坡：',
		list: [
			'1、伊丽莎白医院(新加坡)',
			'2、伊丽莎白诺维娜医院(新加坡)',
			'3、鹰阁医院 (新加坡)'
		]
	}
]

export const MedicalExplainList = [
	'指被保险人是否已参加社会医疗保障，如：',
	'1、城镇职工基本医疗保障',
	'2、城镇居民基本医疗保险',
	'3、新型农村合作医疗（新农合）',
	'4、城乡居民基本医疗保险',
	'5、公费医疗保险等'
]
