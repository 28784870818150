import React, { useEffect, useState } from "react";
import "./index.scss";
import { INewsItem } from "lib/types";
import { fetchNewsJson, fetchJSONWithETag } from "lib/websiteApi";
import "antd/dist/antd.css";
import zuojiantou from "static/imgs/zuojiantou.png";
import { useNavigate, useLocation } from "react-router-dom";
import { Pagination } from "antd";
import emptyPng from "static/imgs/empty_page.png";

const newDataList = {
  "02": {
    id: "02",
    title: "金融教育",
    url: "/data/news_list_02.json",
  },
  "03": {
    id: "03",
    title: "以案说险",
    url: "/data/news_list_03.json",
  },
  "04": {
    id: "04",
    title: "风险提示",
    url: "/data/news_list_04.json",
  },
  "05": {
    id: "05",
    title: "新市民金融服务宣传月",
    url: "/data/news_list_05.json",
  },
  "06": {
    id: "06",
    title: "普惠金融推进月",
    url: "/data/news_list_06.json",
  },
};
const pageSize = 10;
const NewsInfoPage: React.FC = () => {
  const navigate = useNavigate();
  const [pageIndex, setPageIndex] = useState(1);
  const [total, setTotal] = useState(0);
  const [currentNewsList, setCurrentNewsList] = useState<INewsItem[]>([]);
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [data, setData] = useState<INewsItem[]>([]);
  const [activeNews, setActiveNews] = useState<INewsItem>();
  const [type, setType] = useState("02");

  useEffect(() => {
    let { pathname } = location;
    const  pathname_all = [
      '/safety/xb/00',
      '/safety/xb/02',
      '/safety/xb/03',
      '/safety/xb/04',
      '/safety/xb/05',
      '/safety/xb/06']
    if(!pathname_all.includes(pathname)){
      pathname = '/safety/xb/02'
    }
    const [, , , path2] = pathname.split("/");
    setType(path2);
    // @ts-ignore
    setActiveNews(newDataList[path2] || {});
    getData(path2);
    setPageIndex(1);
  }, [location]);

  const getData = async (id: string) => {
    // @ts-ignore
    const url = newDataList?.[id]?.url;
    if (url) {
      const result: any = await fetchJSONWithETag(url);
      setData(result);
      setTotal(result.length);
      setCurrentNewsList(result.slice(0, pageSize));
    }
  };

  const onChange = (page: number) => {
    setPageIndex(page);
    setCurrentNewsList(data.slice((page - 1) * pageSize, page * pageSize));
  };

  const goDetailPage = (url: string) => {
    const _url = url.slice(url.indexOf("news/") + 5, url.indexOf("."));
    navigate(`/news-content/safety/${_url}`, {
      state: {
        backUlr: type,
      },
    });
    // navigate('/news-content/', {
    //   state: {
    //     type: 'safety',
    //     url,
    //     backUlr: type,
    //   },
    // });
  };

  return (
    <>
      <div className="marjor-info-wrap">
        <h2 className="title">{activeNews?.title}</h2>
        {total > 0 ? (
          <div className="p_wrap">
            {currentNewsList.map((item, index) => (
              <div
                key={item.id}
                className="line_item"
                onClick={() => {
                  goDetailPage(item.contentUrl);
                }}
              >
                <div className="line_title">{item.title}</div>
                <div className="line_img">
                  <img src={zuojiantou} alt="" />
                </div>
              </div>
            ))}

            {total > 0 && (
              <Pagination
                defaultCurrent={pageIndex}
                total={total}
                current={pageIndex}
                onChange={onChange}
                pageSize={pageSize}
                showSizeChanger={false}
              />
            )}
          </div>
        ) : (
          <div className="empty-info-wrap">
            <div className="empty_wrap">
              <img src={emptyPng} alt="" />
              <p>暂无内容哦，敬请期待～</p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default NewsInfoPage;
