export const data1 = [
  {
    id: '101',
    name: '非车险',
    link: '50%',
  },
  {
    id: '102',
    name: '车险-机动车商业保险',
    link: '50%',
  },
  {
    id: '103',
    name: '车险-新能源汽车商业保险',
    link: '50%',
  },
];

export const data2 = [
  {
    id: '201',
    name: '保证保险',
    link: '50%',
  },
  {
    id: '202',
    name: '车险',
    link: '50%',
  },
  {
    id: '203',
    name: '船舶保险',
    link: '50%',
  },
  {
    id: '204',
    name: '工程保险',
    link: '50%',
  },
  {
    id: '205',
    name: '货物运输保险',
    link: '50%',
  },
  {
    id: '206',
    name: '普通家财险',
    link: '50%',
  },
  {
    id: '207',
    name: '企业财产保险',
    link: '50%',
  },
  {
    id: '208',
    name: '特殊风险',
    link: '50%',
  },
  {
    id: '209',
    name: '信用保险',
    link: '50%',
  },
  {
    id: '210',
    name: '意外及健康险',
  },
  {
    id: '211',
    name: '责任保险',
  },
  {
    id: '212',
    name: '其他',
  },
];
