import React, { useEffect, useState } from "react";
import "./index.scss";
import Rotate from "common/components/rotate";
import RecommendProduct from "common/components/recommend";
import HotProduct from "../HotProduct";
// import CarInsure from '../CarInsure';
import CarInsure from "../CarInsureNew";
import Consult from "../ConsultNew";
import JdInsure from "../JdInsure";
import { IFloor } from "lib/types";

import { getJSData } from "@/lib/request";
interface IProps {
  floorsList: any;
  cdnUrl: any;
}
const Floors = (props: IProps) => {
  const { floorsList, cdnUrl } = props;

  const [bannerList, setBannerList] = useState<any>([]);
  const [jobNumber, setJobNumber] = useState<any>({});
  const [recommendInfo, setRecommendInfo] = useState<any>([]);

  async function fetchData() {
    if (cdnUrl.websiteHome) {
      const websiteHome: any = await getJSData({
        url: cdnUrl.websiteHome,
        callback: "websiteHomeJDAZCallback",
      });
      setBannerList(websiteHome.banners);
      setJobNumber({
        jobNumberFemale: websiteHome.jobNumberFemale,
        jobNumberMale: websiteHome.jobNumberMale,
      });
      setRecommendInfo(websiteHome.recommendInfo);
      console.log(websiteHome);
    }
  }

  useEffect(() => {
    if (cdnUrl) {
      fetchData();
    }
  }, [cdnUrl]);

  return (
    <div className="floor_wrap">
      {floorsList.map((floor, index) => {
        return (
          <section key={index}>
            {floor.type === "combAdTurn3" && (
              <Rotate data={floor.combAdTurn3.data} />
            )}

            {floor.type === "jingxuan" && (
              <RecommendProduct
                jingxuan={floor.jingxuan}
                recommendInfo={recommendInfo}
              />
            )}

            {floor.type === "jingxuan_hot" && (
              <HotProduct productList={floor.jingxuan_hot} />
            )}

            {floor.type === "combRecommendMove1" && (
              <CarInsure bannerList={bannerList} />
            )}

            {floor.type === "combRecommend2" && (
              <Consult jobNumber={jobNumber} />
            )}

            {/* {floor.type === "img" && <JdInsure dataInfo={floor.img} />} */}
          </section>
        );
      })}
    </div>
  );
};

export default Floors;
