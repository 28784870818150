export const data1 = [
  {
    title: '您的建议',
    value: [
      '我们一直乐于听取您对我们公司的建议，您的建议可以清楚的帮我们认识到我们服务中存在的不足，从而帮助我们更好的服务更多的客户。',
    ],
  },
  {
    title: '您的意见',
    value: [
      '我们期望您能对我们提供的服务满意，如果您有任何的不满意或投诉，我们非常乐意听取。我们会严肃对待您的投诉，同时我们也将尽可能迅速地给您一个满意的答复。',
    ],
  },
  {
    title: '下一步工作',
    value: [
      '我们努力在2个工作日内给您一个满意的答复，如果我们的调查需要更长的时间，我们会向您解释原因，并会给您一个合理的最终答复时间。',
    ],
  },
];
