import React, { memo, useState, useEffect } from 'react';
import './index.scss';
import InsDialog from '@/order-components/ins-dialog';
import { IDetailFeatureItem, IDetailPlanItem, IDetailQuestionItem } from '@/lib/order/types'
import DetailContentItem from './detail-content-item'
import DetailQuestion from './detail-question';
import PlanDetail from './plan-detail'
import closeImg from '@/static/imgs/order/icon_close.svg'

interface ITabItem {
	code: string,
	display: string
}

interface IProps {
	show: boolean,
	onClose: () => void,
	current: string,
	featureData: {
		detail_pic: IDetailFeatureItem[],
		process: IDetailFeatureItem[],
		caseData: IDetailFeatureItem[],
		ques: IDetailQuestionItem[],
		planDetail: IDetailPlanItem[]
	}
}

// const tabs = ['产品介绍', '保障详情', '如何理赔', '常见问题'];

const FeatureDialog = (props: IProps) => {

	const {show, onClose, current, featureData} = props;

	const [currentTab, setCurrentTab] = useState({
		code: '',
		index: -1
	});
	const [tabs, setTabs] = useState<ITabItem[]>([]);

	useEffect(() => {
		if (featureData) {
			const {detail_pic, process, caseData, ques} = featureData;
			let tabList: ITabItem[] = [];
			if (detail_pic?.length > 0) {
				tabList.push({
					code: 'feature',
					display: '产品介绍'
				});
			}
			tabList.push({
				code: 'plan',
				display: '保障详情'
			});
			if (process?.length > 0 || caseData?.length > 0) {
				tabList.push({
					code: 'claim',
					display: '如何理赔'
				});
			}
			if (ques?.length > 0) {
				tabList.push({
					code: 'question',
					display: '常见问题'
				});
			}
			setTabs(tabList);
		}
	}, [featureData])

	useEffect(() => {
		if (!current) {
			setCurrentTab({
				...currentTab,
				code: ''
			});
		}
	}, [current])

	useEffect(() => {
		if (show) {
			requestAnimationFrame(() => {
				setTimeout(() => {
					const currentTabIndex = tabs.findIndex((item) => item.code === current);
					if (currentTabIndex > -1) {
						setCurrentTab({
							code: tabs[currentTabIndex].code,
							index: currentTabIndex
						});
					}
				}, 300)
			})
		}
	}, [show])

	const handleTabItemClick = (code: string, index: number) => {
		if (currentTab.code === code) return
		setCurrentTab({
			code,
			index
		});
	}

	return (
		<InsDialog
			show={show}
			onClose={onClose}
			location={'right'}
		>
			<div className='feature-dialog'>
				<div className='feature-dialog_header'>
					{
						tabs?.length > 0 &&
						tabs.map((item, index) => {
							return (
								<span
									key={`feature-dialog-tab-item-${index}`}
									className={`v-tab-item ${currentTab.code === item.code ? 'v-tab-item-active' : ''}`}
									onClick={() => handleTabItemClick(item.code, index)}
								>
									{item.display}
								</span>
							)
						})
					}
					<img className='v-img-close' src={closeImg} onClick={onClose} />
				</div>
				<div className='feature-dialog_line'>
					<div className='v-indicator' style={{transform: `translateX(${Math.max(currentTab.index, 0) * 104}px)`}} />
				</div>
				<div className='feature-dialog_body'>
					{
						featureData?.detail_pic?.length > 0 &&
						<div className='v-feature-item' style={{display: currentTab.code === 'feature' ? 'flex' : 'none'}}>
							<DetailContentItem
								data={featureData.detail_pic}
							/>
						</div>
					}
					{
						featureData?.planDetail &&
						<div className='v-feature-item' style={{display: currentTab.code === 'plan' ? 'flex' : 'none'}}>
							<PlanDetail data={featureData.planDetail} />
						</div>
					}
					{
						(featureData?.process?.length > 0 || featureData?.caseData?.length > 0) &&
						<div className='v-feature-item' style={{display: currentTab.code === 'claim' ? 'flex' : 'none'}}>
							{
								featureData?.process?.length > 0 &&
								<DetailContentItem
									data={featureData.process}
								/>
							}
							{
								featureData?.caseData?.length > 0 &&
								<DetailContentItem
									data={featureData.caseData}
								/>
							}
						</div>
					}
					{
						featureData?.ques?.length > 0 &&
						<div className='v-feature-item' style={{display: currentTab.code === 'question' ? 'flex' : 'none'}}>
							<DetailQuestion
								data={featureData.ques}
							/>
						</div>
					}
				</div>
			</div>
		</InsDialog>
	)
}

export default memo(FeatureDialog)
