import React, { useEffect, useState } from "react";
import "./index.scss";
import ProductList from "common/components/ProductList";
import { getPathApi, getMenusApi, websiteQuery } from "lib/Apis";
import Floating from "../../common/components/Floating";
import { getJSData } from "@/lib/request";

interface Floor {
  [key: string]: any;
  type: string;
}

const Main: React.FC = () => {
  let [productData, setProductData] = useState<any>([]);
  let [jobNumber, setJobNumber] = useState<any>("");
  let [listPageLink, setFloorsList] = useState<any>("");
  useEffect(() => {
    document.title =
      "成长优享，安联财险成长优享，成长优享儿童高端医疗保险，旅行险，申根，京东安联申根旅行险，高端医疗";
    getFloors();
    window.scrollTo(0, 0);
    // 在组件卸载时移除 overflow: hidden 属性
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  const getFloors = async () => {
    try {
      const path: any = await getPathApi("bxsc");
      const floorsObj1: any = await getMenusApi(path.dataPath);
      const floorsObj2: any = await websiteQuery({});

      const websiteMall: any = await getJSData({
        url: floorsObj2.websiteMall,
        callback: "websiteMallJDAZCallback",
      });
      console.log(floorsObj1, floorsObj2, websiteMall);
      setProductData(websiteMall.categories);
      setJobNumber(websiteMall.jobNumber);
      setFloorsList(websiteMall.listPageLink);
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <div className="bxsc_wrap">
      <ProductList
        productData={productData}
        jobNumber={jobNumber}
        listPageLink={listPageLink}
      />
      <Floating />
    </div>
  );
};
export default Main;
