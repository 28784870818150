import React, { memo, useState, useEffect } from 'react';
import './index.scss';
import { IDetailAgreementItem, IProdFactMaterial } from '@/lib/order/types'
import InsDialog from '@/order-components/ins-dialog';
import InsDialogHeader from '@/order-components/ins-dialog-header'
import InsButton from '@/order-components/ins-button';
import CommonAgreement from '../common-agreement'

interface IProps {
	show: boolean,
	onClose: (type: string) => void,
	onConfirmCallback: (type: string, step: number, index?: number) => void,
	data: IDetailAgreementItem | undefined,
	current: number,
	total: number,
	index?: number
}

const AgreementStepConfirmDialog = (props: IProps) => {

	const {show, onClose, onConfirmCallback, data, current, total, index} = props

	const [readOverState, setReadOverState] = useState(false)

	const [finalAgreementData, setFinalAgreementData] = useState<IDetailAgreementItem>(null);

	useEffect(() => {
		if (data) {
			if (data.type === 'bxtk' && data.sub?.length > 0) {
				let prodFactMaterial: IProdFactMaterial = {
					planCode: '',
					planName: '保险条款',
					pdfName: '',
					pdfPath: '',
					imgPath: []
				};
				data.sub.forEach((item) => {
					if (item.prodFactMaterial?.length > 0) {
						item.prodFactMaterial.forEach((subItem) => {
							prodFactMaterial.imgPath.push(...subItem.imgPath)
						})
					}
				})
				const result = {
					...data,
					isProdFactConf: true,
					prodFactMaterial: [prodFactMaterial],
					sub: []
				}
				console.log('bxtk-result', result);
				setFinalAgreementData(result);
			} else {
				setFinalAgreementData(data);
			}
		}
	}, [data])

	useEffect(() => {
		if (!show) {
			setReadOverState(false);
		}
	}, [show])

	const handleReadOverCallback = () => {
		setReadOverState(true)
	}

	const handleClose = () => {
		onClose(data?.type || '')
	}

	const handleConfirmCallback = () => {
		try {
			onConfirmCallback(data?.type || '', current, index)
		} catch (e) {

		}
	}

	return (
		<InsDialog
			show={show}
			onClose={handleClose}
			location={'center'}
			disableOverlayClose={true}
		>
			<div className='agreement-step-confirm-dialog'>
				{
					show &&
					finalAgreementData &&
					<React.Fragment>
						<InsDialogHeader
							title={finalAgreementData.name}
							onClose={handleClose}
						/>
						<CommonAgreement
							data={finalAgreementData}
							readOverCallback={handleReadOverCallback}
						/>
						<div className='agreement-step-confirm-dialog__footer'>
							<span className='v-footer-step'>{current}/{total}</span>
							<InsButton
								id={'agreement-step-confirm-dialog-footer-agree'}
								className='v-footer-btn'
								value={readOverState ? '我已逐页阅读并同意' : '阅读至页面底部可点击'}
								onClick={handleConfirmCallback}
								disabled={!readOverState}
							/>
						</div>
					</React.Fragment>
				}
			</div>
		</InsDialog>
	)
}

export default memo(AgreementStepConfirmDialog)
