import "./index.scss";
import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { QRCodeCanvas } from "qrcode.react";

import { ONLINEURL } from "lib/config";
import { Carousel } from "antd";
import LazyImg from "common/components/LazyImg";
import LujingImg from "../../img/lujing_icon.png";
interface IImgsInfo {
  mainImg: string;
  subImg: string;
  xy: {
    x: number;
    y: number;
  };
}
interface IProps {
  bannerList: any;
}
const CarInsure = (props: IProps) => {
  const { bannerList } = props;
  const carouselRef: any = useRef();

  let { search } = useLocation();

  const onChange = (currentSlide) => {};

  //下一个回调
  const goToNextSlide = () => {
    carouselRef.current?.next();
  };

  //上一个回调
  const goToPrevSlide = () => {
    carouselRef.current?.prev();
  };

  //获取二维码
  const getQrcodeUrl = (type: any, link: any, id: any) => {
    let url = "";
    if (type === "1") {
      url = link;
    } else {
      url = `${ONLINEURL}?p=${id}&jdaz_source=PC`;
    }
    return url;
  };

  return (
    <div className={bannerList.length > 1 ? "car_insure" : "car_insureOne"}>
      <div className="car_insure_wrap">
        {bannerList.length > 1 && (
          <div className="icon-left" onClick={goToPrevSlide}>
            <div className="icon-left-icon"></div>
          </div>
        )}
        <Carousel afterChange={onChange} autoplay={true} ref={carouselRef}>
          {bannerList.map((item: any) => {
            return (
              <div className="carousel">
                <div className="carousel_left">
                  <div className="carousel_left_title">
                    <span>{item.productionName}</span>
                  </div>
                  <div className="carousel_left_introduce">
                    {item.carouselReasonDesc.split(";").map((label, index) => (
                      <span key={index}>{label}</span>
                    ))}
                  </div>
                  <div className="carousel_left_span">
                    {item.carouselLabel.split(";").map((label, index) => (
                      <span key={index}>{label}</span>
                    ))}
                  </div>
                  <div className="carousel_left_insure">
                    <div className="carousel_left_insure_img">
                      <div className="qrcode">
                        <div className="qrcode-canvas">
                          <QRCodeCanvas
                            value={getQrcodeUrl(
                              item.productionType,
                              item.productionLink,
                              item.productionId
                            )}
                            size={100}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="carousel_left_insure_font">
                      <LazyImg src={LujingImg} alt="" />
                      <span>扫码投保</span>
                    </div>
                  </div>
                </div>
                <div className="carousel_right">
                  <img src={`${item.carouselImageUrl}`} alt="" />
                </div>
              </div>
            );
          })}
        </Carousel>
        {bannerList.length > 1 && (
          <div className="icon-right" onClick={goToNextSlide}>
            <div className="icon-right-icon"></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CarInsure;
