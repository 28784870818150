// import React from 'react';
import { Navigate, Routes, Route } from "react-router-dom";
import Home from "./pages/main";
import Bxsc from "./pages/bxsc";
import BxscDetail from "./pages/bxsc-detail";
import Gzwm from "./pages/gzwm";
import PublishInfo from "./pages/public-info";
import Safety from "./pages/safety";
import Suggestion from "./pages/suggestion";
import ProductionList from "./pages/public-info/ProductionListPage";
import NewsList from "./pages/news/news-list";
import NewsContent from "./pages/news/news-content";
import AboutUs from "./pages/about-us/index";
import OpenDay from "./pages/open-day/index";
import DownloadInfo from "./pages/download-info/index";
import QueryPage from "./pages/query";
import InsuranceServer from "./pages/insurance-server";
import InternetProdList from "./pages/inter-product-list";
import CooperativeList from "./pages/cooperative-insurance-list";
import BitDocument from "./pages/bit-document";
import BitDocumentPageDetail from "./pages/bit-document/components";

import InsureOrder from "./pages/product/order"; // 投保下单
import PayResult from "./pages/product/result"; // 支付结果页
import Test from "./pages/test"; // 测试页

const BasicRoute = () => (
  <Routes>
    <Route index element={<Home />} />
    <Route path="/bxsc/:id" element={<Bxsc />} />
    <Route path="/bxsc" element={<Bxsc />} />
    <Route path="/bxsc/xyx/:id" element={<BxscDetail />} />
    <Route path="/gzwm" element={<Gzwm />} />
    <Route path="/clause-page/:id" element={<ProductionList />} />
    <Route path="/public-info/*" element={<PublishInfo />}></Route>
    <Route path="/safety/*" element={<Safety />}></Route>
    <Route path="/suggestion/*" element={<Suggestion />}></Route>
    <Route path="/download-info/*" element={<DownloadInfo />}></Route>
    <Route path="/about-us/*" element={<AboutUs />}></Route>
    <Route path="/news-list" element={<NewsList />}></Route>
    <Route path="/news-content/:type/:url" element={<NewsContent />}></Route>
    <Route path="/open-day" element={<OpenDay />}></Route>
    <Route path="/internet-prod-list" element={<InternetProdList />}></Route>
    <Route
      path="/cooperative-insurance-list"
      element={<CooperativeList />}
    ></Route>
    <Route path="/query" element={<QueryPage />}></Route>
    <Route path="/insurance-server" element={<InsuranceServer />}></Route>
    <Route path="/bit-document" element={<BitDocument />}></Route>
    <Route
      path="/bit-document/detail"
      element={<BitDocumentPageDetail />}
    ></Route>

	  <Route path="/bxsc/product/detail" element={<InsureOrder />} />
	  <Route path="/bxsc/product/result" element={<PayResult />} />

	  <Route path="/test" element={<Test />} />
    <Route path="*" element={<Navigate to="/" />} />
  </Routes>
);

export default BasicRoute;
