// const TraceEnvList = ['dev', 'test', 'pre', 'prod']
const TraceEnvList = ['test', 'pre', 'prod'];

const ENV_MODE = process.env.REACT_APP_MODE || 'prod';

const JDAZTraceUtils = {

	initStart() {
		try {
			if (!TraceEnvList.includes(ENV_MODE)) return;
			// @ts-ignore
			window.track_sdk && window.track_sdk.initStart();
		} catch (e) {

		}
	},

	resultStart(traceId: string) {
		try {
			if (!traceId) return;
			if (!TraceEnvList.includes(ENV_MODE)) return;
			//@ts-ignore
			window.track_sdk && window.track_sdk.resultStart(traceId);
		} catch (e) {

		}
	}

}

export default JDAZTraceUtils;
