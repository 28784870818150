const RegExpUtils = {
	/**
	 * 字母+数字
	 */
	checkLetterAndNumber(value: string) {
		if (value) {
			let regex = /^[a-zA-Z0-9]+$/;
			return regex.test(value.trim());
		} else {
			return false;
		}
	},

	/**
	 * 字母+数字+汉字
	 */
	checkStr_ch_num(value: string) {
		if (value) {
			let regex = /^[a-zA-Z0-9\u4e00-\u9fa5]+$/;
			return regex.test(value.trim());
		} else {
			return false;
		}
	},
	isLeapYear(year: number): boolean {
		if (isNaN(year)) {
			// 非数字
			return false;
		} else {
			return (year % 4 == 0 && year % 100 != 0) || year % 400 == 0;
		}
	},
	/**
	 * 身份证号码格式验证
	 */
	checkIdCard(idCardNum: string): boolean {
		if (!idCardNum) return false;
		let prov = {
			11: '北京',
			12: '天津',
			13: '河北',
			14: '山西',
			15: '内蒙古',
			21: '辽宁',
			22: '吉林',
			23: '黑龙江',
			31: '上海',
			32: '江苏',
			33: '浙江',
			34: '安徽',
			35: '福建',
			36: '江西',
			37: '山东',
			41: '河南',
			42: '湖北',
			43: '湖南',
			44: '广东',
			45: '广西',
			46: '海南',
			50: '重庆',
			51: '四川',
			52: '贵州',
			53: '云南',
			54: '西藏',
			61: '陕西',
			62: '甘肃',
			63: '青海',
			64: '宁夏',
			65: '新疆',
			71: '台湾',
			81: '香港',
			82: '澳门',
			83: '台湾',
			91: '国外'
		};
		if (!prov[parseInt(idCardNum.substr(0, 2))]) {
			// 省份检验
			return false;
		}
		let regExp;
		let idChars = idCardNum.toUpperCase().split('');
		switch (idCardNum.length) {
			case 15: // 15位身份证号检测
				// if (this.isLeapYear(parseInt(idCardNum.substr(6, 2)) + 1900)) {
				// 	// 闰年
				// 	regExp = /^[1-9][0-9]{5}[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}$/;
				// } else {
				// 	// 平年
				// 	regExp = /^[1-9][0-9]{5}[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|1[0-9]|2[0-8]))[0-9]{3}$/;
				// }
				// return regExp.test(idCardNum);
				return false;
			case 18: // 18位身份号码检测
				if (this.isLeapYear(parseInt(idCardNum.substr(6, 4)))) {
					// 闰年
					regExp = /^[1-9][0-9]{5}[1-9][0-9]{3}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}[0-9Xx]$/;
				} else {
					// 平年
					regExp = /^[1-9][0-9]{5}[1-9][0-9]{3}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|1[0-9]|2[0-8]))[0-9]{3}[0-9Xx]$/;
				}
				if (regExp.test(idCardNum)) {
					// 基本校验
					let modulus,
						checkCodeList = '10X98765432';
					let sum, code;
					sum =
						(parseInt(idChars[0]) + parseInt(idChars[10])) * 7 +
						(parseInt(idChars[1]) + parseInt(idChars[11])) * 9 +
						(parseInt(idChars[2]) + parseInt(idChars[12])) * 10 +
						(parseInt(idChars[3]) + parseInt(idChars[13])) * 5 +
						(parseInt(idChars[4]) + parseInt(idChars[14])) * 8 +
						(parseInt(idChars[5]) + parseInt(idChars[15])) * 4 +
						(parseInt(idChars[6]) + parseInt(idChars[16])) * 2 +
						parseInt(idChars[7]) +
						parseInt(idChars[8]) * 6 +
						parseInt(idChars[9]) * 3; // 计算校验位
					modulus = sum % 11;
					code = checkCodeList.substr(modulus, 1); // 找到校验位
					return code == idChars[17];
				} else {
					return false;
				}
			default:
				return false;
		}
	},
	/**
	 * 手机号码格式验证
	 */
	checkMobile(mobile: string): boolean {
		if (mobile) {
			let regex = /^1[3-9]\d{9}$/;
			return regex.test(mobile.trim());
		} else {
			return false;
		}
	},
	checkMail(mail: string): boolean {
		mail = mail.trim();
		if (!mail) return false;
		// let regex = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/;
		let regex = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,14}$/;
		// let regex = /^\\w+([-+.]\\w+)*@\\w+([-.]\\w+)*\\.\\w+([-.]\\w+)*$/;
		return regex.test(mail.trim());
	},
	checkMsgCode(msgCode: string): boolean {
		if (msgCode) {
			let regex = /^[0-9]{6}$/;
			return regex.test(msgCode.trim());
		} else {
			return false;
		}
	},
	checkPassportNum(passportNum: string): boolean {
		if (passportNum) {
			// 核心的护照校验规则
			let regex = /^(((DE|SE|PE|E[A-Z])[0-9]{7})|((D|S|P|G|E)[0-9]{8})|(M[A-Z][0-9]{7})|([HK][0-9A-Z][0-9]{7}))$/;
			return regex.test(passportNum.trim());
		} else {
			return false;
		}
	},
	//统一社会信用代码
	checkUnionCode(unionCode) {
		if (unionCode) {
			// let regex = /^([0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}|[1-9]\d{14})$/
			let regex = /^[a-zA-Z0-9]{10,40}$/;
			return regex.test(unionCode.trim());
		} else {
			return false;
		}
	},
	//纳税人识别码
	checkInvoiceCode(invoiceCode) {
		if (invoiceCode) {
			// let regex = /^([0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}|[1-9]\d{14})$/
			let regex = /^[a-zA-Z0-9]{10,40}$/;
			return regex.test(invoiceCode.trim());
		} else {
			return false;
		}
	},

	/**
	 * 车牌号
	 */
	checkCarLicenseNumber(value: string) {
		if (value) {
			let regex = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-Z][A-Z0-9]{4,6}$/;
			return regex.test(value.trim());
		} else {
			return false;
		}
	},

	/**
	 * 车架号
	 */
	checkCarFrameNumber(value: string) {
		if (value) {
			let regex = /^[a-zA-Z0-9]{17}$/;
			return regex.test(value.trim());
		} else {
			return false;
		}
	},

	/**
	 * 发动机号
	 */
	checkCarEngineNumber(value: string) {
		if (value) {
			// let regex = /^[0-9A-Za-z\-－\u4e00-\u9fa5]{6,20}$/
			// let regex = /[\u4e00-\u9fa5`~!@#$%^&*()_\+=<>?:"{}|,.\/;'\\[\]·~！@#¥%⋯⋯&*（）——\+={}|《》？：“”【】、；‘’，。、\s]/;
			let regex = /^[0-9A-Za-z\-－]{6,20}$/
			return regex.test(value.trim());
		} else {
			return false;
		}
	},

	/**
	 * 正整数
	 */
	checkWholeNumber(value: string) {
		if (value) {
			// let regex = /^[1-9]\d*$/;
			let regex = /^(0|[1-9][0-9]*)$/;
			return regex.test(value.trim());
		} else {
			return false;
		}
	},

	/**
	 * 货运单号
	 */
	checkTransportNumber(value: string) {
		if (value) {
			let regex = /^[a-zA-Z0-9]{16}$/;
			return regex.test(value.trim());
		} else {
			return false;
		}
	},

	/**
	 * 金额
	 */
	checkMoney(value: string) {
		if (value) {
			let regex = /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0)$)|(^[0-9]\.[0-9]([0-9])?$)/;
			const result = regex.test(value.trim());
			return result && Number.parseFloat(value.trim()) > 0;
		} else {
			return false;
		}
	},

	/**
	 * 车架号-电动车
	 */
	checkEBikeFrameNumber(value: string) {
		if (value) {
			let regex = /^[a-zA-Z0-9]{10,20}$/;
			return regex.test(value.trim());
		} else {
			return false;
		}
	},

	/**
	 * 校验银行卡号码（前6后4）
	 */
	checkBankNoShort(value: string): boolean {
		if (value) {
			let regex = /^[0-9]{10}$/;
			return regex.test(value.trim());
		} else {
			return false;
		}
	},

	/**
	 * 校验银行卡号码
	 */
	checkBankNo(value: string): boolean {
		if (value) {
			let regex = /^([1-9])(\d{15}|\d{18})$/;
			return regex.test(value.trim());
		} else {
			return false;
		}
	},

	/**
	 * 字母+空格
	 */
	checkNamePinYin(value: string) {
		if (value) {
			let regex = /^[a-zA-Z\s]{1,50}$/;
			return regex.test(value.trim());
		} else {
			return false;
		}
	},

	/**
	 * 小于等于10位数字/字母
	 */
	checkCertNum10(value: string) {
		// if (value) {
		// 	let regex = /^[a-zA-Z0-9]{2,10}$/;
		// 	return regex.test(value.trim());
		// } else {
		// 	return false;
		// }
		return this.checkLetterAndNumber(value);
	},

	/**
	 * 15位数字/字母
	 */
	checkCertNum15(value: string) {
		// if (value) {
		// 	let regex = /^[a-zA-Z0-9]{15}$/;
		// 	return regex.test(value.trim());
		// } else {
		// 	return false;
		// }
		return this.checkLetterAndNumber(value);
	},

	/**
	 * 港澳台居民身份证
	 */
	checkGangAoTaiIDCard(value: string) {
		if (value) {
			let regex = /^8[123]0000(?:19|20)\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])\d{3}[\dX]$/;
			return regex.test(value.trim().toUpperCase());
		} else {
			return false;
		}
	}

};

export default RegExpUtils;
