import React, { useEffect, useState } from "react";
import "./index.scss";
import { dataList, undercarriageList } from "./staticData";
import { previewPdf } from "lib/utils";
import { fetchFileList, prodictQuery } from "lib/websiteApi";

const InternetProdList: React.FC = () => {
  const [undercarriage, setUndercarriage] = useState(false);
  const [upList, setUpList] = useState([]); //上架列表
  const [downList, setDownList] = useState([]); //下架列表
  const [list, setList] = useState([]);
  const productOptions: any = {
    "1": "车险产品",
    "2": "农险产品",
    "3": "财险产品（非车非农）",
    "4": "短期人身险产品",
    "5": "航运险",
  };
  const goPdfPage = async (url, id) => {
    const params = {
      fileItem1Code: "002",
      fileItem2Code: id,
      pageIndex: 1,
      pageSize: 10,
      fuzzyFilename: url,
    };
    const result: any = await fetchFileList(params);
    if (result && result.list.length > 0) {
      previewPdf(result.list[0].url);
    }
  };

  //上架 下架切换
  const changeList = () => {
    setUndercarriage(!undercarriage);
    undercarriage ? setList(upList) : setList(downList);
  };

  //初始化信息
  useEffect(() => {
    document.title = "京东安联保险-互联网保险产品名称及产品信息";
    window.scrollTo(0, 0);
    const asyncFn = async () => {
      const res1: any = await prodictQuery(
        "/website/web/insuranceProducts/query?insStatus=1"
      );
      const res2: any = await prodictQuery(
        "/website/web/insuranceProducts/query?insStatus=2"
      );
      setUpList(res1.data);
      setDownList(res2.data);
      setList(res1.data);
      console.log(res1, res2);
    };
    asyncFn();
  }, []);
  return (
    <div className="inter-pro-wrap">
      <div className="open-day-container">
        <div className="page-title">
          {undercarriage
            ? "互联网保险已下架产品信息详情"
            : "互联网保险已上架产品信息详情"}
          <span className="page-sub-title" onClick={changeList}>
            {undercarriage ? "查看在售产品" : "查看已下架产品"}
          </span>
        </div>
        <table>
          <thead>
            <tr>
              <th style={{ width: "170px" }}>实际销售名称</th>
              <th style={{ width: "266px" }}>中国保监会备案产品名称</th>
              <th style={{ width: "100px" }}>产品类别</th>
              <th style={{ width: "180px" }}>备案编号/产品注册号</th>
              <th style={{ width: "120px" }}>报备文件编号</th>
              <th style={{ width: "128px" }}>条款编码</th>
              <th style={{ width: "128px" }}>条款内部编码</th>
              <th style={{ width: "107px" }}>销售区域范围</th>
              <th style={{ width: "107px" }}>批复文号</th>
            </tr>
          </thead>
          <tbody>
            {list.map((item: any, index) => (
              <tr key={index}>
                <td>{item.insName}</td>
                <td>
                  <a
                    className="link"
                    href={`${item.recordFileUrl}`}
                    onClick={() => {
                      // goPdfPage(item.recordFileUrl, item.recordFileCode);
                    }}
                  >
                    {item.recordName}
                  </a>
                </td>
                <td style={{ width: "140px" }}>
                  {productOptions[item.insType]}
                </td>
                <td>{item.recordCode}</td>
                <td>{item.recordFileCode}</td>
                <td>{item.clauseCode}</td>
                <td>{item.clauseInsideCode}</td>
                <td>{item.salesArea}</td>
                <td>{item.approvalCode}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default InternetProdList;
