import React, { useEffect } from 'react';
import './index.scss';
import { Routes, Route } from 'react-router-dom';
import Nav from '../public-info/components/nav';
import CommonPage from './CommonPage/index';

let navMenus = [
  {
    name: '通用材料下载',
    id: 'ty',
  },
  {
    name: '理赔材料下载',
    id: 'lp',
  },
];

const Suggestion: React.FC = () => {
  useEffect(() => {
    document.title = '京东安联保险-资料下载';
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='public-info-wrap'>
      <div className='left'>
        <Nav data={navMenus} />
      </div>
      <div className='right'>
        <Routes>
          <Route path='/ty' element={<CommonPage />} />
          <Route path='/lp' element={<CommonPage />} />
        </Routes>
      </div>
    </div>
  );
};

export default Suggestion;
