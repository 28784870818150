const data1 = [
  {
    title: '个人保险合同保全变更申请书（意健险）',
    urlLink: '个人保险合同保全变更申请书（意健险）.pdf',
  },
  {
    title: '团体保险合同保全变更申请书（意健险）',
    urlLink: '团体保险合同保全变更申请书（意健险）.pdf',
  },
  {
    title: '解除保险合同申请书（意健险）',
    urlLink: '解除保险合同申请书（意健险）.pdf',
  },
  {
    title: '个人投保业务开专票的申请',
    urlLink: '个人投保业务开专票的申请.pdf',
  },
];
const data2 = [
  {
    title: '京东安联车险增值服务细则',
    urlLink: '车险增值服务手册.pdf',
  },
  {
    title: '特种车商业保险示范条款&免责事项说明书（2020版）',
    urlLink:
      '京东安联财产保险有限公司特种车商业保险示范条款&免责事项说明书（2020版）.pdf',
  },
  {
    title: '机动车商业保险示范条款&免责事项说明书（2020版）',
    urlLink:
      '京东安联财产保险有限公司机动车商业保险示范条款&免责事项说明书（2020版）.pdf',
  },
  {
    title:
      '京东安联财产保险有限公司新能源汽车商业保险示范条款&免责事项说明书（试行）',
    urlLink:
      '京东安联财产保险有限公司新能源汽车商业保险示范条款&免责事项说明书（试行）.pdf',
  },
  {
    title: '机动车交通事故责任强制保险条款',
    urlLink: '机动车交通事故责任强制保险条款.pdf',
  },
];

const data3 = [
  {
    title: 'Treatment Guarantee Form',
    urlLink: 'Treatment Guarantee Form20230407.pdf',
  },
  {
    title: '医疗担保申请书',
    urlLink: '医疗担保申请书20230407.pdf',
  },
  {
    title: '特殊医疗机构',
    urlLink: '特殊医疗机构列表 Special Provider List20230407.pdf',
  },
  {
    title: '中国大陆地区直付医疗机构',
    urlLink: '中国大陆地区直付医疗机构20230407.xlsx',
  }
];

const data4 = [
  {
    title: '企财险',
    urlLink: '企财险.pdf',
  },
];

const data5 = [
  {
    title: '金融险',
    urlLink: '金融险.pdf',
  },
];

const data6 = [
  {
    title: '能源险工程险',
    urlLink: '能源险工程险.pdf',
  },
];

const data7 = [
  {
    title: '信用保险指南',
    urlLink: '信用保险指南.pdf',
  },
];

const data8 = [
  {
    title: '娱乐产业保险',
    urlLink: '娱乐产品保险.pdf',
  },
];

const data9 = [
  {
    title: '无人机保险',
    urlLink: '无人机保险.pdf',
  },
];
const data10 = [
  {
    title: '旅行变更索赔清单',
    urlLink: '旅行变更索赔清单（模板）.pdf',
  },
  {
    title: '旅行险索赔指南',
    urlLink: '旅行险索赔指南.pdf',
  },
  {
    title: '旅行险索赔申请表',
    urlLink: '旅行保险索赔申请表-合规cyq20221011.pdf',
  },
  {
    title: '旅行险物品索赔清单',
    urlLink: '损失物品索赔清单（模板）.pdf',
  },
  {
    title: '旅行租车索赔指南',
    urlLink: '租车索赔指南.pdf',
  },
  {
    title: '旅行租车索赔申请表',
    urlLink: '租车索赔申请表.pdf',
  },
  {
    title: 'Travel Insurance Claim Guide',
    urlLink: 'Travel Insurance Claim Guide（En）.pdf',
  },
  {
    title: 'Travel Insurance Claim Form',
    urlLink: 'Travel Insurance Claim Form（En）.pdf',
  },
];
const data11 = [
  {
    title: '意外健康险索赔申请表',
    urlLink: '意外健康险索赔申请表-电子版-合规cyq20221011.pdf',
  },
  {
    title: '意外健康险索赔指南',
    urlLink: '意外健康险索赔指南.pdf',
  },
  {
    title: '个人责任险索赔申请表',
    urlLink: '个人责任险索赔申请表.pdf',
  },
  {
    title: '个人责任险索赔指南',
    urlLink: '个人责任险索赔指南.pdf',
  },
  {
    title: '京东安联购药保药品清单',
    urlLink: '京东安联互联网门诊险药品清单.pdf',
  },
  {
    title: '转账支付授权书',
    urlLink: '转账支付授权书（模板）.pdf',
  },
];
const data12 = [
  {
    title: '家财险索赔指南',
    urlLink: '家财险索赔指南.pdf',
  },
  {
    title: '家财险索赔申请表',
    urlLink: '家财险索赔申请表.pdf',
  },
  {
    title: '宠物医疗保险索赔指南',
    urlLink: '宠物医疗保险索赔指南.pdf',
  },
  {
    title: '宠物医疗保险索赔申请表',
    urlLink: '宠物医疗保险索赔申请表.pdf',
  },
  {
    title: '宠物责任保险索赔指南',
    urlLink: '宠物责任保险索赔指南.pdf',
  },
  {
    title: '宠物责任保险索赔申请表',
    urlLink: '宠物责任保险索赔申请表.pdf',
  },
  {
    title: '个人账户资金损失保险索赔指南',
    urlLink: '个人账户资金损失保险索赔指南.pdf',
  },
  {
    title: '个人账户资金损失保险索赔申请表',
    urlLink: '个人账户资金损失保险索赔申请表.pdf',
  },
];
const data13 = [
  {
    title: '车险索赔申请书',
    urlLink: '车险索赔申请表.pdf',
  },
  {
    title: '代位求偿案件索赔申请书（责任对方为机动车方）',
    urlLink: '代位求偿案件索赔申请书（责任对方为机动车方） .pdf',
  },
  {
    title: '代位求偿案件索赔申请书（责任对方为非机动车方）',
    urlLink: '代位求偿案件索赔申请书（责任对方为非机动车方） .pdf',
  },
  {
    title: '机动车辆索赔所需资料',
    urlLink: '机动车辆索赔所需资料.pdf',
  },
  {
    title: '实物赔付确认书',
    urlLink: '实物赔付确认书.pdf',
  },
  {
    title: '转账支付授权书',
    urlLink: '转账支付授权书.pdf',
  },
];
const data14 = [
  {
    title: 'Claim Form (Individual and UW Groups)',
    urlLink: 'Claim Form (Individual and UW Groups).pdf',
  },
  {
    title: 'Claim Form(non UW Groups)',
    urlLink: 'Claim Form(non UW Groups).pdf',
  },
  {
    title: 'Claim Form(Pharmacy delivery)',
    urlLink: 'Claim Form(Pharmacy delivery).pdf',
  },
  {
    title: '理赔申请表（个险与核保团体适用）',
    urlLink: '理赔申请表（个险与核保团体适用）20230407.pdf',
  },
  {
    title: '理赔申请表（免核保团体适用）',
    urlLink: '理赔申请表（免核保团体适用）20230407.pdf',
  },
  {
    title: '理赔申请表（药物递送）',
    urlLink: '理赔申请表（药物递送）.pdf',
  },
];
const data15 = [
  {
    title: '财产险索赔指南',
    urlLink: '财产险索赔指南.pdf',
  },
  {
    title: '财产险索赔申请表',
    urlLink: '财产险索赔申请表.pdf',
  },
  {
    title: '工程险索赔指南',
    urlLink: '工程险索赔指南.pdf',
  },
  {
    title: '工程险索赔申请表',
    urlLink: '工程险索赔申请表.pdf',
  },
  {
    title: '公众责任险索赔指南',
    urlLink: '公众责任险索赔指南.pdf',
  },
  {
    title: '公众责任险索赔申请表',
    urlLink: '公众责任险索赔申请表.pdf',
  },
  {
    title: '雇主责任险（涉三者责任）索赔指南',
    urlLink: '雇主责任险（涉三者责任）索赔指南 .pdf',
  },
  {
    title: '雇主责任险索赔指南',
    urlLink: '雇主责任险索赔指南.pdf',
  },
  {
    title: '雇主责任险索赔申请表',
    urlLink: '雇主责任险索赔申请表.pdf',
  },
  {
    title: '货运险索赔指南',
    urlLink: '货运险索赔指南.pdf',
  },
  {
    title: '货运险索赔申请表',
    urlLink: '货运险索赔申请表.pdf',
  },
];

export const navData = {
  ty: {
    title: '通用材料下载',
    navMenus: [
      {
        name: '个人与家庭',
        subMenus: [
          {
            name: '意健险',
            id: '01',
            list: data1,
          },
          {
            name: '车险',
            id: '02',
            list: data2,
          },
          {
            name: '个人与团体国际医疗保险',
            id: '03',
            list: data3,
          },
        ],
      },
      {
        name: '企业与机构',
        subMenus: [
          {
            name: '企财险',
            id: '11',
            list: data4,
          },
          {
            name: '金融险',
            id: '12',
            list: data5,
          },
          {
            name: '能源险工程险',
            id: '13',
            list: data6,
          },
          {
            name: '信用险',
            id: '14',
            list: data7,
          },
          {
            name: '娱乐产业保险',
            id: '15',
            list: data8,
          },
          {
            name: '无人机保险',
            id: '16',
            list: data9,
          },
        ],
      },
    ],
  },
  lp: {
    title: '理赔材料下载',
    navMenus: [
      {
        name: '个人与家庭',
        subMenus: [
          {
            name: '旅行险',
            id: '21',
            list: data10,
          },
          {
            name: '意外健康险',
            id: '22',
            list: data11,
          },
          {
            name: '个人财产险',
            id: '23',
            list: data12,
          },
          {
            name: '车险',
            id: '24',
            list: data13,
          },
          {
            name: '个人与团体国际医疗保险',
            id: '25',
            list: data14,
          },
        ],
      },
      {
        name: '企业与机构',
        subMenus: [
          {
            name: '商业险',
            id: '31',
            list: data15,
          },
        ],
      },
    ],
  },
};
