import { get, post, getJson, getJSData } from '../request';
import CryptUtils from '@/lib/order/crypt'
import FileUtils from '@/lib/order/file'

const ENV_MODE = process.env.REACT_APP_MODE;

// 官网域名
// test: 'https://xgw-test.jdazcn.com'
// pre: 'https://xgw-pre.jdazcn.com'
// prod: 'https://www.jdallianz.com'

const URL_PREFIX = {
	dev: '/360Api',
	test: 'https://xgw-test.jdazcn.com/c/api/360',
	pre: 'https://xgw-pre.jdazcn.com/c/api/360',
	prod: 'https://www.jdallianz.com/c/api/360',
}[ENV_MODE];

const URL_PREFIX_CLUB = {
	dev: '/clueApi',
	test: 'https://xgw-test.jdazcn.com/c/2a/club',
	pre: 'https://xgw-pre.jdazcn.com/c/2a/club',
	prod: 'https://www.jdallianz.com/c/2a/club',
}[ENV_MODE];

const URL_PREFIX_CPUB = {
	dev: '/cpubApi',
	test: 'https://xgw-test.jdazcn.com/cpub/api',
	pre: 'https://xgw-pre.jdazcn.com/c/cpub/api',
	prod: 'https://www.jdallianz.com/c/cpub/api',
}[ENV_MODE];

const NOTICE_URL = {
	dev: 'https://jdapi-pre.jdallianz.com/notice/config/getNotice',
	test: 'https://jdapi-pre.jdallianz.com/notice/config/getNotice',
	pre: 'https://jdapi-pre.jdallianz.com/notice/config/getNotice',
	prod: 'https://jdapi.jdallianz.com/notice/config/getNotice'
}[ENV_MODE];


// 获取ip
const getRealIP = () => {
	if (ENV_MODE === 'dev') {
		return '106.38.115.50';
	}
	return get({
		url: `${URL_PREFIX}/tools/client/ip`,
		showLoading: false
	});
};

// 获取楼层化商品包配置信息
const getStoreyPkg = (productId: string, areaCode?: string[]) => {
	let url = `${URL_PREFIX}/storey/check/pkg?plansPackageId=${productId}`
	if (areaCode && areaCode.length > 0) {
		url = `${url}&areaCode=${areaCode}`
	}
	return get({
		url: url,
		showLoading: false
	})
};

// 获取页面配置信息
const getPageData = (dataPath: string, callback?: string) => {
	if (dataPath.includes('.js')) {
		return getJSData({
			url: dataPath,
			callback: callback || ''
		})
	} else {
		return getJson({
			url: dataPath
		})
	}
}

interface IGetPageCommonConfigParams {
	plansPackageId: number | string // 产品id 新保时传入
	channelIdentificationCode?: string // 如果链接有jdaz_chl传入
	sourceCode?: string // 如果链接有jdaz_source传入
}

// 获取页面通用配置
const getPageCommonConfig = (params: IGetPageCommonConfigParams) => {
	return post({
		url: `${URL_PREFIX}/page/getPageCommonConfig`,
		body: params,
		showLoading: false
	})
}

// 获取系统通知
const getSystemNotice = (pageType: string, productId?: string) => {
	return post({
		url: NOTICE_URL,
		body: {
			app: 'ins_mall_h5',
			key: pageType || '',
			subKey: productId || ''
		},
		showLoading: false
	});
}

interface IPeriodParams {
	min: number;
	max: number;
	start: string;
	periodType: string;
	period: number;
	payMode: string
}

// 获取保险期限
const fetchPeriodWithPeriodType = (params: IPeriodParams, showLoading?: boolean) => {
	const {min, max, start, periodType, period, payMode} = params;
	let startDate = start || '';
	if (startDate && startDate.indexOf(':') === -1) {
		startDate = `${startDate} 00:00:00`;
	}
	return post({
		url: `${URL_PREFIX}/tools/period`,
		body: {min, max, start: startDate, periodType, period, payMode},
		showLoading
	});
};

// 算费
const getStoreyTrial = (params: any, showLoading?: boolean) => {
	return post({
		url: `${URL_PREFIX}/order/storey/trial`,
		body: params,
		showLoading
	});
}

// 下单
const orderStoreyOrder = (params: any) => {
	return post({
		url: `${URL_PREFIX}/order/storey/order/security`,
		body: params
	});
}

// 订单详情
const getStoreyOrderDetail = (ot: string) => {
	return get({
		url: `${URL_PREFIX}/order/storey/detail?mask=${true}&show=${true}&ot=${ot}&recommend=${true}`,
	});
}

// 上传图片文件
const uploadImgFile = async (localImgFile: File) => {
	const base64 = await FileUtils.compressLocalImage(localImgFile);
	let apiUrl = '';
	if (ENV_MODE === 'dev') {
		apiUrl = `https://xgw-test.jdazcn.com/c/api/360/upload`;
	} else {
		apiUrl = `${URL_PREFIX}/upload`;
	}
	return post({
		url: apiUrl,
		body: {
			data: base64,
			seq: 0
		}
	});
}

// 上传图片base64
const uploadImg = (base64: string) => {
	return post({
		url: `${URL_PREFIX}/upload`,
		body: {
			data: base64,
			seq: 0
		}
	});
}

// OCR
const uploadImgAndOcr = (ocrType: string, base64: string) => {
	return post({
		url: `${URL_PREFIX}/tools/ocr/${ocrType}`,
		body: {
			data: base64
		}
	});
}

// 获取OSS文件地址
const getOSSPath = (token: string) => {
	if (ENV_MODE === 'dev') {
		return `https://xgw-test.jdazcn.com/c/api/360/tools/file/${token}`;
	} else {
		return `${URL_PREFIX}/tools/file/${token}`;
	}
};

// 上传被保人模版
const uploadInsuredTemplate = (file: File) => {
	const formData = new FormData();
	formData.append('file', file);
	return post({
		url: `${URL_PREFIX_CLUB}/tools/insured/upload`,
		body: formData
	});
}

// 下载被保人模版
const downloadInsuredTemplate = () => {
	if (ENV_MODE === 'dev') {
		return `https://xgw-test.jdazcn.com/c/api/360/tools/download/insuredTemplate`;
	} else {
		return `${URL_PREFIX}/tools/download/insuredTemplate`;
	}
}

// 身份证信息二要素校验（实名认证）
const checkRealName = (name: string, idCard: string) => {
	return new Promise((resolve) => {
		post({
			url: `${URL_PREFIX}/tools/realName/verify`,
			body: {
				certNo: CryptUtils.encrypt(idCard),
				certNoEncrypt: '',
				certNoMd5: CryptUtils.md5(idCard),
				certType: '1',
				name: name,
				nameEncrypt: ''
			}
		})
		.then(() => {
			resolve('');
		})
		.catch((res) => {
			resolve(res?.err || '姓名与身份证号不一致');
		})
	})
}

const getMajorByParentId = (id: number) => {
	return getJson({url: `${URL_PREFIX_CPUB}/basic/v1/major/group?parentId=${id}`})
}

const getMajorByKeyword = (keyword: string) => {
	return getJson({url: `${URL_PREFIX_CPUB}/basic/v1/major/group/search?majorName=${keyword}`})
}

// 发送电子保单到指定邮箱
const sendEPolicy = (ot, email) => {
	return post({
		url: `${URL_PREFIX}/tools/epolicy/down`,
		body: {
			ot: ot,
			email: email,
			emailEncrypt: ''
		}
	});
};

const getDownloadPolicyUrl = (ot: string) => {
	if (ENV_MODE === 'dev') {
		return `https://xgw-test.jdazcn.com/c/api/360/tools/epolicy/down/direct?ot=${ot}`;
	} else {
		return `${URL_PREFIX}/tools/epolicy/down/direct?ot=${ot}`;
	}
}

// const getDownloadPolicyUrl = (ot: string) => {
// 	return get({
// 		url: `${URL_PREFIX}/tools/epolicy/down/ajax?ot=${ot}`,
// 	});
// }

export {
	getRealIP,
	getStoreyPkg,
	getPageData,
	getPageCommonConfig,
	getSystemNotice,
	fetchPeriodWithPeriodType,
	getStoreyTrial,
	orderStoreyOrder,
	getStoreyOrderDetail,
	uploadImgFile,
	// uploadImg,
	// uploadImgAndOcr,
	getOSSPath,
	uploadInsuredTemplate,
	downloadInsuredTemplate,
	checkRealName,
	getMajorByParentId,
	// getMajorByKeyword,
	sendEPolicy,
	getDownloadPolicyUrl
}
