import React, { memo } from 'react';
import './index.scss';

import classNames from 'classnames'
import icon_checkbox_normal from '@/static/imgs/order/icon_checkbox_normal.svg'
import icon_checkbox_checked from '@/static/imgs/order/icon_checkbox_checked.svg'

interface IProps {
	className?: string,
	checked: boolean,
	disabled?: boolean,
	onChange?: (value: boolean) => void
}

const InsCheckbox = (props: IProps) => {

	const {className, checked = false, disabled = false, onChange} = props;

	const handleClick = () => {
		if (!disabled && onChange) {
			onChange(!checked);
		}
	}

	return (
		<div
			className={classNames('ins-checkbox',
				{
					[`ins-checkbox--disabled`]: disabled
				}, className)}
			onClick={handleClick}
		>
			<img className='v-checkbox-img' src={checked ? icon_checkbox_checked : icon_checkbox_normal} alt={''} />
		</div>
	)
}

export default memo(InsCheckbox)
