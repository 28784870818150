import React, { memo } from 'react';
import './index.scss';

import classNames from 'classnames';
import icon_tips from '@/static/imgs/order/icon_tips.svg'

interface IProps {
	className?: string,
	text: string,
	require?: boolean,
	mode?: 'before' | 'after',
	showTips?: boolean
}

const InsInputLabel = (props: IProps) => {

	const {className, text, require, mode = 'before', showTips} = props;

	return (
		<div className={classNames('ins-input-label', className)}>
			{
				require &&
				mode === 'before' &&
				<span className='v-require-before'>*</span>
			}
			<span className='v-input-label'>{text}</span>
			{
				showTips &&
				<img className='v-img-tips' src={icon_tips} />
			}
			{
				require &&
				mode === 'after' &&
				<span className='v-require-end'>*</span>
			}
		</div>
	);
};

export default memo(InsInputLabel);
