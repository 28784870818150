import React, { useState, useEffect, memo } from 'react';
import './index.scss';
import classNames from 'classnames'
import DetailRadio from '../detail-radio'

interface RadioItem {
	className?: string,
	text?: string,
	code: string | number,
	disabled?: boolean,
	display?: string
}

interface IProps {
	className?: string,
	data: RadioItem[],
	checkedCode?: string,
	onChange: (code, index) => void,
	type?: string
}

const DetailRadioGroup = (props: IProps) => {

	const {className, data, checkedCode, onChange, type} = props

	const [currentCheckedCode, setCurrentCheckedCode] = useState(checkedCode || '')

	useEffect(() => {
		setCurrentCheckedCode(checkedCode || '')
	}, [checkedCode])

	const handleOnChange = (code, index) => {
		setTimeout(() => {
			setCurrentCheckedCode(code)
			onChange(code, index)
		}, 100)
	}

	return (
		<div className={classNames('detail-radio-group', className)}>
			{
				data.map((item, index) => {
					return (
						<DetailRadio
							key={index}
							className={item.className}
							text={item.text || item.display || `${item.code}`}
							code={`${item.code}`}
							checked={`${item.code}` === currentCheckedCode}
							disabled={item.disabled}
							onChange={() => handleOnChange(`${item.code}`, index)}
							type={type}
						/>
					)
				})
			}
		</div>
	)
}

export default memo(DetailRadioGroup)
