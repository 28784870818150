export const data1 = [
  {
    title: '法定名称',
    value: ['京东安联财产保险有限公司'],
  },
  {
    title: '缩写',
    value: ['京东安联保险/京东安联财险'],
  },
  {
    title: '注册资本',
    value: ['拾陆亿壹仟万元人民币'],
  },
  {
    title: '住所及营业场所',
    value: [
      '广东省广州市天河区珠江西路5号广州国际金融中心主塔写字楼第34层01-05、11、12单元',
    ],
  },
  {
    title: '成立时间',
    value: [
      '京东安联财产保险有限公司的前身是安联保险公司广州分公司，于2003年在广州注册成立，是安联保险集团在中国设立的首家经营财产保险的公司；2010年1月12日，安联保险公司广州分公司经原中国保险监督管理委员会批准，由外国财产保险分公司改建为具有独立法人资格的全资子公司——安联财产保险（中国）有限公司。2019年8月，经中国银保监会核准，并完成工商变更登记，公司名称变更为“京东安联财产保险有限公司”。',
    ],
  },
  {
    title: '经营范围和经营区域',
    value: [
      {
        subTitle: '经营范围',
        value: [
          '1.财产损失保险、责任保险(包括机动车交通事故责任强制保险）、信用保险、保证保险等财产保险业务；',
          '2.短期健康保险、意外伤害保险；',
          '3.上述业务的再保险业务。除机动车交通事故责任强制保险业务外，公司不得经营其它法定保险业务。',
          '与安联人寿保险有限公司在双方均被批准经营保险业务的地区开展相互代理保险业务。',
        ],
      },
      {
        subTitle: '经营区域',
        value: [
          '广东省行政辖区及已设立分公司的省、自治区和直辖市。目前为广东省（含深圳）、上海市、北京市、四川省。',
        ],
      },
    ],
  },
  {
    title: '法定代表人',
    value: ['梁海健'],
  },
];

export const data2 = [
  {
    name: '广东正和银行业保险业消费者权益保护中心',
    addr: '广东省广州市越秀区沿江西路181号星寰国际商业中心T2栋15楼02、03、04单元',
    phone: '4009-888-188',
    channel: '“广东正和消保中心”微信公众号及小程序'
  },
  {
    name: '深圳市保险消费者权益服务中心',
    addr: '深圳市福田区福华一路123号深圳中国人寿大厦16层',
    phone: '0755-83529699',
    channel: '“深圳保险调解”微信公众号'
  },
  {
    name: '四川保险业消费者权益保护中心',
    addr: '成都市锦江区梓潼桥西街31号',
    phone: '028-84112378，028-86780561',
    channel: '“四川保险消保中心”微信小程序（可受理投诉及调解）'
  },
  {
    name: '上海银行业保险业纠纷调解中心',
    addr: '上海市浦东新区民生路 1286 号汇商大厦17楼',
    phone: '021-63155943',
    channel: '“上海银行业保险业纠纷调解中心”微信公众号'
  },
  {
    name: '北京保险行业协会',
    addr: '北京市朝阳区安定路35号安华发展大厦9层906室',
    phone: '010-95001303转1',
    channel: '———'
  },
  
];

export const data3 = [
  {
    name: '京东安联财产保险有限公司上海分公司',
    addr: '上海市浦东新区银城中路501 号上海中心大厦14 层1406、1407和1408 单元',
    phone: '021-20339669',
  },
  {
    name: '京东安联财产保险有限公司北京分公司',
    addr: '北京市北京经济技术开发区科创十一街20号院3号楼11、12层',
    phone: '010-89128666',
  },
  {
    name: '京东安联财产保险有限公司北京分公司朝阳营销服务部',
    addr: '北京市朝阳区建国门外大街乙12号西塔第11层09B单元',
    phone: '010-85400981',
  },
  {
    name: '京东安联财产保险有限公司广东分公司',
    addr: '广东省广州市天河区珠江西路5号广州国际金融中心主塔写字楼第34层08-10单元',
    phone: '020-83966788',
  },
  {
    name: '京东安联财产保险有限公司四川分公司',
    addr: '四川省成都市温江区光华大道三段1588号珠江国际中心29楼2901-2907、2917单元',
    phone: '028-62347850',
  },
  {
    name: '京东安联财产保险有限公司深圳营销服务部',
    addr: '深圳市前海深港合作区南山街道梦海大道5033号前海卓越金融中心3号楼L13-01（深圳市南山区桂湾片区二单元前海卓越金融中心T201-0075（4）101三号楼L13-01）',
    phone: '0755-88286033',
  },
];

export const data4 = [
  {
    nameL: '广东衡量行保险公估有限公司',
    nameR: '汇中保险公估股份有限公司',
  },
  {
    nameL: '广州德诚保险公估有限公司',
    nameR: '贵州高澜保险公估有限公司',
  },
  {
    nameL: '广州天信保险公估有限公司',
    nameR: '广州和谦信商务咨询服务有限公司',
  },
  {
    nameL: '北京平信保险公估有限公司',
    nameR: '湖北华欣保险公估有限公司北京分公司',
  },
  {
    nameL: '深圳华天保险公估有限公司',
    nameR: '华盾保信（成都）商务服务有限公司',
  },
  {
    nameL: '深圳市众誉企业管理顾问有限公司',
    nameR: '北京汇明保险公估有限公司',
  },
  {
    nameL: '北京国信行保险公估有限公司',
    nameR: '中衡保险公估股份有限公司',
  },
  {
    nameL: '泛华保险公估股份有限公司',
    nameR: '深圳泰和君兴保险公估有限公司',
  },
  {
    nameL: '深圳市中安保险公估有限责任公司',
    nameR: '博弈中立保险公估（北京）有限公司',
  },
  {
    nameL: '北京中达信保险公估有限公司',
    nameR: '北京天恒保险公估有限公司四川分公司',
  },
  {
    nameL: '汕头市均衡保险公估有限公司',
    nameR: '深圳市用顺风险管理有限公司',
  },
  {
    nameL: '北京名利无忧风险管理顾问有限公司',
    nameR: '大童保险公估有限公司',
  },
  {
    nameL: '北京佳实德保险公估有限责任公司',
    nameR: '成都方圆新思路企业管理有限公司',
  },
  {
    nameL: '广州建泓保险公估有限公司',
    nameR: '北京中爱联众风险管理咨询有限公司',
  },
  {
    nameL: '心有灵犀保险公估（杭州）有限公司',
    nameR: '上海天衡保险公估有限公司',
  },
  {
    nameL: '清远市弘远风险保障顾问服务有限公司',
    nameR: '北京诚光信息咨询有限公司',
  },
  {
    nameL: '江苏乐凡保险公估有限公司',
    nameR: '平衡国际保险公估（北京）有限责任公司',
  },
  {
    nameL: '江西泯越信息咨询服务有限公司',
    nameR: '安徽安腾保险公估有限公司',
  },
  {
    nameL: '上海城市保险公估中心（普通合伙）',
    nameR: '天津维诚商务信息咨询服务有限公司',
  },
  {
    nameL: '釜银商务咨询（上海）有限公司',
    nameR: '清远市润辉保险顾问有限公司',
  },
  {
    nameL: '四川明嘉保险公估有限公司',
    nameR: '河北名阳保险公估有限公司',
  },
  {
    nameL: '民太安财产保险公估有限公司',
    nameR: '上海阳昌保险公估有限公司',
  },
  {
    nameL: '广州万泰保险公估有限公司',
    nameR: '上海古众保险公估有限公司',
  },
  {
    nameL: '上海查泰保险公估有限公司',
    nameR: '四川金联保险公估有限公司',
  },
  {
    nameL: '安联世合国际救援服务（北京）有限公司',
    nameR: '思奥思国际旅行援助服务（北京）有限公司',
  },
  {
    nameL: '上海风时健康管理健康咨询有限公司',
    nameR: '万欣和（上海）企业服务有限公司',
  },
  {
    nameL: '北京合理至臻科技有限公司',
    nameR: '北京启泰元康健康科技有限公司',
  },
  {
    nameL: '泛华榕数保险销售服务有限公司',
    nameR: '中民保险经纪股份有限公司',
  },
  {
    nameL: '大童保险销售服务有限公司',
    nameR: '保通保险代理有限公司',
  },
  {
    nameL: '旅云保-友太安保险经纪有限公司',
    nameR: '明亚保险经纪股份有限公司',
  },
  {
    nameL: '唯诚保险经纪有限公司',
    nameR: '上海安国保险经纪有限公司',
  },
  {
    nameL: '思派（北京）保险经纪有限公司',
    nameR: 'AON-中怡保险经纪有限责任公司',
  },
  {
    nameL: '蜗牛保险经纪有限公司',
    nameR: '',
  },
];
