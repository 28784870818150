import React, { useEffect, useState, memo } from 'react';
import './index.scss';
import { useNavigate } from 'react-router-dom';
import { Checkbox } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { IChannelFile, IDetailAgreementItem, IPackageInfo, IPageCommonConfig } from '@/lib/order/types'
import AgreementDialog from '../agreement/agreement-dialog'

import footer_protocol_prompt from '@/static/imgs/order/footer_protocol_prompt.png'
import InsButton from '@/order-components/ins-button'
import HandbookListDialog from '../agreement/handbook-list-dialog';
import FactoryAgreementListDialog from '../agreement/factory-agreement-list-dialog';
import AgreementListDialog from '../agreement/agreement-list-dialog';
import EventUtils from '@/lib/order/event'
import { INS_EVENT_PRICE } from '@/lib/order/constants'
import InsCheckbox from '@/order-components/ins-checkbox';
import { LINK_TO_BXSC} from "lib/utils";
interface IProps {
	packageInfo: IPackageInfo,
	agreementData: IDetailAgreementItem[],
	onSubmit: () => void,
	pageCommonConfig?: IPageCommonConfig
}

interface IAgreementDialog {
	show: boolean,
	data: IDetailAgreementItem | null
}

const IgnoreTypes = ['jkgz']
const NeedChangeNameGroup = ['ystk', 'xxpl', 'qiangzhiyuedu']
const AgreementTextMap = {
	'ystk': '隐私政策',
	'xxpl': '信息披露与授权',
	'qiangzhiyuedu': '重要提醒'
}

const OrderFooter = (props: IProps) => {

	const {packageInfo, agreementData, onSubmit, pageCommonConfig} = props;

	const navigate = useNavigate();

	const [stateData, setStateData] = useState({
		checked: false,
		showPrompt: false,
		showAgreementListDialog: false,
		showHandbookListDialog: false,
		showFactoryAgreementListDialog: false
	})

	const [agreementDialog, setAgreementDialog] = useState<IAgreementDialog>({
		show: false,
		data: null
	})

	const [agreementListData, setAgreementListData] = useState<IDetailAgreementItem>(null)

	const [handbookListData, setHandbookListData] = useState<IDetailAgreementItem>(null)

	const [price, setPrice] = useState({
		value: '',
		accurate: false
	});

	useEffect(() => {

		const handleEvent = (eventData) => {
			console.log('OrderFooter-INS_EVENT_PRICE', eventData);
			if (eventData?.type === 'minPrice') {
				setPrice({
					value: eventData.data,
					accurate: false
				})
			} else if (eventData?.type === 'price') {
				setPrice({
					value: `${eventData.data.salePrice.first}`,
					accurate: true
				})
			}
		};

		EventUtils.on(INS_EVENT_PRICE, handleEvent);

		return () => {
			EventUtils.off(INS_EVENT_PRICE, handleEvent);
		};
	}, []);

	useEffect(() => {
		console.log('OrderFooter-price', price);
	}, [price])

	useEffect(() => {
		if (agreementData) {
			const bxtkData = agreementData.find(item => item.type === 'bxtk')
			if (bxtkData) {
				if ((bxtkData.isProdFactConf && bxtkData.prodFactMaterial?.length > 0) || bxtkData.sub?.length > 0) {
					setAgreementListData(bxtkData)
				}
			}
			const handbookData = agreementData.find(item => item.type === 'fuwushouce')
			if (handbookData && handbookData.sub?.length > 0) {
				setHandbookListData(handbookData)
			}
		}
	}, [agreementData])

	const handleProdFactFactAgreementClick = (item: IDetailAgreementItem) => {
		// console.error('handleProdFactFactAgreementClick', item)
		if (item.type === 'bxtk') {
			setStateData({
				...stateData,
				showFactoryAgreementListDialog: true
			})
		} else {
			setAgreementDialog({
				show: true,
				data: item
			})
		}
	}

	const handleContent = (item: IDetailAgreementItem, name: string) => {
		const {content} = item
		if (content.startsWith('https://')) {
			window.open(content);
		} else {
			setAgreementDialog({
				show: true,
				data: item
			})
		}
	}

	const handleAgreementClick = (item: IDetailAgreementItem) => {
		// console.error('handleAgreementClick', item)
		const {type, name, pdf, content, sub, isProdFactConf, prodFactMaterial} = item
		if (isProdFactConf && prodFactMaterial?.length > 0) {
			handleProdFactFactAgreementClick(item)
			return
		}
		if (pdf) {
			window.open(pdf);
		} else if (content) {
			handleContent(item, name)
		} else {
			switch (type) {
				case 'bxtk':
					if (sub?.length > 0) {
						setStateData({
							...stateData,
							showAgreementListDialog: true
						})
					}
					break;
				case 'fuwushouce':
					if (sub?.length > 0) {
						setStateData({
							...stateData,
							showHandbookListDialog: true
						})
					}
					break;
				case 'xxpl':
					if (sub?.length > 0) {
						const checkedItem = sub.find(item => item.checked)
						if (checkedItem) {
							if (checkedItem.pdf) {
								window.open(checkedItem.pdf);
							} else if (checkedItem.content) {
								handleContent(checkedItem, name);
							}
						}
					}
					break;
				default:
					break;
			}
		}
	}

	const handleChlNoticeClick = (channelFile: IChannelFile) => {
		if (channelFile) {
			if (channelFile.detailAgreementItem) {
				setAgreementDialog({
					show: true,
					data: channelFile.detailAgreementItem
				})
			} else {
				window.open(channelFile.path);
			}
		}
	}

	const handleAgreementListDialogClose = () => {
		setStateData({
			...stateData,
			showAgreementListDialog: false,
			showFactoryAgreementListDialog: false
		})
	}

	const handleHandbookListDialogClose = () => {
		setStateData({
			...stateData,
			showHandbookListDialog: false
		})
	}

	const handleAgreementDialogClose = () => {
		setAgreementDialog({
			show: false,
			data: null
		})
	}

	const handleAgree = (value: boolean) => {
		console.log(`handleAgree`, value);
		setStateData({
			...stateData,
			checked: value,
			showPrompt: false
		})
	}

	const handleSubmit = () => {
		if (packageInfo?.offlineAttr) {
			if (packageInfo.offlineAttr.policyEnable) {
				checkAgreeState()
			} else {
				// ToastUtils.info('如需投保，请联系身边的销售人员')
			}
		} else {
			checkAgreeState()
		}
	}

	const checkAgreeState = () => {
		if (stateData.checked) {
			onSubmit();
		} else {
			setStateData({
				...stateData,
				showPrompt: true
			})
		}
	}

	const handleBack = () => {
		LINK_TO_BXSC()
	}

	return (
		<div className='order-footer'>

			<div className='order-footer_left'>
				<img
					className='v-prompt'
					src={footer_protocol_prompt}
					style={{display: stateData.showPrompt ? 'flex' : 'none'}}
				/>
				<InsCheckbox
					className='v-agree'
					checked={stateData.checked}
					onChange={handleAgree}
				/>
				<div className='v-agreement-wrap'>
					<span className='v-text'>我已阅读并同意</span>
					{
						agreementData &&
						agreementData.map((item, index) => {
							return (
								<React.Fragment key={`agreement-item-${index}`}>
									{
										item.checked &&
										!IgnoreTypes.includes(item.type) &&
										<span
											id={`agreement-item-${index}`}
											className='v-link'
											onClick={() => handleAgreementClick(item)}
										>
											{NeedChangeNameGroup.includes(item.type) ? AgreementTextMap[item.type] : item.name}
										</span>
									}
								</React.Fragment>
							)
						})
					}
					{
						pageCommonConfig?.channelAttrs?.channelAttrs ?
							<React.Fragment>
								{
									pageCommonConfig.channelAttrs.channelAttrs.length > 0 &&
									pageCommonConfig.channelAttrs.channelAttrs.map((item, index) => {
										return (
											<React.Fragment key={`detail-footer-agreement-notify-${index}`}>
												{
													item.display &&
													<span
														id={`detail-footer-agreement-notify-${index}`}
														className='v-link'
														onClick={() => handleChlNoticeClick(item)}
													>
														{item.name}
													</span>
												}
											</React.Fragment>
										)
									})
								}
							</React.Fragment>
							:
							<React.Fragment>
								{
									pageCommonConfig?.channelAttrs?.customNotify?.display &&
									<span
										id={`detail-footer-agreement-notify`}
										className='v-link'
										// @ts-ignore
										onClick={() => handleChlNoticeClick(pageCommonConfig.channelAttrs.customNotify)}
									>
										{pageCommonConfig?.channelAttrs?.customNotify.name}
									</span>
								}
							</React.Fragment>
					}
				</div>
			</div>

			<div className='order-footer_right'>

				{/*<span className='v-text'>合计</span>*/}
				<span className='v-amount'>{price.value || '--'}元{price.accurate ? '' : '起'}</span>

				<InsButton
					className='btn-submit'
					value='立即投保'
					onClick={handleSubmit}
				/>
				<InsButton
					className='btn-back'
					mode={'plain'}
					value='返回'
					onClick={handleBack}
				/>
			</div>

			<AgreementDialog
				show={agreementDialog.show}
				onClose={handleAgreementDialogClose}
				data={agreementDialog.data}
			/>

			{
				agreementListData &&
				<React.Fragment>
					{
						agreementListData.isProdFactConf ?
							<FactoryAgreementListDialog
								show={stateData.showFactoryAgreementListDialog}
								onClose={handleAgreementListDialogClose}
								data={agreementListData}
							/>
							:
							<AgreementListDialog
								show={stateData.showAgreementListDialog}
								onClose={handleAgreementListDialogClose}
								data={agreementListData}
							/>
					}
				</React.Fragment>
			}

			{
				handbookListData &&
				<HandbookListDialog
					show={stateData.showHandbookListDialog}
					onClose={handleHandbookListDialogClose}
					data={handbookListData}
				/>
			}


		</div>
	);
};

export default memo(OrderFooter);
