import React, { useEffect, useState } from 'react';
import './index.scss';
import bandan_r from 'static/imgs/banner20230327.png';
import bandan_bander from 'static/imgs/bandan_bander2.png';
import bandan_font from 'static/imgs/bandan_font.png';

import { useNavigate } from 'react-router-dom';
import { data1 } from './staticData';
import { previewPdf } from 'lib/utils';

const navData = [
  {
    name: '非车险服务',
  },
  {
    name: '车险服务',
  },
  {
    name: '货运险服务',
  },
];

const NewsPreview: React.FC = () => {
  const navigate = useNavigate();
  const [dataList, setDataList] = useState(data1[0].list);
  const [activeIndex, setActiveIndex] = useState(0);
  useEffect(() => {
    document.title = '京东安联保险-保险服务';
    window.scrollTo(0, 0);
  }, []);

  const goDetailPage = (item: any) => {
    if (item.url) {
      previewPdf(item.url);
    } else {
      navigate(`/query`, {
        state: {
          path: item.path,
        },
      });
    }
  };

  useEffect(() => {
    setDataList(data1[activeIndex].list);
  }, [activeIndex]);

  return (
    <div className='insure_server_wrap page-content-wrap'>
      <div className='banner_wrap'>
        <img src={bandan_bander} alt='' />
        <div className='font_wrap'>
          <img src={bandan_font} alt='图片文字' />
        </div>
      </div>

      <div className='content_wrap'>
        <div className='left'>
          <h2 className='title'>保险服务</h2>
          <div className='qa_nav_wrap'>
            {navData.map((item, index) => (
              <div
                className={activeIndex === index ? 'qa_nav active' : 'qa_nav'}
                onClick={() => setActiveIndex(index)}
                key={index}
              >
                {item.name}
              </div>
            ))}
          </div>

          <div className='news_list'>
            {dataList.map((item, index) => (
              <div
                className='news_item'
                key={index}
                onClick={() => goDetailPage(item)}
              >
                <img src={item.img} alt='图片' />
              </div>
            ))}
          </div>
        </div>
        <div className='right'>
          <img src={bandan_r} alt='图片' />
        </div>
      </div>
    </div>
  );
};
export default NewsPreview;
