import React, { useEffect, useState, memo } from 'react';
import './index.scss';

interface IProps {
	html: string
}

const RichTextAgreement = (props: IProps) => {

	const {html} = props

	const [htmlData, setHtmlData] = useState('')

	useEffect(() => {
		if (html) {
			try {
				new Promise((resolve) => {
					let result = html
					const regex = /width/
					if (regex.test(result)) {
						result = result.replace(/width/g, 'jdaz-width')
					}
					const regexFontSize = /font-size/
					if (regexFontSize.test(result)) {
						result = result.replace(/font-size/g, 'jdaz-font-size')
					}
					const regexNowrap = /nowrap/
					if (regexNowrap.test(result)) {
						result = result.replace(/nowrap/g, 'jdaz-nowrap')
					}
					resolve(result)
				})
				.then((res: string) => {
					if (res) {
						setHtmlData(res)
					}
				})
			} catch (e) {
				setHtmlData(html)
			}
		}
	}, [html])

	return (
		<React.Fragment>
			{
				htmlData &&
				<div
					className='richtext-agreement'
					style={{
						maxWidth: '100%',
						wordBreak: 'break-word'
					}}
					dangerouslySetInnerHTML={{
						__html: htmlData
					}}
				/>
			}
		</React.Fragment>
	)
};

export default memo(RichTextAgreement);
