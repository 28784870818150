import React, { memo } from 'react';
import './index.scss';

import classNames from 'classnames'

const mode = 'style4';

interface IProps {
	className?: string,
	// mode: 'style1' | 'style2' | 'style3' | 'style4' | 'style5',
	text: string,
	code: string,
	checked: boolean,
	disabled?: boolean,
	onChange?: (code) => void,
	type?: string
}

const DetailRadio = (props: IProps) => {

	const {className, text, code, checked = false, disabled = false, onChange, type} = props

	const handleClick = () => {
		if (!disabled && onChange) {
			onChange(code)
		}
	}

	return (
		<div
			id={`detail-radio-${type || mode}-${code}`}
			className={classNames('detail-radio',
				{
					[`detail-radio--active`]: checked,
					[`detail-radio--disabled`]: disabled
				}, className)}
			onClick={handleClick}
		>
			<div className='detail-radio__style4'>
				<span className='detail-radio__style4--text'>{text}</span>
			</div>
		</div>
	)
}

export default memo(DetailRadio)
