import React, { memo, useEffect, useState } from 'react';
import './index.scss';
import { ICDNInfo, IDetailAgreementItem } from '@/lib/order/types'
import DetailHtml from '@/order-components/detail-html';


interface IProps {
	data: IDetailAgreementItem
}

interface IRowItemProps {
	itemData: ICDNInfo
}

const itemHeight = 532;

const RowItem = React.memo((props: IRowItemProps) => {

		const {itemData} = props

		const [loadStatus, setLoadStatus] = useState(0)

		useEffect(() => {
			// console.log('loadStatus', loadStatus)
		}, [loadStatus])

		const handleImgLoadSuccess = () => {
			setLoadStatus(2)
		}

		const handleImgLoadError = () => {
			console.log('handleImgLoadError', itemData.cdnPath)
		}

		return (
			<div
				className='merge-img-list-item'
				style={{minHeight: `${itemHeight}px`}}
			>
				<div
					className='merge-img-list-item-wrap'
					style={{display: loadStatus !== 2 ? 'flex' : 'none'}}
				>
					<div className='merge-loading-normal' />
				</div>
				{
					itemData.cdnPath &&
					<img
						className='merge-img-list-item-img'
						src={itemData.cdnPath}
						onLoad={handleImgLoadSuccess}
						onError={handleImgLoadError}
						decoding={'async'}
						loading={'lazy'}
					/>
				}
			</div>
		)
	}
)

const DetailMergeAgreement = (props: IProps) => {

	const {data} = props

	return (
		<div className={`detail-agreement-merge ${data.content ? 'merge-html-padding' : ''}`}>
			<p className='detail-agreement-merge-name'>{data.name}</p>
			{
				data.isProdFactConf &&
				data.prodFactMaterial?.length > 0 &&
				<React.Fragment>
					{
						data.prodFactMaterial[0].imgPath?.map((item, index) => {
							return (
								<React.Fragment key={`detail-agreement-merge-item-${index}`}>
									<RowItem itemData={item} />
								</React.Fragment>
							)
						})
					}
				</React.Fragment>
			}
			{
				!data.isProdFactConf &&
				data.content &&
				<div className='detail-agreement-merge-text'>
					<DetailHtml html={data.content} />
				</div>
			}
		</div>
	)
}

export default memo(DetailMergeAgreement)
