import React, { useEffect, useState } from "react";
import "./index.scss";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { fetchNewsJson, fetchJSONWithETag } from "lib/websiteApi";
import { INewsItem } from "lib/types";
import nav_arrow_r from "static/imgs/arrow_right.svg";
import nav_tuozhuai from "static/imgs/tuozhuai.svg";
import phone3 from "static/imgs/phone4.svg";
import mail from "static/imgs/mail1.svg";
import { LINK_MODE} from "lib/utils";
const NewsPreview: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const [type, setType] = useState("news");
  const [backUrl, setBackUrl] = useState("02");
  const [newsInfo, setNewsInfo] = useState<INewsItem>({
    title: "",
    publishDate: "",
    content: "",
    id: "",
    contentUrl: "",
  });

  useEffect(() => {
    document.title = "京东安联保险-资讯详情";
    window.scrollTo(0, 0);
    if (location) {
      initFn(location);
    }
  }, [location]);

  const initFn = async (location: any) => {
    console.log(location, 88888, params);
    const { state = {} } = location;
    // /data/news/0768281A05DA9F27DF178B5C39A51263.json
    const backUlr = state && state.backUlr;
    const { type, url } = params;
    if (url) {
      // const result: any = await fetchNewsJson(`/data/news/${url}.json`);
      const result: any = await fetchJSONWithETag(`/data/news/${url}.json`);
      if (result) {
        setNewsInfo(result);
      }
    }

    if (type) {
      setType(type);
    }
    if (backUlr) {
      setBackUrl(backUlr);
    }
  };

  const goBack = () => {
    const backPath = type === "news" ? LINK_MODE + "/news-list" : LINK_MODE + "/safety/xb/" + backUrl;
    //navigate(backPath);
    window.location.href = backPath;
  };

  return (
    <div className="news_preview_wrap">
      <div className="news_nav_wrap">
        <img src={nav_tuozhuai} alt="icon" />
        <div className="news_nav_back" onClick={goBack}>
          {type === "news" ? "新闻资讯" : "安全与教育"}
        </div>
        <img src={nav_arrow_r} alt="icon" />
        <div className="news_nav_title">
          {type === "news" ? newsInfo.title : "互联网保险安全教育"}
        </div>
      </div>
      <div className="news_header">
        <h2>{newsInfo.title}</h2>
        <div className="date">
          <span>发布日期：</span>
          <span>{newsInfo.publishDate}</span>
        </div>
      </div>

      <div className="news_body">
        <div dangerouslySetInnerHTML={{ __html: newsInfo.content ?? "" }}></div>
      </div>

      {type !== "news" && (
        <div className="box2">
          <div className="box2_item">
            <img src={phone3} alt="icon" />
            <div className="header_wrap">
              <div className="top">全国热线</div>
              <div className="bottom fs_20">950610</div>
            </div>
          </div>
          <div className="divi_line"></div>
          <div className="box2_item">
            <img src={mail} alt="icon" />
            <div className="header_wrap">
              <div className="top">举报邮箱</div>
              <div className="bottom fs_14">
                <a href="mailto:Contact@allianz.cn" className="link">
                  Contact@allianz.cn
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default NewsPreview;
