import React, { useEffect, useState } from 'react';
import './index.scss';
import { Pagination } from 'antd';
import 'antd/dist/antd.css';
import { fetchFileGroupList } from 'lib/websiteApi';
import { previewPdf } from 'lib/utils';
import { IGroupItem } from 'lib/types';
const pageSize = 10;
const MajorIssues: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    getData();
  }, []);

  const [pageIndex, setPageIndex] = useState(1);
  const [total, setTotal] = useState();
  const [data, setData] = useState<IGroupItem[]>([]);

  const onChange = (page: any) => {
    setPageIndex(page);
    getData(page);
  };

  const getData = async (pageNum?: number) => {
    const params = {
      fileItem1Code: '004',
      fileItem2Code: '004',
      pageIndex: pageNum ? pageNum : pageIndex,
      pageSize: pageSize,
    };
    try {
      const result: any = await fetchFileGroupList(params);
      setData(result.list);
      setTotal(result.total);
      setPageIndex(result.pageIndex);
    } catch (e) {}
  };

  return (
    <div className='marjor-info-wrap'>
      <h2 className='title'>重大事项信息</h2>

      <div className='p_wrap'>
        {data.map((item, index) => (
          <div key={index} className='year_item_wrap'>
            <div className='year_title'>{item.groupKey}</div>
            {item.groupData.map((child) => (
              <div
                key={child.fileId}
                className='line_item'
                onClick={() => previewPdf(child.url)}
              >
                <div className='line_title'>{child.fileName}</div>
                <div className='line_date'>{child.dateLabel}</div>
              </div>
            ))}
          </div>
        ))}
      </div>
      <Pagination
        defaultCurrent={pageIndex}
        total={total}
        current={pageIndex}
        onChange={onChange}
        showSizeChanger={false}
      />
    </div>
  );
};

export default MajorIssues;
