import React, { useState, useEffect } from 'react'
import './index.scss'

const Index = () => {

	const [appBuildInfo, setAppBuildInfo] = useState({
		version: '',
		date: ''
	})

	useEffect(() => {

		// @ts-ignore
		setAppBuildInfo(jdazAppBuildInfo || {
			version: '',
			date: ''
		})

	}, [])

	return (
		<div className='test-container'>

			<p className='test-text'>{navigator.userAgent}</p>

			<p className='test-text'>{appBuildInfo.version}</p>
			<p className='test-text'>{appBuildInfo.date}</p>

		</div>
	)
}

export default Index;
