import React, { memo } from 'react';
import './index.scss';
import { IDetailFeatureItem } from '@/lib/order/types'

interface IProps {
	data: IDetailFeatureItem[]
}

const DetailContentItem = (props: IProps) => {

	const {data} = props

	const handleItemClick = (imgData: IDetailFeatureItem) => {
		const {linkUrl, pdfFile} = imgData;
		if (pdfFile) {
			window.open(pdfFile);
		} else if (linkUrl && linkUrl.startsWith('https://')) {
			window.open(linkUrl);
		}
	}

	return (
		<div className='detail-content-item'>
			{
				data &&
				data.map((item, index) => {
					return (
						<React.Fragment key={`detail-content-item-${index}`}>
							{
								item.mediaPath &&
								item.mediaType &&
								(item.mediaType.endsWith('Image') || item.mediaType.endsWith('Img')) &&
								<img
									className='detail-content-item__img'
									src={item.mediaPath}
									decoding={'async'}
									onClick={() => handleItemClick(item)}
									alt={''}
								/>
							}
						</React.Fragment>
					)
				})
			}
		</div>
	)
}

export default memo(DetailContentItem)
