import React from 'react';
import './index.scss';

const AboutJd: React.FC = () => {
  return (
    <div className='jd-wrap'>
      <h2 className='title'>京东集团</h2>
      <p className='fee_desc mr_b'>
      京东集团于2004年正式涉足电商领域。2014年5月，京东集团在美国纳斯达克证券交易所正式挂牌上市，是中国第一个成功赴美上市的综合型电商平台。2020年6月，京东集团在香港联交所二次上市，募集资金用于投资以供应链为基础的关键技术创新，以进一步提升用户体验及提高运营效率。
      </p>
      <p className='fee_desc mr_b'>
      2017年初，京东集团全面向技术转型，迄今京东体系已经投入了近1300亿元用于技术研发。
      </p>
      <p className='fee_desc mr_b'>
      京东集团定位于“以供应链为基础的技术与服务企业”，<span className='f_w'>目前业务已涉及零售、科技、物流、健康、产发、工业、自有品牌、保险和国际等领域</span>。作为同时具备实体企业基因和属性、拥有数字技术和能力的新型实体企业，京东集团依托“有责任的供应链”，持续推进“链网融合”，实现了货网、仓网、云网的“三网通”，不仅保障自身供应链稳定可靠，也带动产业链上下游合作伙伴数字化转型和降本增效，更好服务实体经济高质量发展。
      </p>
      <p className='fee_desc'>
      京东集团奉行客户为先、创新、拼搏、担当、感恩、诚信的价值观，以“技术为本，让生活更美好”为使命，坚持“成本、效率、产品、价格、服务”的核心经营理念，愿景是成为全球最值得信赖的企业。
      </p>
    </div>
  );
};

export default AboutJd;
