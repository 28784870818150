import React, { useEffect, useState, memo } from 'react';
import './index.scss';
import InsInputLabel from '@/order-components/ins-input-label'
import { DatePicker } from 'antd'
import moment from 'moment';
import 'moment/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN'
import { DatePickerProps, RangePickerProps } from 'antd/lib/date-picker'
import { IDetailPeriod, IPeriod } from '@/lib/order/types'
import useDebounceList from '@/lib/useDebounceList'
import { fetchPeriodWithPeriodType } from '@/lib/order/apis'
import { getSessionStorageItem, setSessionStorageItem } from '@/lib/storage'
import dayjs from 'dayjs';

interface IProps {
	data: IPeriod,
	startData: {
		min: number,
		max: number,
		manualReview?: {
			// 非身份证需要人核使用
			max: number,
			min: number
		}
	},
	productId: string,
	onDataChange: (data: IDetailPeriod) => void,
	updateData: string, // auto|manual
	defStartDate?: string
}

const MODE_OPTIONS = [
	{label: '自选时间', value: 'custom'},
	{label: '全年计划', value: 'fix'}
];

const PeriodTypeMap = {
	'D': 'Day',
	'M': 'Month',
	'Y': 'Year'
}

let lastUpdateData = '';
let needUpdate = false;

const OrderPeriod = (props: IProps) => {

	const {data, startData, productId, onDataChange, updateData, defStartDate} = props;

	const [customState, setCustomState] = useState({
		startCustom: false,
		endCustom: false
	})

	const [period, setPeriod] = useState({
		start: defStartDate || '',
		end: '',
		fixEnd: '',
		periodType: 'fix',
		fixPeriod: data?.fix?.val?.v || 1,
		customPeriod: data?.custom?.min?.v || 1,
		fixPeriodUnit: data?.fix?.val?.unit === 'D' ? 'Day' : 'Year',
		customPeriodUnit: data?.custom?.min?.unit === 'D' ? 'Day' : 'Year',
		slot: {
			val: {
				v: 0,
				unit: ''
			},
			display: ''
		}
	})

	const [startDateRange, setStartDateRange] = useState({
		minDate: undefined,
		maxDate: undefined
	})

	const [endDateRange, setEndDateRange] = useState({
		minDate: undefined,
		maxDate: undefined
	})

	const [targetMinMax, setTargetMinMax] = useState({
		min: startData?.min,
		max: startData?.max,
		update: false
	})

	const periodDebounce = useDebounceList([period], 300)

	useEffect(() => {
		lastUpdateData = '';
		needUpdate = false;
		const cachePeriodStr = getSessionStorageItem(`jdaz-period-${productId}`)
		if (cachePeriodStr) {
			setPeriod(JSON.parse(cachePeriodStr))
		}
	}, [])

	useEffect(() => {
		if (period.start) {
			setSessionStorageItem('StartDate', period.start)
		}
	}, [period.start])

	useEffect(() => {
		if (period.periodType && period.start) {
			if (period.periodType === 'custom' && data?.custom) {
				const {min, max} = data.custom
				const minDate = dayjs(period.start, 'YYYY-MM-DD').add(min.v - 1, min.unit.toUpperCase() === 'D' ? 'day' : 'year').format('YYYY-MM-DD')
				const maxDate = dayjs(period.start, 'YYYY-MM-DD').add(max.v - 1, max.unit.toUpperCase() === 'D' ? 'day' : 'year').format('YYYY-MM-DD')
				setEndDateRange({
					minDate: moment(minDate, 'YYYY-MM-DD'),
					maxDate: moment(maxDate, 'YYYY-MM-DD')
				})
			}
		}
	}, [period.periodType, period.start])

	useEffect(() => {
		if (data && startData && updateData) {
			console.log('DetailPeriod-updateData', updateData, lastUpdateData)
			if (updateData === lastUpdateData) return;
			lastUpdateData = updateData;
			let target = {
				min: 1,
				max: 1
			}
			if (updateData === 'manual') {
				if (!startData.manualReview) {
					target.min = startData.min
					target.max = startData.max
				} else {
					target.min = startData.manualReview.min
					target.max = startData.manualReview.max
				}
			} else {
				target.min = startData.min
				target.max = startData.max
			}
			setTargetMinMax({
				min: target.min,
				max: target.max,
				update: true
			})
		}
	}, [updateData])

	useEffect(() => {
		if (data && updateData && targetMinMax.update) {
			// console.error('targetMinMax', targetMinMax)
			needUpdate = true
			const {custom} = data
			const {min, max} = targetMinMax
			setCustomState({
				startCustom: min !== max,
				endCustom: custom?.min?.v !== custom?.max?.v
			})
			updatePeriod()
		}
	}, [targetMinMax])

	useEffect(() => {
		if (customState.startCustom || customState.endCustom) {
			const today = dayjs().format('YYYY-MM-DD')
			if (customState.startCustom) {
				const {min, max} = targetMinMax
				const minDate = dayjs(today, 'YYYY-MM-DD').add(min, 'day').format('YYYY-MM-DD')
				const maxDate = dayjs(today, 'YYYY-MM-DD').add(max, 'day').format('YYYY-MM-DD')
				setStartDateRange({
					minDate: moment(minDate, 'YYYY-MM-DD'),
					maxDate: moment(maxDate, 'YYYY-MM-DD')
				})
			}
			if (customState.endCustom && data?.custom) {
				const {min, max} = data.custom
				const minDate = dayjs(today, 'YYYY-MM-DD').add(min.v, min.unit.toUpperCase() === 'D' ? 'day' : 'year').format('YYYY-MM-DD')
				const maxDate = dayjs(today, 'YYYY-MM-DD').add(max.v, max.unit.toUpperCase() === 'D' ? 'day' : 'year').format('YYYY-MM-DD')
				setEndDateRange({
					minDate: moment(minDate, 'YYYY-MM-DD'),
					maxDate: moment(maxDate, 'YYYY-MM-DD')
				})
			}
		}
	}, [customState, targetMinMax])

	useEffect(() => {
		if (period.start) {
			setSessionStorageItem(`jdaz-period-${productId}`, JSON.stringify(period))
		}
		if (needUpdate) {
			if (period.periodType === 'fix') {
				let fixPeriodUnit = period.fixPeriodUnit;
				let fixPeriod = period.fixPeriod
				if (period.slot.display) {
					fixPeriodUnit = PeriodTypeMap[period.slot.val.unit]
					fixPeriod = period.slot.val.v
				}
				calculatePeriod(fixPeriodUnit, fixPeriod)
			} else {
				calculatePeriod(period.customPeriodUnit, period.customPeriod)
			}
		}
	}, [periodDebounce])

	useEffect(() => {
		console.log('startDateRange', startDateRange);
	}, [startDateRange])

	useEffect(() => {
		console.log('endDateRange', endDateRange);
	}, [endDateRange])

	const updatePeriod = () => {
		if (data.fix?.support) {
			let v = 1;
			let unit = 'Y';
			if (data.fix.val) {
				v = data.fix.val.v;
				unit = data.fix.val.unit;
			} else if (data.fix.periodSlotList && data.fix.periodSlotList.length > 0) {
				v = data.fix.periodSlotList[0].val?.v || 1;
				unit = data.fix.periodSlotList[0].val?.unit || 'Y';
			}
			let type = PeriodTypeMap[unit]
			setPeriod({
				...period,
				periodType: 'fix',
				fixPeriod: v,
				fixPeriodUnit: type
			})
		} else if (data.custom?.support) {
			const {min: {v, unit}} = data.custom
			let type = PeriodTypeMap[unit]
			setPeriod({
				...period,
				periodType: 'custom',
				customPeriod: v,
				customPeriodUnit: type
			})
		}
	}

	/**
	 * 计算保障期间
	 */
	const calculatePeriod = (type: string, periodCount: number) => {
		needUpdate = false
		const {min, max} = targetMinMax
		fetchPeriodWithPeriodType({
			min: min,
			max: max,
			start: period.start ? `${period.start} 00:00:00` : '',
			// periodType: periodCount > 1 ? 'Day' : type,
			periodType: type,
			period: periodCount,
			payMode: 'F'
		})
		.then((res: IDetailPeriod) => {
			const {expiry: {start, end}} = res
			const startDateStr = start.split(' ')[0]
			const endDateStr = end.split(' ')[0]
			if (period.periodType === 'custom') {
				setPeriod({
					...period,
					start: startDateStr,
					end: endDateStr
				})
			} else {
				setPeriod({
					...period,
					start: startDateStr,
					fixEnd: endDateStr
				})
			}
			onDataChange(res);
		})
	}

	const onModeChange = (value: string) => {
		console.log('onModeChange', value);
		if (value !== period.periodType) {
			needUpdate = true
			setPeriod({
				...period,
				periodType: value
			})
		}
	};

	const disabledStartDate: RangePickerProps['disabledDate'] = current => {
		if (!startDateRange.minDate || !startDateRange.maxDate) return false;
		return current.diff(startDateRange.minDate, 'days') < 0 || current.diff(startDateRange.maxDate, 'days') > 0
	};

	const disabledEndDate: RangePickerProps['disabledDate'] = current => {
		if (!endDateRange.minDate || !endDateRange.maxDate) return false;
		return current.diff(endDateRange.minDate, 'days') < 0 || current.diff(endDateRange.maxDate, 'days') > 0
	};

	const onStartDateChange: DatePickerProps['onChange'] = (date, dateString) => {
		console.log('onStartDateChange', dateString);
		const result = dateString;
		if (result !== period.start) {
			needUpdate = true
			setPeriod({
				...period,
				start: result,
				// end: '',
				// fixEnd: ''
			})
		}
	};

	const onEndDateChange: DatePickerProps['onChange'] = (date, dateString) => {
		console.log('onEndDateChange', dateString);
		const result = dateString;
		if (period.periodType === 'fix') {
			if (result !== period.fixEnd) {
				needUpdate = true
				let type = period.fixPeriodUnit
				let count
				if (type === 'Year' || result === period.start) {
					count = 1
				} else {
					count = dayjs(result).diff(dayjs(period.start), 'day') + 1
				}
				setPeriod({
					...period,
					fixEnd: result,
					fixPeriod: count
				})
			}
		} else {
			if (result !== period.end) {
				needUpdate = true
				let type = period.customPeriodUnit
				let count
				if (type === 'Year' || result === period.start) {
					count = 1
				} else {
					count = dayjs(result).diff(dayjs(period.start), 'day') + 1
				}
				setPeriod({
					...period,
					end: result,
					customPeriod: count
				})
			}
		}
	};

	return (
		<div className='order-period'>

			{
				data.fix?.support && data.custom?.support &&
				<div className='v-item-wrap'>
					<InsInputLabel text='选择方式' require={true} />
					<div className='v-period-mode-wrap'>
						{
							MODE_OPTIONS.map((item, index) => {
								return (
									<span
										key={`v-period-mode-item-${index}`}
										className={`v-period-mode-item ${item.value === period.periodType ? 'v-period-mode-item-active' : ''}`}
										onClick={() => onModeChange(item.value)}
									>
									{item.label}
								</span>
								)
							})
						}
					</div>
				</div>
			}

			<div className='v-item-wrap'>
				<InsInputLabel text='保障开始时间' require={true} />
				{
					period.start &&
					<DatePicker
						className='v-item-wrap_input'
						locale={locale}
						placeholder={'请选择保障开始日期'}
						disabled={!customState.startCustom}
						disabledDate={disabledStartDate}
						onChange={onStartDateChange}
						// defaultValue={moment(period.start, 'YYYY-MM-DD')}
						value={moment(period.start, 'YYYY-MM-DD')}
					/>
				}
			</div>

			<div className='v-item-wrap' style={{display: period.periodType === 'custom' ? 'flex' : 'none'}}>
				<InsInputLabel text='保障结束时间' require={true} />
				{
					period.end &&
					<DatePicker
						className='v-item-wrap_input'
						locale={locale}
						placeholder={'请选择保障结束日期'}
						disabled={!customState.endCustom}
						disabledDate={disabledEndDate}
						onChange={onEndDateChange}
						// defaultValue={moment(period.end, 'YYYY-MM-DD')}
						value={moment(period.end, 'YYYY-MM-DD')}
					/>
				}
			</div>

			<div className='v-item-wrap' style={{display: period.periodType === 'fix' ? 'flex' : 'none'}}>
				<InsInputLabel text='保障结束时间' require={true} />
				{
					period.fixEnd &&
					<DatePicker
						className='v-item-wrap_input'
						locale={locale}
						placeholder={'请选择保障结束日期'}
						disabled={true}
						// disabledDate={disabledEndDate}
						// onChange={onEndDateChange}
						// defaultValue={moment(period.fixEnd, 'YYYY-MM-DD')}
						value={moment(period.fixEnd, 'YYYY-MM-DD')}
					/>
				}
			</div>

			<div className='v-item-wrap'>
				<InsInputLabel text='保障期限' require={false} />
				<span className='v-item-wrap_period'>
					{period.periodType === 'custom' ? `${period.customPeriod}${period.customPeriodUnit === 'Day' ? '天' : '年'}` : `${period.fixPeriod}${period.fixPeriodUnit === 'Day' ? '天' : '年'}`}
				</span>
			</div>

		</div>
	);
};

export default memo(OrderPeriod);
