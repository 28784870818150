import React, { Suspense } from 'react';

const ErrorBoundaryFallback = React.lazy(() => import('./fallback'));

// 使用 HOC 以方便为各个页面复用这段逻辑
function ErrorBoundaryHOC(Page) {
	return class ErrorBoundary extends React.PureComponent {
		constructor(props) {
			super(props);
		}

		state = {
			error: null,
		};

		handleErrorEvent = (event) => {
			console.log('ErrorBoundary---error Listener', event);
		};

		componentWillMount() {
			window.addEventListener('error', this.handleErrorEvent);
		}

		componentWillUnmount() {
			window.removeEventListener('error', this.handleErrorEvent);
		}

		componentDidCatch(error, errorInfo) {
			console.log('ErrorBoundary---componentDidCatch', error, errorInfo);
			if (error?.stack) {
				this.setState({
					error: error.stack,
				});
			}
		}

		render() {
			return this.state.error ?
				<Suspense fallback={<div />}>
					<ErrorBoundaryFallback />
				</Suspense>
				: <Page />;
		}
	};
}

export default ErrorBoundaryHOC;
