import React, { useEffect, useState, memo } from 'react';
import './index.scss';

import { Button, Image } from 'antd';
import {
	IDetailFeatureItem,
	IDetailPlan,
	IDetailPlanItem,
	IDetailQuestionItem,
	IMedia,
	IMinPremiumItem
} from '@/lib/order/types'
import { getInitPlanIndex } from '@/lib/order/utils'
import { getSessionStorageItem, setSessionStorageItem } from '@/lib/storage'
import FeatureDialog from './feature-dialog'
import DetailHtml from '@/order-components/detail-html'
import icon_arrow_red from '@/static/imgs/order/icon_arrow_red.svg'
import icon_qrcode from '@/static/imgs/order/icon_qrcode.svg'
import InsCheckbox from '@/order-components/ins-checkbox'
import OptionalDialog from './optional-dialog'

interface IProps {
	productId: string,
	productName: string,
	imgData: IMedia[],
	planData: IDetailPlan,
	minPremiums: IMinPremiumItem[],
	detail_pic: IDetailFeatureItem[],
	process: IDetailFeatureItem[],
	caseData: IDetailFeatureItem[],
	ques: IDetailQuestionItem[],
	onPlanChange: (data: IDetailPlanItem) => void,
	onOptionalCoverageChange: (data: string[]) => void
}

let optionalCoverageIdList: string[] = [];
let currentPlanId = '';

const OrderHeader = (props: IProps) => {

	const {
		productId, productName, imgData, planData, minPremiums,
		detail_pic, process, caseData, ques, onPlanChange, onOptionalCoverageChange
	} = props;

	const [minPriceMapData, setMinPriceMapData] = useState({});
	const [currentTabIndex, setCurrentTabIndex] = useState(-1);

	const [stateData, setStateData] = useState<any>(null)
	const [planStateData, setPlanStateData] = useState<any>(null)
	const [checkedData, setCheckedData] = useState<string[]>(null)

	const [featureDialogState, setFeatureDialogState] = useState({
		show: false,
		code: ''
	});

	const [showOptionalDialog, setShowOptionalDialog] = useState(false);

	useEffect(() => {
		optionalCoverageIdList = [];
		currentPlanId = '';
		const cacheStr = getSessionStorageItem(`jdaz-coverage-${productId}`)
		if (cacheStr) {
			const cacheStateData = JSON.parse(cacheStr)
			setStateData(cacheStateData)
		}
	}, []);

	useEffect(() => {
		if (planData?.planDetail?.length > 0) {
			const initIndex = getInitPlanIndex(productId, planData.planDetail)
			setCurrentTabIndex(initIndex)
		}
	}, [planData])

	useEffect(() => {
		if (minPremiums?.length > 0) {
			let mapData = {};
			minPremiums.forEach((item) => {
				mapData[item.groupId] = item.minPremium;
			})
			console.log('minPriceMapData', mapData);
			setMinPriceMapData(mapData);
		}
	}, [minPremiums])

	useEffect(() => {
		if (currentTabIndex > -1) {
			currentPlanId = planData.planDetail[currentTabIndex].groupId;
			requestAnimationFrame(() => {
				setSessionStorageItem(`jdaz-plan-index-${productId}`, `${currentTabIndex}`);
			})
			if (stateData) {
				setPlanStateData(stateData[`${currentPlanId}`] || {})
			}
			onPlanChange(planData.planDetail[currentTabIndex]);
		}
	}, [currentTabIndex])

	useEffect(() => {
		console.log('stateData', stateData)
		if (stateData && currentPlanId) {
			setPlanStateData(stateData[`${currentPlanId}`] || {})
			requestAnimationFrame(() => {
				setSessionStorageItem(`jdaz-coverage-${productId}`, JSON.stringify(stateData));
			})
		}
	}, [stateData])

	useEffect(() => {
		console.log('planStateData', planStateData)
		if (planStateData) {
			const values = Object.values(planStateData);
			if (values) {
				let result = [];
				optionalCoverageIdList = [];
				values.map((item: any) => {
					if (item.state) {
						result.push(...item.data);
						if (item.id) {
							optionalCoverageIdList.push(item.id);
						}
					}
				})
				console.log('checkedData', result);
				console.log('optionalCoverageIdList', optionalCoverageIdList);
				setCheckedData(result);
				onOptionalCoverageChange(result);
			}
		}
	}, [planStateData])

	const handlePlanChange = (index) => {
		setCurrentTabIndex(index);
	}

	const handleOptionalItemClick = (codes: string[], itemIndex: number, id: string) => {
		// const codeStr = codes.join('-');
		console.log('handleOptionalItemClick', codes, id);
		let obj = planStateData || {};
		const flag = obj[`${currentPlanId}-${itemIndex}`]?.state
		// && checkResultData[id]
		// if (!flag) return
		setStateData({
			...stateData,
			[`${currentPlanId}`]: {
				...obj,
				[`${currentPlanId}-${itemIndex}`]: {
					state: !flag,
					data: codes,
					id: id || ''
				}
			}
		})
	}

	const handleOpenFeatureDialog = (code: string) => {
		setFeatureDialogState({
			code: code,
			show: true
		})
	}

	const handleFeatureDialogClose = () => {
		setFeatureDialogState({
			show: false,
			code: ''
		})
	}

	const handleOpenOptionalDialog = () => {
		setShowOptionalDialog(true);
	}

	const handleOptionalDialogClose = () => {
		setShowOptionalDialog(false);
	}

	return (
		<div className='order-header'>

			<div className='order-header_left'>
				<Image
					className='v-img-banner'
					src={imgData[0].mediaPath}
					preview={false}
				/>
				<div className='v-feature'>
					{
						detail_pic?.length > 0 &&
						<React.Fragment>
							<Button className='v-btn' type='link' onClick={() => handleOpenFeatureDialog('feature')}>产品介绍</Button>
						</React.Fragment>
					}
					{
						(process?.length > 0 || caseData?.length > 0) &&
						<React.Fragment>
							<div className='v-gap' />
							<Button className='v-btn' type='link' onClick={() => handleOpenFeatureDialog('claim')}>如何理赔</Button>
						</React.Fragment>
					}
					{
						ques?.length > 0 &&
						<React.Fragment>
							<div className='v-gap' />
							<Button className='v-btn' type='link' onClick={() => handleOpenFeatureDialog('question')}>常见问题</Button>
						</React.Fragment>
					}
				</div>
			</div>

			<div className='order-header_right'>

				<div className='v-title-wrap'>
					<span className='v-name'>{productName}</span>
					<div className='v-qrcode' style={{display: 'none'}}>
						<img className='v-img-qrcode' src={icon_qrcode} alt={''} />
						<span className='v-text-qrcode'>扫码查看</span>
					</div>
				</div>

				{
					planData.predescription &&
					planData.predescription.is_show &&
					planData.predescription.desc &&
					<div className='v-desc'>
						<DetailHtml html={planData.predescription.desc} />
					</div>
				}

				{
					planData.basic &&
					planData.basic.length > 0 &&
					<div className='v-tips-wrap'>
						<p className='v-text-prompt'>购买须知</p>
						<div className='v-tips-row'>
							{
								planData.basic.map((item, index) => {
									return (
										<div key={`plan-basic-item-${index}`} className='v-tips-row-item'>
											<span>{item.title}：</span>
											<DetailHtml html={item.desc} />
										</div>
									)
								})
							}
						</div>
					</div>
				}

				<div className='v-plan-header'>
					<span className='v-plan-header_left'>保障计划</span>
					<div className='v-plan-header_right' onClick={() => handleOpenFeatureDialog('plan')}>
						<span>查看计划保障详情与对比</span>
						<img className='v-img-detail' src={icon_arrow_red} alt={''} />
					</div>
				</div>

				<div className='v-list'>

					{
						planData.planDetail.map((item, index) => {
							return (
								<div
									key={`plan-item-${index}`}
									className={`v-plan-item ${index === currentTabIndex ? 'v-plan-item-active' : ''}`}
									onClick={() => handlePlanChange(index)}
								>
									<span className='v-strong'>{item.groupName}</span>
									<span>{minPriceMapData[item.groupId]}元起</span>
								</div>
							)
						})
					}

				</div>

				<div className='v-plan-wrap'>
					<span className='v-text-prompt'>责任条款</span>
					{/*<div className='v-plan-detail-divider' />*/}
					<div className='v-plan-detail'>
						{
							currentTabIndex > -1 &&
							planData.planDetail[currentTabIndex].grouping.map((item, index) => {
								return (
									<React.Fragment key={`plan-grouping-item-${index}`}>
										<div className='v-plan-detail-group-item-wrap'>
											<div className='v-plan-detail-group-item'>
												<div className='v-plan-detail-group-item_left'>
													<DetailHtml html={item.name} />
												</div>
												<DetailHtml className='v-plan-detail-group-item_right' html={item.shared_amount || ''} />
											</div>
										</div>
										{
											item.list.map((subItem, subItemIndex) => {
												return (
													<div key={`plan-grouping-subItem-${subItemIndex}`} className='v-plan-detail-item'>
														<div className='v-plan-detail-item_left'>
															<DetailHtml html={subItem.name} />
															{/*{*/}
															{/*subItem.abstract &&*/}
															{/*<DetailHtml html={subItem.abstract} />*/}
															{/*}*/}
														</div>
														<DetailHtml className='v-plan-detail-item_right' html={subItem.money} />
													</div>
												)
											})
										}
										<div className='v-plan-detail-group-item-divider' />
									</React.Fragment>
								)
							})
						}
					</div>
				</div>

				{
					currentTabIndex > -1 &&
					planData.planDetail[currentTabIndex].optional?.length > 0 &&
					<React.Fragment>
						<div className='v-plan-header v-optional-wrap'>
							<span className='v-text-prompt'>可选保障</span>
							<div className='v-plan-header_right' onClick={handleOpenOptionalDialog}>
								<span>查看可选保障详情与对比</span>
								<img className='v-img-detail' src={icon_arrow_red} alt={''} />
							</div>
						</div>
						<div className='v-list'>
							{
								planData.planDetail[currentTabIndex].optional.map((item, index) => {
									return (
										<div
											key={`plan-optional-item-${index}`}
											className={`v-optional-item ${planStateData && planStateData[`${currentPlanId}-${index}`]?.state ? 'v-optional-item-active' : ''}`}
											onClick={() => handleOptionalItemClick(item.code, index, item.identify)}
										>
											<InsCheckbox
												className='v-optional-checkbox'
												checked={planStateData && planStateData[`${currentPlanId}-${index}`]?.state}
											/>
											<DetailHtml className='' html={item.name} />
											<span className=''>{item.money}</span>
										</div>
									)
								})
							}
						</div>
					</React.Fragment>
				}

			</div>

			{
				planData &&
				<FeatureDialog
					show={featureDialogState.show}
					onClose={handleFeatureDialogClose}
					current={featureDialogState.code}
					featureData={{
						detail_pic: detail_pic,
						process: process,
						caseData: caseData,
						ques: ques,
						planDetail: planData.planDetail
					}}
				/>
			}

			{
				planData.planDetail &&
				<OptionalDialog
					show={showOptionalDialog}
					onClose={handleOptionalDialogClose}
					data={planData.planDetail}
				/>
			}

		</div>
	);
};

export default memo(OrderHeader);
