import React, { useEffect } from 'react';
import './index.scss';
import { Routes, Route } from 'react-router-dom';
import Nav from '../public-info/components/nav';
import Feedback from './Feedback/index';
import QuestionPage from './QuestionPage/index';
import DissentForm from './DissentForm/index';

import Complaint from './Complaint/index';

let navMenus = [
  {
    name: '常见问题',
    id: 'qa',
  },
  {
    name: '意见与反馈',
    id: 'feedback',
    matchIds: ['feedback', 'dissentForm'],
  },
  {
    name: '投诉处理流程',
    id: 'complaint',
  },
];

const Suggestion: React.FC = () => {
  useEffect(() => {
    document.title = '京东安联保险-安全与教育';
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='public-info-wrap'>
      <div className='left'>
        <Nav data={navMenus} />
      </div>
      <div className='right'>
        <Routes>
          <Route path='/qa' element={<QuestionPage />} />
          <Route path='/feedback' element={<Feedback />} />
          <Route path='/complaint' element={<Complaint />} />
          <Route path='/dissentForm' element={<DissentForm />} />
        </Routes>
      </div>
    </div>
  );
};

export default Suggestion;
