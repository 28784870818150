import React, { useEffect, useState, memo } from 'react';
import './index.scss';
import { ICDNInfo } from '@/lib/order/types'

interface IProps {
	data: ICDNInfo,
	index: number,
	scale: number
}

const itemHeight = 1243;

const ImgAgreementItem = (props: IProps) => {

	const {data, index, scale} = props;

	const [loadStatus, setLoadStatus] = useState(0)

	useEffect(() => {

	}, []);

	const handleImgLoadSuccess = () => {
		setLoadStatus(2)
	}

	const handleImgLoadError = () => {
		console.log('handleImgLoadError', data.cdnPath)
	}

	return (
		<div className='img-agreement-item' style={{minHeight: `${itemHeight}px`}}>
			<div
				className='img-agreement-item-wrap'
				style={{display: loadStatus !== 2 ? 'flex' : 'none'}}
			>
				<div className={`loading-normal ${scale > 1 ? 'loading-scale' : ''}`} />
			</div>
			{
				data.cdnPath &&
				<img
					className='img-agreement-item-img'
					src={data.cdnPath}
					onLoad={handleImgLoadSuccess}
					onError={handleImgLoadError}
					decoding={'async'}
					loading={index < 3 ? 'eager' : 'lazy'}
				/>
			}
		</div>
	);
};

export default memo(ImgAgreementItem);
