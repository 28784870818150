import React, { memo, useEffect, useState } from 'react';
import { getGlobalData } from '@/lib/global'
import { IDetailAgreementItem, IPageCommonConfig } from '@/lib/order/types'
import AgreementStepConfirmDialog from '../agreement-step-confirm-dialog';
import AgreementMergeDialog from '../agreement-merge-dialog';

interface IProps {
	show: boolean,
	onClose: () => void,
	onConfirmCallback: () => void,
	data: IDetailAgreementItem[]
}

const NeedMergeTypeGroup = ['zrmc', 'pfsm'];

const StepMap = {
	'tbxz': 1,
	'zrmc': 2,
	'merge': 2
}

const baseForceReadTypeGroup = ['tbxz', 'zrmc', 'pfsm'];

const AgreementConfirmDialog = (props: IProps) => {

	const {show, onClose, onConfirmCallback, data} = props

	const [mergeData, setMergeData] = useState<IDetailAgreementItem[]>(null)

	const [dialogState, setDialogState] = useState({
		'tbxz': false,
		'zrmc': false,
		'merge': false
	})

	const [forceReadData, setForceReadData] = useState<IDetailAgreementItem[]>(null);

	const [total, setTotal] = useState(0);

	useEffect(() => {
		if (data) {
			console.log('AgreementConfirmDialog-total', total);
			const findData = data.find((item) => item.type === 'pfsm')
			if (findData) {
				const findMergeData = data.filter((item) => NeedMergeTypeGroup.includes(item.type))
				console.log('findMergeData', findMergeData)
				setMergeData(findMergeData)
			} else {
				// @ts-ignore
				setMergeData(null);
			}
			let forceReadList = data.filter(item => !baseForceReadTypeGroup.includes(item.type));

			const dataLen = data.length;

			// 判断渠道文件是否需要强制阅读
			const pageCommonConfig: IPageCommonConfig = getGlobalData('pageCommonConfig');
			if (pageCommonConfig?.channelAttrs) {
				const {channelAttrs, customNotify} = pageCommonConfig?.channelAttrs;
				if (channelAttrs?.length > 0) {
					let count = 0;
					channelAttrs.forEach((item) => {
						if (item?.display && item?.isForcedReading && item?.detailAgreementItem) {
							forceReadList.push(item.detailAgreementItem)
							count = count + 1;
						}
					})
					setTotal(dataLen + count);
				} else if (customNotify?.display && customNotify?.isForcedReading && customNotify?.detailAgreementItem) {
					forceReadList.push(customNotify.detailAgreementItem)
					setTotal(dataLen + 1);
				} else {
					setTotal(dataLen);
				}
			} else {
				setTotal(dataLen);
			}

			console.log('forceReadList', forceReadList);
			setForceReadData(forceReadList);

			let stateData: any = {};
			forceReadList.forEach((item) => {
				stateData[item.type] = false;
			})
			setDialogState({
				...dialogState,
				...stateData
			});
		}
	}, [data])

	useEffect(() => {
		if (show) {
			setDialogState({
				...dialogState,
				'tbxz': true
			});
		}
	}, [show])

	useEffect(() => {
		console.log('dialogState', dialogState)
	}, [dialogState])

	const handleStepConfirmDialogClose = (type: string) => {
		onClose()
		setDialogState({
			...dialogState,
			[type]: false
		})
	}

	const handleStepConfirmDialogConfirm = (type: string, step: number, index: number) => {
		// const step = StepMap[type]
		if (step === (mergeData ? total - 1 : total)) {
			onConfirmCallback()
		} else {
			let state = {
				...dialogState,
				[type]: false
			}
			setDialogState(state)
			let nextType = ''
			switch (type) {
				case 'tbxz':
					nextType = mergeData ? 'merge' : 'zrmc'
					break;
				case 'zrmc':
					// nextType = 'bbxrtysm'
					nextType = forceReadData[0].type
					break;
				default:
					nextType = forceReadData[index + 1].type
					break
			}
			if (nextType) {
				setTimeout(() => {
					setDialogState({
						...state,
						[nextType]: true
					})
				}, 400)
			}
		}
	}

	const handleMergeDialogClose = () => {
		onClose()
		setDialogState({
			...dialogState,
			'merge': false
		})
	}

	const handleMergeDialogConfirm = () => {
		const step = StepMap['merge']
		if (step === total - 1) {
			onConfirmCallback()
		} else {
			let state = {
				...dialogState,
				'merge': false
			}
			setDialogState(state)
			setTimeout(() => {
				const nextType = forceReadData[0].type;
				setDialogState({
					...state,
					[nextType]: true
				})
			}, 400)
		}
	}

	return (
		<React.Fragment>
			{
				total > 0 &&
				<React.Fragment>
					{
						<AgreementStepConfirmDialog
							show={dialogState['tbxz']}
							onClose={handleStepConfirmDialogClose}
							data={data.find((item) => item.type === 'tbxz')}
							current={StepMap['tbxz']}
							total={mergeData ? total - 1 : total}
							onConfirmCallback={handleStepConfirmDialogConfirm}
						/>
					}
					{
						<AgreementStepConfirmDialog
							show={dialogState['zrmc']}
							onClose={handleStepConfirmDialogClose}
							data={data.find((item) => item.type === 'zrmc')}
							current={StepMap['zrmc']}
							total={mergeData ? total - 1 : total}
							onConfirmCallback={handleStepConfirmDialogConfirm}
						/>
					}
					{
						mergeData &&
						<AgreementMergeDialog
							show={dialogState['merge']}
							onClose={handleMergeDialogClose}
							data={mergeData}
							current={StepMap['merge']}
							total={mergeData ? total - 1 : total}
							onConfirmCallback={handleMergeDialogConfirm}
						/>
					}
					{
						forceReadData?.length > 0 &&
						forceReadData.map((item, index) => {
							return (
								<React.Fragment key={`AgreementStepConfirmDialog-${index}`}>
									<AgreementStepConfirmDialog
										show={dialogState[item.type]}
										onClose={handleStepConfirmDialogClose}
										data={item}
										current={index + 3}
										total={mergeData ? total - 1 : total}
										onConfirmCallback={handleStepConfirmDialogConfirm}
										index={index}
									/>
								</React.Fragment>
							)
						})
					}
				</React.Fragment>
			}
		</React.Fragment>
	)
}

export default memo(AgreementConfirmDialog)
