import { get, post } from "./request";

// const PATH_API = '/website/website/';
// const PATH_API = {
//   dev: '//api-work-test.jdazcn.com/website/website',
//   test: '//api-work-test.jdazcn.com/website/website',
//   pre: '//api-work-pre.jdazcn.com/website/website',
//   prod: '//www.jdallianz.com/website/website',
//   //@ts-ignore
// }[process.env.REACT_APP_MODE];

const PATH_API = {
  dev: "//xgw-pre.jdazcn.com/websiteApi",
  test: "//xgw-test.jdazcn.com/websiteApi",
  pre: "//xgw-pre.jdazcn.com/websiteApi",
  prod: "//www.jdallianz.com/websiteApi",
  //@ts-ignore
}[process.env.REACT_APP_MODE];

export const fetchSmsCode = (params: any) => {
  return get({
    url: `${PATH_API}/message/send`,
    params: params,
  });
};

// 异议登记
export const fetchDissentSubmit = (params: any) => {
  return post({
    url: `${PATH_API}/objectionRegist/regist`,
    body: params,
  });
};
