import React, { useEffect, useState } from 'react';
import './index.scss';
import cardProjection from '../../img/cardProjection.png';
import { ILink } from 'lib/types';
import { createCodeUtil } from 'lib/utils';
import { useLocation } from 'react-router-dom';
import { IMGURL } from 'lib/config';
import { fetchCampaignList } from 'lib/websiteApi';
import Item from 'antd/lib/list/Item';
type DaTAProps = {
  data: any;
};
interface IDataItem {
  backgroundOssUrl?: string;
  barcodeOssUrl?: string;
  campaignContent?: string;
  campaignOssUrl?: string;
  campaignType?: string;
  jdazSite?: string;
  orderNum: string;
}

const RotatePic: React.FC<DaTAProps> = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(-1);
  const [data1, setData1] = useState<IDataItem[]>([]);
  let { search } = useLocation();
  useEffect(() => {
    //   data.map((item: any, index: number) => {
    //     return createCode(item.link, index);
    //   });
    getData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const createCode = (linkInfo: ILink, index: number) => {
    createCodeUtil(linkInfo, search, `canvas_${index}`, 120);
  };

  useEffect(() => {
    if (data1 && data1.length > 0) {
      data1.forEach((item, index) => {
        if (item.campaignType === '01' && item.campaignContent) {
          createCode(
            { type: 'product', link: item.campaignContent, linkParam: '' },
            index
          );
        }
      });
    }
  }, [data1]);

  const getData = async () => {
    // @ts-ignore
    const result: any = await fetchCampaignList();
    setData1(result);
  };

  const showCode = (link: IDataItem, index: number) => {
    setActiveIndex(index);
  };

  const goOutLink = (link: IDataItem) => {
    if (link.campaignType === '02') {
      window.location.href = link.campaignContent
    }
  };

  const removeCode = () => {
    setActiveIndex(-1);
  };
  useEffect(() => {});
  return (
    <div className='rodate_all' key='rodate_all'>
      <div className='rodate' key='rodate'>
        {data1.map((item: any, index: any) => (
          <div
            className='rodate_box hander'
            key={index}
            onMouseEnter={() => showCode(item, index)}
            onMouseLeave={removeCode}
            onClick={() => goOutLink(item)}
          >
            <div className='front' key={index}>
              <img src={`${item.campaignOssUrl}`} alt='' />
            </div>

            {item.campaignType == '01' && (
              <div
                className={index === activeIndex ? 'qrcode actived' : 'qrcode'}
                key='qrcode'
              >
                <img src={`${item.backgroundOssUrl}`} alt='' />
                {<canvas id={`canvas_${index}`} className='code'></canvas>}
              </div>
            )}

            {item.campaignType == '03' && (
              <div
                className={index === activeIndex ? 'qrcode actived' : 'qrcode'}
                key='qrcode'
              >
                <img src={`${item.barcodeOssUrl}`} alt='' />
              </div>
            )}
          </div>
        ))}
      </div>
      <div className='cardProjection'>
        <img src={cardProjection} alt='' />
      </div>
    </div>
  );
};

export default RotatePic;
