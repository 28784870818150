import React, { memo } from 'react';
import './index.scss';
import classNames from 'classnames'
import { ISelectOptionItem } from '@/lib/order/types'
import InsRadio from '@/order-components/ins-radio'

interface IProps {
	className?: string,
	options: ISelectOptionItem[]
	value: string,
	onChange?: (data: ISelectOptionItem) => void,
	disabled?: boolean
}

const InsRadioGroup = (props: IProps) => {

	const {className, options, value, onChange, disabled} = props

	const handleRadioChange = (data) => {
		onChange && onChange(data);
	}

	return (
		<div className={classNames('ins-radio-group', className)}>
			{
				options?.length > 0 &&
				options.map((item, index) => {
					return (
						<InsRadio
							key={`ins-radio-${index}`}
							data={item}
							checked={value === item.value}
							onChange={handleRadioChange}
							disabled={disabled}
						/>
					)
				})
			}
		</div>
	)
}

export default memo(InsRadioGroup)
