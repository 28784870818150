import React, { useEffect, useState } from "react";
import "./index.scss";
import { biddingNoticeQuery, queryById } from "lib/websiteApi";
import { Table, Pagination } from "antd";
import newsImg from "../../common/img/newsImg.png";
import { useNavigate } from "react-router-dom";
const InternetProdList: React.FC = () => {
  const [list, setList] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const [total, setTotal] = useState(1);
  const navigate = useNavigate();

  const columns = [
    {
      title: "列表名称",
      dataIndex: "noticeName",
    },
    {
      title: "发布时间",
      dataIndex: "createTime",
    },
  ];

  const onChange = (page: number, pageSize: any) => {
    setPageIndex(page);
    setPageSize(pageSize);
    getList(page, pageSize);
  };

  //获取列表数据
  const getList = async (pageIndex: any, pageSize: any) => {
    const res: any = await biddingNoticeQuery({
      pageIndex: pageIndex,
      pageSize: pageSize,
    });
    setList(res.list);
    setTotal(res.total);
    setDataLoading(false);
  };

  //点击行回调
  const clickCellFn = async (record: any) => {
    // const res: any = await queryById({ noticeId: record.noticeId });
    // console.log(record, res);
    // //删除本地存储
    // var getVal = localStorage.getItem("tableCell");
    // if (getVal != null) {
    //   localStorage.removeItem("tableCell");
    // } else {
    //   console.log("未找到tableCell");
    // }

    // //存储数据到本地
    // localStorage.setItem("tableCell", JSON.stringify(res));
    navigate(`/bit-document/detail?noticeId=${record.noticeId}`);
  };

  useEffect(() => {
    getList(1, 10);
  }, []);

  return (
    <div>
      <div className="header-img">
        <img src={newsImg} alt="" />
      </div>
      <div className="inter-pro-wrap-bit">
        <div className="bit-document">
          <div className="bit-document-card">
            <h2 className="page-title">招标公告</h2>
            <Table
              showHeader={false}
              columns={columns}
              dataSource={list}
              onRow={(record) => {
                return {
                  onClick: (event) => {
                    clickCellFn(record);
                  },
                };
              }}
              rowKey="id"
              size="middle"
              pagination={false}
              loading={dataLoading}
              locale={{ emptyText: "暂无相关信息" }}
            />

            <Pagination
              defaultCurrent={pageIndex}
              total={total}
              current={pageIndex}
              onChange={onChange}
              // showSizeChanger
              // showQuickJumper
              // showTotal={(total, range) => `共${total}条`}
              // pageSize={pageSize}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InternetProdList;
