const FileUtils = {

	/**
	 * 压缩本地图片，返回压缩后的图片base64,大于500K才压缩
	 * @param localImgFile 本地图片对象
	 */
	compressLocalImage(localImgFile: File) {
		return new Promise((resolve) => {
			try {
				const fileReader = new FileReader();
				fileReader.onload = () => {
					// @ts-ignore
					const oriBase64: string = fileReader.result
					if (oriBase64) {
						console.log('oriBase64.length', `${Math.ceil(oriBase64.length / 1024)}k`)
						if (oriBase64?.length > 512000) {
							let image = new Image();
							image.crossOrigin = 'Anonymous';
							image.onload = () => {
								let canvas = document.createElement('canvas');
								// let width = window.screen.width * window.devicePixelRatio;
								// let height = window.screen.height * window.devicePixelRatio;
								const devicePixelRatio = 2;
								canvas.width = image.width / devicePixelRatio;
								canvas.height = image.height / devicePixelRatio;
								// if (image.width > width && image.height > height) {
								// 	canvas.width = image.width / devicePixelRatio;
								// 	canvas.height = image.height / devicePixelRatio;
								// } else {
								// 	canvas.width = image.width;
								// 	canvas.height = image.height;
								// }
								console.log('canvas.width&height', {
									'canvas.width': canvas.width,
									'canvas.height': canvas.height
								});
								let ctx = canvas.getContext('2d');
								// @ts-ignore
								ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
								let result = canvas.toDataURL(localImgFile.type, 0.4);//压缩质量，image/jpeg 有效
								console.log('base64.length', `${Math.ceil(result.length / 1024)}k`);
								resolve(result);
							};
							image.src = oriBase64;
						} else {
							resolve(oriBase64);
						}
					} else {
						resolve('');
					}
				};
				fileReader.onerror = (e) => {
					console.error('fileReader-onerror', e)
					resolve('');
				};
				fileReader.readAsDataURL(localImgFile);
			} catch (e) {
				console.error('compressLocalImage-catch', e)
				resolve('');
			}
		})
	}

};

export default FileUtils;
