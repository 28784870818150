import React, { useState } from 'react';
import './index.scss';
import { useNavigate, useLocation } from 'react-router-dom';
import { ReactComponent as Select_arrow_up } from 'static/imgs/Select_arrow_up.svg';
import Icon from '@ant-design/icons';
interface INavItem {
  name: string;
  id: string;
  isOpen?: boolean;
  subMenu?: {
    name: string;
    id: string;
  }[];
}

interface IProps {
  data: INavItem[];
}

const Nav = (props: IProps) => {
  const { data } = props;
  const location = useLocation();
  const navigate = useNavigate();

  const [menus, setMenus] = useState(data);

  const { pathname } = location;

  const [, , path1, path2] = pathname.split('/');

  const goNav = (routePath: any, index: number) => {
    if (routePath.subMenu) {
      let copyMenu = menus.concat();
      copyMenu[index].isOpen = !menus[index].isOpen;
      setMenus(copyMenu);
    } else {
      navigate(routePath.id);
    }
  };

  const isMath = (path: string, activeMenu: any) => {
    if (activeMenu.matchIds && Array.isArray(activeMenu.matchIds)) {
      if (activeMenu.matchIds.includes(path)) {
        return true;
      } else {
        return false;
      }
    } else if (path === activeMenu.id) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <div className='nav_wrap'>
      {menus.map((menuItem, index) => (
        <div className='nav_item' key={menuItem.id}>
          <div
            className={
              isMath(path1, menuItem)
                ? menuItem.subMenu
                  ? 'nav_item_inner p_active'
                  : 'nav_item_inner active'
                : 'nav_item_inner'
            }
            onClick={() => goNav(menuItem, index)}
          >
            <div className='inner_menu'>
              <p className='nav_item_p'>{menuItem.name}</p>
              {menuItem.subMenu && (
                <Icon
                  component={Select_arrow_up}
                  className={menuItem.isOpen ? 'img open' : 'img'}
                />
              )}
            </div>
          </div>
          <div className='dia_line'></div>

          {menuItem.subMenu && menuItem.isOpen && (
            <div className='sub_item_wrap'>
              {menuItem.subMenu.map((subMenu) => (
                <div
                  className={
                    path2 === subMenu.id ? 'sub_item active' : 'sub_item'
                  }
                  key={subMenu.id}
                  onClick={() => navigate(menuItem.id + '/' + subMenu.id)}
                >
                  <span>{subMenu.name}</span>
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default Nav;
