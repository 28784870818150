import React, { useEffect, useState } from "react";
import "./index.scss";
import { intermediaryAgenciesQuery } from "lib/websiteApi";

const InternetProdList: React.FC = () => {
  const [list, setList] = useState([]);

  useEffect(() => {
    const asyncFn = async () => {
      const res: any = await intermediaryAgenciesQuery({});
      setList(res);
    };
    asyncFn();
  }, []);

  return (
    <div className="inter-pro-wrap">
      <div className="open-day-container">
        <div className="page-title">合作保险中介机构清单</div>
        <table>
          <thead>
            <tr>
              <th style={{ width: "170px" }}>合作中介机构全称</th>
              <th style={{ width: "266px" }}>中介机构网络平台网站地址</th>
              <th style={{ width: "140px" }}>合作起始时间</th>
              <th style={{ width: "140px" }}>合作截止时间</th>
              <th style={{ width: "140px" }}>业务合作范围</th>
            </tr>
          </thead>
          <tbody>
            {list.map((item, index) => (
              <tr key={index}>
                <td>{item.intermName}</td>
                <td>{item.intermWebAddr}</td>
                <td>{item.startTime}</td>
                <td>{item.endTime}</td>
                <td>{item.cooperationRange}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default InternetProdList;
