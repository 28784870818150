import React, { useState, useEffect } from "react";
import "./index.scss";
import { IMGURL } from "lib/config";
import QueueAnim from "rc-queue-anim";
import { OverPack } from "rc-scroll-anim";
import womanIMG from "../../img/xuqiudaoxiang.png";
import manIMG from "../../img/zhuanyedingzhi.png";
import baoguMan from "../../img/baoguMan.png";
import baoguWoman from "../../img/baoguWoman.png";
import LazyImg from "common/components/LazyImg";
import { randomInsConsultantInfo } from "lib/Apis";

interface IImgsInfo {
  mainImg: string;
  subImg: string;
  xy: {
    x: number;
    y: number;
  };
}
interface IProps {
  jobNumber: any;
}
const Consult = (props: IProps) => {
  const { jobNumber } = props;
  const [consult, setConsult] = useState(false);
  const [jobNumberFemale, setJobNumberFemale] = useState("");
  const [jobNumberMale, setJobNumberMale] = useState("");

  useEffect(() => {
    setTimeout(() => {
      setConsult(true);
    }, 500);

    //获取二维码
    async function fetchData() {

      if(jobNumber.jobNumberMale&&jobNumber.jobNumberMale){
        const jobNumberFemale: any = await randomInsConsultantInfo({
          jobNumber: jobNumber.jobNumberFemale,
        });
        const jobNumberMale: any = await randomInsConsultantInfo({
          jobNumber: jobNumber.jobNumberMale,
        });
        console.log(jobNumberFemale, jobNumberMale);
  
        setJobNumberFemale(jobNumberFemale.ewechatUrl);
        setJobNumberMale(jobNumberMale.ewechatUrl);
      }
      }
    fetchData();
  }, [jobNumber]);

  return (
    <div className="consult_wrap">
      <div className="inner">
        <div className="title">VIP免费咨询服务</div>

        <OverPack
          playScale={0.1}
          // @ts-ignore
          always={false}
          appear={consult}
        >
          <QueueAnim key="queue" type={"bottom"} delay={100} duration={600}>
            <div className="content flex-between" key="content">
              {Object.keys(jobNumber).map((item, index) => {
                return (
                  <div className="card" key={index}>
                    <div className="card-title">
                      <img
                        src={item == "jobNumberFemale" ? womanIMG : manIMG}
                        alt=""
                      />
                      <div className="card-title-code">
                        <img
                          src={
                            item === "jobNumberFemale"
                              ? jobNumberFemale
                              : jobNumberMale
                          }
                          alt=""
                        />
                        <div className="card-title-code-rignt">
                          <span className="span1">微信扫码</span>
                          <span className="span2">免费咨询</span>
                        </div>
                      </div>
                    </div>
                    <img
                      src={item === "jobNumberFemale" ? baoguWoman : baoguMan}
                      alt="img"
                      className="right_img"
                    />
                  </div>
                );
              })}
            </div>
          </QueueAnim>
        </OverPack>
      </div>
    </div>
  );
};

export default Consult;
