import React, { useEffect, useState, memo } from 'react';
import './index.scss';

import { Button, Upload } from 'antd';
import InsuredDialog from './insured-dialog'
import { IInsuredLimit, IInsureForm, ISafeguardLimitData } from '@/lib/order/types'
import EventUtils from '@/lib/order/event'
import {
	CertTypesMap,
	DefInsureFormInfo,
	INS_EVENT_POLICYHOLDER,
	INS_EVENT_POLICYHOLDER_SYNC,
	INS_EVENT_PRICE,
	INS_EVENT_RELATION_SELF,
	RelationMap
} from '@/lib/order/constants'
import { getSessionStorageItem, setSessionStorageItem } from '@/lib/storage'
import { downloadInsuredTemplate, uploadInsuredTemplate } from '@/lib/order/apis'
import ToastUtils from '@/lib/toast'
import { UploadChangeParam, UploadFile, UploadProps } from 'antd/lib/upload'
import {
	checkInsuredBaseData,
	getBirthdayFromIdCard,
	getGenderByIdCard,
	getSimpleUUID,
	zhToPin
} from '@/lib/order/utils'
import useDebounceList from '@/lib/useDebounceList'
import RegExpUtils from '@/lib/order/regex'
import icon_add from '@/static/imgs/order/icon_add.svg'
import InsLoading from '@/order-components/ins-loading'

interface IProps {
	productId: string,
	limitData: IInsuredLimit,
	safeguardLimitData: ISafeguardLimitData,
	onDataChange: (data: IInsureForm[]) => void
}

let needUpdate = true;
let cacheInsuredList: IInsureForm[] = null;
let defCertType = '01';

const OrderInsured = (props: IProps) => {

	const {productId, limitData, safeguardLimitData, onDataChange} = props;

	const [insuredDialogState, setInsuredDialogState] = useState({
		isNew: false,
		show: false,
		data: null
	});

	const [insuredList, setInsuredList] = useState<IInsureForm[]>([]);

	const insuredListDebounce = useDebounceList([insuredList], 1000);

	const [showLoading, setShowLoading] = useState(false);

	useEffect(() => {

		const handleEvent = (eventData) => {
			console.log('OrderInsured-INS_EVENT_PRICE', eventData);
			needUpdate = false;
			// console.log('cacheInsuredList', cacheInsuredList);
			if (cacheInsuredList?.length > 0) {
				let lastList = Array.from(cacheInsuredList);
				if (eventData?.type === 'minPrice') {
					lastList.forEach((item) => {
						item.amount = '';
					})
				} else if (eventData?.type === 'price') {
					eventData.data.trialInsuredInfos.forEach((trialItem) => {
						let findData = lastList.find((item) => item.id === trialItem.id);
						if (findData) {
							findData.amount = trialItem.salePrice.first;
						}
					})
				}
				setInsuredList(lastList);
			}
		};

		const handlePolicyholderChangeEvent = (eventData) => {
			console.log('OrderInsured-INS_EVENT_POLICYHOLDER', eventData);
			if (cacheInsuredList?.length > 0) {
				let lastList = Array.from(cacheInsuredList);
				// const self = lastList.find(item => item.relation === '01');
				let self = lastList.find(item => item.id === eventData.id || item.relation === '01');
				if (self) {
					needUpdate = true;
					Object.assign(self, eventData, {
						id: self.id,
						relation: '01',
						secondCertType: self.secondCertType,
						secondCertNo: self.secondCertNo || '',
						secondCertNoError: self.secondCertNoError || '',
						amount: self.amount || '',
						occupation: self.occupation,
						medical: self.medical || '',
						deductible: self.deductible || '',
						expensiveHospital: self.expensiveHospital || ''
					});
					const {errorData} = checkInsuredBaseData(self, limitData, safeguardLimitData);
					self.ruleError = errorData.ruleError;
				}
				setInsuredList(lastList);
			}
		};

		EventUtils.on(INS_EVENT_PRICE, handleEvent);
		EventUtils.on(INS_EVENT_POLICYHOLDER, handlePolicyholderChangeEvent);

		const cacheStr = getSessionStorageItem(`jdaz-insuredList-${productId}`);
		if (cacheStr?.length > 2) {
			setInsuredList(JSON.parse(cacheStr));
		}

		return () => {
			EventUtils.off(INS_EVENT_PRICE, handleEvent);
			EventUtils.off(INS_EVENT_POLICYHOLDER, handlePolicyholderChangeEvent);
		};
	}, []);

	useEffect(() => {
		if (limitData?.certTypes?.length > 0) {
			defCertType = limitData.certTypes[0];
		}
	}, [limitData])

	useEffect(() => {
		cacheInsuredList = insuredList;
		console.log('OrderInsured-insuredList', insuredList);
		if (needUpdate) {
			onDataChange(insuredList);
		}
		setSessionStorageItem(`jdaz-insuredList-${productId}`, JSON.stringify(insuredList || []));
	}, [insuredList]);

	useEffect(() => {
		if (insuredList) {
			if (insuredList.length > 0 && insuredList.find(item => item.relation === '01')) {
				EventUtils.emit(INS_EVENT_POLICYHOLDER_SYNC, true);
			} else {
				EventUtils.emit(INS_EVENT_POLICYHOLDER_SYNC, false);
			}
		}
	}, [insuredListDebounce])

	useEffect(() => {
		if (safeguardLimitData && insuredList?.length > 0) {
			// 校验被保人信息
			let lastList = Array.from(insuredList);
			lastList.forEach((item) => {
				const {errorData} = checkInsuredBaseData(item, limitData, safeguardLimitData);
				item.ruleError = errorData.ruleError;
			})
			setInsuredList(lastList);
		}
	}, [safeguardLimitData])

	const handleAdd = () => {
		setInsuredDialogState({
			isNew: true,
			data: {
				...DefInsureFormInfo,
				insureType: 'individual',
				certType: defCertType,
				secondCertType: '63'
			},
			show: true
		})
	}

	const handleEdit = (item) => {
		setInsuredDialogState({
			isNew: false,
			data: {
				...DefInsureFormInfo,
				...item
			},
			show: true
		})
	}

	const handleRemove = (index: number) => {
		needUpdate = true;
		let lastList = Array.from(insuredList);
		lastList.splice(index, 1);
		setInsuredList(lastList);
	}

	const handleInsuredDialogClose = () => {
		setInsuredDialogState({
			...insuredDialogState,
			show: false,
			data: null
		})
	}

	const handleInsuredDialogConfirm = (isNew: boolean, data: IInsureForm) => {
		needUpdate = true;
		handleInsuredDialogClose();
		let lastList = Array.from(insuredList);
		if (isNew) {
			lastList.push({
				...data,
				id: getSimpleUUID()
			});
		} else {
			let oriItem: any = lastList.find(item => item.id === data.id);
			Object.assign(oriItem, data);
			oriItem.ruleError = '';
		}
		setInsuredList(lastList);
		if (data.relation === '01') {
			EventUtils.emit(INS_EVENT_RELATION_SELF, data);
		}
	}

	const beforeUpload = () => {
		return false;
	}

	const handleFileChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
		// console.log('handleFileChange', info);
		if (!info.file.name.endsWith('.xls') && !info.file.name.endsWith('.xlsx')) {
			ToastUtils.info('请选择正确的模版文件');
			return;
		}
		setShowLoading(true);
		// @ts-ignore
		uploadInsuredTemplate(info.file)
		.then((res: any) => {
			setShowLoading(false);
			if (res?.insuredInfoList?.length > 0) {
				const insuredInfoList: any[] = res.insuredInfoList;

				// 判断被保人最大人数
				let maxCount = limitData?.numberLimit?.max || 0;
				if (maxCount && insuredInfoList.length > maxCount) {
					ToastUtils.info(`最多只能添加${maxCount}名被保人`);
					return;
				}

				// // 校验本人关系
				// if (insuredInfoList.filter(item => item.relation === '01').length > 1) {
				// 	ToastUtils.info('最多只能添加一个[本人]关系的被保人');
				// 	return;
				// }
				// // 校验配偶关系
				// if (insuredInfoList.filter(item => item.relation === '04').length > 1) {
				// 	ToastUtils.info('最多只能添加一个[配偶]关系的被保人');
				// 	return;
				// }

				// birthDay:"1984/10/10"
				// certNum:"110101200007286800"
				// certType:"01"
				// gender:"M"
				// insuredName:"测试"
				// mobile:"18900000000"
				// relation:"01"
				let lastList: IInsureForm[] = [];
				let relationSelfData = null;
				insuredInfoList.forEach((item) => {
					let birthday = '';
					let gender = '';
					if (item.certType === '01' && RegExpUtils.checkIdCard(item.certNum)) {
						birthday = getBirthdayFromIdCard(item.certNum);
						gender = getGenderByIdCard(item.certNum);
					} else {
						birthday = item.birthDay ? item.birthDay.replaceAll('/', '-') : '';
						gender = item.gender || '';
					}
					let certType = limitData?.certTypes?.includes(item.certType || '') ? item.certType : CertTypesMap[item.certType];

					const convertData = {
						id: getSimpleUUID(),
						relation: item.relation || '',
						insureType: 'individual',
						name: item.insuredName || '',
						namePinYin: item.insuredName ? zhToPin(item.insuredName).toUpperCase() : '',
						certType: certType,
						certNo: item.certNum || '',
						birthday: birthday || '',
						gender: gender || '',
						mobile: item.mobile || '',
						imgData: []
					};
					if (convertData.relation === '01' && !relationSelfData) {
						relationSelfData = convertData;
					}
					let resultData: IInsureForm = {
						...DefInsureFormInfo,
						...convertData
					}
					const {errorData} = checkInsuredBaseData(resultData, limitData, safeguardLimitData);
					resultData.ruleError = errorData.ruleError;

					lastList.push(resultData);
				})
				if (relationSelfData) {
					EventUtils.emit(INS_EVENT_RELATION_SELF, relationSelfData);
				}
				needUpdate = true;
				setInsuredList(lastList);
			} else {
				ToastUtils.info('上传失败，文件内容不能为空')
			}
		})
		.catch(() => {
			setShowLoading(false);
			ToastUtils.info('上传失败')
		})
	}

	return (
		<div className='order-insured'>

			<InsLoading show={showLoading} />

			<div className='v-template'>
				<Upload
					name='file'
					accept='.xls,.xlsx'
					maxCount={1}
					showUploadList={false}
					beforeUpload={beforeUpload}
					onChange={handleFileChange}
				>
					<span className='v-action-item'>批量导入</span>
				</Upload>
				<div className='v-action-gap' />
				<a className='v-action-item' href={downloadInsuredTemplate()}>下载导入模板</a>
			</div>

			<div className='order-insured_list'>
				{
					insuredList &&
					insuredList.length > 0 &&
					insuredList.map((item, index) => {
						return (
							<React.Fragment key={`insuredList-item-${index}`}>
								<div className='list-item'>
									<div className='list-item_left'>
										<span className='text-relation'>{RelationMap[item.relation || ''] || ''}</span>
										<span className='text-name'>{item.name}</span>
										{/*<span className='text-desc'>{item.namePinYin || ''}</span>*/}
										<span className='text-desc'>{item.certNo || ''}</span>
									</div>
									<div className='list-item_right'>
										<span className='v-premium'>{item.amount ? `保费：${item.amount}元` : ''}</span>
										<Button
											className='btn-edit'
											type='link'
											onClick={() => handleEdit(item)}
										>
											修改
										</Button>
										<Button
											className='btn-remove'
											type='link'
											onClick={() => handleRemove(index)}
										>
											删除
										</Button>
									</div>
									<span className='list-item_error'>{item.ruleError || ''}</span>
								</div>
							</React.Fragment>
						)
					})
				}
			</div>

			<div style={{display: insuredList?.length >= (limitData?.numberLimit?.max || 100) ? 'none' : 'block'}}>
				<Button className='btn-add' onClick={handleAdd}>
					<img className='v-img-add' src={icon_add} alt={''} />
					<span className='v-text-add'>点击添加被保险人</span>
				</Button>
			</div>

			<InsuredDialog
				show={insuredDialogState.show}
				onClose={handleInsuredDialogClose}
				onConfirm={handleInsuredDialogConfirm}
				data={insuredDialogState.data}
				isNew={insuredDialogState.isNew}
				listData={insuredList}
				limitData={limitData}
				safeguardLimitData={safeguardLimitData}
			/>

		</div>
	);
};

export default memo(OrderInsured);
