import {
	IDetailPeriod,
	IDetailPlanItem,
	IInsuredLimit,
	IInsureForm,
	INumLimit,
	IObjectCommonListItem,
	IPackageInfo,
	IPolicyHolderLimit,
	IPremium,
	ISafeguardLimitData,
	IStoreyProduction,
	IUploadImgInfo
} from './types'
import { getSessionStorageItem, setSessionStorageItem } from '../storage'
import jdlbs from '@jd/lbs-sdk'
import dayjs from 'dayjs'
import RegExpUtils from '@/lib/order/regex'
import { CertTypesMap, JDAndJDAZAreaCodeMap } from '@/lib/order/constants'
import ToastUtils from '@/lib/toast'
import { checkRealName } from '@/lib/order/apis'
import Pinyin from 'pinyin';
import { getGlobalData } from '@/lib/global'

const H5_PAGE_URL = {
	dev: 'https://m-test.jdallianz.com/ins-temp-m',
	test: 'https://m-test.jdallianz.com/ins-temp-m',
	pre: 'https://m-pre.jdallianz.com/ins-temp-m',
	prod: 'https://m.jdallianz.com/ins-temp-m',
	//@ts-ignore
}[process.env.REACT_APP_MODE];

const unitMap = {
	Y: 'year',
	D: 'day'
};

const unitTextMap = {
	Y: '周岁',
	M: '月',
	D: '天',
	y: '周岁',
	d: '天'
};

const defAddressData = {
	'china': true,
	'province': '北京市',
	'city': '北京市',
	'district': '通州区',
	'address': '北京市通州区',
	'provinceId': '1',
	'cityId': '1',
	'districtId': '2809'
};

/**
 * 获取位置信息，如果授权就使用经纬度定位，否则使用ip定位
 */
const getLBSInfo = (ipInfo?: string) => {
	return new Promise(resolve => {
		jdlbs.getAddress({
			appid: '2b2c59199d6f8cfab8628e6434637fd9',
			sceneId: 'basicShoppingProcess',
			ifdetail: !ipInfo,
			ifip: !ipInfo,
			timeout: 600000,
			success(res: any) {
				const {status, data} = res
				console.log('getLBSInfo-success', res)
				if (status === '0' && data) {
					const {
						province, city, district, detailaddr,
						provinceid, cityid, districtid, region
					} = data
					let address = {}
					if (region === '中国') {
						const zhiXiaShi = ['1', '2', '3', '4'].includes(provinceid);
						const provinceName = `${province}${zhiXiaShi ? '市' : '省'}`
						const cityName = zhiXiaShi ? provinceName : city
						const districtName = zhiXiaShi ? city : district
						const addressDetail = `${provinceName}${zhiXiaShi ? '' : cityName}${districtName}${detailaddr || ''}`
						address = {
							china: true,
							province: provinceName,
							city: cityName,
							district: districtName,
							address: addressDetail,
							provinceId: provinceid,
							cityId: zhiXiaShi ? provinceid : cityid,
							districtId: zhiXiaShi ? cityid : districtid
						}
					} else {
						address = {
							china: false,
							province: region
						}
					}
					console.log('getLBSInfo-address', address)
					setSessionStorageItem('jdaz-geocoder-address', JSON.stringify(address))
					resolve(address)
				} else {
					ipInfo && setSessionStorageItem('jdaz-geocoder-address', JSON.stringify(defAddressData));
					resolve(ipInfo ? defAddressData : null);
				}
			},
			fail(res: any) {
				console.error('getLBSInfo-fail', res)
				ipInfo && setSessionStorageItem('jdaz-geocoder-address', JSON.stringify(defAddressData));
				resolve(ipInfo ? defAddressData : null);
			},
			ipaddr: ipInfo || ''
		});
	})
}

/**
 * 获取默认选择计划index
 * @param productId
 * @param data
 */
const getInitPlanIndex = (productId: string, data: IDetailPlanItem[]) => {
	if (!data) return -1
	let currentIndex = 0
	if (data.length > 1) {
		const cacheIndexStr = getSessionStorageItem(`jdaz-plan-index-${productId}`)
		if (cacheIndexStr) {
			currentIndex = Number.parseInt(cacheIndexStr)
		} else {
			data.some((item, index) => {
				if (item.active) {
					currentIndex = index
					return true
				}
			})
		}
	}
	return currentIndex
}

const getBirthdayFromIdCard = (idCard: string) => {
	let birthday = '';
	if (idCard != null && idCard != '') {
		if (idCard.length == 15) {
			birthday = '19' + idCard.slice(6, 12);
		} else if (idCard.length == 18) {
			birthday = idCard.slice(6, 14);
		}
		birthday = birthday.replace(/(.{4})(.{2})/, '$1-$2-');
	}
	return birthday;
}

// 15位身份证号码的第15位代表性别，18位身份证号码的第17位代表性别。奇数为男，偶数为女
const getGenderByIdCard = (idCard: string) => {
	let gender: string = '';
	if (idCard.length == 15) {
		gender = Number.parseInt(idCard.substring(14)) % 2 == 0 ? 'F' : 'M';
	} else {
		gender = Number.parseInt(idCard.substr(16, 1)) % 2 == 0 ? 'F' : 'M';
	}
	return gender;
}

const getCertImageToken = (imgData: IUploadImgInfo[]) => {
	if (imgData?.length < 1) {
		return [];
	} else {
		const tokens = imgData.map((item) => {
			return item.token;
		})
		return tokens || [];
	}
}

const getPolicyAreaInfo = () => {
	try {
		const addressInfoStr = getSessionStorageItem('jdaz-geocoder-address');
		if (addressInfoStr) {
			const addressInfo = JSON.parse(addressInfoStr);
			return {
				provinceName: addressInfo.province || '',
				cityName: addressInfo.city || '',
				regionName: addressInfo.district || '',
				provinceCode: addressInfo.provinceId || '',
				cityCode: addressInfo.cityId || '',
				regionCode: addressInfo.districtId || '',
				detailAddress: addressInfo.address || ''
			}
		}
	} catch (e) {

	}
	return null;
}

const getRedirectUrl = () => {
	const urlObj = new URL(window.location.href);
	const domain = urlObj.host;
	return `${urlObj.protocol}//${domain}`;
}

interface IStoreyTrialParams {
	clientToken: string,
	currentPlanData: IDetailPlanItem,
	coverageData: string[],
	checkType: number,
	storeyProductionData: IStoreyProduction,
	insuredList: IInsureForm[],
	premiumData: IPremium,
	periodData: IDetailPeriod,
	payMode?: string // 支付形式：F 一次性支付;M  月付
}

const getStoreyTrialParams = (params: IStoreyTrialParams) => {

	const {
		storeyProductionData, checkType, insuredList, currentPlanData, coverageData,
		clientToken, periodData, premiumData, payMode = 'F'
	} = params

	let insuredParams: any = []

	const {socialSecurity, deductibleLimit} = storeyProductionData.insuredInfo

	if (checkType === 1 || checkType === 3 || checkType === 5) {
		//被保人是【个人】
		let insuredData = Array.from(insuredList);
		insuredData.forEach((item) => {
			insuredParams.push({
				birthday: item.birthday || getBirthdayFromIdCard(item.certNo),
				gender: item.gender || getGenderByIdCard(item.certNo),
				coreItemCodes: [],
				coverageCodes: currentPlanData.requiredCode,//必选责任代码
				optionalCoverageCodes: coverageData,//可选责任代码
				deductible: deductibleLimit ? item.deductible : '',//免赔额
				id: item.id,
				insuredObjectList: [],
				planCodes: [currentPlanData.groupId],//计划代码
				socialSecurity: socialSecurity ? item.medical : '',
				groupId: currentPlanData.groupId,
				expensiveHospital: item.expensiveHospital === 'Y'
			})
		})
	} else {
		//被保人是【企业】或者不展示
		insuredParams.push({
			coreItemCodes: [],
			coverageCodes: currentPlanData.requiredCode,//必选责任代码
			optionalCoverageCodes: coverageData,//可选责任代码
			deductible: '',//免赔额
			id: '1',
			insuredObjectList: [],
			planCodes: [`${currentPlanData.groupId}`],//计划代码
			socialSecurity: '',
			groupId: currentPlanData.groupId
		})
	}

	// let paySplit = 1;
	// if (storeyProductionData.installmentSupport && payMode === 'M') {
	// 	paySplit = storeyProductionData.paySplit || 1;
	// }

	return {
		clientToken: clientToken,
		groupId: currentPlanData.groupId,
		insuredInfoParams: insuredParams,
		pageVer: storeyProductionData.productionVer,
		payMode: payMode || 'F',
		productionVer: storeyProductionData.productionVer,
		start: periodData.expiry.start,
		end: periodData.expiry.end,
		priceToken: premiumData?.priceToken || '',
		insureObjectInfo: [],
		paySplit: 1
	}
}

interface IStoreyOrderParams {
	productId: string,
	packageInfo: IPackageInfo,
	currentPlanData: IDetailPlanItem,
	coverageData: string[],
	checkType: number,
	policyholder: IInsureForm,
	storeyProductionData: IStoreyProduction,
	insuredList: IInsureForm[],
	premiumData: IPremium,
	periodData: IDetailPeriod,
	needInvoice: boolean,
	showDDA: boolean,
	ddaFlag: string,
	healthAllOk: boolean,
	extParams?: any,
	payMode?: string
}

const getStoreyOrderParams = (params: IStoreyOrderParams) => {

	const {
		productId, packageInfo, storeyProductionData, checkType, insuredList, policyholder, currentPlanData, coverageData,
		periodData, premiumData, showDDA, ddaFlag, healthAllOk,
		extParams = {}, payMode = 'F'
	} = params;

	// insuredSchoolTypeLimit
	const {socialSecurity, deductibleLimit, supportOccupationLimit, insuredSecondCertTypeSupportLimit} = storeyProductionData.insuredInfo;

	const policyAreaInfo = getPolicyAreaInfo();

	const areaCode = packageInfo.offlineAttr?.areaCode || (policyAreaInfo ? JDAndJDAZAreaCodeMap[Number.parseInt(policyAreaInfo.provinceCode)] : '');

	//投保人信息
	let proposer: any = {
		areaCode: areaCode,// 投保区域
		email: policyholder.email || '',
		namePinyin: policyholder.certType !== '01' ? policyholder.namePinYin : '',
		phone: policyholder.mobile,
		// phoneEncrypt: policyholder.mobileEncrypt || ''
	}

	if (policyholder.insureType === 'individual') {
		proposer = {
			...proposer,
			name: policyholder.name,
			// nameEncrypt: policyholder.nameEncrypt,
			certType: policyholder.certType,
			certNum: policyholder.certNo,
			// certNumEncrypt: policyholder.certNoEncrypt,
			birthDay: policyholder.birthday || getBirthdayFromIdCard(policyholder.certNo),
			gender: policyholder.gender || getGenderByIdCard(policyholder.certNo),
			type: '1',
			certImageToken: policyholder.certType !== '01' ? getCertImageToken(policyholder.imgData) : []
		}
	}
	// else {
	// 	proposer = {
	// 		...proposer,
	// 		name: policyholder.companyName,
	// 		certType: '74',
	// 		certNum: policyholder.companyLicence,
	// 		type: '2',
	// 		industryCategory: policyholder.industryDetail?.country?.code || '',
	// 		isGreenIndustry: policyholder.greenIndustry === '是' ? 1 : 0,
	// 		policyHolderBusinessLicenseUrl: policyholder.businessLicenseUrl || ''
	// 	}
	// }

	//被保人信息
	let insuredParams: any = []
	let insuredCommonInfo: any = {
		groupId: currentPlanData.groupId,
		coreItemCodes: [],//标的代码
		corePlanCode: currentPlanData.groupId,//计划代码
		coverageCodes: currentPlanData.requiredCode,//必选责任代码
		optionalCoverageCodes: coverageData,//可选责任代码
		insuredObjectList: [],//标的信息
		planCode: [`${currentPlanData.groupId}`],//计划代码
		prof: '',//职业
		seqToken: ''
	}
	if (checkType === 1 || checkType === 3 || checkType === 5) {
		//被保人是【个人】
		let insuredData = Array.from(insuredList);

		insuredData.forEach((item, index) => {
			// let schoolInfo = {}
			// if (insuredSchoolTypeLimit?.length > 0) {
			// 	schoolInfo = {
			// 		insuredSchoolTypeCode: item.schoolTypeCode,
			// 		insuredSchoolType: item.schoolTypeDisplay,
			// 		insuredSchoolName: item.schoolName
			// 	}
			// }
			insuredParams.push({
				...insuredCommonInfo,
				type: '1',
				birthday: item.birthday || getBirthdayFromIdCard(item.certNo),
				certType: item.certType,
				certNum: item.certNo,
				// certNumEncrypt: item.certificateEncrypt,
				certType1: (item.certType === '01' && item.secondCertNo) ? (item.secondCertType || '63') : '',
				certNum1: (item.certType === '01' && item.secondCertNo) ? item.secondCertNo : '',
				// certNum1Encrypt: '',
				deductible: deductibleLimit ? item.deductible : '',//免赔额
				gender: item.gender || getGenderByIdCard(item.certNo),
				name: item.name,
				// nameEncrypt: item.nameEncrypt || '',
				namePinyin: ((item.certType !== '01' || insuredSecondCertTypeSupportLimit) && item.namePinYin) ? item.namePinYin : '',
				phone: item.mobile,
				// phoneEncrypt: item.mobileEncrypt,
				price: premiumData.trialInsuredInfos[index].salePrice,
				relation: item.relation,
				socialSecurity: socialSecurity ? item.medical : '',
				certImageToken: item.certType !== '01' ? getCertImageToken(item.imgData) : [],
				prof: supportOccupationLimit ? (item.occupation?.code || '') : '',
				// ...schoolInfo,
				health: item.health || 'Y',
				groupId: currentPlanData.groupId,
				corePlanCode: currentPlanData.groupId, // 计划代码
				coverageCodes: currentPlanData.requiredCode, // 必选责任代码
				optionalCoverageCodes: coverageData, // 可选责任代码
				planCode: [currentPlanData.groupId], // 计划代码
				expensiveHospital: item.expensiveHospital === 'Y'
			})
		})
	} else {
		//不展示
		insuredParams.push({
			...insuredCommonInfo,
			...proposer,
			deductible: '',//免赔额
			price: premiumData.trialInsuredInfos[0].salePrice,
			relation: '01',
			socialSecurity: ''
		})
	}

	// //发票信息
	// let invoiceParams: any = {
	// 	draw: needInvoice,
	// 	email: policyholder.email || ''
	// }
	// if (policyholder.insureType === 'individual') {
	// 	invoiceParams = {
	// 		...invoiceParams,
	// 		header: policyholder.name,
	// 		headerEncrypt: policyholder.nameEncrypt,
	// 		taxpayerId: policyholder.certificate,
	// 		taxpayerIdEncrypt: policyholder.certificateEncrypt || ''
	// 	}
	// } else {
	// 	invoiceParams = {
	// 		...invoiceParams,
	// 		header: policyholder.companyName,
	// 		taxpayerId: policyholder.companyLicence
	// 	}
	// }

	//支付参数
	let payOrderParams: any = {};
	if (showDDA) {
		payOrderParams = {
			...payOrderParams,
			ddaVal: ddaFlag === 'on' ? '1' : '0'
		}
	}

	let paySplit = 1;
	if (storeyProductionData.installmentSupport && payMode === 'M') {
		paySplit = storeyProductionData.paySplit || 1;
	}

	let traceNoReplace = '';
	// 可回溯
	try {
		// @ts-ignore
		traceNoReplace = window.helper?.getSeq();
		console.log('traceNoReplace', traceNoReplace);
	} catch (e) {
	}

	return {
		applyToken: '',
		authToken: '',
		clientToken: packageInfo?.clientToken,
		groupId: currentPlanData.groupId,
		health: healthAllOk ? 'Y' : 'N',
		insuredVoList: insuredParams,
		intelligence: {
			// 智能核保
			cancelUrl: window.location.href,
			failedUrl: window.location.href,
			successUrl: `${H5_PAGE_URL}/intelligentr?p=${productId}`
		},
		pageVer: storeyProductionData.productionVer,
		pay: {
			// 支付
			ext: JSON.stringify({ver: 1}), //扩展信息
			onCancel: '',
			redirectUrl: `${getRedirectUrl()}/bxsc/product/result`,
			payMethod: '',
			renewalPayFlag: payOrderParams.ddaVal || ''
		},
		payModel: payMode || 'F',
		paySplit: paySplit,
		policyEffectTime: periodData.expiry.start,
		policyExpiryTime: periodData.expiry.end,
		priceToken: premiumData.priceToken,
		productionVer: storeyProductionData.productionVer,
		proposer: proposer,
		totalSalePrice: premiumData.salePrice,
		insureObjectInfo: [],
		// invoice: invoiceParams,
		location: window.location.href,
		policyArea: policyAreaInfo,
		...extParams,
		traceNoReplace: traceNoReplace || ''
	}

}

const getMergeSocialSecurityList = (socialSecurityLimitList: string[][]) => {
	if (!socialSecurityLimitList || socialSecurityLimitList.length === 0) return undefined
	if (socialSecurityLimitList.length === 1) return socialSecurityLimitList[0]
	const mergeSocialSecurityList = socialSecurityLimitList.reduce((pre, cur) => {
		return pre.filter((preItem) => {
			return cur.some((curItem) => {
				return curItem === preItem
			})
		})
	})
	console.log('mergeSocialSecurityList', mergeSocialSecurityList)
	if (mergeSocialSecurityList && mergeSocialSecurityList.length > 0) {
		return mergeSocialSecurityList
	} else {
		return undefined
	}
}

const getMergeDeductibleList = (deductibleLimitList: IObjectCommonListItem[][]) => {
	if (!deductibleLimitList || deductibleLimitList.length === 0) return undefined
	if (deductibleLimitList.length === 1) return deductibleLimitList[0]
	const mergeDeductibleList = deductibleLimitList.reduce((pre, cur) => {
		return pre.filter((preItem) => {
			return cur.some((curItem) => {
				return curItem.code === preItem.code
			})
		})
	})
	console.log('mergeDeductibleList', mergeDeductibleList)
	if (mergeDeductibleList && mergeDeductibleList.length > 0) {
		return mergeDeductibleList
	} else {
		return undefined
	}
}

const getMergeGenderList = (genderLimitList: string[][]) => {
	if (!genderLimitList || genderLimitList.length === 0) return undefined
	if (genderLimitList.length === 1) return genderLimitList[0]
	const mergeGenderList = genderLimitList.reduce((pre, cur) => {
		return pre.filter((preItem) => {
			return cur.some((curItem) => {
				return curItem === preItem
			})
		})
	})
	console.log('mergeGenderList', mergeGenderList)
	if (mergeGenderList && mergeGenderList.length > 0) {
		return mergeGenderList
	} else {
		return undefined
	}
}

const getAgeCount = (age: INumLimit) => {
	const minUnit = age.min.unit.toUpperCase() || 'D'
	const minValue = age.min.v || 0
	const minCount = minUnit === 'Y' ? minValue * 1000 : minValue
	const maxUnit = age.max.unit.toUpperCase() || 'D'
	const maxValue = age.max.v || 0
	const maxCount = maxUnit === 'Y' ? maxValue * 1000 : maxValue
	return {
		minCount,
		maxCount
	}
}

interface IMaxAge {
	max: {
		unit: string,
		v: number
	},
	count: number
}

interface IMinAge {
	min: {
		unit: string,
		v: number
	},
	count: number
}

/**
 * 思路：
 * 1、先找到每个分组中的最大年龄中的最小值（取每个分组最大值集合，然后排序取最小）maxAge
 * 2、再找到所有数据中最大年龄中的最小值（取所有数据最大值集合，然后排序取最小）minMaxAge、最小年龄中的最大值（取所有数据最小值集合，然后排序取最大））minAge
 * 3、排除最小值大于maxAge
 * 4、排除相同的最小值和最大值（最小值相同，最大值取最小值；最大值相同，最小值取最大值）
 * @param ageLimitList
 */
const getMergeAgeList = (ageLimitList: INumLimit[][]) => {
	if (!ageLimitList || ageLimitList.length === 0) return undefined
	if (ageLimitList.length === 1) return ageLimitList[0]

	let groupMaxAgeList: IMaxAge[] = []

	ageLimitList.forEach((list) => {
		const listLength = list.length
		const item = list[listLength - 1]
		const {maxCount} = getAgeCount(item)
		groupMaxAgeList.push({
			max: item.max,
			count: maxCount
		})
	})

	groupMaxAgeList.sort((pre, next) => {
		if (pre.count < next.count) {
			return -1
		} else {
			return 1
		}
	})

	const maxAge = groupMaxAgeList[0]
	console.log('maxAge', maxAge)

	let minList: IMinAge[] = []
	let maxAgeList: IMaxAge[] = []

	ageLimitList.forEach((list) => {
		list.forEach((item, index) => {
			const {minCount, maxCount} = getAgeCount(item)
			if (index === 0) {
				minList.push({
					min: item.min,
					count: minCount
				})
			}
			maxAgeList.push({
				max: item.max,
				count: maxCount
			})
		})
	})

	maxAgeList.sort((pre, next) => {
		if (pre.count < next.count) {
			return -1
		} else {
			return 1
		}
	})

	const minMaxAge = maxAgeList[0]
	console.log('minMaxAge', minMaxAge)

	minList.sort((pre, next) => {
		if (pre.count < next.count) {
			return 1
		} else {
			return -1
		}
	})

	const minAge = minList[0]
	console.log('minAge', minAge)
	let mergeAgeList: any[] = []

	if (maxAge.count === minMaxAge.count) {
		mergeAgeList.push({
			min: minAge.min,
			max: minMaxAge.max,
			minCount: minAge.count,
			maxCount: minMaxAge.count
		})
	} else {
		ageLimitList.forEach((ageLimitListItem) => {
			ageLimitListItem.forEach((curItem) => {
				const {minCount, maxCount} = getAgeCount(curItem)
				let min: any = null
				let max: any = null
				let targetMinCount = 0
				let targetMaxCount = 0
				if (minCount <= maxAge.count) {
					if (minCount <= minAge.count) {
						min = minAge.min
						targetMinCount = minAge.count
						max = minMaxAge.max
						targetMaxCount = minMaxAge.count
					} else {
						min = curItem.min
						targetMinCount = minCount
						if (maxCount < maxAge.count) {
							max = curItem.max
							targetMaxCount = maxCount
						} else {
							max = maxAge.max
							targetMaxCount = maxAge.count
						}
					}
					if (min && max && !mergeAgeList.find((item) => item.minCount === targetMinCount)) {
						const findIndex = mergeAgeList.findIndex((item) => item.maxCount >= targetMaxCount)
						if (findIndex > 0) {
							const findData = mergeAgeList[findIndex]
							mergeAgeList.splice(findIndex, 1)
							if (findData.minCount < targetMinCount) {
								mergeAgeList.push({
									min,
									max,
									minCount: targetMinCount,
									maxCount: targetMaxCount
								})
							} else {
								mergeAgeList.push({
									min: findData.min,
									max,
									minCount: findData.minCount,
									maxCount: targetMaxCount
								})
							}
						} else {
							mergeAgeList.push({
								min,
								max,
								minCount: targetMinCount,
								maxCount: targetMaxCount
							})
						}
					}
				}
			})
		})
	}

	console.log('mergeAgeList', mergeAgeList)

	if (mergeAgeList && mergeAgeList.length > 0) {
		return mergeAgeList
	} else {
		return undefined
	}
}

/**
 * 获取缓存的保障起期
 */
const getCacheStartDate = () => {
	return getSessionStorageItem(`StartDate`) || '';
}

/**
 * 获取可选日期范围
 * @param limit
 * @param ignoreCache
 */
const getEnsureAgeRange = (limit: INumLimit, ignoreCache?: boolean) => {

	const {min, max} = limit;

	let result = {
		minDate: '',
		maxDate: ''
	};

	let minStart = ignoreCache ? dayjs().format('YYYY-MM-DD') : getCacheStartDate();
	// console.log('cacheStartDate', minStart)

	let minAge = min.v
	let maxAge = max.v
	let minAgeUnit: any = unitMap[min.unit.toUpperCase()]
	let maxAgeUnit: any = unitMap[max.unit.toUpperCase()]

	if (minAgeUnit === 'day' && minAge > 0) {
		minAge = minAge - 1
	}

	const startDate = dayjs(minStart, 'YYYY-MM-DD');
	result.maxDate = startDate.subtract(minAge, minAgeUnit).format('YYYY-MM-DD');
	// result.minDate = startDate.subtract(maxAge, maxAgeUnit).format("YYYY-MM-DD");

	if (maxAgeUnit === 'year') {
		maxAge = maxAge + 1;
		let minDate = startDate.subtract(maxAge, maxAgeUnit).format('YYYY-MM-DD');
		result.minDate = dayjs(minDate, 'YYYY-MM-DD').add(1, 'day').format('YYYY-MM-DD');
	} else {
		result.minDate = startDate.subtract(maxAge, maxAgeUnit).format('YYYY-MM-DD');
	}

	return result
}

/**
 * 校验投被保人年龄
 */
const checkInsureAge = (certificate: string, oriBirthday: string, limit: INumLimit, ignoreCache?: boolean) => {
	let flag = true
	let error = ''
	const ageRange = getEnsureAgeRange(limit, ignoreCache)
	const minDate = ageRange.minDate;
	const maxDate = ageRange.maxDate;

	let birthday = ''
	if (certificate && certificate.indexOf('*') === -1 && (RegExpUtils.checkIdCard(certificate) || RegExpUtils.checkGangAoTaiIDCard(certificate))) {
		birthday = getBirthdayFromIdCard(certificate)
	} else {
		birthday = oriBirthday
	}
	if (birthday) {
		let ageDateValue = dayjs(birthday).valueOf()
		if (ageDateValue > dayjs(maxDate).valueOf() || ageDateValue < dayjs(minDate).valueOf()) {
			flag = false
			error = `投保年龄需在${limit.min.v}${unitTextMap[limit.min.unit]}-${limit.max.v}${unitTextMap[limit.max.unit]}之间`
		}
	} else {
		flag = false
		error = ''
	}
	return {
		flag: flag,
		error: error
	}
}

/**
 * 校验性别
 */
const checkSafeguardGender = (safeguardLimitData: ISafeguardLimitData, oriGender: string, certificate?: string) => {
	if (!safeguardLimitData) return false
	if (!safeguardLimitData.genderLimit) return true
	if ((oriGender || certificate) && safeguardLimitData.limitData?.Gender) {
		let gender
		if (certificate && certificate.indexOf('*') === -1) {
			gender = getGenderByIdCard(certificate)
		} else {
			gender = oriGender
		}
		return safeguardLimitData.limitData.Gender.includes(gender)
	}
	return false
}

/**
 * 校验年龄
 */
const checkSafeguardAge = (safeguardLimitData: ISafeguardLimitData, birthday: string, certificate?: string) => {
	if (!safeguardLimitData) return {flag: false, error: ''}
	let result = {
		flag: true,
		error: ''
	}
	if (!safeguardLimitData.ageLimit) return result
	if ((birthday || certificate) && safeguardLimitData.limitData?.Age) {
		const findData = safeguardLimitData.limitData.Age.find((item) => {
			const ageResult = checkInsureAge(certificate || '', birthday, item)
			return ageResult.flag
		})
		if (findData) {
			result = {
				flag: true,
				error: ''
			}
		} else {
			result = {
				flag: false,
				error: '年龄不符合投保要求'
			}
		}
	} else {
		result = {
			flag: false,
			error: ''
		}
	}
	return result
}

/**
 * 校验医保/社保
 */
const checkSafeguardSocialSecurity = (safeguardLimitData: ISafeguardLimitData, socialSecurity: string) => {
	if (!safeguardLimitData) return false
	if (!safeguardLimitData.socialSecurityLimit) return true
	if (socialSecurity && safeguardLimitData.limitData?.SocialSecurity) {
		return safeguardLimitData.limitData.SocialSecurity.includes(socialSecurity)
	}
	return false
}

/**
 * 校验免赔额
 */
const checkSafeguardDeductible = (safeguardLimitData: ISafeguardLimitData, deductible: string) => {
	if (!safeguardLimitData) return false
	if (!safeguardLimitData.deductibleLimit) return true
	if (deductible && safeguardLimitData.limitData?.Deductible) {
		const findData = safeguardLimitData.limitData.Deductible.find((item) => `${item.code}` === deductible)
		return !!findData
	}
	return false
}

/**
 * 校验多被保人
 */
const checkSafeguardInsuredListInfo = (safeguardLimitData: ISafeguardLimitData, insuredList: IInsureForm[]) => {
	if (!safeguardLimitData) return false;
	if (insuredList && insuredList.length > 0) {
		insuredList.some((insuredItem) => {
			// const itemSafeguardLimitData = insuredItem.safeguardLimitData || safeguardLimitData;
			const itemSafeguardLimitData = safeguardLimitData;
			if (!checkSafeguardGender(itemSafeguardLimitData, insuredItem.gender)) {
				return true;
			}
			if (!checkSafeguardAge(itemSafeguardLimitData, insuredItem.birthday).flag) {
				return true;
			}
			if (!checkSafeguardSocialSecurity(itemSafeguardLimitData, insuredItem.medical)) {
				return true;
			}
			if (!checkSafeguardDeductible(itemSafeguardLimitData, insuredItem.deductible)) {
				return true;
			}
		})
		return true;
	} else {
		return false;
	}
}

/**
 * 判断被保人信息是否可以算费
 */
const checkInsuredInfoCanCalculatePremium = (
	insuredInfoConfig: IInsuredLimit,
	insuredList: IInsureForm[],
	safeguardLimitData: ISafeguardLimitData
) => {
	let canCalculatePremium = false;
	if (insuredInfoConfig.show && !insuredInfoConfig.supportWithPolicyHolder) {
		if (insuredInfoConfig.supportIndividual) {
			// 个人
			const minCount = insuredInfoConfig.numberLimit?.min || 1;
			if (insuredList && insuredList.length >= minCount) {
				// canCalculatePremium = checkSafeguardInsuredListInfo(safeguardLimitData, insuredList);
				const findResult = insuredList.find((item) => item.ruleError);
				canCalculatePremium = !findResult;
			}
		} else if (insuredInfoConfig.supportCompany) {
			// 企业
			canCalculatePremium = true;
		}
	} else {
		canCalculatePremium = true;
	}
	console.log('insuredList-canCalculatePremium', canCalculatePremium);
	return canCalculatePremium;
}

/**
 * 校验投保人信息
 */
const checkApplicationData = async (data: IInsureForm, limit: IPolicyHolderLimit) => {

	let errorData = checkFormBaseData({
		data: data,
		ageLimit: limit.policyHolderAge,
		dataType: 'application'
	})

	if (errorData.alertMsg) {
		ToastUtils.info(errorData.alertMsg)
		return {
			flag: false,
			errorData: errorData
		}
	} else if (data.insureType === 'individual' && data.certType === '01') {
		// 校验实名信息
		// @ts-ignore
		errorData.realNameError = await checkRealName(data.name, data.certNo);
		if (errorData.realNameError) {
			ToastUtils.info(errorData.realNameError)
			return {
				flag: false,
				errorData: errorData
			}
		}
	}

	return {
		flag: true,
		errorData: errorData
	}
}

/**
 * 校验被保人信息
 */
const checkInsuredData = async (data: IInsureForm, limit: IInsuredLimit, safeguardLimitData: ISafeguardLimitData) => {

	let errorData = checkFormBaseData({
		data: data,
		ageLimit: limit.ageLimit,
		genderLimit: limit.gender,
		relationLimit: limit.relations,
		dataType: 'insured',
		safeguardLimitData: safeguardLimitData,
		supportOccupationLimit: limit.supportOccupationLimit
	})

	if (errorData.alertMsg) {
		ToastUtils.info(errorData.alertMsg);
		return {
			flag: false,
			errorData: errorData
		}
	} else if (data.insureType === 'individual' && data.certType === '01' && checkIsNeedRealName(data.certNo, data.birthday)) {
		// 校验实名信息
		// @ts-ignore
		errorData.realNameError = await checkRealName(data.name, data.certNo);
		if (errorData.realNameError) {
			ToastUtils.info(errorData.realNameError);
			return {
				flag: false,
				errorData: errorData
			}
		}
	}

	return {
		flag: true,
		errorData: errorData
	}
}

/**
 * 校验是否需要实名认证（3岁以下不用实名校验）
 */
const checkIsNeedRealName = (certificate: string, oriBirthday: string, ignoreCache?: boolean) => {
	const startDate = ignoreCache ? dayjs().format('YYYY-MM-DD') : getCacheStartDate();
	const dateStr = dayjs(startDate).subtract(3, 'year').format('YYYY-MM-DD');
	let birthday;
	if (certificate.indexOf('*') === -1) {
		birthday = getBirthdayFromIdCard(certificate);
	} else {
		birthday = oriBirthday;
	}
	return dayjs(birthday).valueOf() < dayjs(dateStr).valueOf();
};

type CheckFormBaseDataParamsType = {
	data: IInsureForm,
	ageLimit: INumLimit,
	genderLimit?: string,
	relationLimit?: string[],
	dataType: string,
	safeguardLimitData?: ISafeguardLimitData,
	insuredSecondCertTypeSupportLimit?: boolean,
	supportOccupationLimit?: boolean
}

/**
 * 校验基础信息
 */
const checkFormBaseData = (params: CheckFormBaseDataParamsType) => {

	// insuredSecondCertTypeSupportLimit
	const {data, ageLimit, genderLimit, dataType, safeguardLimitData, supportOccupationLimit} = params;

	let errorData = {
		nameError: '',
		namePinYinError: '',
		certNoError: '',
		mobileError: '',
		relationError: '',
		realNameError: '',
		birthdayError: '',
		genderError: '',
		imgDataError: '',
		emailError: '',
		secondCertNoError: '',
		medicalError: '',
		deductibleError: '',
		occupationError: '',
		expensiveHospitalError: '',
		alertMsg: ''
	};

	if (dataType === 'application') {
		if (data.insureType === 'individual') {
			checkIndividualData(errorData, data, ageLimit, genderLimit);
		}
	} else if (dataType === 'insured' && safeguardLimitData) {
		checkInsuredIndividualData(errorData, data, safeguardLimitData);
	}

	if (dataType === 'application') {
		if (!data.mobile) {
			errorData.mobileError = '请输入手机号';
		} else if (!RegExpUtils.checkMobile(data.mobile)) {
			errorData.mobileError = '请输入正确的手机号';
		}
		if (data.email && !RegExpUtils.checkMail(data.email)) {
			errorData.emailError = '请输入正确的邮箱';
		}
	} else {
		// 被保人
		// 判断第二证件类型+姓名拼音
		// insuredSecondCertTypeSupportLimit &&
		if (data.certType === '01') {
			if (data.secondCertNo && !RegExpUtils.checkLetterAndNumber(data.secondCertNo)) {
				errorData.secondCertNoError = `请输入正确的${CertTypesMap[data.secondCertType]}号`;
			}
			if (data.namePinYin && !RegExpUtils.checkNamePinYin(data.namePinYin)) {
				errorData.namePinYinError = `请输入正确的姓名拼音（只支持字母+空格）`;
			}
		}
		// 判断职业
		if (supportOccupationLimit && !data.occupation?.code) {
			errorData.occupationError = '请选择职业';
		}
		// 判断医保
		if (!checkSafeguardSocialSecurity(safeguardLimitData, data.medical)) {
			errorData.medicalError = '请选择有无医保';
		}
		// 判断免赔额
		if (!checkSafeguardDeductible(safeguardLimitData, data.deductible)) {
			errorData.deductibleError = '请选择免赔额';
		}
		// 判断昂贵医院
		if (!checkExpensiveHospital(data.expensiveHospital)) {
			errorData.expensiveHospitalError = '请选择昂贵医院';
		}
	}

	errorData.alertMsg = errorData.nameError || errorData.namePinYinError || errorData.certNoError || errorData.mobileError
		|| errorData.imgDataError || errorData.birthdayError || errorData.genderError || errorData.emailError || errorData.secondCertNoError
		|| errorData.occupationError || errorData.medicalError || errorData.deductibleError || errorData.expensiveHospitalError;

	return errorData;
}

/**
 * 校验性别
 */
const checkGender = (certificate: string, oriGender: string, genderLimit: string) => {
	if (genderLimit && genderLimit !== '-' && genderLimit !== 'Any') {
		let gender
		if (certificate && certificate.indexOf('*') === -1) {
			gender = getGenderByIdCard(certificate)
		} else {
			gender = oriGender
		}
		return genderLimit.toUpperCase().startsWith(gender.toUpperCase())
	} else {
		return true
	}
}

const checkIndividualData = (
	errorData: any,
	data: IInsureForm,
	ageLimit?: INumLimit,
	genderLimit?: string
) => {
	if (!data.name) {
		errorData.nameError = '请输入姓名'
	} else if (data.name.length < 2) {
		errorData.nameError = '请输入正确的姓名'
	}

	if (data.certType === '01') {
		if (!data.certNo) {
			errorData.certNoError = '请输入身份证号'
		} else if (!RegExpUtils.checkIdCard(data.certNo)) {
			errorData.certNoError = '请输入正确的身份证号'
		} else {
			if (genderLimit && !checkGender(data.certNo, data.gender, genderLimit)) {
				//校验性别
				errorData.certNoError = '性别不符合投保要求'
			} else if (ageLimit && ageLimit.min?.unit && ageLimit.max?.unit) {
				//校验年龄
				const checkResult = checkInsureAge(data.certNo, data.birthday, ageLimit, true)
				errorData.certNoError = checkResult.error
			}
		}
	} else {
		if (!data.certNo) {
			errorData.certNoError = `请输入${CertTypesMap[data.certType]}号`
		} else {
			if ((data.certType === '70' && !RegExpUtils.checkGangAoTaiIDCard(data.certNo))) {
				errorData.certNoError = `请输入正确的${CertTypesMap[data.certType]}号`
			} else if (!RegExpUtils.checkLetterAndNumber(data.certNo)) {
				errorData.certNoError = `请输入正确的${CertTypesMap[data.certType]}号`
			}
		}
		// const imgCount = TwoPictureCertTypeList.includes(data.certType || '') ? 2 : 1
		const imgCount = 1;
		if (data.imgData?.length < imgCount || data.imgData.find((item) => !item.ossUrl)) {
			errorData.imgDataError = '请先上传证件照片'
		}
		// if (!data.namePinYin) {
		// 	errorData.namePinYinError = '请输入姓名拼音'
		// } else if (!RegExpUtils.checkNamePinYin(data.namePinYin)) {
		// 	errorData.namePinYinError = '请输入正确的姓名拼音（只支持字母+空格）'
		// }
		if (!data.gender) {
			errorData.genderError = '请选择性别'
		} else if (genderLimit && !checkGender('', data.gender, genderLimit)) {
			//校验性别
			errorData.genderError = '性别不符合投保要求'
		}
		if (!data.birthday) {
			errorData.birthdayError = '请选择出生日期'
		} else if (ageLimit && ageLimit.min?.unit && ageLimit.max?.unit) {
			//校验年龄
			const checkResult = checkInsureAge('', data.birthday, ageLimit, true)
			errorData.birthdayError = checkResult.error
		}
	}

	if (!data.mobile) {
		errorData.mobileError = '请输入手机号'
	} else if (!RegExpUtils.checkMobile(data.mobile)) {
		errorData.mobileError = '请输入正确的手机号'
	}

}

const checkInsuredIndividualData = (
	errorData: any,
	data: IInsureForm,
	safeguardLimitData: ISafeguardLimitData
) => {
	if (!data.name) {
		errorData.nameError = '请输入姓名'
	} else if (data.name.length < 2) {
		errorData.nameError = '请输入正确的姓名'
	}

	if (data.certType === '01') {
		if (!data.certNo) {
			errorData.certNoError = '请输入身份证号'
		} else if (!RegExpUtils.checkIdCard(data.certNo)) {
			errorData.certNoError = '请输入正确的身份证号'
		} else {
			if (!checkSafeguardGender(safeguardLimitData, data.gender, data.certNo)) {
				errorData.certNoError = '性别不符合投保要求'
			} else {
				const checkResult = checkSafeguardAge(safeguardLimitData, data.birthday, data.certNo)
				errorData.certNoError = checkResult.error
			}
		}
	} else {
		if (!data.certNo) {
			errorData.certNoError = `请输入${CertTypesMap[data.certType]}号`
		} else {
			if ((data.certType === '70' && !RegExpUtils.checkGangAoTaiIDCard(data.certNo))) {
				errorData.certNoError = `请输入正确的${CertTypesMap[data.certType]}号`
			} else if (!RegExpUtils.checkLetterAndNumber(data.certNo)) {
				errorData.certNoError = `请输入正确的${CertTypesMap[data.certType]}号`
			}
		}
		// const imgCount = TwoPictureCertTypeList.includes(data.certType || '') ? 2 : 1
		const imgCount = 1;
		if (data.imgData?.length < imgCount || data.imgData.find((item) => !item.ossUrl)) {
			errorData.imgDataError = '请先上传证件照片'
		}
		if (!data.namePinYin) {
			errorData.namePinYinError = '请输入姓名拼音'
		} else if (!RegExpUtils.checkNamePinYin(data.namePinYin)) {
			errorData.namePinYinError = '请输入正确的姓名拼音（只支持字母+空格）'
		}
		if (!data.gender) {
			errorData.genderError = '请选择性别'
		} else if (!checkSafeguardGender(safeguardLimitData, data.gender)) {
			//校验性别
			errorData.genderError = '性别不符合投保要求'
		}
		if (!data.birthday) {
			errorData.birthdayError = '请选择出生日期'
		} else {
			//校验年龄
			const checkResult = checkSafeguardAge(safeguardLimitData, data.birthday)
			errorData.birthdayError = checkResult.error
		}
	}
}

// 中文转拼音
const zhToPin = zh => {
	let arr: any = [];
	if (zh) {
		arr = Pinyin(zh, {style: Pinyin.STYLE_NORMAL}).flat();
		arr = arr.map(item => {
			item = item.substring(0, 1).toUpperCase() + item.substring(1, item.length);
			return item;
		});
	}
	return arr.join(' ');
};

const checkInsuredBaseData = (data: IInsureForm, limit: IInsuredLimit, safeguardLimitData: ISafeguardLimitData) => {

	let errorData = {
		nameError: '',
		namePinYinError: '',
		certTypeError: '',
		certNoError: '',
		mobileError: '',
		relationError: '',
		birthdayError: '',
		genderError: '',
		imgDataError: '',
		secondCertNoError: '',
		medicalError: '',
		deductibleError: '',
		occupationError: '',
		expensiveHospitalError: '',
		ruleError: ''
	};

	if (!data.relation) {
		errorData.relationError = '请补充与投保人关系';
	}

	if (!data.name) {
		errorData.nameError = '请补充姓名'
	} else if (data.name.length < 2) {
		errorData.nameError = '姓名不符合投保要求'
	}

	if (!limit?.certTypes?.includes(data.certType)) {
		errorData.certTypeError = '当前证件类别不支持投保'
	} else if (data.certType === '01') {
		if (!data.certNo) {
			errorData.certNoError = '请补充身份证号'
		} else if (!RegExpUtils.checkIdCard(data.certNo)) {
			errorData.certNoError = '身份证号不正确'
		} else {
			if (!checkSafeguardGender(safeguardLimitData, data.gender, data.certNo)) {
				errorData.certNoError = '性别不符合投保要求'
			} else {
				const checkResult = checkSafeguardAge(safeguardLimitData, data.birthday, data.certNo)
				errorData.certNoError = checkResult.error
			}
		}
	} else {
		if (!data.certNo) {
			errorData.certNoError = `请补充${CertTypesMap[data.certType]}号`
		} else {
			if ((data.certType === '70' && !RegExpUtils.checkGangAoTaiIDCard(data.certNo))) {
				errorData.certNoError = `${CertTypesMap[data.certType]}号不正确`
			} else if (!RegExpUtils.checkLetterAndNumber(data.certNo)) {
				errorData.certNoError = `${CertTypesMap[data.certType]}号不正确`
			}
		}
		// const imgCount = TwoPictureCertTypeList.includes(data.certType || '') ? 2 : 1
		const imgCount = 1;
		if (data.imgData?.length < imgCount || data.imgData.find((item) => !item.ossUrl)) {
			errorData.imgDataError = '请补充证件照片'
		}
		if (!data.namePinYin) {
			errorData.namePinYinError = '请补充姓名拼音'
		} else if (!RegExpUtils.checkNamePinYin(data.namePinYin)) {
			errorData.namePinYinError = '姓名拼音不正确（只支持字母+空格）'
		}
		if (!data.gender) {
			errorData.genderError = '请补充性别'
		} else if (!checkSafeguardGender(safeguardLimitData, data.gender)) {
			//校验性别
			errorData.genderError = '性别不符合投保要求'
		}
		if (!data.birthday) {
			errorData.birthdayError = '请补充出生日期'
		} else {
			//校验年龄
			const checkResult = checkSafeguardAge(safeguardLimitData, data.birthday)
			errorData.birthdayError = checkResult.error
		}
	}

	// 判断第二证件类型+姓名拼音
	// insuredSecondCertTypeSupportLimit &&
	if (data.certType === '01') {
		if (data.secondCertNo && !RegExpUtils.checkLetterAndNumber(data.secondCertNo)) {
			errorData.secondCertNoError = `${CertTypesMap[data.secondCertType]}号不正确`;
		}
		if (data.namePinYin && !RegExpUtils.checkNamePinYin(data.namePinYin)) {
			errorData.namePinYinError = `姓名拼音不正确（只支持字母+空格）`;
		}
	}
	// 判断职业
	if (limit?.supportOccupationLimit && !data.occupation?.code) {
		errorData.occupationError = '请补充职业信息';
	}
	// 判断医保
	if (!checkSafeguardSocialSecurity(safeguardLimitData, data.medical)) {
		errorData.medicalError = '请补充医保信息';
	}
	// 判断免赔额
	if (!checkSafeguardDeductible(safeguardLimitData, data.deductible)) {
		errorData.deductibleError = '请补充免赔额信息';
	}
	// 判断昂贵医院
	if (!checkExpensiveHospital(data.expensiveHospital)) {
		errorData.expensiveHospitalError = '请补充昂贵医院信息';
	}

	errorData.ruleError = errorData.relationError || errorData.nameError || errorData.namePinYinError || errorData.certTypeError || errorData.certNoError
		|| errorData.mobileError || errorData.imgDataError || errorData.birthdayError || errorData.genderError || errorData.secondCertNoError
		|| errorData.occupationError || errorData.medicalError || errorData.deductibleError || errorData.expensiveHospitalError;

	if (errorData.ruleError) {
		return {
			flag: false,
			errorData: errorData
		}
	}

	return {
		flag: true,
		errorData: errorData
	}

}

const getSimpleUUID = () => {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		let r = Math.random() * 16 | 0;
		let v = c === 'x' ? r : (r & 0x3 | 0x8);
		return v.toString(16);
	});
}

const openH5ProductDetailPage = () => {
	const pageUrl = `${H5_PAGE_URL}/detail${window.location.search}`;
	console.log('pageUrl', pageUrl);
	window.location.href = pageUrl;
}

const checkExpensiveHospital = (value: string) => {
	const showExpensiveHospital = getGlobalData('showExpensiveHospital');
	return !!(!showExpensiveHospital || value);
}

export {
	getLBSInfo,
	getInitPlanIndex,
	getBirthdayFromIdCard,
	getGenderByIdCard,
	getStoreyTrialParams,
	getStoreyOrderParams,
	getMergeSocialSecurityList,
	getMergeDeductibleList,
	getMergeGenderList,
	getMergeAgeList,
	checkInsuredInfoCanCalculatePremium,
	checkApplicationData,
	checkInsuredData,
	zhToPin,
	checkInsuredBaseData,
	getSimpleUUID,
	openH5ProductDetailPage,
	checkExpensiveHospital
}
