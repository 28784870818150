import React, { useState, useEffect } from "react";
import "./index.scss";
import erweima from "../../img/erweima-1.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { ILink } from "lib/types";

import { createCodeUtil } from "lib/utils";
interface IProps {
  link: any;
  text: any;
  recommendProductionAllowPolicyType: any;
}
const BuyBtn: React.FC<IProps> = (props: IProps) => {
  const { text, link, recommendProductionAllowPolicyType } = props;
  const [isShowCode, setIsShowCode] = useState(false);
  const navigate = useNavigate();

  let { search } = useLocation();
  useEffect(() => {
    if (isShowCode) {
      createCode();
    }
  }, [isShowCode]); // eslint-disable-line react-hooks/exhaustive-deps

  const showCode = () => {
    setIsShowCode(true);
  };
  const createCode = () => {
    createCodeUtil(link, search, "canvas", 160);
  };
  const removeCode = () => {
    setIsShowCode(false);
  };

  //立即投保
  const goInsure = () => {
    navigate(link);
  };
  return (
    <>
      {text === "扫码投保" ? (
        <div
          className="buy_btn_wrap flex-between hander noselect"
          onMouseEnter={showCode}
          onMouseLeave={removeCode}
        >
          {!(recommendProductionAllowPolicyType === 1) && (
            <p className="title">{text}</p>
          )}
          <img src={erweima} alt="icon" className="icon" />
          {isShowCode && (
            <div className="qrcode">
              <canvas id="canvas"></canvas>
              <p className="text">微信扫码，立即询价</p>
              <div className="arrow"></div>
            </div>
          )}
        </div>
      ) : (
        <div
          className="buy_btn_wrap flex-between hander noselect"
          onClick={goInsure}
        >
          <p className="title">{text}</p>
        </div>
      )}
    </>
  );
};

export default BuyBtn;
