import React, { memo } from 'react';
import './index.scss';

interface IProps {
	title: string,
	desc?: string,
	children: React.ReactNode
}

const OrderModule = (props: IProps) => {

	const {title, desc, children} = props;

	return (
		<div className='order-module'>

			<div className='order-module-title-tag' />

			<div className='order-module-title-wrap'>
				<span className='order-module-title'>{title}</span>
				{
					desc &&
					<span className='order-module-desc'>{desc}</span>
				}
			</div>

			{children}

		</div>
	);
};

export default memo(OrderModule);
