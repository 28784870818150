import React, { useEffect, useState } from 'react';
import './index.scss';
import { Form, Input, Button, message, Select, Radio, Row, Col } from 'antd';
import { fetchSmsCode, fetchDissentSubmit } from 'lib/smsCodeApi';
import { useNavigate } from 'react-router-dom';
import { idCardHandler } from 'lib/utils';

const COUNTNUM = 60;
const { Option } = Select;
const { TextArea } = Input;
const DissentForm: React.FC = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [isSendingCode, setIsSendingCode] = useState(false);
  const [second, setSecond] = useState(COUNTNUM);
  const [isShowSlide, setIsShowSlide] = useState(false);
  const [verifyCode, setVerifyCode] = useState();
  const [loading, setLoading] = useState(false);
  const [identityType, setIdentityType] = useState('01')
  const submitQuery = () => {
    form.validateFields().then(async (fieldsValue) => {
      setLoading(true);
      fetchDissentSubmit(fieldsValue)
        .then((res: any) => {
          message.success('信息登记成功');
          navigate(-1);
        })
        .catch((err: any) => {
          message.error(err.desc);
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };
  const handleChange = (value) =>{
    setIdentityType(value)
  }
  const prefixSelector = (
    <Form.Item
      name='identityType'
      noStyle
      rules={[{ required: true, message: '请选择证件类型' }]}
      initialValue={'01'}
    >
      <Select style={{ width: 200 }} onChange={handleChange}>
        <Option value='01'>身份证</Option>
        <Option value='07'>中国护照</Option>
        <Option value='09'>港澳居民来往内地通行证</Option>
        <Option value='10'>台湾居民来往大陆通行证</Option>
        <Option value='99'>统一社会信用代码</Option>
      </Select>
    </Form.Item>
  );
  
  const handleCodeSend = () => {
    if (isSendingCode) {
      return;
    }
    form.validateFields(['mobileNo']).then(async () => {
      setIsSendingCode(true);
      const phone = form.getFieldValue('mobileNo');
      try {
        const result = await fetchSmsCode({
          phone,
          type: 'OBJECTION_REGIST',
          verifyCode,
        });
        if (result) {
          countDown();
        }
      } catch (e) {
        message.error(e?.desc);
        setIsSendingCode(false);
        showSlide();
      }
    });
  };
  let countDownTimer: any;
  const clearCountDownTimer = () => {
    if (countDownTimer) {
      clearTimeout(countDownTimer as number);
      countDownTimer = undefined;
    }
  };

  const countDown = () => {
    setSecond((prev) => {
      const current = --prev;
      if (current === 0) {
        setIsSendingCode(false);
        clearCountDownTimer();
        return COUNTNUM;
      }
      countDownTimer = setTimeout(() => {
        countDown();
      }, 1000);
      return current;
    });
  };

  const showSlide = () => {
    setIsShowSlide(true);
    // @ts-ignore
    initJdSlide(
      {
        id: 'JdSlide-jdaz',
        appId: '1740b585a56',
        scene: 'embed',
        product: 'embed',
      },
      function (obj: any) {
        let validate = obj.getValidate();
        try {
          setVerifyCode(validate);
        } catch (err) {}
      }
    );
  };

  useEffect(() => {
    setTimeout(() => {
      showSlide();
    }, 500);

    return () => {
      clearCountDownTimer();
    };
  }, []);
// pattern: new idCardHandler().realChecker()

const validatorFun = (rule, value, callback) => {
  if (!value) {
    callback('请输入证件号')
  }
  if (!new idCardHandler().realChecker(value)) {
    callback('请输入正确的证件号')
  }
  callback()
}
  return (
    <div className='dissent-form-wrap'>
      <h2 className='title'>异议登记</h2>
      <div className='content_wrap'>
        <Form
          form={form}
          name='advanced_search'
          labelCol={{ span: 3 }}
          labelAlign='left'
          size='large'
        >
          <Form.Item name='policyNo' label='保单号'>
            <Input allowClear></Input>
          </Form.Item>
          <Form.Item
            name='name'
            label='姓名'
            rules={[{ required: true, message: '请输入姓名' }]}
          >
            <Input allowClear></Input>
          </Form.Item>
          <Form.Item 
            name='identityNo'
            label='证件号'
            rules={[
              // { required: true, message: '请输入证件号' },
              // identityType === '01' ? {
              //   pattern: new RegExp(/^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9X]$/),
              //   message: '请输入正确的证件号',
              //   validateTrigger: 'onBlur',
              // } : {}
              { validator: validatorFun }
            ]}
            validateTrigger={['onBlur', 'onChange']}
          >
            <Input
              addonBefore={prefixSelector}
              style={{ width: '100%' }}
              allowClear
            ></Input>
          </Form.Item>
          <Form.Item
            name='mobileNo'
            label='手机号'
            rules={[
              { required: true, message: '请输入手机号' },
              {
                min: 11,
                max: 11,
                message: '请输入正确的手机号',
                validateTrigger: 'onBlur',
              },
            ]}
            validateTrigger={['onBlur', 'onChange']}
          >
            <Input allowClear></Input>
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 3 }}>
            {isShowSlide && (
              <Row>
                <div className='query__form-container-slide-wrapper'>
                  <div id='JdSlide-jdaz' className='JDValidate-wrap' />
                </div>
              </Row>
            )}
          </Form.Item>
          <Form.Item
            name='verifyCode'
            label='验证码'
            rules={[{ required: true, message: '请输入验证码' }]}
          >
            <Row gutter={12}>
              <Col flex='370px'>
                <Input maxLength={6} allowClear />
              </Col>
              <Col>
                <Button
                  type='default'
                  onClick={handleCodeSend}
                  disabled={verifyCode ? false : true}
                  className='code_btn'
                >
                  {isSendingCode ? `${second}s` : '获取验证码'}
                </Button>
              </Col>
            </Row>
          </Form.Item>

          <Form.Item name='type' label='异议类型' initialValue={'01'}>
            <Radio.Group>
              <Radio value='01'>承保</Radio>
              <Radio value='02'>理赔</Radio>
              <Radio value='03'>互联网保险业务违法犯罪举报渠道</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name='content'
            label='提交内容'
            rules={[{ required: true, message: '请输入内容' }]}
          >
            <TextArea rows={4} />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 3 }}>
            <Button
              htmlType='submit'
              onClick={submitQuery}
              type='primary'
              className='btn_secondary_z'
              loading={loading}
            >
              提 交
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default DissentForm;
