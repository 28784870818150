import React, { useEffect, useState } from 'react';
import './index.scss';
import { Pagination } from 'antd';
import 'antd/dist/antd.css';
import { fetchFileList } from 'lib/websiteApi';
import { previewPdf } from 'lib/utils';
import { IFileItem } from 'lib/types';
import emptyPng from 'static/imgs/empty_page.png';
const pageSize = 10;
const ConnectedInfo: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    getData();
  }, []);

  const [activeIndex, setActiveIndex] = useState(161);
  const [pageIndex, setPageIndex] = useState(1);
  const [total, setTotal] = useState(0);
  const [data, setData] = useState<IFileItem[]>([]);
  // 个人人身险信息  产品目录 产品条款及费率  产品停售情况
  const tabTitleList = [
    {
      id: 161,
      title: '产品目录',
    },
    {
      id: 162,
      title: '产品条款及费率',
    },
    {
      id: 163,
      title: '产品停售情况',
    }
  ];

  const onChange = (page: any) => {
    setPageIndex(page);
    getData(page);
  };

  const tabChange = (id: number) => {
    setActiveIndex(id);
    getData(1, id);
  };

  const getData = async (pageNum?: number, tabIndex?: number) => {
    const params = {
      fileItem1Code: '016',
      fileItem2Code: tabIndex ? tabIndex : activeIndex,
      pageIndex: pageNum ? pageNum : pageIndex,
      pageSize: pageSize,
    };
    try {
      const result: any = await fetchFileList(params);
      setData(result.list);
      setTotal(result.total);
      setPageIndex(result.pageIndex);
    } catch (e) {}
  };

  return (
    <div className='person-info-wrap'>
      <h2 className='title'>个人人身险信息披露</h2>
      <div className='tab_wrap'>
        {tabTitleList.map((item) => (
          <div
            className={activeIndex === item.id ? 'tab_item active' : 'tab_item'}
            key={item.id}
            onClick={() => tabChange(item.id)}
          >
            {item.title}
          </div>
        ))}
      </div>
      {data.length > 0 ? <>
      {activeIndex === 162 ? 
      <div className='box'>
        <div className='box_header'>
          <div className='header_item'>序号</div>
          <div className='header_item body_item_border'>保险条款说明</div>
          <div className='header_item'>费率说明</div>
        </div>
        {data.map((item, index) => (
          <div className='box_body' key={index}>
            <div className='body_item'>{index + 1}</div>
            <div className='body_item body_item_border' onClick={() => previewPdf(item.fileInfoEntityList && item.fileInfoEntityList[0].fileOssUrl)}>{item.fileInfoEntityList && item.fileInfoEntityList[0].fileName}</div>
            <div className='body_item' onClick={() => previewPdf(item.fileInfoEntityList && item.fileInfoEntityList[1].fileOssUrl)}>立即查看</div>
          </div>
        ))}
       </div> : <div className='p_wrap'>
        {data.map((item) => (
          <div
            key={item.fileId}
            className='line_item'
            onClick={() => {
              previewPdf(item.url);
            }}
          >
            <div className='line_title'>{item.fileName}</div>
            <div className='line_date'>{item.dateLabel}</div>
          </div>
        ))}
      </div>}
      <Pagination
        defaultCurrent={pageIndex}
        total={total}
        current={pageIndex}
        onChange={onChange}
        pageSize={pageSize}
        showSizeChanger={false}
      /> </>: 
      <div className='empty-info-wrap'>
      <div className='empty_wrap'>
        <img src={emptyPng} alt='' />
        <p>暂无内容哦，敬请期待～</p>
      </div>
    </div>}
    </div>
  );
};

export default ConnectedInfo;
