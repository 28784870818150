import QRCode from "qrcode";
import { ONLINEURL } from "lib/config";
import { ILink } from "lib/types";

export const createCodeUtil = (
  linkParams: ILink,
  urlParams: string,
  id: string,
  width: number
) => {
  let url = "";

  const { type, link, linkParam } = linkParams || {};
  const productSearchParams = searchStr2Object(linkParam);
  const urlSearchParams = getUrlSearchParams(urlParams);
  const defaultSerchParams = {
    jdaz_source: "PC",
  };

  console.log(linkParams, urlParams);

  let newProductLinkObject = Object.assign(
    {},
    defaultSerchParams,
    productSearchParams,
    urlSearchParams
  );
  let newProductLinkStr = "";

  for (let key in newProductLinkObject) {
    // @ts-ignore
    newProductLinkStr += "&" + key + "=" + newProductLinkObject[key];
  }

  if (type === "product") {
    url = `${ONLINEURL}?p=${link}${newProductLinkStr}`;
  } else if (type === "wxMiniPage") {
    url = link;
  } else if (type === "0") {
    url = `${ONLINEURL}?p=${link}${newProductLinkStr}`;
  } else if (type === "1") {
    url = link;
  } else {
    url = `${link}${newProductLinkStr}`;
  }

  console.log(url);
  QRCode.toCanvas(
    document.getElementById(id),
    url,
    { width: width },
    function (error) {
      if (error) console.error(error);
    }
  );
};

export const createCodeUtilNew = (
  linkParams: ILink,
  urlParams: string,
  id: string,
  width: number
) => {
  let url = "";

  const { type, link, linkParam } = linkParams || {};
  const productSearchParams = searchStr2Object(linkParam);
  const urlSearchParams = getUrlSearchParams(urlParams);
  const defaultSerchParams = {
    jdaz_source: "PC",
  };

  console.log(linkParams, urlParams);

  let newProductLinkObject = Object.assign(
    {},
    defaultSerchParams,
    productSearchParams,
    urlSearchParams
  );
  let newProductLinkStr = "";

  for (let key in newProductLinkObject) {
    // @ts-ignore
    newProductLinkStr += "&" + key + "=" + newProductLinkObject[key];
  }

  if (type === "product") {
    url = `${ONLINEURL}?p=${link}${newProductLinkStr}`;
  } else if (type === "wxMiniPage") {
    url = link;
  } else {
    url = `${link}${newProductLinkStr}`;
  }

  QRCode.toCanvas(
    document.getElementById(id),
    url,
    { width: width },
    function (error) {
      if (error) console.error(error);
    }
  );
};

// 获取地址栏search参数
const getUrlSearchParams = (search: string) => {
  let searchParams = {};
  if (search) {
    search = search.slice(1);
    searchParams = searchStr2Object(search);
  }
  return searchParams;
};

// search字符串转对象
const searchStr2Object = (str: string) => {
  let searchParams = {};
  if (!str || str.indexOf("=") < 0) {
    return searchParams;
  }

  str.split("&").map((item, index) => {
    let [key, value] = item.split("=");
    // @ts-ignore
    return (searchParams[key] = value);
  });
  return searchParams;
};

// 预览pdf文件
const previewPdf = (url: string) => {
  var elink = document.createElement("a");
  elink.style.display = "none";
  elink.href = url;
  elink.target = "_blank";
  document.body.appendChild(elink);
  elink.click();
};
const isLeapYear = function (year) {
  if (isNaN(year)) {
    // 非数字
    return false;
  } else {
    return (year % 4 == 0 && year % 100 != 0) || year % 400 == 0;
  }
};
class IdCard {
  constructor() {}
  formator(str) {
    const newStr = String(str).replace(/([^\dXx])/g, "");
    return newStr;
  }
  clearFormation(value) {
    return value;
  }
  realChecker(value) {
    const idCardNum = String(value).replace(/\s/g, ""); // 删除所有空格
    const errMsg = "请输入正确的身份证号";
    const prov = {
      11: "北京",
      12: "天津",
      13: "河北",
      14: "山西",
      15: "内蒙古",
      21: "辽宁",
      22: "吉林",
      23: "黑龙江",
      31: "上海",
      32: "江苏",
      33: "浙江",
      34: "安徽",
      35: "福建",
      36: "江西",
      37: "山东",
      41: "河南",
      42: "湖北",
      43: "湖南",
      44: "广东",
      45: "广西",
      46: "海南",
      50: "重庆",
      51: "四川",
      52: "贵州",
      53: "云南",
      54: "西藏",
      61: "陕西",
      62: "甘肃",
      63: "青海",
      64: "宁夏",
      65: "新疆",
      71: "台湾",
      81: "香港",
      82: "澳门",
      91: "国外",
    };
    const len = idCardNum.length;
    let regExp;
    const idChars = idCardNum.split("");
    if (len === 1) {
      return idCardNum !== "0" ? "" : errMsg;
    } else if (len >= 2 && len < 18) {
      return prov[parseInt(idCardNum.substr(0, 2))] ? "" : errMsg;
    } else if (len === 15) {
      // 15位身份证号检测
      if (isLeapYear(parseInt(idCardNum.substr(6, 2)) + 1900)) {
        // 闰年
        regExp =
          /^[1-9][0-9]{5}[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}$/;
      } else {
        // 平年
        regExp =
          /^[1-9][0-9]{5}[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|1[0-9]|2[0-8]))[0-9]{3}$/;
      }
      return regExp.test(idCardNum) ? "" : errMsg;
    } else if (len === 18) {
      console.log("18");
      if (isLeapYear(parseInt(idCardNum.substr(6, 4)))) {
        // 闰年
        regExp =
          /^[1-9][0-9]{5}[1-9][0-9]{3}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}[0-9Xx]$/;
      } else {
        // 平年
        regExp =
          /^[1-9][0-9]{5}[1-9][0-9]{3}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|1[0-9]|2[0-8]))[0-9]{3}[0-9Xx]$/;
      }
      if (regExp.test(idCardNum)) {
        // 基本校验
        let modulus,
          checkCodeList = "10X98765432";
        let sum, code;
        sum =
          (parseInt(idChars[0]) + parseInt(idChars[10])) * 7 +
          (parseInt(idChars[1]) + parseInt(idChars[11])) * 9 +
          (parseInt(idChars[2]) + parseInt(idChars[12])) * 10 +
          (parseInt(idChars[3]) + parseInt(idChars[13])) * 5 +
          (parseInt(idChars[4]) + parseInt(idChars[14])) * 8 +
          (parseInt(idChars[5]) + parseInt(idChars[15])) * 4 +
          (parseInt(idChars[6]) + parseInt(idChars[16])) * 2 +
          parseInt(idChars[7]) +
          parseInt(idChars[8]) * 6 +
          parseInt(idChars[9]) * 3; // 计算校验位
        modulus = sum % 11;
        code = checkCodeList.substr(modulus, 1); // 找到校验位

        return code == idChars[17];
      } else {
        return errMsg;
      }
    } else {
      return "";
    }
  }
}
const idCardHandler = IdCard;

//现有链接的链接环境模式
// const LINK_MODE = window.location.origin
const LINK_MODE = window.location.origin

// 跳转保险商城列表
const LINK_BXSC = window.location.origin + '/bxsc'

const LINK_TO_BXSC = ()=>{
  window.location.href = LINK_BXSC
}


export { getUrlSearchParams, searchStr2Object, previewPdf, idCardHandler, LINK_MODE,LINK_TO_BXSC };
