import React, { memo } from 'react';
import './index.scss';
import InsDialog from '@/order-components/ins-dialog'
import { Button } from 'antd'

interface IProps {
	show: boolean,
	onClose: () => void
}

const privatePdfUrl = 'https://img03.az.jd.com/ins-user-m/develop/pdf/private.pdf';

const InsureNoticeDialog = (props: IProps) => {

	const {show, onClose} = props;

	const handlePrivateClick = () => {
		window.open(privatePdfUrl);
	}

	return (
		<InsDialog
			show={show}
			onClose={onClose}
			location={'bottom'}
			disableOverlayClose={true}
			// transparent={true}
		>
			<div className='insure-notice-dialog'>
				<div className='v-notice-ctn'>
					<p className='top-text-notice'>消费者权益保障服务</p>
					<div className='insure-confirm-desc'>
						<span>您已进入投保流程，请您仔细阅读保险条款、责任免除、投保须知等内容，重点阅读并知晓</span>
						<span className='insure-confirm-link' onClick={handlePrivateClick}>《京东安联保险隐私政策》</span>
						<span>，我们将会安全记录您的操作轨迹。</span>
					</div>
					<Button
						className='insure-confirm-button'
						type='primary'
						onClick={onClose}
					>
						我已阅读并确认以上内容
					</Button>
				</div>
			</div>
		</InsDialog>
	);
};

export default memo(InsureNoticeDialog);
