import React, { useEffect, memo } from 'react';
import './index.scss';

import classNames from 'classnames';

interface IProps {
	children: React.ReactNode,
	show: boolean,
	onClose?: () => void,
	disableOverlayClose?: boolean,
	location?: 'top' | 'bottom' | 'center' | 'left' | 'right',
	transparent?: boolean,
	bgColor?: string,
	alpha?: number // 0~1
}

const justifyContentMap = {
	top: 'flex-start',
	bottom: 'flex-end',
	center: 'center',
	left: 'flex-start',
	right: 'flex-start',
};

function InsDialog(props: IProps) {

	const {
		show, onClose, disableOverlayClose = false,
		location = 'center', transparent, bgColor, alpha = 0.6,
	} = props;

	useEffect(() => {
		if (show) {
			freezeBody();
		} else {
			requestAnimationFrame(() => {
				setTimeout(() => {
					const node = document.getElementsByClassName('ins-dialog-container--active');
					// console.log('node', node.length);
					if (node.length === 0) {
						releaseBody();
					}
				}, 300)
			})
		}
	}, [show])

	const freezeBody = () => {
		try {
			document.body.style.overflow = 'hidden';
		} catch (e) {

		}
	};

	const releaseBody = () => {
		try {
			document.body.style.overflow = 'visible';
		} catch (e) {

		}
	};

	const close = () => {
		if (!disableOverlayClose) {
			onClose && onClose();
		}
	};

	const fixedPage = (e) => {
		try {
			e.stopPropagation();
			e.preventDefault();
		} catch (e) {

		}
	};

	return (
		<div
			className={classNames('ins-dialog-container', {'ins-dialog-container--active': show})}
			style={{
				justifyContent: justifyContentMap[location],
			}}
		>
			<div
				className='ins-dialog-overlay'
				style={{
					background: transparent ? 'transparent' : bgColor || `rgba(0, 0, 0, ${Math.min(1, Math.max(alpha, 0))})`,
				}}
				onClick={close}
				onTouchMove={fixedPage}
				onMouseMove={fixedPage}
			/>
			<div className={classNames('ins-dialog-ctn', {
				[`location-${location}`]: true,
			})}>
				{
					props.children
				}
			</div>
		</div>
	);
}

export default memo(InsDialog);
