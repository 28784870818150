import React, { memo, useEffect, useRef } from 'react';
import './index.scss';

import classNames from 'classnames';

interface IProps {
	className?: string
	children?: React.ReactNode,
	lowerThreshold?: number,
	onScrollToLower?: () => void, // 滚动到底部时回调
	data?: any,
	onOnePage?: () => void // 内容少于一屏时回调
}

// 防抖函数
function debounce(func, wait) {
	let timeout;
	return function () {
		const context = this;
		const args = arguments;
		clearTimeout(timeout);
		timeout = setTimeout(() => {
			func.apply(context, args);
		}, wait);
	};
}

const InsScrollView = (props: IProps) => {

	const {className, children, lowerThreshold = 50, onScrollToLower, data, onOnePage} = props;

	const scrollViewRef = useRef(null);
	const ctnRef = useRef(null);

	useEffect(() => {
		if (data) {
			requestAnimationFrame(() => {
				setTimeout(() => {
					if (scrollViewRef.current && ctnRef.current) {
						if (ctnRef.current.offsetHeight < scrollViewRef.current.offsetHeight) {
							console.log('内容少于一屏');
							onOnePage && onOnePage();
						}
					}
				}, 1000)
			})
		}
	}, [data])

	const handleOnScroll = () => {
		if (scrollViewRef?.current) {
			const totalHeight = scrollViewRef.current.scrollHeight;
			const viewportHeight = scrollViewRef.current.offsetHeight;
			const scrolledHeight = scrollViewRef.current.scrollTop;
			if (scrolledHeight + viewportHeight >= (totalHeight - lowerThreshold)) {
				console.log('滚动到底部了');
				onScrollToLower && onScrollToLower();
			}
		}
	}

	// 防抖
	const debouncedScroll = debounce(handleOnScroll, 200);

	return (
		<div
			ref={scrollViewRef}
			className={classNames('ins-scroll-view', className)}
			onScroll={debouncedScroll}
		>
			<div ref={ctnRef} className='ins-scroll-view-ctn'>
				{children}
			</div>
		</div>
	)

}

export default memo(InsScrollView);
