import React from 'react';
import './index.scss';
import { data1 } from './staticData';
import feeback_png from 'static/imgs/feeback.png';
import phone3 from 'static/imgs/phone3.svg';
import mail from 'static/imgs/mail.svg';
import { useNavigate } from 'react-router-dom';
const ServePromise: React.FC = () => {
  const navigate = useNavigate();
  const goFormPage = () => {
    navigate('/suggestion/dissentForm');
  };
  return (
    <div className='fee-back-wrap'>
      <h2 className='title'>意见与反馈</h2>
      <div className='content_wrap'>
        <div className='header_img'>
          <img src={feeback_png} alt='' />
        </div>
        <p className='fee_desc'>
          公司一贯承诺为我们的客户提供快速的、透明的、高质量的服务，您的意见和建议是我们不懈改进客户服务的动力来源，我们会尽快的回复您的意见和建议。
        </p>

        <div className='fee_line'></div>

        {data1.map((item, index) => (
          <div key={index}>
            <h3 className='sub_title'>{item.title}</h3>
            {item.value.map((subItem, subIndex) => (
              <p className='sub_title_desc' key={subIndex}>
                {subItem}
              </p>
            ))}
          </div>
        ))}

        <div className='fee_desc_2'>
          希望京东安联保险能给您生活和工作带来愉快！
        </div>

        <div className='fee_btn' onClick={goFormPage}>
          异议登记
        </div>
      </div>
      <div className='box2'>
        <div className='box2_item'>
          <img src={phone3} alt='icon' />
          <div className='header_wrap'>
            <div className='top'>全国热线</div>
            <div className='bottom fs_20'>950610</div>
          </div>
        </div>
        <div className='divi_line'></div>
        <div className='box2_item'>
          <img src={mail} alt='icon' />
          <div className='header_wrap'>
            <div className='top'>举报邮箱</div>
            <div className='bottom fs_14'>
              <a href='mailto:Contact@allianz.cn' className='link'>
                Contact@allianz.cn
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServePromise;
