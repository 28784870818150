import React, {useEffect,useRef} from 'react';
import defaultImg from 'static/imgs/default_p.png'
const io = new IntersectionObserver(
  entries =>{
    entries.forEach(item=>{
      // @ts-ignore
      if(item.intersectionRatio && item.target.src !== item.target.getAttribute('_src')){
        // @ts-ignore
        item.target.src = item.target.getAttribute('_src');
      }
    })
  }
)
interface IProps {
  src:string;
  alt ?: string;
  className ?: string;
}
const LazyImg = (props: IProps) => {
  const ref: any = useRef(null);
  useEffect(() => {
    if (ref.current) {
      io.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        io.unobserve(ref.current)// eslint-disable-line react-hooks/exhaustive-deps
      }
    }
  }, [props.src])
  // @ts-ignore
  return <img ref={ref} _src={props.src} src={defaultImg} alt={props.alt || ''} className={props.className}></img>
};

export default LazyImg;




