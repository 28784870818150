import React, { useEffect, useState } from "react";
import "./index.scss";
import { data1 } from "./staticData";
import bumenPng from "static/imgs/banner20240125.png";
import { companyGovernQuery } from "../../../lib/websiteApi";
const CompanyGovernance: React.FC = () => {
  const [queryData, setQueryData] = useState<any>({});
  const [shareholdings, setShareholdings] = useState<any>([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    companyGovernQuery({}).then((res: any) => {
      setQueryData(res);
      const shareholdingsJson = JSON.parse(res.shareholdings);
      console.log(res, shareholdingsJson);
      setShareholdings(shareholdingsJson);
    });
  }, []);

  return (
    <div className="company-info-wrap">
      <h2 className="title">公司治理概要</h2>
      <h3 className="sub_title">持股比例在5%以上的股东及其持股情况</h3>
      <div className="box_4">
        <div className="box_4_header">
          <div className="header_item">序号</div>
          <div className="header_item body_item_border">股东名称</div>
          <div className="header_item">持股比例</div>
        </div>

        {Array.isArray(shareholdings) &&
          shareholdings.map((item, index) => (
            <div className="box_4_body" key={index}>
              <div className="body_item">{index + 1}</div>
              <div className="body_item body_item_border">{item.last}</div>
              <div className="body_item">{item.cell}</div>
            </div>
          ))}
      </div>
      <h3 className="sub_title">历年股东会决议</h3>
      <div className="box_3">
        {queryData &&
          Array.isArray(queryData.shareholdersMeetingsOfPastYears) &&
          queryData?.shareholdersMeetingsOfPastYears.map((item: any) => {
            return (
              <p className="p_box">
                <a
                  target="_blank"
                  href={item.governInfo}
                  className="link"
                  rel="noopener noreferrer"
                >
                  <span>{item.governName}</span>
                </a>
              </p>
            );
          })}
      </div>

      <h3 className="sub_title">董事简历</h3>
      <div className="box_3">
        {queryData &&
          Array.isArray(queryData.biographiesOfDirectors) &&
          queryData?.biographiesOfDirectors.map((item: any) => {
            return (
              <p className="p_box">
                <a
                  target="_blank"
                  href={item.governInfo}
                  className="link"
                  rel="noopener noreferrer"
                >
                  <span>{item.governName}</span>
                </a>
              </p>
            );
          })}
      </div>

      <h3 className="sub_title">监事简历</h3>
      <div className="box_3">
        {queryData &&
          Array.isArray(queryData.curriculumVitaeOfSupervisors) &&
          queryData?.curriculumVitaeOfSupervisors.map((item: any) => {
            return (
              <p className="p_box">
                <a
                  target="_blank"
                  href={item.governInfo}
                  className="link"
                  rel="noopener noreferrer"
                >
                  <span>{item.governName}</span>
                </a>
              </p>
            );
          })}
      </div>

      <h3 className="sub_title">高级管理人员简历、职责及其履职情况</h3>
      <div className="box_3">
        {queryData &&
          Array.isArray(queryData.curriculumVitaeOfSeniorExecutives) &&
          queryData?.curriculumVitaeOfSeniorExecutives.map((item: any) => {
            return (
              <p className="p_box">
                <a
                  target="_blank"
                  href={item.governInfo}
                  className="link"
                  rel="noopener noreferrer"
                >
                  <span>{item.governName}</span>
                </a>
              </p>
            );
          })}
      </div>

      <h3 className="sub_title">公司部门设置情况</h3>
      <div className="box_3">
        {queryData &&
          Array.isArray(queryData.theCompanyDepartmentSetup) &&
          queryData?.theCompanyDepartmentSetup.map((item: any) => {
            return (
              <img src={item.governInfo} alt="图片" className="img_w100" />
            );
          })}
      </div>
    </div>
  );
};

export default CompanyGovernance;
