import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./index.scss";
import IceRight from "common/img/ic_right.svg";
import BuyBtn from "../BuyBtnNew";
import { Button } from "antd";

type ProductItemProps = {
  prokind: any;
};
const ProductItem: React.FC<ProductItemProps> = ({ prokind }) => {
  const navigate = useNavigate();

  const goOut = (link: any, type: any) => {
    if (type === "1") {
      window.open(link, "_blank");
    } else {
    }
  };

  //内链和外链跳转url
  const getJumpUrl = (type: any, link: any, id: any) => {
    let url = "";
    if (type === "1") {
      url = link;
      window.open(link, "_blank");
    } else {
      const url = `${window.location.origin}/bxsc/product/detail?p=${id}&jdaz_chl=360&jdaz_source=PC`;
      window.location.href = url

    }
    return url;
  };

  useEffect(() => {
    console.log(prokind);
  }, [prokind]);
  return (
    <>
      {prokind.length > 0 && (
        <div className="product_item">
          {prokind.map((item: any, index: number) => (
            <div className="list_left" key={index}>
              <div className="list_left_img">
                <img src={item.storeImageUrl} alt="" />
              </div>
              <div className="list_bigTitle">{item.productionName}</div>
              <div className="list_desc">
                {item.storeReasonDesc &&
                  item.storeReasonDesc.split(";").map((label, index) => (
                    <div className="desc_item">
                      <div className="desc_img">
                        <img src={IceRight} alt="" />
                      </div>
                      {<span key={index}>{label}</span>}
                    </div>
                  ))}
              </div>

              {/* 产品-展示二维码 */}

              <div className={item.openType === "scan" ? "list_do" : ""}>
                {item.openType === "scan" ? (
                  <>
                    <div className="list_price flex-end">
                      <div> {item.productionBottomPrice}</div>
                      <div className="price_start">{"元/起"}</div>
                    </div>
                    <div style={{ display: "flex" }}>
                      {item.storeProductionAllowPolicyType === 1 && (
                        <div style={{ marginRight: "10px" }}>
                          <Button
                            className="jumpBtn"
                            onClick={() =>
                              getJumpUrl(
                                item.productionType,
                                item.productionLink,
                                item.productionId
                              )
                            }
                          >
                            <span className="title">立即投保</span>
                          </Button>
                        </div>
                      )}
                      <BuyBtn
                        recommendProductionAllowPolicyType={
                          item.storeProductionAllowPolicyType
                        }
                        text={"扫码投保"}
                        link={{
                          type: item.productionType,
                          link:
                            item.productionType === "1"
                              ? item.productionLink
                              : item.productionId,
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <div className="list_do_2">
                    <div
                      className="buy_btn_wrap flex-center hander noselect"
                      onClick={() =>
                        goOut(item.productionLink, item.productionType)
                      }
                    >
                      <p className="title">查看更多</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};
export default ProductItem;
