import React, { useEffect, useState } from 'react';
import './index.scss';
// import news_banner from 'static/imgs/news_banner.png';
import news_banner from 'static/imgs/news_banner3.jpg';
import news_banner_font from 'static/imgs/news_banner_font.png';
import { useNavigate } from 'react-router-dom';
import { fetchNewsJson } from 'lib/websiteApi';
import { INewsItem } from 'lib/types';
import LazyImg from 'common/components/LazyImg';
import { Pagination } from 'antd';

const NewsPreview: React.FC = () => {
  const navigate = useNavigate();
  const [newsList, setNewsList] = useState<INewsItem[]>([]);
  const [currentNewsList, setCurrentNewsList] = useState<INewsItem[]>([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [total, setTotal] = useState(0);
  useEffect(() => {
    document.title = '京东安联保险-新闻列表';
    window.scrollTo(0, 0);
    getData();
  }, []);

  const goDetailPage = (url: string) => {
    const _url = url.slice(url.indexOf('news/') + 5, url.indexOf('.'))
    // console.log(url, _url)
    // navigate('/news-content/', {
    //   state: {
    //     type: 'news',
    //     url,
    //   },
    // });
    // navigate(`/news-content/news/${_url}`)
    window.location.href = `${window.location.origin}/news-content/news/${_url}`
  };

  const getData = async () => {
    // @ts-ignore
    const url = '/data/news_list_01.json';
    if (url) {
      const result: any = await fetchNewsJson(url);
      setNewsList(result);
      setTotal(result.length);
      if (result && result.length > 0) {
        setCurrentNewsList(result.slice(0, 10));
      }
    }
  };

  const onChange = (page: number) => {
    setPageIndex(page);
    setCurrentNewsList(newsList.slice((page - 1) * 10, page * 10));
  };

  return (
    <div className='news_list_wrap page-content-wrap'>
      <div className='banner_wrap'>
        <img src={news_banner} alt='图片' />
        <div className='font_wrap'>
          <img src={news_banner_font} alt='图片文字' />
        </div>
      </div>

      <div className='news_list'>
        {currentNewsList?.map((newsItem, index) => (
          <div key={index}>
            <div
              className='news_item'
              onClick={() => goDetailPage(newsItem.contentUrl)}
            >
              <div className='img_wrap'>
                <LazyImg src={newsItem.coverImgUrl ?? ''} alt='图片' />
              </div>
              <div className='r_box'>
                <h2 className='title'>{newsItem.title}</h2>
                <p className='date'>{newsItem.publishDate}</p>
                <div className='news_desc ellipsis'>{newsItem.headlines}</div>
              </div>
            </div>
            {/* {index !== currentNewsList.length - 1 && (
              <div className='divider'></div>
            )} */}
            <div className='divider'></div>
          </div>
        ))}

        <Pagination
          defaultCurrent={pageIndex}
          total={total}
          current={pageIndex}
          onChange={onChange}
          pageSize={10}
          showSizeChanger={false}
        />
      </div>
    </div>
  );
};
export default NewsPreview;
