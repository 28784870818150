import React, { memo } from 'react';
import './index.scss';

import classNames from 'classnames'
import icon_radio_normal from '@/static/imgs/order/icon_radio_normal.svg'
import icon_radio_checked from '@/static/imgs/order/icon_radio_checked.svg'
import { ISelectOptionItem } from '@/lib/order/types'

interface IProps {
	className?: string,
	data: ISelectOptionItem,
	checked: boolean,
	disabled?: boolean,
	onChange?: (data) => void
}

const InsRadio = (props: IProps) => {

	const {className, data, checked = false, disabled = false, onChange} = props

	const handleClick = () => {
		if (!disabled && onChange) {
			onChange(data)
		}
	}

	return (
		<div
			id={`ins-radio-${data.value}`}
			className={classNames('ins-radio',
				{
					[`ins-radio--active`]: checked,
					[`ins-radio--disabled`]: disabled
				}, className)}
			onClick={handleClick}
		>
			<img className='v-radio-img' src={checked ? icon_radio_checked : icon_radio_normal} alt={''} />
			<span className='v-radio-text'>{data.label}</span>
		</div>
	)
}

export default memo(InsRadio)
