import { useLocation } from 'react-router-dom';

export const useUrlSearchParams = () => {

	let _UrlSearchParams: any = {};

	const search = useLocation().search;

	if (search) {
		const searchParams = new URLSearchParams(search);
		searchParams.forEach((value: string, key: string) => {
			_UrlSearchParams[key] = value;
		})
	}

	return _UrlSearchParams;
}
