import React, { useEffect, useState, memo } from 'react';
import './index.scss';
import { useNavigate } from 'react-router-dom';
import {
	IDetailAgreementItem,
	IDetailPeriod,
	IDetailPlanItem, IDiscountLimit,
	IInsureForm, INumLimit, IObjectCommonListItem,
	IPackageInfo, IPageCommonConfig, IPlanGroupLimit, IPremium,
	IProductDetail, ISafeguardLimit, ISafeguardLimitData
} from '@/lib/order/types'
import OrderHeader from '../header'
import OrderModule from '../module'
import OrderPolicyholder from '../policyholder'
import OrderInsured from '../insured'
import OrderPeriod from '../period'
import OrderFooter from '../footer'
import InsureNoticeDialog from '../insure-notice-dialog'
import InsLoading from '@/order-components/ins-loading'
import {
	checkApplicationData,
	checkInsuredInfoCanCalculatePremium,
	getMergeAgeList,
	getMergeDeductibleList,
	getMergeGenderList, getMergeSocialSecurityList,
	getStoreyOrderParams, getStoreyTrialParams
} from '@/lib/order/utils'
import HealthNoticeDialog from '../agreement/health-notice-dialog';
import { getStoreyTrial, orderStoreyOrder } from '@/lib/order/apis'
import AgreementConfirmDialog from '../agreement/agreement-confirm-dialog';
import ToastUtils from '@/lib/toast'
import useDebounceList from '@/lib/useDebounceList'
import EventUtils from '@/lib/order/event'
import { INS_EVENT_PRICE } from '@/lib/order/constants'
import { setGlobalData } from '@/lib/global'
import icon_menu from '@/static/imgs/order/icon_menu.svg'
import icon_arrow_right from '@/static/imgs/order/icon_arrow_right.svg'
import { LINK_TO_BXSC } from "lib/utils";
interface IProps {
	productId: string,
	packageInfo: IPackageInfo,
	pageData: IProductDetail,
	pageCommonConfig: IPageCommonConfig
}

interface ICalculatePremiumState {
	periodData: IDetailPeriod,
	insuredList: IInsureForm[],
	currentPlanData: IDetailPlanItem,
	coverageData: string[]
}

const ConfirmTypes = ['tbxz', 'zrmc', 'pfsm', 'bbxrtysm', 'zyts', 'qiangzhiyuedu'];

let currentPlanData: IDetailPlanItem = null; // 计划
let coverageData: string[] = []; // 责任
let policyholder: IInsureForm = null; // 投保人
let insuredList: IInsureForm[] = null; // 被保人
let periodData: IDetailPeriod = null; // 保障期间
let filterConfirmAgreementData: IDetailAgreementItem[] = null; // 协议确认
let healthAllOk = true;
let trialError = '';
let premiumData: IPremium = null; // 保费
let premiumCalculating = false;
let discountLimitData: IDiscountLimit = null;
let optionalCoverageIdList: string[] = [];
let lastCalculatePremiumParamsStr = '';

const OrderForm = (props: IProps) => {

	const {productId, packageInfo, pageData, pageCommonConfig} = props;

	const navigate = useNavigate();

	const [showLoading, setShowLoading] = useState(false);

	const [stateData, setStateData] = useState({
		showInsureNoticeDialog: false, // 投保告知（可回溯）
		showHealthNoticeDialog: false, // 健康告知弹窗
		showAgreementConfirmDialog: false, // 协议确认弹窗
	})

	// 影响算费的数据
	const [calculatePremiumState, setCalculatePremiumState] = useState<ICalculatePremiumState>({
		periodData: null,
		insuredList: [],
		currentPlanData: null,
		coverageData: []
	});

	const [confirmAgreementData, setConfirmAgreementData] = useState<IDetailAgreementItem[]>(null); //确认协议
	const [healthNoticeData, setHealthNoticeData] = useState<IDetailAgreementItem>(null); //健康告知

	const [periodUpdateData, setPeriodUpdateData] = useState('auto');

	const safeguardLimitDebounceData = useDebounceList([coverageData, currentPlanData], 300);

	const calculatePremiumDebounceData = useDebounceList([calculatePremiumState], 500);

	const [safeguardLimitData, setSafeguardLimitData] = useState<ISafeguardLimitData>(null); // 责任限制规则

	useEffect(() => {
		if (pageData) {
			requestAnimationFrame(() => {
				setTimeout(() => {
					setStateData({
						...stateData,
						showInsureNoticeDialog: true
					})
				}, 1000)
			})

			const contracts = pageData.storeyPageData.contract.content;
			if (contracts && contracts.length > 0) {
				filterConfirmAgreementData = contracts.filter(item => {
					return item.checked && (item.isForcedReading || (item.isForcedReading === undefined && ConfirmTypes.includes(item.type)))
				})
				if (pageData.storeyProductionData.insuredInfo.healthReport) {
					setHealthNoticeData(contracts.find(item => item.type === 'jkgz'));
				}
			}

		}
	}, [pageData]);

	useEffect(() => {
		if (currentPlanData) {
			getTargetSafeguardLimitData(currentPlanData.groupId)
			.then((res: any) => {
				setSafeguardLimitData(res);
			})
		}
	}, [safeguardLimitDebounceData])

	useEffect(() => {
		console.log('safeguardLimitData', safeguardLimitData);
	}, [safeguardLimitData])

	useEffect(() => {
		new Promise((resolve) => {
			let premiumPlanCodes: string[] = [];
			if (premiumData) {
				if (premiumData.salePrice) {
					premiumPlanCodes = premiumData.planCode || [];
				} else {
					premiumPlanCodes = [];
				}
			} else {
				premiumPlanCodes = [];
			}

			console.log('premiumPlanCodes', premiumPlanCodes)
			if (filterConfirmAgreementData && filterConfirmAgreementData.length > 0 && premiumPlanCodes) {
				let filterData: IDetailAgreementItem[] = []
				if (premiumPlanCodes.length === 0) {
					filterData.push(...filterConfirmAgreementData)
				} else {
					const oriConfirmAgreementData = Array.from(filterConfirmAgreementData)
					oriConfirmAgreementData.forEach((item: IDetailAgreementItem) => {
						if (item.isProdFactConf && item.prodFactMaterial?.length > 1) {
							filterData.push({
								...item,
								prodFactMaterial: item.prodFactMaterial.filter((prodFactMaterial) => premiumPlanCodes.includes(prodFactMaterial.planCode))
							})
						} else {
							filterData.push(item)
						}
					})
				}
				console.log('confirmAgreement-filterData', filterData)
				setConfirmAgreementData(filterData)
			}
			resolve(true)
		})
	}, [premiumData])

	useEffect(() => {
		if (!premiumCalculating) {
			checkCanCalculatePremium();
		}
	}, [calculatePremiumDebounceData])

	const getTargetSafeguardLimitData = (groupId: string) => {
		return new Promise((resolve) => {
			if (pageData && groupId) {
				const groupLimit = pageData.storeyProductionData.groupLimit
				const {socialSecurity, deductibleLimit, deductibles, ageLimit, ageLimitExist, genderLimit, gender} = pageData.storeyProductionData.insuredInfo
				// console.log('insuredInfoLimit', pageData.storeyProductionData.insuredInfo)
				console.log('groupLimit', groupLimit)
				const insuredLimitInfo = {
					socialSecurityLimit: socialSecurity,
					ageLimit: !!(ageLimitExist && ageLimit && ageLimit.min),
					genderLimit: !!(genderLimit && gender && gender !== '-' && gender !== 'Any'),
					deductibleLimit: !!(deductibleLimit && deductibles)
				}
				const insuredLimitData = {
					SocialSecurity: insuredLimitInfo.socialSecurityLimit ? ['Y', 'N'] : undefined,
					Age: insuredLimitInfo.ageLimit ? [ageLimit] : undefined,
					Gender: insuredLimitInfo.genderLimit ? [gender] : undefined,
					Deductible: insuredLimitInfo.deductibleLimit ? deductibles[groupId] : undefined
				}
				let safeguardLimitList: ISafeguardLimit[] = []
				safeguardLimitList.push(insuredLimitData)
				if (groupLimit) {
					if (!discountLimitData) {
						discountLimitData = groupLimit['ori'];
					}
					// console.log('discountLimitData', discountLimitData)
					if (discountLimitData) {
						const currentPlanGroupLimitData: IPlanGroupLimit = discountLimitData[groupId]
						// 必选保障
						let basicLimitData = currentPlanGroupLimitData['BASIC']
						if (basicLimitData) {
							safeguardLimitList.push(basicLimitData)
						}
						if (optionalCoverageIdList && optionalCoverageIdList.length > 0) {
							// 附加保障
							optionalCoverageIdList.forEach((id) => {
								const optionalLimitData = currentPlanGroupLimitData[id]
								if (optionalLimitData) {
									safeguardLimitList.push(optionalLimitData)
								}
							})
						}
					}
				}

				let socialSecurityLimitList: string[][] = []
				let deductibleLimitList: IObjectCommonListItem[][] = []
				let genderLimitList: string[][] = []
				let ageLimitList: INumLimit[][] = []
				safeguardLimitList.forEach((item) => {
					if (insuredLimitInfo.socialSecurityLimit && item.SocialSecurity) {
						socialSecurityLimitList.push(item.SocialSecurity)
					}
					if (insuredLimitInfo.deductibleLimit && item.Deductible) {
						deductibleLimitList.push(item.Deductible)
					}
					if (insuredLimitInfo.genderLimit && item.Gender) {
						genderLimitList.push(item.Gender)
					}
					if (insuredLimitInfo.ageLimit && item.Age) {
						ageLimitList.push(item.Age)
					}
				})
				// 取交集
				let mergeSafeguardLimit: ISafeguardLimit = {
					SocialSecurity: getMergeSocialSecurityList(socialSecurityLimitList),
					Deductible: getMergeDeductibleList(deductibleLimitList),
					Gender: getMergeGenderList(genderLimitList),
					Age: getMergeAgeList(ageLimitList)
				}
				resolve({
					...insuredLimitInfo,
					limitData: mergeSafeguardLimit
				})
			} else {
				resolve(null)
			}
		})
	}

	const checkCanCalculatePremium = () => {
		if (packageInfo && currentPlanData && periodData && safeguardLimitData) {
			// 判断被保人
			let canCalculatePremium = checkInsuredInfoCanCalculatePremium(pageData.storeyProductionData.insuredInfo, insuredList, safeguardLimitData);
			console.log('canCalculatePremium', canCalculatePremium);
			if (canCalculatePremium) {
				calculatePremium();
				return;
			} else {
				premiumData = null;
				setMinPrice();
			}
		}
	}

	/**
	 * 计算保费
	 */
	const calculatePremium = () => {

		premiumCalculating = true;

		const trialParamsOriginalData = {
			clientToken: packageInfo.clientToken,
			currentPlanData,
			coverageData,
			checkType: 1,
			storeyProductionData: pageData.storeyProductionData,
			insuredList,
			premiumData,
			periodData,
			payMode: 'F'
		}

		const body = getStoreyTrialParams(trialParamsOriginalData);

		const paramsStr = JSON.stringify(trialParamsOriginalData);
		if (lastCalculatePremiumParamsStr && lastCalculatePremiumParamsStr === paramsStr) {
			premiumCalculating = false;
			return;
		}
		lastCalculatePremiumParamsStr = paramsStr;

		getStoreyTrial(body)
		.then((res: any) => {
			premiumCalculating = false;
			trialError = '';
			premiumData = res;
			EventUtils.emit(INS_EVENT_PRICE, {
				type: 'price',
				data: premiumData
			});
		})
		.catch((err: any) => {
			premiumCalculating = false;
			trialError = err?.err || '算费失败';
			ToastUtils.info(trialError);
			premiumData = null;
			setMinPrice();
		})
	}

	/**
	 * 设置最低价格
	 */
	const setMinPrice = () => {
		if (currentPlanData) {
			const oriMinPremiums = pageData.storeyProductionData.minPremiums.ori
			if (oriMinPremiums) {
				const oriMinPremium = oriMinPremiums.find(item => item.groupId === currentPlanData.groupId && item.payModel === 'F')
				if (oriMinPremium) {
					EventUtils.emit(INS_EVENT_PRICE, {
						type: 'minPrice',
						data: oriMinPremium.minPremium
					});
				}
			}
		}
	}

	const handleInsureNoticeDialogClose = () => {
		setStateData({
			...stateData,
			showInsureNoticeDialog: false
		})
	}

	const handlePlanChange = (data: IDetailPlanItem) => {
		currentPlanData = data;
		setCalculatePremiumState({
			...calculatePremiumState,
			currentPlanData: data
		})
	}

	const handleOptionalCoverageChange = (data: string[]) => {
		coverageData = data;
		setCalculatePremiumState({
			...calculatePremiumState,
			coverageData: data
		})
	}

	const handlePolicyholderChange = (data: IInsureForm) => {
		policyholder = data;
		setGlobalData('jdaz-policyholder', policyholder);
		let periodMode = periodUpdateData;
		if (pageData?.storeyProductionData?.start?.manualReview && policyholder?.certType) {
			periodMode = policyholder?.certType !== '01' ? 'manual' : 'auto';
		}
		if (periodMode !== periodUpdateData) {
			periodData = null;
			setPeriodUpdateData(periodMode);
		}
	}

	const handleInsuredListChange = (data: IInsureForm[]) => {
		insuredList = data;
		let periodMode = periodUpdateData;
		if (pageData?.storeyProductionData?.start?.manualReview && insuredList && insuredList.length > 0) {
			const findData = insuredList.find((item) => item.certType !== '01');
			periodMode = findData ? 'manual' : 'auto';
		}
		if (periodMode !== periodUpdateData) {
			periodData = null;
			setPeriodUpdateData(periodMode);
		}
		setCalculatePremiumState({
			...calculatePremiumState,
			insuredList: data
		})
	}

	const handlePeriodDataChange = (data: IDetailPeriod) => {
		console.log('handlePeriodDataChange', data);
		periodData = data;
		setCalculatePremiumState({
			...calculatePremiumState,
			periodData: data
		})
	}

	const handleHealthNoticeDialogClose = () => {
		setStateData({
			...stateData,
			showHealthNoticeDialog: false
		})
	}

	const handleHealthNoticeCallback = (flag: boolean) => {
		healthAllOk = flag;
		if (flag) {
			insuredList.forEach((item) => {
				item.health = 'Y';
			})
			setStateData({
				...stateData,
				showHealthNoticeDialog: false,
				showAgreementConfirmDialog: true
			})
		} else {
			setStateData({
				...stateData,
				showHealthNoticeDialog: false,
			})
			setTimeout(() => {
				ToastUtils.info('被保人不符合健康要求，很抱歉暂时无法投保该产品');
			}, 200)
		}
	}

	const handleAgreementConfirmDialogClose = () => {
		setStateData({
			...stateData,
			showAgreementConfirmDialog: false
		})
	}

	const handleAgreementConfirmDialogCallback = () => {
		submitOrder();
	}

	const scrollToView = (id: string) => {
		requestAnimationFrame(() => {
			const node = document.getElementById(id)
			if (node) {
				node.scrollIntoView({
					behavior: 'smooth'
				})
			}
		})
	}

	const handleSubmit = async () => {
		console.log('currentPlanData?.groupId', currentPlanData?.groupId);
		console.log('coverageData', coverageData);
		console.log('policyholder', policyholder);
		console.log('insuredList', insuredList);
		console.log('periodData', periodData);

		setShowLoading(true);

		// 校验投保人信息
		const checkApplicationResult = await checkApplicationData(policyholder, pageData.storeyProductionData.policyHolderInfo);
		setShowLoading(false);
		if (!checkApplicationResult.flag) {
			scrollToView('order-form');
			return;
		}

		// 校验被保人
		const minCount = pageData.storeyProductionData.insuredInfo.numberLimit.min || 1;
		if (!insuredList || insuredList.length < minCount) {
			ToastUtils.info(`请至少添加${minCount}位被保人`);
			scrollToView('order-form');
			return;
		}

		const findResult = insuredList.find((item) => item.ruleError);
		if (findResult) {
			ToastUtils.info(`请完善被保人信息`);
			scrollToView('order-form');
			return;
		}

		if (insuredList.length > 1) {
			// 校验本人关系
			if (insuredList.filter(item => item.relation === '01').length > 1) {
				ToastUtils.info('最多只能添加一个[本人]关系的被保人');
				scrollToView('order-form');
				return;
			}
			// 校验配偶关系
			if (insuredList.filter(item => item.relation === '04').length > 1) {
				ToastUtils.info('最多只能添加一个[配偶]关系的被保人');
				scrollToView('order-form');
				return;
			}
			// 判断重复（通过证件号码判断）
			const firstInsuredCertNo = insuredList[0].certNo;
			const repeatData = insuredList.filter(item => item.certNo === firstInsuredCertNo);
			if (repeatData?.length > 1) {
				ToastUtils.info(`证件号码[${firstInsuredCertNo}]不能重复添加`);
				scrollToView('order-form');
				return;
			}
		}

		if (trialError) {
			ToastUtils.info(trialError);
			scrollToView('order-form');
			return;
		}

		const healthReport = pageData.storeyProductionData.insuredInfo.healthReport;
		console.log('healthReport', healthReport);
		if (healthReport) {
			setStateData({
				...stateData,
				showHealthNoticeDialog: true
			})
		} else {
			setStateData({
				...stateData,
				showAgreementConfirmDialog: true
			})
		}
	}

	const submitOrder = () => {

		setShowLoading(true);

		const body = getStoreyOrderParams({
			productId,
			packageInfo,
			currentPlanData,
			coverageData,
			checkType: 1,
			policyholder,
			storeyProductionData: pageData.storeyProductionData,
			insuredList,
			premiumData,
			periodData,
			needInvoice: false,
			showDDA: false,
			ddaFlag: '',
			healthAllOk,
			payMode: 'F'
		})

		orderStoreyOrder(body)
		.then((res: any) => {
			setShowLoading(false);
			setStateData({
				...stateData,
				showAgreementConfirmDialog: false
			})
			const {autoUnderwriting, autoUnderwritingParam, ot, payParam, operationInfo} = res;
			console.log('operationInfo', operationInfo)
			console.log('autoUnderwriting', autoUnderwriting)
			console.log('ot', ot)
			if (operationInfo && operationInfo.operation == 'CertManualAuditWaiting') {
				// 非身份证需要审核
				navigate(`/bxsc/product/result?p=${productId}&ot=${ot}&type=CertManualAuditWaiting`, {
					replace: true
				});
			} else {
				if (autoUnderwriting) {
					//智能核保
					console.log('autoUnderwritingParamUrl', autoUnderwritingParam.url);
					window.location.href = autoUnderwritingParam.url;
				} else {
					//判断调用收银台方式：invokeType
					if (payParam && payParam.invokeType === 'GET') {
						window.location.href = payParam.invokeParams;
					}
				}
			}
		})
		.catch((res) => {
			console.log('orderStoreyOrder-catch', res);
			setShowLoading(false);
			ToastUtils.info(res?.err || '请求失败');
		})

	}

	const getInsuredDesc = () => {
		const {numberLimit} = pageData.storeyProductionData.insuredInfo;
		return (numberLimit?.min && numberLimit?.max) ? `（${numberLimit.min}-${numberLimit.max}人）` : '';
	}

	const handleGo2Bxsc = () => {
		LINK_TO_BXSC()
	}

	return (
		<div className='order-form'>

			<InsLoading show={showLoading} />

			<div className='order-form_header'>
				<div className='order-form_form'>

					<div className='v-breadcrumb'>
						<img className='v-breadcrumb-img' src={icon_menu} alt={''} />
						<span className='v-breadcrumb-item' onClick={handleGo2Bxsc}>保险商城</span>
						<img className='v-breadcrumb-img' src={icon_arrow_right} alt={''} />
						<span className='v-breadcrumb-item-active'>保险详情</span>
					</div>

					<OrderHeader
						productId={productId}
						imgData={pageData.storeyPageData.head_img.img}
						planData={pageData.storeyPageData.plan}
						productName={pageData.storeyProductionData.productionName}
						minPremiums={pageData.storeyProductionData.minPremiums?.ori}
						detail_pic={pageData.storeyPageData.detail_pic?.content}
						process={pageData.storeyPageData.process?.content}
						caseData={pageData.storeyPageData.case?.content}
						ques={pageData.storeyPageData.ques?.content}
						onPlanChange={handlePlanChange}
						onOptionalCoverageChange={handleOptionalCoverageChange}
					/>
				</div>
			</div>

			{
				safeguardLimitData &&
				<React.Fragment>

					<div id='order-form' className='order-form_form'>
						<OrderModule title='投保人'>
							<OrderPolicyholder
								productId={productId}
								limitData={pageData.storeyProductionData.policyHolderInfo}
								// safeguardLimitData={safeguardLimitData}
								onDataChange={handlePolicyholderChange}
							/>
						</OrderModule>

						<OrderModule title='被保人' desc={getInsuredDesc()}>
							<OrderInsured
								productId={productId}
								limitData={pageData.storeyProductionData.insuredInfo}
								safeguardLimitData={safeguardLimitData}
								onDataChange={handleInsuredListChange}
							/>
						</OrderModule>

						<OrderModule title='保障期间'>
							<OrderPeriod
								data={pageData.storeyProductionData.period}
								startData={pageData.storeyProductionData.start}
								productId={productId}
								onDataChange={handlePeriodDataChange}
								updateData={periodUpdateData}
							/>
						</OrderModule>
					</div>

					<div className='order-form_footer'>
						<div className='order-form_form'>
							<OrderFooter
								packageInfo={packageInfo}
								agreementData={pageData.storeyPageData?.contract?.content}
								onSubmit={handleSubmit}
								pageCommonConfig={pageCommonConfig}
							/>
						</div>
					</div>

					{/*健康告知弹窗*/}
					{
						pageData.storeyProductionData.insuredInfo.healthReport &&
						<HealthNoticeDialog
							show={stateData.showHealthNoticeDialog}
							onClose={handleHealthNoticeDialogClose}
							onConfirmCallback={handleHealthNoticeCallback}
							data={healthNoticeData}
						/>
					}

					{/*协议确认弹窗*/}
					{
						confirmAgreementData &&
						<AgreementConfirmDialog
							show={stateData.showAgreementConfirmDialog}
							onClose={handleAgreementConfirmDialogClose}
							onConfirmCallback={handleAgreementConfirmDialogCallback}
							data={confirmAgreementData}
						/>
					}
				</React.Fragment>
			}

			<InsureNoticeDialog
				show={stateData.showInsureNoticeDialog}
				onClose={handleInsureNoticeDialogClose}
			/>

		</div>
	);
};

export default memo(OrderForm);
