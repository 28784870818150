import React, { memo, useEffect, useState } from 'react';
import './index.scss';
import InsDialog from '@/order-components/ins-dialog';
import { IDetailPlanItem } from '@/lib/order/types'
import closeImg from '@/static/imgs/order/icon_close.svg'
import DetailHtml from '@/order-components/detail-html'

interface IProps {
	show: boolean,
	onClose: () => void,
	data: IDetailPlanItem[]
}

interface ICoverage {
	name: string,
	money: string,
	detail: string
}

interface ITable {
	name: string,
	list: ICoverage[]
}

const OptionalDialog = (props: IProps) => {

	const {show, onClose, data} = props;

	const [tableState, setTableState] = useState<ITable[]>(null);

	useEffect(() => {
		if (data?.length > 0) {
			let _columns = [];
			let maxLength = 0;
			data.forEach((item) => {
				let _coverages = [];
				if (item.optional) {
					item.optional.forEach((optionalItem) => {
						_coverages.push({
							name: optionalItem.name,
							money: optionalItem.money,
							detail: optionalItem.details
						})
					})
				}
				if (_coverages.length > maxLength) {
					maxLength = _coverages.length;
				}
				_columns.push({
					name: `${item.groupName}（可选保障）`,
					list: _coverages
				})
			})

			// 补全数据
			_columns.forEach((item) => {
				let list = item.list;
				const listLength = list.length;
				if (listLength < maxLength) {
					const makeUpData = new Array(maxLength - listLength).fill({
						name: '',
						money: '',
						detail: ''
					});
					list.push(...makeUpData);
				}
			})

			setTableState(_columns)

		}
	}, [data])

	return (
		<InsDialog
			show={show}
			onClose={onClose}
			location={'right'}
		>
			<div className='optional-dialog'>
				<div className='optional-dialog_header'>
					<span className='v-text-title'>可选保障详情</span>
					<img className='v-img-close' src={closeImg} onClick={onClose} />
				</div>
				<div className='optional-dialog_line' />
				<div className='optional-dialog_body'>
					<div className='plan-detail'>

						{
							tableState &&
							<div className='v-table'>
								<div className='v-table-header'>
									{
										tableState.map((item, index) => {
											return (
												<span key={`row-${index}`} className='v-table-row-item'>{item.name}</span>
											)
										})
									}
								</div>
								<div className='v-table-row-wrap'>
									{
										tableState.map((item, index) => {
											return (
												<div key={`column-${index}`} className='v-table-column'>
													{
														item.list.map((coverage, coverageIndex) => {
															return (
																<div key={`coverage-${coverageIndex}`} className='v-coverage-wrap'>
																	{/*{*/}
																	{/*coverage.name &&*/}
																	{/*<Popover*/}
																	{/*content={coverage.detail ? <DetailHtml html={coverage.detail} /> : ''}*/}
																	{/*title=''*/}
																	{/*placement='bottom'*/}
																	{/*trigger='hover'*/}
																	{/*>*/}
																	{/*<div className='v-popover' />*/}
																	{/*</Popover>*/}
																	{/*}*/}
																	{
																		coverage.name &&
																		<React.Fragment>
																			<DetailHtml className='v-coverage-wrap_left' html={coverage.name} />
																			<span className='v-coverage-wrap_right'>{coverage.money}</span>
																		</React.Fragment>
																	}
																</div>
															)
														})
													}
												</div>
											)
										})
									}
								</div>
							</div>
						}

					</div>
				</div>
			</div>
		</InsDialog>
	)
}

export default memo(OptionalDialog)
