import React, { useEffect, memo } from 'react'
import './index.scss'

import classNames from 'classnames'

interface IProps {
	className?: string
	value?: string,
	onClick: () => void,
	disabled?: boolean,
	mode?: 'normal' | 'plain'
	delay?: number,
	id?: string,
	children?: React.ReactNode
}

let timer;

const InsButton = (props: IProps) => {

	const {className, value, onClick, disabled = false, mode = 'normal', delay = 200, id, children} = props;

	useEffect(() => {
		return () => {
			stopTimer();
		}
	}, [])

	const handleClick = () => {
		if (!disabled) {
			const timeout = delay ? Math.max(delay, 200) : 200;
			stopTimer();
			timer = setTimeout(() => {
				onClick();
			}, timeout)
		}
	}

	const stopTimer = () => {
		try {
			if (timer) {
				clearTimeout(timer);
				timer = null;
			}
		} catch (e) {

		}
	}

	return (
		<div
			id={id || 'ins-button'}
			className={classNames('ins-button',
				{
					[`ins-button_disabled-${mode}`]: disabled,
					[`ins-button_enable-${mode}`]: !disabled,
					[`ins-button_v-text-${mode}`]: mode
				}, className)}
			onClick={disabled ? undefined : handleClick}
		>
			{value || children}
		</div>
	)

}

export default memo(InsButton);
