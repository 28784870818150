import React, { useEffect, useState} from 'react';
import './index.scss';
import BuyBtn from '../BuyBtn'
import classNames from 'classnames'
import arrow_icon from 'static/imgs/hot_arrow.svg'
import left_arrow from 'static/imgs/left_arrow.svg'
import { useNavigate } from 'react-router-dom';
import {IMGURL} from 'lib/config'
import LazyImg from 'common/components/LazyImg'
import QueueAnim from 'rc-queue-anim';
import {OverPack } from "rc-scroll-anim";
import { LINK_TO_BXSC} from "lib/utils";

interface IProduct{
  display : {
    id : string;
    name : string;
    icon : string;
    feature : string;
    feature1 : string;
    premium : string;
    premium2 : string;
  },
  link : {
    type : string;
    link : string;
    linkParam : string;
  }
}
interface IGroup{
  group : string;
  icon : string;
  products : IProduct[]
}

interface IProps {
  productList : {
    title : string;
    groups : IGroup[]
  }
}
  
const HotProducts = (props: IProps) => {
  const navigate = useNavigate();
  const [hotpro,setHotPro] = useState(false)
  const {title,groups} = props.productList;
  const [activeIndex,setActiveIndex] = useState(0);
  const changePId = (index:number)=>{
    setActiveIndex(index);
  }
  useEffect(()=>{
    setTimeout(() => {
      setHotPro(true)
    }, 1000);
  },[])

  return (
    <div className='hot-products-wrap'>
      
      <div className="inner">
     
        <div className="nav flex-between" key="nav">
          <div className="title">{title}</div>
          <div className="more" onClick={()=>{LINK_TO_BXSC()}}>
            <span>查看更多</span>
            <img src={left_arrow} alt="箭头" className='more_icon'/>
          </div>
        </div>
        <OverPack 
          playScale={0.10}
          // @ts-ignore
          always = {false}
          appear = {hotpro}
        >
        <QueueAnim key="queue"
          leaveReverse
          type={'bottom'}
          delay={100}
          duration={600}
        >
        <div className="products flex-between-start" key="products">
        
          <div className="left" key="left">
            {
              groups.map((item,index)=>{
                return <div className={classNames('group_name noselect flex-center',activeIndex === index ? 'active' : '')}  key={index} onClick={()=>changePId(index)} onMouseEnter={()=>changePId(index)}>
                  <p className='title'>{item.group}</p>
                  {
                    activeIndex === index && <img src={`${IMGURL}${item.icon}`} alt="icon" className='group_name_icon'/>
                  }
                  {
                    activeIndex === index && <img src={arrow_icon} alt="icon" className='arrow_icon'/>
                  }
                </div>
              })
            }
          </div>
            <div className="right flex-between" key="right">
            {
              groups[activeIndex].products.map((product,index)=>{
                return <div  className={classNames('product_info_wrap ',index < groups.length/2 ? 'add-margin-bottom' : '')}  key={index}>
                  <LazyImg src={`${IMGURL}${product.display.icon}`} className='product_img'/>
                  <section className='product_info_desc'>
                    <h2>{product.display.name}</h2>
                    <p className='sub_title'>{product.display.feature}</p>
                    <div className="flex-between">
                      <p>
                        <span className='price'>{product.display.premium }</span>
                        <span className='price_desc'>{ product.display.premium2}</span>
                      </p>
                      <BuyBtn link={product.link}/>
                    </div>
                  </section>
                </div>
              })
            }
          </div>
        </div>
        </QueueAnim>
      </OverPack>
      </div>
      
    </div>
  );
};

export default HotProducts;
