import React, { memo, useState } from 'react';
import './index.scss';
import arrow_right_icon from '@/static/imgs/order/arrow_right_icon_gray.svg'
import { IDetailAgreementItem, IProdFactMaterial } from '@/lib/order/types'
import InsDialog from '@/order-components/ins-dialog';
import InsDialogHeader from '@/order-components/ins-dialog-header'
import ImgAgreement from '../img'

interface IProps {
	show: boolean,
	onClose: () => void,
	data: IDetailAgreementItem
}

const FactoryAgreementListDialog = (props: IProps) => {

	const {show, onClose, data} = props;

	const [imgAgreementState, setImgAgreementState] = useState({
		show: false,
		data: null
	});

	const handleItemClick = (item: IProdFactMaterial) => {
		setImgAgreementState({
			show: true,
			data: item
		})
	}

	const handleImgAgreementDialogClose = () => {
		setImgAgreementState({
			show: false,
			data: null
		})
	}

	return (
		<React.Fragment>
			<InsDialog
				show={show}
				onClose={onClose}
				location={'center'}
			>
				<div className='factory-agreement-list-dialog'>
					<InsDialogHeader
						title={data ? data.name : '保险条款'}
						onClose={onClose}
					/>
					<div className='factory-agreement-list-dialog__body'>
						{
							show &&
							data?.prodFactMaterial &&
							<React.Fragment>
								{
									data.prodFactMaterial.length > 1 ?
										<React.Fragment>
											{
												data.prodFactMaterial.map((item, index) => {
													return (
														<React.Fragment key={index}>
															<div
																className='factory-agreement-list-dialog__body-item'
																onClick={() => handleItemClick(item)}
															>
															<span className='factory-agreement-list-dialog__body-item-name'>
																{item.planName}
															</span>
																<img
																	className='factory-agreement-list-dialog__body-item-arrow' src={arrow_right_icon}
																	alt={''}
																/>
															</div>
														</React.Fragment>
													)
												})
											}
										</React.Fragment>
										:
										<React.Fragment>
											<ImgAgreement data={data.prodFactMaterial[0].imgPath} />
										</React.Fragment>
								}
							</React.Fragment>
						}
					</div>
				</div>
			</InsDialog>

			<InsDialog
				show={imgAgreementState.show}
				onClose={handleImgAgreementDialogClose}
				location={'center'}
			>
				<div className='factory-agreement-list-dialog'>
					<InsDialogHeader
						title={imgAgreementState.data?.planName || ''}
						onClose={handleImgAgreementDialogClose}
					/>
					<div className='factory-agreement-list-dialog__body'>
						{
							imgAgreementState.data &&
							<ImgAgreement data={imgAgreementState.data.imgPath} />
						}
					</div>
				</div>
			</InsDialog>

		</React.Fragment>
	)
}

export default memo(FactoryAgreementListDialog)
