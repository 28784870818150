let insGlobalData: any = {};

const checkGlobalData = () => {
	if (!insGlobalData) {
		insGlobalData = {};
	}
}

const clearGlobalData = () => {
	if (insGlobalData) {
		insGlobalData = null;
	}
}

const setGlobalData = (key: string, data: any) => {
	try {
		checkGlobalData();
		insGlobalData[key] = data;
		// console.log('insGlobalData', insGlobalData);
	} catch (e) {

	}
}

const getGlobalData = (key: string): any => {
	try {
		checkGlobalData();
		const data = insGlobalData[key];
		console.log(`insGlobalData-${key}`, data);
		return data;
	} catch (e) {
		return null;
	}
}

export {
	setGlobalData,
	getGlobalData,
	clearGlobalData
}
