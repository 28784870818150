import { get, post } from "./request";

// const PATH_API = '/websiteApi';
// const PATH_API = {
//   dev: "//api-work-test.jdazcn.com/websiteApi",
//   test: "//api-work-test.jdazcn.com/websiteApi",
//   pre: "//api-work-pre.jdazcn.com/websiteApi",
//   prod: "//www.jdallianz.com/websiteApi",
//   //@ts-ignore
// }[process.env.REACT_APP_MODE];

const PATH_API = {
  dev: "//xgw-test.jdazcn.com/websiteApi",
  test: "//xgw-test.jdazcn.com/websiteApi",
  pre: "//xgw-pre.jdazcn.com/websiteApi",
  prod: "//www.jdallianz.com/websiteApi",
  //@ts-ignore
}[process.env.REACT_APP_MODE];

export const fetchFileList = (params: any) => {
  return post({
    url: `${PATH_API}/website/web/file/queryForList`,
    body: params,
  });
};

export const fetchFileGroupList = (params: any) => {
  return post({
    url: `${PATH_API}/website/web/file/queryForGroup`,
    body: params,
  });
};

export const fetchCampaignList = () => {
  return get({
    url: `${PATH_API}/website/web/campaign/query/list`,
    params: {},
  });
};

export const fetchNewsJson = (url: any) => {
  return get({
    url: `${PATH_API}${url}`,
    params: {},
    isJson: true,
  });
};

export const prodictQuery = (url: any) => {
  return get({
    url: `${PATH_API}${url}`,
    params: {},
    isJson: true,
  });
};

export const intermediaryAgenciesQuery = (params: any) => {
  return post({
    url: `${PATH_API}/website/web/intermediaryAgencies/query`,
    body: params,
  });
};

//总经理接待日查询接口
export const listOpenDay = (params: any) => {
  return post({
    url: `${PATH_API}/website/web/openDay/listOpenDay`,
    body: params,
  });
};

//招标公告查询
export const biddingNoticeQuery = (params: any) => {
  return post({
    url: `${PATH_API}/website/web/biddingNotice/query`,
    body: params,
  });
};

//招标公告id查询
export const queryById = (params: any) => {
  return post({
    url: `${PATH_API}/website/web/biddingNotice/queryById?noticeId=${params.noticeId}`,
    body: params,
  });
};

//JSON转换
function storeETag(url, etag) {
  const etagMap = JSON.parse(localStorage.getItem("jsonETags") || "{}");
  etagMap[url] = etag;
  localStorage.setItem("jsonETags", JSON.stringify(etagMap));
}

function getStoredETag(url) {
  const etagMap = JSON.parse(localStorage.getItem("jsonETags") || "{}");
  return etagMap[url];
}

function fetchJSON(url) {
  return fetch(url, {
    headers: {
      "Cache-Control": "no-cache", // 不允许缓存
    },
  })
    .then((response) => {
      console.log(response);
      if (response.ok) {
        // 存储ETag
        const etag = response.headers.get("Etag");
        console.log(etag);
        if (etag) {
          storeETag(url, etag);
        }
        return response.json();
      } else {
        throw new Error(`Failed to fetch JSON: ${response.status}`);
      }
    })
    .then((data) => {
      // 存储解析后的 JSON 数据到本地存储
      localStorage.setItem(`cachedJson_${url}`, JSON.stringify(data));
      return data; // 继续将数据传递给后续的处理函数
    })
    .catch((error) => {
      console.error("Error fetching JSON:", error);
    });
}

function getFromCache(url) {
  const cachedData = localStorage.getItem(`cachedJson_${url}`);
  if (cachedData) {
    try {
      return JSON.parse(cachedData);
    } catch (error) {
      console.warn("Failed to parse cached JSON data:", error);
      return fetchJSON(url); // 按常规方式请求
    }
  } else {
    return fetchJSON(url); // 按常规方式请求
  }
}

// 后续请求时带上ETag
export function fetchJSONWithETag(url) {
  const storedETag = getStoredETag(`${PATH_API}${url}`);
  if (storedETag) {
    return fetch(`${PATH_API}${url}`, {
      headers: {
        "If-None-Match": storedETag,
      },
    })
      .then((response) => {
        if (response.status === 304) {
          // 从缓存中获取数据，这里假设您已经实现了缓存机制
          return getFromCache(`${PATH_API}${url}`);
        } else {
          return response.json();
        }
      })
      .catch((error) => {
        return fetchJSON(`${PATH_API}${url}`); // 按常规方式请求
      });
  } else {
    return fetchJSON(`${PATH_API}${url}`); // 若无ETag，按常规方式请求
  }
}

//公司治理查询接口
export const companyGovernQuery = (params: any) => {
  return post({
    url: `${PATH_API}/website/web/companyGovern/query`,
    body: params,
  });
};
