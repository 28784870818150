import React, { useEffect, useState } from "react";
import "./index.scss";
import { useLocation, useNavigate } from "react-router-dom";
import nav_arrow_r from "static/imgs/arrow_right.svg";
import nav_tuozhuai from "static/imgs/tuozhuai.svg";
import { queryById } from "lib/websiteApi";

const NewsPreview: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [content, setContent] = useState<any>({});

  const goBack = () => {
    const backPath = "/bit-document";
    navigate(backPath);
  };

  useEffect(() => {
    document.title = "京东安联保险-资讯详情";
    window.scrollTo(0, 0);
    if (location) {
      initFn(location);
    }
  }, []);

  //截取noticeId
  function getNoticeId(search) {
    const params = new URLSearchParams(search);
    const noticeId = params.get("noticeId");
    return noticeId;
  }

  const initFn = async (location: any) => {
    // //本地存储
    // var getVal = localStorage.getItem("tableCell");
    // console.log(JSON.parse(getVal));
    // console.log(getNoticeId(location.search));
    const res: any = await queryById({
      noticeId: getNoticeId(location.search),
    });
    setContent(res);
  };

  return (
    content && (
      <div className="news_preview_wrap">
        <div className="news_nav_wrap">
          <img src={nav_tuozhuai} alt="icon" />
          <div className="news_nav_back" onClick={goBack}>
            招标公告
          </div>
          <img src={nav_arrow_r} alt="icon" />
          <div className="news_nav_title">{content.noticeName}</div>
        </div>
        <div className="news_body">
          <div className="news_body_title">
            <span>{content.biddingName}</span>
          </div>
          {content.noticeHeadImg && (
            <div className="news_body_img">
              <img src={content.noticeHeadImg} alt="" />
            </div>
          )}
          <div
            dangerouslySetInnerHTML={{ __html: content.noticeCont ?? "" }}
          ></div>
        </div>
      </div>
    )
  );
};
export default NewsPreview;
