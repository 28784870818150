import React, { useEffect, useState, useRef } from 'react';
import './index.scss';
import { useLocation } from 'react-router-dom';
import { OLD_WEBSITE_PATH } from 'lib/config';

const baseUrl = `${OLD_WEBSITE_PATH}/query.html#/`;

// 本地联调地址
// const baseUrl = 'http://localhost/query.html#/';

const QueryPage: React.FC = () => {
  const location = useLocation();
  const iframeRef = useRef();
  const [url, setUrl] = useState('');
  useEffect(() => {
    document.title = '京东安联保险-保险服务';
    window.scrollTo(0, 0);
    if (location) {
      // @ts-ignore
      const { state = {} } = location;
      if (state) {
        console.log(baseUrl, state, 777777)
        // @ts-ignore
        const { path } = state;
        setUrl(baseUrl + path);
      }
    }
  }, [location]);

  return (
    <div className='query_iframe_wrap'>
      <iframe
        id='ifrma2'
        title='queryPage'
        style={{
          width: '100%',
          minHeight: '870px',
          overflow: 'scroll',
        }}
        src={url}
        // scrolling='no'
        frameBorder='0'
        ref={iframeRef}
      />
    </div>
  );
};
export default QueryPage;
