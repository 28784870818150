import React, { useEffect, useState } from 'react';
import './index.scss';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import Achina from './Achina/index';
import Allianz from './Allianz/index';
import CompInfo from './CompInfo/index';
import Jd from './Jd/index';
import Milepost from './Milepost/index';
import ContactUs from './ContactUs/index';
import about_banner from 'static/imgs/about_banner2.png';
import about_font from 'static/imgs/about_font.png';

let navMenus = [
  {
    name: '公司介绍',
    id: 'comp-info',
  },
  {
    name: '公司里程碑',
    id: 'milepost',
  },
  {
    name: '安联集团',
    id: 'allianz',
  },
  {
    name: '京东集团',
    id: 'jd',
  },
  {
    name: '安联在中国',
    id: 'achina',
  },
  {
    name: '联系我们',
    id: 'contact-us',
  },
];

const AboutUs: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const [, , path1] = pathname.split('/');

  useEffect(() => {
    document.title = '京东安联保险-关于我们';
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    navMenus.forEach((item, index) => {
      if (item.id === path1) {
        setActiveIndex(index);
      }
    });
  }, [path1]);

  const goNav = (routePath: string, index: number) => {
    setActiveIndex(index);
    navigate(routePath);
  };

  return (
    <div className='about-us-wrap page-content-wrap'>
      <div className='banner_wrap'>
        <img src={about_banner} alt='图片' />
        <div className='font_wrap'>
          <img src={about_font} alt='图片文字' />
        </div>
      </div>

      <div className='about-us-content'>
        <div className='qa_nav_wrap'>
          {navMenus.map((item, index) => (
            <div
              className={activeIndex === index ? 'qa_nav active' : 'qa_nav'}
              onClick={() => goNav(item.id, index)}
              key={index}
            >
              {item.name}
            </div>
          ))}
        </div>
        <div className='right'>
          <Routes>
            <Route path='/comp-info' element={<CompInfo />} />
            <Route path='/milepost' element={<Milepost />} />
            <Route path='/allianz' element={<Allianz />} />
            <Route path='/jd' element={<Jd />} />
            <Route path='/achina' element={<Achina />} />
            <Route path='/contact-us' element={<ContactUs />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
