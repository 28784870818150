import bandan_yanzhen from "static/imgs/bandan_yanzhen.png";
import baodan_xz from "static/imgs/baodan_xz.png";
import baodan_sbcx from "static/imgs/baodan_sbcx.png";
import baodan_zxsb from "static/imgs/baodan_zxsb.png";
import bandan_lipei from "static/imgs/bandan_lipei.png";
import bandan_query from "static/imgs/bandan_query.png";
import policy_car_query from "static/imgs/policy_car_query.png";
import policy_query from "static/imgs/policy_query.png";
import lipei_query from "static/imgs/lipei_query.png";
import policy_yanzhen from "static/imgs/policy_yanzhen.png";
import online_shenbao from "static/imgs/online_shenbao.png";
import shenbao_query from "static/imgs/shenbao_query.png";

export const data1 = [
  {
    list: [
      {
        id: "1",
        title: "保单查询",
        path: "query/policy",
        img: policy_query,
      },
      {
        id: "2",
        title: "理赔查询",
        path: "query/claim",
        img: lipei_query,
      },
      {
        id: "3",
        title: "保单验真",
        path: "policy/policyVerify",
        img: policy_yanzhen,
      },
    ],
  },
  {
    list: [
      {
        id: "1",
        title: "保单查询、下载",
        path: "query/carPolicy",
        img: policy_car_query,
      },
      {
        id: "2",
        title: "理赔查询",
        path: "query/carClaim",
        img: lipei_query,
      },
      {
        id: "3",
        title: "保单验真",
        // url: "http://eservice.ciitc.com.cn/ePolicy/validata",
        path: "policy/policyCarVerify",
        img: policy_yanzhen,
      },
      // {
      //   id: '4',
      //   title: '保单下载',
      //   url: 'http://eservice.ciitc.com.cn/ePolicy/download',
      //   img: baodan_xz,
      // },
    ],
  },
  {
    list: [
      {
        id: "1",
        title: "在线申报",
        url: "https://eservice.allianz.cn/pls/apex/f?p=118:199:1018273722314819:P10_PAGE_POLICY:NO::P0_LANG:zh-cn",
        img: online_shenbao,
      },
      {
        id: "2",
        title: "申报查询",
        url: "https://eservice.allianz.cn/pls/apex/f?p=118:22:1018273722314819:P10_PAGE_MOTOR:NO::P0_LANG:zh-cn",
        img: shenbao_query,
      },
    ],
  },
];
