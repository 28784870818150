import EventEmitter from 'event-emitter';

// 创建一个全局的事件管理器对象
const eventBus = new EventEmitter();

try {
	eventBus.setMaxListeners(30);
} catch (e) {

}

const EventUtils = {

	on(eventName: string, listener) {
		try {
			if (eventBus) {
				eventBus.on(eventName, listener);
			}
		} catch (e) {

		}
	},

	off(eventName: string, listener) {
		try {
			if (eventBus) {
				eventBus.off(eventName, listener);
			}
		} catch (e) {

		}
	},

	emit(eventName: string, data: any) {
		try {
			if (eventBus) {
				eventBus.emit(eventName, data);
			}
		} catch (e) {

		}
	}

};

export default EventUtils;
