import { useState, useEffect, DependencyList } from 'react'

/**
 * 防抖
 * @param data：频繁变化的数据
 * @param delay：延迟时间，单位ms
 */
const useDebounceList = (data: DependencyList, delay: number) => {

	const [debounceData, setDebounceData] = useState(data);

	useEffect(() => {
		let timer = setTimeout(() => {
			setDebounceData(data);
		}, delay)
		return () => {
			clearTimeout(timer);
		}
	}, data)

	return debounceData;
}

export default useDebounceList;
