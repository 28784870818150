import React, { memo } from 'react';
import './index.scss';
import { IDetailAgreementItem } from '@/lib/order/types'
import InsDialog from '@/order-components/ins-dialog';
import InsDialogHeader from '@/order-components/ins-dialog-header'
import CommonAgreement from '../common-agreement';

interface IProps {
	show: boolean,
	onClose: () => void,
	data: IDetailAgreementItem | null
}

const AgreementDialog = (props: IProps) => {

	const {show, onClose, data} = props;

	return (
		<InsDialog
			show={show}
			onClose={onClose}
			location={'center'}
		>
			<div className='agreement-dialog'>
				<InsDialogHeader
					title={data?.name || ''}
					onClose={onClose}
				/>
				<CommonAgreement data={data} />
			</div>
		</InsDialog>
	)
}

export default memo(AgreementDialog)
