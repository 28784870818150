import React, { useEffect } from 'react';
import './index.scss';
import { Routes, Route } from 'react-router-dom';
import Nav from './components/nav';
import CompanyProfile from './CompanyProfile/index';
import CompanyGovernance from './CompanyGovernance/index';
import ShortInsureance from './ShortInsurance/index';
import Production from './Production/index';
import NewProduction from './NewProducts/index';
import Fund from './Fund/index';
import InternetInsurance from './InternetInsurance/index';
import ConnectedInfo from './ConnectedInfo/index';
import MajorIssues from './MajorIssues/index';
import YearInfo from './YearInfo/index';
import StockRight from './StockRight/index';
import TrafficInsurance from './TrafficInsurance/index';
import Solvency from './Solvency/index';
import OhterInfo from './OtherInfo/index';
import AccidentInsurance from './accidentInsurance/index';
import PersonInsurance from './PersonInsurance'

let navMenus = [
  {
    name: '基本信息',
    id: 'base-info',
    isOpen: true,
    subMenu: [
      {
        name: '公司概况',
        id: 'company-profile',
      },
      {
        name: '公司治理概要',
        id: 'company-governance',
      },
      {
        name: '产品基本信息',
        id: 'production',
      },
    ],
  },
  {
    name: '年度信息',
    id: 'year-info',
  },
  {
    name: '重大事项信息',
    id: 'major-issues',
  },
  {
    name: '专项信息',
    id: 'special-info',
    isOpen: true,
    subMenu: [
      {
        name: '关联交易',
        id: 'connected-info',
      },
      {
        name: '股东股权',
        id: 'stock-right',
      },
      {
        name: '偿付能力',
        id: 'solvency',
      },
      {
        name: '互联网保险',
        id: 'internet-insurance',
      },
      {
        name: '新型产品',
        id: 'new-products',
      },
      {
        name: '资金运用',
        id: 'fund',
      },
      {
        name: '交强险',
        id: 'traffic-insurance',
      },
      {
        name: '短期健康保险',
        id: 'short-insurance',
      },
      {
        name: '个人意外险业务',
        id: 'accident-insurance',
      },
      {
        name: '个人人身险信息披露',
        id: 'person-insurance',
      },
      {
        name: '其他披露事项',
        id: 'other-info',
      },
    ],
  },
];

const PublishInfo: React.FC = () => {
  useEffect(() => {
    document.title = '京东安联保险-公开信息披露';
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='public-info-wrap'>
      <div className='left'>
        <Nav data={navMenus} />
      </div>
      <div className='right'>
        <Routes>
          <Route
            path='/base-info/company-profile'
            element={<CompanyProfile />}
          />
          <Route
            path='/base-info/company-governance'
            element={<CompanyGovernance />}
          />
          <Route path='/base-info/production' element={<Production />} />
          <Route path='/year-info' element={<YearInfo />} />
          <Route path='/major-issues' element={<MajorIssues />} />
          <Route
            path='/special-info/connected-info'
            element={<ConnectedInfo />}
          />
          <Route
            path='/special-info/person-insurance'
            element={<PersonInsurance />}
          />
          <Route
            path='/special-info/internet-insurance'
            element={<InternetInsurance />}
          />
          <Route path='/special-info/solvency' element={<Solvency />} />
          <Route
            path='/special-info/traffic-insurance'
            element={<TrafficInsurance />}
          />
          <Route path='/special-info/stock-right' element={<StockRight />} />
          <Route path='/special-info/fund' element={<Fund />} />
          <Route
            path='/special-info/new-products'
            element={<NewProduction />}
          />
          <Route
            path='/special-info/short-insurance'
            element={<ShortInsureance />}
          />
          <Route path='/special-info/accident-insurance' element={<AccidentInsurance />} />
          <Route path='/special-info/other-info' element={<OhterInfo />} />
        </Routes>
      </div>
    </div>
  );
};

export default PublishInfo;
