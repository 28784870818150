import { message } from 'antd';

let timeoutId = null;

const ToastUtils = {

	info(msg: string, duration: number = 2000) {
		if (!msg?.trim()) return;
		this.showJdazToast(msg, duration)
	},

	showJdazToast(msg: string, duration: number = 2000) {
		if (!msg) return;
		try {
			let timeout = duration;
			if (msg.length > 20) {
				timeout = duration * 2;
			}
			let toastDom = document.getElementById('jdaz-toast');
			let toastTextDom = document.getElementById('jdaz-toast-text');
			if (toastDom && toastTextDom) {
				if (timeoutId) {
					clearTimeout(timeoutId);
					timeoutId = null;
				}
				toastTextDom.innerText = msg;
				toastDom.style.display = 'flex';
				timeoutId = setTimeout(() => {
					toastDom.style.display = 'none';
					toastTextDom.innerText = '';
					clearTimeout(timeoutId);
					timeoutId = null;
				}, timeout)
			} else {
				message.error(msg);
			}
		} catch (e) {
			console.error('showJdazToast', e);
			message.error(msg);
		}
	}

};

export default ToastUtils;
