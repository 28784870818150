import React, { memo } from 'react';
import './index.scss';
import { Spin } from 'antd';

interface IProps {
	show: boolean
}

const InsLoading = (props: IProps) => {

	const {show} = props;

	return (
		<Spin spinning={show} size='large' className='ins-loading' style={{display: show ? 'flex' : 'none'}} />
	);
};

export default memo(InsLoading);
