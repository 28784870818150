import React, { useEffect } from "react";
import "./index.scss";
import { Routes, Route } from "react-router-dom";
import Nav from "../public-info/components/nav";
import ServePromise from "./ServePromise/index";
import NewsInfo from "./NewsInfo/index";

let navMenus = [
  {
    name: "消费者权益保护",
    id: "xb",
    isOpen: true,
    subMenu: [
      {
        name: "新市民金融服务宣传月",
        id: "05",
      },
      {
        name: "金融教育",
        id: "02",
      },
      {
        name: "以案说险",
        id: "03",
      },
      {
        name: "风险提示",
        id: "04",
      },
      {
        name: "销售承诺和服务承诺",
        id: "00",
      },
      {
        name: "普惠金融推进月",
        id: "06",
      },
    ],
  },
];

const PublishInfo: React.FC = () => {
  useEffect(() => {
    document.title = "京东安联保险-安全与教育";
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="safety-info-wrap page-content-wrap">
      <div className="left">
        <Nav data={navMenus} />
      </div>
      <div className="right">
        <Routes>
          <Route path="/xb/02" element={<NewsInfo />} />
          <Route path="/xb/03" element={<NewsInfo />} />
          <Route path="/xb/04" element={<NewsInfo />} />
          <Route path="/xb/05" element={<NewsInfo />} />
          <Route path="/xb/00" element={<ServePromise />} />
          <Route path="/xb/06" element={<NewsInfo />} />
          <Route path="*" element={<NewsInfo />} />
        </Routes>
      </div>
    </div>
  );
};

export default PublishInfo;
