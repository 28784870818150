import React, { useEffect, useState } from "react";
import "./index.scss";
// import { data1, data2, data3, data4, data5 } from "./staticData";
import { listOpenDay } from "lib/websiteApi";
const OpenDay: React.FC = () => {
  const [title, setTitle] = useState<any>(""); //标题
  const [data1, setData1] = useState<any>([]);
  const [data2, setData2] = useState<any>([]);
  const [data3, setData3] = useState<any>([]);
  const [data4, setData4] = useState<any>([]);
  const [data5, setData5] = useState<any>([]);
  const [status, setStatus] = useState<any>(false); //接口请求状态

  useEffect(() => {
    document.title = "京东安联保险-总经理接待日";
    window.scrollTo(0, 0);
    listOpenDay({})
      .then((res: any) => {
        setTitle(res.topStr.split(".")[0]); //截取标题
        const arr = [];
        const arr2 = [];
        const arr3 = [];
        const arr4 = [];
        const arr5 = [];

        res.openDayDetailList.map((item: any) => {
          switch (item.detailOrder) {
            case 1:
              arr.push(item);
              setData1(arr);
              break;
            case 2:
              arr2.push(item);
              setData2(arr2);
              break;
            case 3:
              arr3.push(item);
              setData3(arr3);
              break;
            case 4:
              arr4.push(item);
              setData4(arr4);
              break;
            case 5:
              arr5.push(item);
              setData5(arr5);
              break;
            default:
              break;
          }
        });
      })
      .finally(() => {
        setStatus(true);
      });
  }, []);

  return (
    <div className="open-day-wrap">
      {status && (
        <div className="open-day-container">
          <div className="page-title">
            {/* 2023年京东安联财产保险有限公司总经理接待日安排 */}
            {title}
          </div>
          <div className="line"></div>
          <div className="table-title">
            {/* 2023年京东安联财产保险有限公司北京分公司总经理接待日安排表 */}
            {data1[0]?.detailDesc}
          </div>
          <table>
            <thead>
              <tr>
                <th>序号</th>
                <th>机构名称</th>
                <th>时间</th>
                <th>接访人</th>
                <th>地点（具体地点）</th>
                <th>预约联系人</th>
                <th>预约联系电话</th>
              </tr>
            </thead>
            <tbody>
              {data1.map((item, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>{item.organizationName}</td>
                  <td>{item.accessTime}</td>
                  <td>{item.accessPeople}</td>
                  <td>{item.accessAddr}</td>
                  <td>{item.subscribePeople}</td>
                  <td>{item.subscribePhone}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <p className="table-title">
            {/* 2023年京东安联财产保险有限公司上海分公司总经理接待日安排表 */}
            {data2[0]?.detailDesc}
          </p>
          <table>
            <thead>
              <tr>
                <th>序号</th>
                <th>机构名称</th>
                <th>时间</th>
                <th>接访人</th>
                <th>地点（具体地点）</th>
                <th>预约联系人</th>
                <th>预约联系电话</th>
              </tr>
            </thead>
            <tbody>
              {data2.map((item, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>{item.organizationName}</td>
                  <td>{item.accessTime}</td>
                  <td>{item.accessPeople}</td>
                  <td>{item.accessAddr}</td>
                  <td>{item.subscribePeople}</td>
                  <td>{item.subscribePhone}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <p className="table-title">
            {/* 2023年京东安联财产保险有限公司广东分公司总经理接待日安排表 */}
            {data3[0]?.detailDesc}
          </p>
          <table>
            <thead>
              <tr>
                <th>序号</th>
                <th>机构名称</th>
                <th>时间</th>
                <th>接访人</th>
                <th>地点（具体地点）</th>
                <th>预约联系人</th>
                <th>预约联系电话</th>
              </tr>
            </thead>
            <tbody>
              {data3.map((item, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>{item.organizationName}</td>
                  <td>{item.accessTime}</td>
                  <td>{item.accessPeople}</td>
                  <td>{item.accessAddr}</td>
                  <td>{item.subscribePeople}</td>
                  <td>{item.subscribePhone}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <p className="table-title">
            {/* 2023年京东安联财产保险有限公司四川分公司总经理接待日安排表 */}
            {data4[0]?.detailDesc}
          </p>
          <table>
            <thead>
              <tr>
                <th>序号</th>
                <th>机构名称</th>
                <th>时间</th>
                <th>接访人</th>
                <th>地点（具体地点）</th>
                <th>预约联系人</th>
                <th>预约联系电话</th>
              </tr>
            </thead>
            <tbody>
              {data4.map((item, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>{item.organizationName}</td>
                  <td>{item.accessTime}</td>
                  <td>{item.accessPeople}</td>
                  <td>{item.accessAddr}</td>
                  <td>{item.subscribePeople}</td>
                  <td>{item.subscribePhone}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <p className="table-title">
            {/* 2023年京东安联财产保险有限公司深圳营销服务部总经理接待日安排表 */}
            {data5[0]?.detailDesc}
          </p>
          <table>
            <thead>
              <tr>
                <th>序号</th>
                <th>机构名称</th>
                <th>时间</th>
                <th>接访人</th>
                <th>地点（具体地点）</th>
                <th>预约联系人</th>
                <th>预约联系电话</th>
              </tr>
            </thead>
            <tbody>
              {console.log(data5)}
              {data5.map((item, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>{item.organizationName}</td>
                  <td>{item.accessTime}</td>
                  <td>{item.accessPeople}</td>
                  <td className="table-td">{item.accessAddr}</td>
                  <td>{item.subscribePeople}</td>
                  <td>{item.subscribePhone}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default OpenDay;
